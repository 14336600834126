import React from 'react';
import Image from 'react-bootstrap/Image';
import { ThemeContext } from '../../../App';
import styles from './Logo.module.css';
import Icon from '../Icon';

interface Props {
  href: string;
}

export function Logo({ href }: Props) {
  const theme = React.useContext(ThemeContext);

  return (
    <nav className={styles.nav_container}>
      <a href={href} className={styles.ref_container}>
        { theme.images.logo && (
          <Image src={theme.images.logo} alt="logo" className={styles.logo} />
        )}
        { !theme.images.logo && (
          <Icon
          icon={'house'}
          color={theme.primaryText}
          size={30}
          />
        )}
      </a>
    </nav>
  );
}
