export function calcStartTime(
  beTimeStamp: string | undefined | null,
  totalDuration: number
): number {
  if (beTimeStamp === undefined || beTimeStamp === null) {
    return totalDuration;
  }

  const userDate = new Date();
  const userTimestamp: number = userDate.getTime();

  const beDate = new Date(beTimeStamp);
  const beTimestamp: number = beDate.getTime();

  const passedSecs: number = (userTimestamp - beTimestamp) / 1000;

  const final: number = Math.round(totalDuration - passedSecs);
  if (final <= 0) {
    return 0;
  }
  if (final > totalDuration) {
    return totalDuration;
  }
  return final;
}
