import React from 'react';
import { ThemeContext } from '../../../../App';
import { companyThemes, mainColors } from '../../../../utils';
import styles from './TimerProgressBar.module.css';

interface Props {
  color?: string;
  status?: number;
  label?: string;
  sectionName?: string;
  variant?: 'small' | 'big';
}

const TimerProgressBar: React.FC<Props> = ({
  color,
  status,
  label,
  sectionName,
  variant = 'big',
}) => {
  const theme = React.useContext(ThemeContext);

  const fillerStyles = {
    width: status ? `${status}%` : '0%',
    backgroundColor: color || theme.buttonColors.active,
  };

  if (variant === 'big') {
    return (
      <div style={{ position: 'relative' }}>
        <p
          className={styles.timerProgressBardLabel}
          style={{
            color:
              theme.id === companyThemes.erst.id &&
                theme.buttonColors.disabledText
                ? theme.buttonColors.disabledText
                : mainColors.white,
          }}
        >
          {`${label}`}
        </p>
        <div
          className={styles.timerContainerStyles}
          style={{ backgroundColor: mainColors.darkGrey }}
        >
          <div style={fillerStyles} className={styles.timerInnerContainer} />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <p className={styles.timerSmallLabel}>{`${label}`}</p>
            <p className={styles.timerSmallLabel_text}>{sectionName}</p>
          </div>
          <div
            className={styles.timerSmallContainerStyles}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.9);',
            }}
          >
            <div style={fillerStyles} className={styles.timerInnerContainer} />
          </div>
          {/* To center the timer */}
          <p style={{ fontSize: '0.75rem' }}></p>
        </div>
      </div>
    );
  }
};

export default TimerProgressBar;
