import { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import Keycloak from 'keycloak-js';

interface Redirect {
  redirect_uri?: string;
}

interface AuthOptions {
  loginSystem: 'auth0' | 'keycloak';
}

interface ReturnLogoutUrl {
  returnTo: string;
}

export class Auth {
  constructor(options: AuthOptions) {
    this.loginSystem = options.loginSystem;
    if (options.loginSystem === 'auth0') {
      this.auth0 = new Auth0Client({
        domain: 'login.complayance.dk',
        client_id: 'wEIaNzjGl3n6PTqLaOyk0BZ0sLhGLA4l',
        audience: 'http://gamengine.link/',
        redirect_uri: window.location.origin,
        useRefreshTokens: true,
      });
    } else {
      const url = process.env.REACT_APP_AUTH_URL || 'https://login.staging2.complayance.dk/auth/';
      this.keycloak = new Keycloak({
        url,
        realm: 'GameEngine',
        clientId: 'webapp',
      });
    }
  }

  private loginSystem: 'auth0' | 'keycloak';
  private auth0?: Auth0Client;
  private keycloak?: Keycloak;

  public async loginWithRedirect(url: Redirect): Promise<void> {
    if (this.loginSystem === 'auth0' && this.auth0) {
      return await this.auth0.loginWithRedirect(url);
    } else if (this.loginSystem === 'keycloak' && this.keycloak) {
      if (!this.keycloak.authenticated) {
        await this.keycloak.init({
          onLoad: 'login-required',
        });
      }
      return;
    }
    //return Object.prototype.toString.call(str) === '[object String]';
  }

  public async getTokenSilently(): Promise<string> {
    if (this.loginSystem === 'auth0' && this.auth0) {
      return await this.auth0.getTokenSilently();
    } else if (this.loginSystem === 'keycloak' && this.keycloak) {
      if (!this.keycloak.authenticated) {
        await this.keycloak.init({
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        });
      } else {
        // we need to refresh the accessToken
        this.keycloak.updateToken(5 * 60)
          .then().catch(function () {
            console.log('Failed to refresh the token, or the session has expired');
          });
      }

      const accessToken = this.keycloak.token;

      if (accessToken === undefined) {
        throw new Error('Access token undefined');
      }

      return accessToken;
    }

    throw new Error('No valid login system set');
  }

  public async getIdTokenClaims(): Promise<IdToken> {
    if (this.loginSystem === 'auth0' && this.auth0) {
      return await this.auth0.getIdTokenClaims();
    } else if (this.loginSystem === 'keycloak' && this.keycloak) {
      if (!this.keycloak.authenticated) {
        await this.keycloak.init({
          onLoad: 'login-required',
        });
      }

      const profile = await this.keycloak.loadUserProfile();
      if (profile?.emailVerified === undefined) {
        throw new Error('Access token undefined');
      }

      const idClaims: IdToken = {
        __raw: this.keycloak.idToken || '',
        email_verified: profile.emailVerified,
      };

      return idClaims;
    }
    throw new Error('No valid login system set');
  }

  public async logout(returnUrl: ReturnLogoutUrl): Promise<void> {
    if (this.loginSystem === 'auth0' && this.auth0) {
      return await this.auth0.logout(returnUrl);
    } else if (this.loginSystem === 'keycloak' && this.keycloak) {
      if (this.keycloak.authenticated) {
        await this.keycloak.logout({ redirectUri: returnUrl.returnTo });
      }
    }
  }

  public async getUser() {
    if (this.loginSystem === 'auth0' && this.auth0) {
      return await this.auth0.getUser();
    }
  }
}
