import React from 'react';
import ReflectionCard from './cards/ReflectionCard';
import ActionCard from './cards/ActionCard';
import RolePlayCard from './cards/RolePlayCard';

export interface AnswerBoxProps {
  activeCard: DCardInstance | undefined;
  currentTeam: DTeam | undefined;
  onAnswer: (reply: Reply) => void;
  isGameOwner: boolean | undefined;
  section: GameSection | null | undefined;
  isModal?: boolean;
  reflectionText: string;
  updateReflectionText: (text: string) => void;
}

export function AnswerBox(props: AnswerBoxProps) {
  switch (props.activeCard?.type) {
    case 'reflection': {
      return (
        <ReflectionCard
          updateReflectionText={props.updateReflectionText}
          reflectionText={props.reflectionText}
          isModal={props.isModal}
          onAnswer={props.onAnswer}
          activeCard={props.activeCard}
          currentTeam={props.currentTeam}
          isGameOwner={props.isGameOwner}
        />
      );
    }
    case 'discussion':
    case 'action':
    case 'challenge': {
      return (
        <ActionCard
          isGameOwner={props.isGameOwner}
          sectionColor={props.section?.color}
          activeCard={props.activeCard}
          currentTeam={props.currentTeam}
          onAnswer={props.onAnswer}
          modal={props.isModal}
        />
      );
    }
    case 'dilemma': // todo: ensure this is placed correct
    case 'roleplay': {
      return (
        <RolePlayCard
          isGameOwner={props.isGameOwner}
          activeCard={props.activeCard}
          currentTeam={props.currentTeam}
          isModal={props.isModal}
          onAnswer={props.onAnswer}
        />
      );
    }
    default: {
      return <div />;
    }
  }
}

export default AnswerBox;
