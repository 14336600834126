import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamation,
  faArrowRight,
  faArrowLeft,
  faChevronDown,
  faCaretDown,
  faCaretLeft,
  faPencilAlt,
  faAngleDoubleDown,
  faPlus,
  faMinus,
  faStar,
  faRocket,
  faGlassMartini,
  faMusic,
  faHeart,
  faRoad,
  faFlag,
  faPlane,
  faLeaf,
  faKey,
  faBullhorn,
  faCoffee,
  faFlask,
  faAnchor,
  faArchive,
  faStopCircle,
  faEye,
  faCheck,
  faQuestion,
  faTimes,
  faEdit,
  faRedo,
  faAngleDoubleRight,
  faShip,
  // WARNING, NEVER REMOVE ANY OF THE ICONS
  // WE DONT KNOW WHERE THEY ARE USED!
} from '@fortawesome/free-solid-svg-icons';
import * as svgIcons from '../../assets/svgs';
export type SVGIcon = keyof typeof svgIcons;

/*
 -HOW TO IMPORT A NEW ICON-
  Import it from @fortawesome/free-solid-svg-icons.
  put it in listOfIcons.
  From the fontawesome page where you found the icon, copy the name and add it to IconNames
*/
const listOfIcons = [
  { name: faShip },
  { name: faExclamation },
  { name: faArrowRight },
  { name: faArrowLeft },
  { name: faCaretDown },
  { name: faCaretLeft },
  { name: faChevronDown },
  { name: faAngleDoubleDown },
  { name: faPencilAlt },
  { name: faPlus },
  { name: faPencilAlt },
  { name: faMinus },
  { name: faRocket },
  { name: faGlassMartini },
  { name: faAnchor },
  { name: faFlask },
  { name: faLeaf },
  { name: faKey },
  { name: faCoffee },
  { name: faBullhorn },
  { name: faPlane },
  { name: faFlag },
  { name: faMusic },
  { name: faHeart },
  { name: faRoad },
  { name: faStar },
  { name: faArchive },
  { name: faStopCircle },
  { name: faEye },
  { name: faCheck },
  { name: faQuestion },
  { name: faTimes },
  { name: faEdit },
  { name: faRedo },
  { name: faAngleDoubleRight },
];

// icon names can be found where you get the icon
export type IconNames =
  | 'exclamation'
  | 'flask'
  | 'anchor'
  | 'glass-martini'
  | 'rocket'
  | 'stop-circle'
  | 'eye'
  | 'question'
  | 'times'
  | 'redo'
  | 'arrow-right'
  | 'caret-down'
  | 'caret-left'
  | 'star'
  | 'road'
  | 'heart'
  | 'music'
  | 'flag'
  | 'plane'
  | 'bullhorn'
  | 'coffee'
  | 'key'
  | 'leaf'
  | 'angle-double-right'
  | 'arrow-left'
  | 'chevron-down'
  | 'angle-double-down'
  | 'pencil-alt'
  | 'plus'
  | 'minus'
  | 'star'
  | 'archive'
  | 'check'
  | 'edit'
  | 'check'
  | 'ship'
  | string;

interface Props {
  icon: IconNames | SVGIcon;
  color: string;
  size: 'tiny' | 'small' | 'medium' | 'large' | 'token' | 'smallToken' | number;
  iconStyle?: React.CSSProperties;
  onClick?: () => void;
}

function Icon(props: Props) {
  const iconSize = (): number => {
    switch (props.size) {
      case 'tiny': {
        return 15;
      }
      case 'small': {
        return 20;
      }
      case 'medium': {
        return 40;
      }
      case 'large': {
        return 80;
      }
      case 'token': {
        return 30;
      }
      case 'smallToken': {
        return 15;
      }
      default: {
        return props.size;
      }
    }
  };

  // @ts-ignore
  if (svgIcons[props.icon]) {
    /* If svg icon */
    // @ts-ignore
    const Svg = svgIcons[props.icon];

    return (
      <Svg
        // @ts-ignore Ignore cuz it doesnt know its a svg
        width={iconSize()}
        height={iconSize()}
        fill={props.color}
        stroke={props.color}
        style={props.iconStyle}
      />
    );
  } else {
    /* If font awesome icon */
    let iconLink = React.useMemo(
      () =>
        listOfIcons.find(
          (singleItem) => singleItem.name.iconName === props.icon
        ),
      [props.icon]
    );

    if (!iconLink) {
      iconLink = { name: faExclamation };
    }

    const actualIcon = React.useCallback(() => {
      return (
        <FontAwesomeIcon
          icon={iconLink!.name}
          style={{
            fontSize: iconSize(),
            transition: '1s',
            ...props.iconStyle,
          }}
          color={props.color}
        />
      );
    }, [props.color, props.iconStyle, iconLink, iconSize]);

    if (props.onClick) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={props.onClick}
          onKeyDown={props.onClick}
        >
          {actualIcon()}
        </div>
      );
    }
    return actualIcon();
  }
}

export default Icon;
