import React from 'react';
import AnswerElement from '../AnswerElement';
import Timer from '../../../../../../components/UI/Timer';
import AnswerText from './AnswerText';
import { StringsConsumer } from '../../../../../../locales';

interface Props {
  activeCard: DCardInstance | undefined;
  currentTeam: DTeam | undefined;
  onAnswer: (reply: Reply) => void;
  isGameOwner: boolean | undefined;
  sectionColor: string | undefined;
  modal: boolean | undefined;
}

function ActionCard(props: Props) {
  const {
    activeCard,
    currentTeam,
    onAnswer,
    isGameOwner,
    sectionColor,
    modal,
  } = props;

  let cardReply: DReply | undefined;
  if (activeCard?.replies) {
    if (activeCard.replies.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      cardReply = activeCard.replies[0];
    }
  }

  const onPressAnswer = (answerId: number) => {
    if (
      activeCard &&
      answerId &&
      currentTeam &&
      currentTeam.players.length > 0
    ) {
      const answerObject: Reply = {
        type: 'answer',
        cardId: activeCard.id,
        answerId,
        teamId: currentTeam.id,
        playerId: currentTeam.players[0].id,
      };
      onAnswer(answerObject);
    }
  };

  const whatPlayerAnswered = () => {
    if (cardReply?.answer?.id && activeCard) {
      const theChosenAnswer = activeCard.answers.find(
        (answerEl) => answerEl.id === cardReply!.answer!.id
      );
      if (theChosenAnswer) {
        return theChosenAnswer.short;
      }
    }
    return 'unknown';
  };
  return (
    <div>
      <StringsConsumer>
        {({ screens: { gamePage } }) => {
          return (
            <div>
              {currentTeam && currentTeam.players.length > 0 ? (
                <div>
                  {activeCard?.answers?.map((answer, index) => {
                    return (
                      <AnswerElement
                        key={`answers-${index}`}
                        answer={answer}
                        userHasAnswered={!!cardReply}
                        selected={cardReply?.answer?.short === answer.short}
                        sectionColor={sectionColor}
                        disabled={!isGameOwner || cardReply !== undefined}
                        onClick={() => onPressAnswer(answer.id)}
                      />
                    );
                  })}
                </div>
              ) : (
                <p style={{ textAlign: 'center' }}>
                  {gamePage.activeCard.answerText.noPlayers}
                </p>
              )}
              {cardReply && (
                <AnswerText
                  points={cardReply.awardedPoints}
                  playerAnswer={whatPlayerAnswered()}
                  gameMasterGuidance={activeCard?.guidance}
                />
              )}
            </div>
          );
        }}
      </StringsConsumer>
    </div>
  );
}

export default ActionCard;
