import React, { lazy, Suspense, useEffect } from 'react';
import { persistStore } from 'redux-persist';
import { useThemeHelper } from './hooks';
import { companyThemes } from './utils';
import Loader from './components/UI/Loader';
import { StringsProvider } from './locales';
import { usePresentToast } from './components/UI/toastNotificatons';
import { PersistGate } from 'redux-persist/integration/react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, useSelector } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import reducer from './redux/reducers';
import rootSaga from './redux/sagas';
import Navigation from './containers/navigation';
import './App.css';
import { APP_INITIALIZE_REQUEST, LOCALIZE_LANGUAGE_SET } from './redux/actions';
import APILoader from './containers/APILoader';
import CookieModal from './components/cookieModal';
// Lazy loaded PAGES
const HomePage = lazy(() => import('./pages/main/Home'));
const GameCreationPage = lazy(
  () => import('./pages/main/factory/FactoryNavigation')
);
const GamePage = lazy(() => import('./pages/main/browser/GamePage'));
const ReflectionPage = lazy(
  () => import('./pages/main/browser/UserReflectionPage')
);
const JoinGamePage = lazy(() => import('./pages/main/factory/JoinGamePage'));
const SettingsPage = lazy(() => import('./pages/main/more/Settings'));
const GameCodesPage = lazy(() => import('./pages/main/factory/TeamCodesPage'));
const AnonymousJoinGame = lazy(
  () => import('./pages/main/factory/AnonymousJoinGame')
);
const WelcomePage = lazy(() => import('./pages/main/factory/WelcomePage'));

// Middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares: any[] = [sagaMiddleware];

const store: any = createStore(
  reducer,
  composeWithDevTools({
    actionsDenylist: [APP_INITIALIZE_REQUEST.type]
  })(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// Refactor to an app init ACTION, which then calls AUTH0_INIT and GET PROFILE
store.dispatch(APP_INITIALIZE_REQUEST.create(true));

const Content: React.FC = () => {
  usePresentToast();
  const isLoggedIn = useSelector(
    (state: AppState) => state.auth.isAuthenticated
  );
  const isEmailVerified = useSelector(
    (state: AppState) => state.auth.emailVerified
  );

  const gameboardBackground = useSelector(
    (state: AppState) => state.engine.game?.virtualGameboard?.backgroundColor
  );

  const shouldHaveAccessToSites = isLoggedIn && isEmailVerified;
  const theme = React.useContext(ThemeContext);

  useEffect(() => {
    if (theme.defaultLanguage) {
      store.dispatch(LOCALIZE_LANGUAGE_SET.create(theme.defaultLanguage));
    }
  }, [theme.defaultLanguage]);
  return (
    <>
      <Router>
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: gameboardBackground || theme.background,
          }}
        >
          <Suspense fallback={<APILoader />}>
            <Navigation />
            {shouldHaveAccessToSites === true ? (
              <Switch>
                <Route path="/GameCodes/:id">
                  <GameCodesPage />
                </Route>
                <Route path="/GameCreation">
                  <GameCreationPage />
                </Route>
                <Route path="/Game/reflections/:id">
                  <ReflectionPage />
                </Route>
                <Route path="/Game/:id">
                  <GamePage />
                </Route>
                <Route path="/JoinGame">
                  <JoinGamePage />
                </Route>
                <Route path="/Settings">
                  <SettingsPage />
                </Route>
                <Route path="/join">
                  <AnonymousJoinGame />
                </Route>
                <Route path="/intro">
                  <WelcomePage />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route path="/join">
                  <AnonymousJoinGame />
                </Route>
                <Route path="/intro">
                  <WelcomePage />
                </Route>
                <Route path="/">
                  <HomePage />
                </Route>
              </Switch>
            )}
          </Suspense>
        </div>
        <CookieModal />
      </Router>
    </>
  );
};

export const ThemeContext = React.createContext(companyThemes.erst);

function App() {
  const { currentTheme } = useThemeHelper({ companyThemes });

  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <StringsProvider>
          <ThemeContext.Provider value={currentTheme}>
            <ToastProvider autoDismissTimeout={6000}>
              <Content />
            </ToastProvider>
          </ThemeContext.Provider>
        </StringsProvider>
      </PersistGate>
    </Provider>
  );
}
export default App;
