import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';

import {
  API_COMPANY_APPLY_REQUEST,
  API_COMPANY_PLAYERS_GET_SUCCESS,
  API_COMPANY_PLAYERS_GET_FAILURE,
  API_COMPANY_PLAYERS_GET_REQUEST,
  API_COMPANY_APPLY_SUCCESS,
  API_COMPANY_APPLY_FAILURE,
} from '../../actions';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_COMPANY_PLAYERS_GET_REQUEST.type: {
      const companyId = API_COMPANY_PLAYERS_GET_REQUEST.payload(action);
      const retry = API_COMPANY_PLAYERS_GET_REQUEST.meta(action);

      try {
        const response: Player[] = yield request(
          `/company/${companyId}/players`,
          {},
          expandProfile(retry)
        );

        yield put(API_COMPANY_PLAYERS_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_COMPANY_PLAYERS_GET_FAILURE.create(error));
      }
      break;
    }
    case API_COMPANY_APPLY_REQUEST.type: {
      const payload = API_COMPANY_APPLY_REQUEST.payload(action);
      const retry = API_COMPANY_APPLY_REQUEST.meta(action);

      const options = {
        method: 'POST',
        body: JSON.stringify(payload),
      };

      try {
        const response: { id: number } = yield request(
          '/company/apply',
          options,
          expandProfile(retry)
        );

        yield put(API_COMPANY_APPLY_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_COMPANY_APPLY_FAILURE.create(error));
      }

      break;
    }

    default:
  }
}

export function* companyRequests() {
  yield takeEvery(
    [API_COMPANY_PLAYERS_GET_REQUEST.type, API_COMPANY_APPLY_REQUEST.type],
    handleRequest
  );
}
