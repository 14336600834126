import { DefaultLangKeys } from '../en';

export const languageTag = 'en_FC';

export const en_FC: RecursivePartial<DefaultLangKeys> = {
   label: "Facilitation",
   sagaTranslate: {
      id1: "Unable to load the session: We are unable to open the session at the moment. Please try again later.",
      id2: "is not a valid session instance ID",
      id3: "Something went wrong",
      id4: "Drawn element doesn't exists",
      id5: "SessionID couldn't be found",
      id6: "Hang on, the internet is a bit slow right now. Please hold on while we retry your request",
      id7: "Still no luck. We are still trying to get through to our servers. Please wait...",
      id8: "It seem that there is no access to the internet at the moment.\n\nPlease reconnect and try again.",
      id9: "No internet access",
      id10: "Unable to retrieve access token.",
      id11: "You will need to load the session before closing it.",
      id12: "Could not stop the session",
      id13: "Team not found",
      id14: "Participant token couldn't move, since it's out of bounds.",
      id15: "We didn't get a valid reply (reply is undefined)",
      id16: "No more available elements.",
      id17: "Session could not be found",
      id18: "Couldn't update state",
      id19: "Users were successfully send to end session reflection.",
      id20: "Session is finished, go to join session to find a new one!",
      id21: "Your reflection has been submitted",
      id22: "Element concluded"
   },
   screens: {
      errors: {
         goHome: "If the issue persist, go to the home page and try again",
         homeBtn: "Go Home",
         login: "Login",
         loginBtn: "Login",
         notLoggedIn: "You dont seem to be logged in. Click here to login.",
         reloadBtn: "reload",
         reloadPage: "Press here to reload the page"
      },
      gameCodes: {
         code: "Code",
         startGame: "Start Session",
         waitingForCode: " Waiting for the codes to be made"
      },
      gameCreation: {
         addTeam: "Add a team",
         availableGames: "Available sessions",
         createGameBtn: "Create Session",
         createTeams: "Create Teams",
         creationStep: "Step",
         enterGameName: "Enter session name",
         enterTeamName: "Enter team name",
         goBtn: "Go",
         header: "Session Creation",
         noRole: "You do not have the required role to create sessions.",
         progressBar: {
            active: "In Progress",
            done: "Completed",
            inactive: "Overview",
            summary: "Waiting"
         },
         removeTeam: "Remove a team",
         selectGameName: "Please select your session name",
         selectIconName: "Select an icon and name",
         subHeaders: {
            gameName: "Session Name",
            gameTemplate: "Session Template",
            summary: "Summary",
            teams: "Teams"
         },
         submit: "Submit",
         summary: {
            description: "Description",
            difficulty: "Difficulty",
            gameName: "Session name",
            summary: "Summary",
            team: "Team",
            template: "Template"
         },
         team: "TEAM",
         upTo: "up to"
      },
      gamePage: {
         activeCard: {
            answerText: {
               correct: "correct",
               guidance: "Guidance",
               incorrect: "incorrect",
               noPlayers: "Can't answer the question, as there are no participants in this team.",
               points: " ",
               thisIs: "This is",
               youAnswered: "You answered"
            },
            cardTypes: {
               action: " ",
               challenge: " ",
               dilemma: " ",
               discussion: " ",
               reflection: " ",
               rolePlay: " ",
               teamChallenge: " "
            },
            enterReflection: "Type in your reflections",
            gameIsGoing: "Session is running",
            guidance: "Guidance",
            noPlayers: {
               noPlayers: "No participants on this team.",
               pleaseJoin: "Please join using the following code"
            },
            notYourTurn: "NOT YOUR TURN",
            reflectionStateTextForGM: "We are currently in end session reflection phase.\n             Wait for the participants to write their reflections, and then you can stop the session, \n             by clicking the Stop Session button.",
            replied: "Reply",
            roleDistribution: "Role distribution",
            roleplay: {
               myAssignment: "My assignment:",
               myRole: "My role:",
               noAssignment: "You weren't assigned a role, so you have no assigment.",
               noRole: "No role"
            },
            submit: "Submit",
            timer: {
               first: "You now have",
               second: "seconds to complete"
            },
            waitForNewCard: "Wait for Facilitator to draw new element.",
            waitForNewCardHeader: "No Element"
         },
         challengeMode: {
            awaitTeams: "Wait for the teams to write their reflections",
            title: "#"
         },
         copiedJoinTeamMsg: "You're part of team",
         copiedLinkMsg: "Click here, to participate in the session:",
         copiedTeamCode: "Teamcode copied",
         copiedTeamCodeMsg: "Your teamcode is",
         currentTeamTurn: "active",
         gameEndedBody: "The session is finished. So we're taking you back home.",
         gameEndedHeader: "Session is complete",
         gmControls: {
            accept: "Accept",
            availCards: "Available elements on tile",
            buyToken: "Buy token",
            cardIsAlreadyDrawnBody: "An element is already active, are you sure you want to activate another?",
            cardIsAlreadyDrawnHeader: "An element is already active",
            challenge: "Challenge team",
            changeTurn: "Change turn",
            currentCardId: "Current element id",
            drawCard: "Draw Element",
            endGameReflectionsbtn: "Final Reflect",
            gameId: "Session id",
            gameInfo: "The Session Info",
            gamename: "Session name",
            lostChallenge: "Incomplete elements",
            moveBack: "Move Backwards",
            moveForwards: "Move Forwards",
            moveNextSection: "Move to next section",
            notEveryOneContent: "Are you sure you want to move?",
            notEveryOneHeader: "Not Everyone Has Send Their Reflection",
            ok: "Ok",
            reflectionDeletion: ["The participant reflections will be deleted, \n            have you ensured that all the participants have printed them?"],
            rollDice: "Roll dice",
            sectionId: "Section id",
            sectionName: "Section name",
            showInfo: "Show Info",
            startGame: "Start session",
            stopGame: "Stop Session",
            stopGameAndShowResults: "Stop session and show results",
            stopGameQuestion: "Are you sure you want to stop the session?",
            submitPoints: "Submit points",
            submitReflectionsPoints: ["Give", "reflections"],
            teams: "Teams:",
            wonChallenge: "Completed elements",
            yes: "Yes"
         },
         noPlayersPart1: "No players on this team, use code:",
         noPlayersPart2: "to invite them.",
         notPartOfGameBody: "Go back to homepage, and enter this session via Join Session.",
         notPartOfGameHeader: "You're not part of this game",
         okBtn: "Ok",
         plaidModal: {
            startGame: "Start session",
            stopGame: "End session"
         },
         stillHereBody: "You've been here for a long time. Are you still playing?",
         stillHereHeader: "Are you still here?",
         teamCard: {
            active: "Active",
            areYouSure: "Are you sure you want to remove the participant?",
            gamemaster: "Facilitator",
            noPlayers: "No participants",
            players: "Participants",
            role: "Role:",
            score: "Status:",
            sections: "Sections",
            youreAboutToKickPlayer: "Youre about to remove a participant"
         },
         timer: {
            timesUp: "Time's up"
         },
         upNextTeamTurn: "up next",
         yesBtn: "Yes"
      },
      home: {
         buttonLogin: "Log in",
         created: "Created",
         enterGameBtn: "Enter Session",
         gameowner: "Session owner",
         goTo: "Go",
         goToGameCreation: "Create a new session",
         goToJoinGame: "Join a running session",
         guestButton: "Join",
         hi: "Hi",
         joinasGuest: "or join a session as a guest",
         login: "Please log in to access site",
         loginBtn: "Login",
         noRunningGames: "No running sessions were found, you have the following options:",
         notVerifiedBody: "To verify your account please open the email we've just sent\n          you, and follow the instructions.",
         notVerifiedDone: "When done, reload the page.",
         notVerifiedHeader: "Your account doesn't seem to be verifed.",
         reloadBtn: "Reload",
         welcome: "Welcome to Flow4Impact ",
         yourRunningGames: "Now, choose a running session."
      },
      joinGame: {
         enterName: "Enter Name",
         enterPin: "Enter PIN",
         title: "Join Session"
      },
      navigation: {
         about: "About",
         createGame: "Create Session",
         game: "Session",
         home: "Home",
         joinGame: "Join Session",
         logOut: "Log Out",
         settings: "Settings",
         signed: "Signed in as"
      },
      others: {
         closeBtn: "Close"
      },
      reflectionPage: {
         mainReflectionCardPlaceholder: "Type in your main reflections",
         mainSubTitle: "My main reflection",
         print: "Print",
         submit: "Submit",
         subTitle: "My reflections"
      },
      settings: {
         avatar: "Avatar",
         changeName: "Change Name",
         chooseNewImage: "Choose new image",
         email: "Email",
         environment: "Environment",
         environmentVariables: {
            apiUrl: "API endpoint",
            buildShortTag: "Build tag"
         },
         language: "Language",
         name: "Name",
         playerIcon: "Participant icon",
         selectLanguage: "Select language",
         settingsHeader: "Settings",
         unknown: "Unknown",
         uploadImage: "Upload Image"
      }
   }
};
