import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';

import {
  API_INVITE_GET_REQUEST,
  API_INVITE_GET_SUCCESS,
  API_INVITE_GET_FAILURE,
  API_INVITE_ACCEPT_REQUEST,
  API_INVITE_ACCEPT_SUCCESS,
  API_INVITE_ACCEPT_FAILURE,
  API_INVITE_REJECT_REQUEST,
  API_INVITE_REJECT_SUCCESS,
  API_INVITE_REJECT_FAILURE,
} from '../../actions';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_INVITE_GET_REQUEST.type: {
      try {
        const payload = API_INVITE_GET_REQUEST.payload(action);
        const retry = API_INVITE_GET_REQUEST.meta(action);

        const endpoint = `/profile/invite${
          payload !== null ? `/${payload}` : ''
        }`;
        const response: GameInvite[] = yield request(
          endpoint,
          {},
          expandProfile(retry)
        );

        yield put(API_INVITE_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_INVITE_GET_FAILURE.create(error));
      }
      break;
    }

    case API_INVITE_ACCEPT_REQUEST.type: {
      try {
        const payload = API_INVITE_ACCEPT_REQUEST.payload(action);
        const retry = API_INVITE_ACCEPT_REQUEST.meta(action);
        const endpoint = `/profile/invite/${payload}/accept`;

        yield request(endpoint, {}, expandProfile(retry));
        yield put(API_INVITE_ACCEPT_SUCCESS.create(payload));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_INVITE_ACCEPT_FAILURE.create(error));
      }
      break;
    }

    case API_INVITE_REJECT_REQUEST.type: {
      try {
        const payload = API_INVITE_REJECT_REQUEST.payload(action);
        const retry = API_INVITE_REJECT_REQUEST.meta(action);
        const endpoint = `/profile/invite/${payload}/reject`;

        yield request(endpoint, {}, expandProfile(retry));
        yield put(API_INVITE_REJECT_SUCCESS.create(payload));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_INVITE_REJECT_FAILURE.create(error));
      }
      break;
    }

    default:
  }
}

export function* inviteRequests() {
  yield takeEvery(
    [
      API_INVITE_GET_REQUEST.type,
      API_INVITE_ACCEPT_REQUEST.type,
      API_INVITE_REJECT_REQUEST.type,
    ],
    handleRequest
  );
}
