import { takeEvery, take, put, race } from 'redux-saga/effects';

import {
  ACCOUNT_USER_INFO_FAILURE,
  ACCOUNT_USER_INFO_REQUEST,
  ACCOUNT_USER_INFO_SUCCESS,
  API_PROFILE_GET_FAILURE,
  API_PROFILE_GET_REQUEST,
  API_PROFILE_GET_SUCCESS,
  ACCOUNT_UPDATE_NAME_REQUEST,
  API_PROFILE_POST_REQUEST,
  API_PROFILE_POST_SUCCESS,
  API_PROFILE_POST_FAILURE,
  ACCOUNT_UPDATE_NAME_FAILURE,
  ACCOUNT_UPDATE_NAME_SUCCESS,
  TOAST_NOTIFICATION_OPEN,
  ACCOUNT_UPDATE_AVATAR_REQUEST,
  ACCOUNT_UPDATE_AVATAR_SUCCESS,
  ACCOUNT_UPDATE_AVATAR_FAILURE,
} from '../../actions';

function* accountRequestHandler(action: GenericAction) {
  switch (action.type) {
    case ACCOUNT_USER_INFO_REQUEST.type: {
      const payload = ACCOUNT_USER_INFO_REQUEST.payload(action);
      let retry = ACCOUNT_USER_INFO_REQUEST.meta(action);

      if (!retry) {
        retry = { profileLabel: 'defaultGet' };
      }

      yield put(API_PROFILE_GET_REQUEST.create(payload, retry));

      const response: GenericAction[] = yield race([
        take(API_PROFILE_GET_SUCCESS.type),
        take(API_PROFILE_GET_FAILURE.type),
      ]);

      try {
        const profile = API_PROFILE_GET_SUCCESS.payload(response[0]);
        yield put(ACCOUNT_USER_INFO_SUCCESS.create(profile));
      } catch (e: any) {
        yield put(ACCOUNT_USER_INFO_FAILURE.create(e));
      }

      break;
    }
    case ACCOUNT_UPDATE_NAME_REQUEST.type: {
      const payload = ACCOUNT_UPDATE_NAME_REQUEST.payload(action);
      yield put(API_PROFILE_POST_REQUEST.create({ name: payload }));
      const response: GenericAction = yield take([
        API_PROFILE_POST_SUCCESS.type,
        API_PROFILE_POST_FAILURE.type,
      ]);
      if (response.type === API_PROFILE_POST_SUCCESS.type) {
        const responsePayload = API_PROFILE_POST_SUCCESS.payload(response);
        yield put(ACCOUNT_UPDATE_NAME_SUCCESS.create(responsePayload));
      } else {
        const responsePayload = API_PROFILE_POST_FAILURE.payload(response);
        yield put(ACCOUNT_UPDATE_NAME_FAILURE.create(responsePayload));
      }
      break;
    }
    case ACCOUNT_UPDATE_AVATAR_REQUEST.type: {
      const payload = ACCOUNT_UPDATE_AVATAR_REQUEST.payload(action);

      yield put(
        API_PROFILE_POST_REQUEST.create(
          { avatar: payload },
          { profileLabel: 'defaultPost' }
        )
      );

      const updatedProfileAction: GenericAction = yield take([
        API_PROFILE_POST_SUCCESS.type,
        API_PROFILE_POST_FAILURE.type,
      ]);

      try {
        const updatedProfile =
          API_PROFILE_POST_SUCCESS.payload(updatedProfileAction);
        yield put(ACCOUNT_UPDATE_AVATAR_SUCCESS.create(updatedProfile));
      } catch (e: any) {
        yield put(ACCOUNT_UPDATE_AVATAR_FAILURE.create(e));
      }
      break;
    }

    case ACCOUNT_UPDATE_NAME_FAILURE.type: {
      const payload = ACCOUNT_UPDATE_NAME_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'info',
          autoDismiss: true,
        })
      );
      break;
    }
    case ACCOUNT_UPDATE_AVATAR_FAILURE.type: {
      const payload = ACCOUNT_UPDATE_AVATAR_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'info',
          autoDismiss: true,
        })
      );
      break;
    }
    default:
  }

  return undefined;
}

export function* accountSaga() {
  yield takeEvery(
    [
      ACCOUNT_USER_INFO_REQUEST.type,
      ACCOUNT_UPDATE_NAME_REQUEST.type,
      ACCOUNT_UPDATE_NAME_FAILURE.type,
      ACCOUNT_UPDATE_AVATAR_REQUEST.type,
      ACCOUNT_UPDATE_AVATAR_FAILURE.type,
    ],
    accountRequestHandler
  );
}
