import React from 'react';
import { ThemeContext } from '../../../App';
import styles from './PlaidModal.module.css';
import Icon from '../Icon';
import IntroImage from '../../../assets/erst-icons/jagten-paa-hemmeligheden.svg';
import SectionPresentation from '../SectionPresentation';
import {
  combinedPointsTokenGame,
  hightestCombinedPointsTokenGame,
  tokenPointMultiplier,
} from '../../../utils';

interface AwardsProps {
  teams: DTeam[];
  isMobile: boolean;
}

export function Awards({ teams, isMobile }: AwardsProps) {
  const highestScore = hightestCombinedPointsTokenGame(teams);

  const theme = React.useContext(ThemeContext);

  return (
    <div style={{ color: theme.primaryText }}>
      <div
        className={styles.thanks}
        style={isMobile ? { fontSize: '1.2rem' } : undefined}
      >
        Tak fordi i spillede
      </div>
      <div className="introImageContainer">
        <IntroImage />
      </div>
      <p
        className={styles.explainer}
        style={isMobile ? { fontSize: '1.2rem' } : undefined}
      >
        Her er jeres resultater!
      </p>
      <div
        className={styles.teamHolder}
        style={isMobile ? { flexDirection: 'column' } : undefined}
      >
        {teams.map((t) => (
          <TeamRender
            team={t}
            highestScore={highestScore}
            isMobile={isMobile}
            activeColor={theme.buttonColors.active}
          />
        ))}
      </div>
    </div>
  );
}

interface TeamRenderProps {
  team: DTeam;
  highestScore: number;
  isMobile: boolean;
  activeColor: string;
}
function TeamRender({
  highestScore,
  team,
  isMobile,
  activeColor,
}: TeamRenderProps) {
  const combinedScore = combinedPointsTokenGame(team);

  const isWinner = combinedScore === highestScore;

  return (
    <div
      className={styles.team}
      style={isMobile ? { border: 'none', marginTop: '2rem' } : undefined}
    >
      {isWinner && (
        <div className={styles.crownContainer}>
          <Icon icon="crown" color={activeColor} size="large" />
        </div>
      )}
      <div
        className={styles.name}
        style={isWinner ? { color: activeColor } : {}}
      >
        {team.name} {isWinner && 'vinder'}
      </div>
      <div className={styles.score}>{combinedScore} Refleksioner</div>
      <div className={styles.answers}>svar: {team.victoryPoints || 0}</div>
      <div className={styles.sectionsContainer}>
        {team.teamSectionPoints?.map((sect) => (
          <SectionPresentation
            showIcon
            gameSection={sect}
            overridePoint={sect.tokens.length * tokenPointMultiplier}
          />
        ))}
      </div>
    </div>
  );
}
