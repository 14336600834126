import * as React from 'react';
import { ThemeContext } from '../../../../App';
import { useTeamCodesHelper } from '../../../../hooks/useTeamCodesHelper';
import { StringsContext } from '../../../../locales';
import Icon from '../../Icon';
import styles from './TeamCodesDropdown.module.css';

interface Props {
  teams: Team[];
  onCopy: (message: string) => void;
}

const TeamCodesDropdown = ({ teams, onCopy }: Props) => {
  const [dropped, setDropped] = React.useState(false);

  const theme = React.useContext(ThemeContext);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: theme.primary, color: theme.primaryText }}
    >
      <button
        style={{ color: theme.primaryText }}
        className={styles.displayButton}
        onClick={() => setDropped((prev) => !prev)}
      >
        Hold ID
        <Drop color={theme.buttonColors.active} dropped={dropped} />
      </button>
      {dropped && (
        <div
          className={styles.dropdownContent}
          style={{ backgroundColor: theme.primary }}
        >
          {teams.map((team) => (
            <TeamCode
              team={team}
              onCopy={onCopy}
              onClick={() => setDropped((prev) => !prev)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamCodesDropdown;

interface TeamCodeProps {
  team: Team;
  onCopy: (message: string) => void;
  onClick: () => void;
}

function TeamCode({ team, onCopy, onClick }: TeamCodeProps) {
  const { copyToClip } = useTeamCodesHelper({ team, onCopy, onClick });
  const theme = React.useContext(ThemeContext);
  return (
    <button className={styles.codeButton} onClick={copyToClip}>
      <p>{team.name} </p>
      <p style={{ color: theme.buttonColors.active }}>{team.code}</p>
      <span style={{ textAlign: 'right' }}>
        <Icon size="small" color={theme.buttonColors.active} icon="link" />
      </span>
    </button>
  );
}

interface DropProps {
  color: string;
  dropped: boolean;
}

function Drop({ color, dropped }: DropProps) {
  return (
    <div style={dropped ? { transform: 'rotate(180deg)' } : {}}>
      <Icon size="tiny" color={color} icon="chevron-down" />
    </div>
  );
}
