/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Icon from '../../../../../components/UI/Icon';
import { iconSizes } from '../../../../../utils';

interface Props {
  link: QuestionLink;
  colorWay?: 'light' | 'dark';
}

function LinkBox({ link, colorWay = 'dark' }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <a
        href={link.URL}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecorationColor: colorWay === 'dark' ? '#212529' : 'white',

          display: 'flex',
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            margin: 'auto 5px auto auto',
            fontSize: '15px',
            color: colorWay === 'dark' ? '#212529' : 'white',
          }}
          className="ActiveCardQuestion"
        >
          {link.linkText}
        </p>
        <Icon
          icon={link.icon}
          size={iconSizes.tiny}
          color={colorWay === 'dark' ? '#212529' : 'white'}
          iconStyle={{ alignSelf: 'center' }}
        />
      </a>
    </div>
  );
}

export default LinkBox;
