/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

export const FACTORY_TEMPLATES_AVAILABLE_REQUEST = new ActionCreator<null>('FACTORY_TEMPLATES_AVAILABLE_REQUEST');
export const FACTORY_TEMPLATES_AVAILABLE_SUCCESS = new ActionCreator<GameTemplate[]>('FACTORY_TEMPLATES_AVAILABLE_SUCCESS');
export const FACTORY_TEMPLATES_AVAILABLE_FAILURE = new ActionCreator<Error>('FACTORY_TEMPLATES_AVAILABLE_FAILURE');

export const FACTORY_DRAFT_CLEAR_UPDATE = new ActionCreator<undefined>('FACTORY_DRAFT_CLEAR_UPDATE');

export const FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST = new ActionCreator<GameTemplate>('FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST');
export const FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS = new ActionCreator<GameTemplate>('FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS');
export const FACTORY_DRAFT_SET_GAME_TEMPLATE_FAILURE = new ActionCreator<Error>('FACTORY_DRAFT_SET_GAME_TEMPLATE_FAILURE');

export const FACTORY_DRAFT_SET_TEAM_REQUEST = new ActionCreator<string | undefined>('FACTORY_DRAFT_SET_TEAM_REQUEST');
export const FACTORY_DRAFT_SET_TEAM_SUCCESS = new ActionCreator<GameDraft>('FACTORY_DRAFT_SET_TEAM_SUCCESS');
export const FACTORY_DRAFT_SET_TEAM_FAILURE = new ActionCreator<Error>('FACTORY_DRAFT_SET_TEAM_FAILURE');

export const FACTORY_DRAFT_SUBMIT_REQUEST = new ActionCreator<undefined>('FACTORY_DRAFT_SUBMIT_REQUEST');
export const FACTORY_DRAFT_SUBMIT_SUCCESS = new ActionCreator<GameInstance>('FACTORY_DRAFT_SUBMIT_SUCCESS');
export const FACTORY_DRAFT_SUBMIT_FAILURE = new ActionCreator<Error>('FACTORY_DRAFT_SUBMIT_FAILURE');

export const FACTORY_DRAFT_SET_COOP_REQUEST = new ActionCreator<undefined>('FACTORY_DRAFT_SET_COOP_REQUEST');
export const FACTORY_DRAFT_SET_COOP_SUCCESS = new ActionCreator<GameDraft>('FACTORY_DRAFT_SET_COOP_SUCCESS');
export const FACTORY_DRAFT_SET_COOP_FAILURE = new ActionCreator<Error>('FACTORY_DRAFT_SET_COOP_FAILURE');

export const FACTORY_TEAMS_AVAILABLE_REQUEST = new ActionCreator<null>('FACTORY_TEAMS_AVAILABLE_REQUEST');
export const FACTORY_TEAMS_AVAILABLE_SUCCESS = new ActionCreator<GameTemplate[]>('FACTORY_TEAMS_AVAILABLE_SUCCESS');

export const FACTORY_COMPANY_PLAYERS_UPDATE = new ActionCreator<Player[]>('FACTORY_COMPANY_PLAYERS_UPDATE');

export const FACTORY_DRAFT_GET_PLAYERS_REQUEST = new ActionCreator<null>('FACTORY_DRAFT_GET_PLAYERS_REQUEST');

export const FACTORY_GAME_NAME_SET = new ActionCreator<string>('FACTORY_GAME_NAME_SET');

export const FACTORY_SELECT_TOGGLE_PLAYER = new ActionCreator<number>('FACTORY_SELECT_TOGGLE_PLAYER');
export const FACTORY_SELECT_UPDATE_SELECTED = new ActionCreator<Player[]>('FACTORY_SELECT_UPDATE_SELECTED');

export const FACTORY_JOIN_GAME_REQUEST = new ActionCreator<{name: string, gameCode: string}>('FACTORY_JOIN_GAME_REQUEST');
export const FACTORY_JOIN_GAME_FAILURE = new ActionCreator<Error>('FACTORY_JOIN_GAME_FAILURE');
export const FACTORY_JOIN_GAME_SUCCESS = new ActionCreator<GameInstance>('FACTORY_JOIN_GAME_SUCCESS');

interface InstanceRequest {
  instanceId: number;
}

interface TeamHolderUpdate {
  teamsHolder:{
    emails: string[];
    name?: string;
    icon?: string;
    code?: string;
  }[]
}

export const FACTORY_INSTANCE_LOAD_REQUEST = new ActionCreator<InstanceRequest>('FACTORY_INSTANCE_LOAD_REQUEST');
export const FACTORY_INSTANCE_LOAD_SUCCESS = new ActionCreator<GameInstance>('FACTORY_INSTANCE_LOAD_SUCCESS');
export const FACTORY_INSTANCE_LOAD_FAILURE = new ActionCreator<Error>('FACTORY_INSTANCE_LOAD_FAILURE');

export const FACTORY_TEAM_ICON_UPDATE = new ActionCreator<{iconName: string, teamIndex: number}>('FACTORY_TEAM_ICON_UPDATE');
export const FACTORY_TEAM_ICON_SUCCESS = new ActionCreator<TeamHolderUpdate>('FACTORY_TEAM_ICON_SUCCESS');
export const FACTORY_TEAM_ICON_FAILURE = new ActionCreator<Error>('FACTORY_TEAM_ICON_FAILURE');

export const FACTORY_TEAM_NAME_UPDATE = new ActionCreator<{name: string, teamIndex: number}>('FACTORY_TEAM_NAME_UPDATE');
export const FACTORY_TEAM_NAME_SUCCESS = new ActionCreator<TeamHolderUpdate>('FACTORY_TEAM_NAME_SUCCESS');
export const FACTORY_TEAM_NAME_FAILURE = new ActionCreator<Error>('FACTORY_TEAM_NAME_FAILURE');

export const FACTORY_TEAM_ADD_NEW_SET = new ActionCreator<undefined>('FACTORY_TEAM_ADD_NEW_SET');
export const FACTORY_TEAM_REMOVE_SET = new ActionCreator<undefined>('FACTORY_TEAM_REMOVE_SET');

export const FACTORY_ANON_GAME_JOIN_REQUEST = new ActionCreator<{name: string, gameCode: string}>('FACTORY_ANON_GAME_JOIN_REQUEST');
export const FACTORY_ANON_GAME_JOIN_SUCCESS = new ActionCreator<undefined>('FACTORY_ANON_GAME_JOIN_SUCCESS')
export const FACTORY_ANON_GAME_JOIN_FAILURE = new ActionCreator<Error>('FACTORY_ANON_GAME_JOIN_FAILURE')

export const FACTORY_TEAMS_SET = new ActionCreator<{teams: {emails:any[]}[]}>('FACTORY_TEAMS_SET'); 
