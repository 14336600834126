export const en = {
  label: 'English',
  cookie: {
    title: 'This site uses cookies',
    content: [
      'We are using cookies regarding your login.',
      'Login is handled by the platform ComPLAYance. By accepting our required cookies, you allow us to register, that you have used the site. Both now, and possibly in the future.',
      'Read more about our cookie and privacy policy here (DK):',
    ],
    button: 'Accept all functional cookies',
  },
  sagaTranslate: {
    id1: 'Unable to load game: We are unable to open the game at the moment. Please try again later.',
    id2: 'is not a valid game instance ID',
    id3: 'Something went wrong',
    id4: "Drawn card doesn't exists",
    id5: 'GameId couldnt be found',
    id6: 'Hang on, the internet is a bit slow right now. Please hold on while we retry your request',
    id7: 'Still no luck. We are still trying to get through to our servers. Please wait...',
    id8: 'It seem that there is no access to the internet at the moment.\n\nPlease reconnect and try again.',
    id9: 'No internet access',
    id10: 'Unable to retreive access token.',
    id11: 'You will need to load the game before closing it.',
    id12: 'Could not stop game',
    id13: 'Team not found',
    id14: "Player piece couldnt move, since it's out of bounds.",
    id15: "We didn't get a valid reply (reply is undefined)",
    id16: 'No more available cards.',
    id17: 'Game could not be found',
    id18: 'Couldnt update state',
    id19: 'Users were successfully send to end game reflection.',
    id20: 'Game is finished, go to join game to find a new one!',
    id21: 'Your reflection has been submitted',
    id22: 'Card answered',
  },
  screens: {
    navigation: {
      signed: 'Signed in as',
      home: 'Home',
      createGame: 'Create Game',
      joinGame: 'Join Game',
      about: 'About',
      logOut: 'Log Out',
      game: 'Game',
      settings: 'Settings',
      welcome: 'Welcome',
    },
    home: {
      hi: 'Hi',
      welcome: 'Welcome to Gaming4Impact in a browser.',
      goTo: 'Go',
      goToGameCreation: 'Create a new game',
      goToJoinGame: 'Join a running game',
      yourRunningGames: 'Now, choose a running game.',
      noRunningGames:
        'No running games were found, you have the following options:',
      login: 'Please log in to access site',
      loginBtn: 'Login',
      joinasGuest: 'or join a game as a guest',
      guestButton: 'Join',
      buttonLogin: 'Log in',
      gameowner: 'Gameowner',
      created: 'Created',
      enterGameBtn: 'Enter Game',
      notVerifiedHeader: "Your account doesn't seem to be verifed.",
      notVerifiedBody: `To verify your account please open the email we've just sent
       you, and follow the instructions.`,
      notVerifiedDone: 'When done, reload the page.',
      reloadBtn: 'Reload',
    },

    welcome: {
      login: 'Welcome to',
      loginBtn: 'Login',
      continueBtn: 'Continue',
      explainer:
        "You're about to create a new dilemma game. When clicking the button below, you'll get the option to create a game. Remember to read the rules, before you start the game. Have fun!",
      explainerLogin:
        "You're about to create a new dilemma game. When clicking the button below, you'll be prompted to log in. Afterwards you'll have the option to create a game. Remember to read the rules, before you start the game. Have fun!",
      explainerBottom: 'Click here to login.',
    },
    settings: {
      settingsHeader: 'Settings',
      avatar: 'Avatar',
      name: 'Name',
      language: 'Language',
      contact: {
        title: 'Contact',
        description: 'Contact support',
      },
      email: 'Email',
      environment: 'Environment',
      environmentVariables: {
        buildShortTag: 'Build tag',
        apiUrl: 'API endpoint',
      },
      unknown: 'Unknown',
      selectLanguage: 'Select language',
      chooseNewImage: 'Choose new image',
      playerIcon: 'Player icon',
      changeName: 'Change Name',
      uploadImage: 'Upload Image',
    },
    gameCodes: {
      code: 'Code',
      waitingForCode: ' Waiting for the codes to be made',
      startGame: 'Start Game',
    },
    joinGame: {
      title: 'Join Game',
      enterPin: 'Enter PIN',
      enterName: 'Enter name',
      enterNewName: 'Enter new name',
      logoutButton: 'Log out',
      guestMsg: 'Joining as guest user with name',
    },
    gameCreation: {
      team: 'TEAM',
      selectIconName: 'Select an icon and name',
      createTeams: 'Create Teams',
      addTeam: 'Add a team',
      removeTeam: 'Remove a team',
      submit: 'Submit',
      enterTeamName: 'Enter team name',
      selectGameName: 'Please select your game name',
      enterGameName: 'Enter game name',
      header: 'Game Creation',
      noRole: 'You do not have the required role to create games.',
      availableGames: 'Available games',
      creationStep: 'Step',
      goBtn: 'Go',
      createGameBtn: 'Create Game',
      upTo: 'up to',
      progressBar: {
        inactive: 'Overview',
        summary: 'Waiting',
        active: 'In Progress',
        done: 'Completed',
      },
      summary: {
        summary: 'Summary',
        gameName: 'Game name',
        team: 'Team',
        template: 'Template',
        description: 'Description',
        difficulty: 'Difficulty',
      },
      subHeaders: {
        gameName: 'Game Name',
        gameTemplate: 'Game Template',
        teams: 'Teams',
        summary: 'Summary',
      },
    },
    reflectionPage: {
      submit: 'Submit',
      print: 'Print',
      mainReflectionCardPlaceholder: 'Type in your main reflections',
      subTitle: 'My reflections',
      mainSubTitle: 'My main reflection',
    },
    gamePage: {
      challengeMode: {
        title: 'Challenge',
        awaitTeams: 'Wait for the teams to write their reflections',
      },
      noPlayersPart1: 'No players on this team, use code:',
      noPlayersPart2: 'to invite them.',
      yesBtn: 'Yes',
      okBtn: 'Ok',
      stillHereHeader: 'Are you still here?',
      stillHereBody: "You've been here for a long time. Are you still playing?",
      gameEndedHeader: 'Game is complete',
      gameEndedBody: "The game is finished. So we're taking you back home.",
      notPartOfGameHeader: "You're not part of this game",
      notPartOfGameBody:
        'Go back to homepage, and enter this game via Join Game.',
      currentTeamTurn: 'active',
      upNextTeamTurn: 'up next',
      timer: {
        timesUp: "Time's up",
      },
      plaidModal: {
        startGame: 'Start game',
        stopGame: 'End game',
      },
      copiedTeamCode: 'Teamcode copied',
      copiedLinkMsg: 'Click here, to participate in the game:',
      copiedTeamCodeMsg: 'Your teamcode is',
      copiedJoinTeamMsg: "You're part of team",

      activeCard: {
        timer: { first: 'You now have', second: 'seconds to answer' },
        gameIsGoing: 'Game is playing',
        roleDistribution: 'Role distribution',
        cardTypes: {
          action: 'Action',
          challenge: 'Challenge',
          rolePlay: 'Roleplay',
          teamChallenge: 'Team Challenge',
          reflection: 'Reflection',
          discussion: 'Discussion',
          dilemma: 'Dilemma',
        },
        noPlayers: {
          noPlayers: 'No players on this team.',
          pleaseJoin: 'Please join using the following code',
        },
        waitForNewCard: 'Wait for Game Master to draw new card.',
        waitForNewCardHeader: 'No Card',
        enterReflection: 'Type in your reflections',
        submit: 'Submit',
        guidance: 'Guidance',
        replied: 'Reply',
        reflectionStateTextForGM: `We are currently in end game reflection phase.
         Wait for the players to write their reflections, and then you can stop the game, 
         by clicking the Stop Game button.`,
        roleplay: {
          myAssignment: 'My assignment:',
          noAssignment:
            "You weren't assigned a role, so you have no assigment.",
          myRole: 'My role:',
          noRole: 'No role',
        },
        answerText: {
          youAnswered: 'You answered',
          thisIs: 'This is',
          correct: 'correct',
          incorrect: 'incorrect',
          points: 'points',
          guidance: 'Guidance',
          noPlayers:
            "Can't answer the question, as there are no players on this team.",
        },
        notYourTurn: 'NOT YOUR TURN',
      },
      teamCard: {
        score: 'Total score:',
        role: 'Role:',
        gamemaster: 'Gamemaster',
        youreAboutToKickPlayer: 'Youre about to kick a player',
        areYouSure: 'Are you sure you want to kick the player?',
        active: 'Active',
        players: 'Players',
        sections: 'Sections',
        noPlayers: 'No players',
      },
      gmControls: {
        submitPoints: 'Submit points',
        wonChallenge: 'Won challenge',
        lostChallenge: 'Lost challenge',
        rollDice: 'Roll dice',
        gameInfo: 'The Game Info',
        stopGameAndShowResults: 'Stop game and show results',
        startGame: 'Start game',
        ok: 'Ok',
        stopGame: 'Stop Game',
        submitReflectionsPoints: ['Give', 'reflections'],
        challenge: 'Challenge team',
        stopGameQuestion: 'Are you sure you want to stop the game?',
        reflectionDeletion: [
          `The players reflections will be deleted, 
        have you ensured that all the players have printed them?`,
        ],
        moveNextSection: 'Move to next section',
        moveBack: 'Move Backwards',
        moveForwards: 'Move Forwards',
        drawCard: 'Draw Card',
        showInfo: 'Show Info',
        accept: 'Accept',
        endGameReflectionsbtn: 'Final Reflect',
        changeTurn: 'Change turn',
        gamename: 'Game name',
        gameId: 'Game id',
        sectionId: 'Section id',
        sectionName: 'Section name',
        availCards: 'Available cards on tile',
        currentCardId: 'Current card id',
        teams: 'Teams:',
        yes: 'Yes',
        notEveryOneHeader: 'Not Everyone Has Send Their Reflection',
        notEveryOneContent: 'Are you sure you want to move?',
        cardIsAlreadyDrawnBody:
          'A card is already drawn, are you sure you want to draw another?',
        cardIsAlreadyDrawnHeader: 'A card is already drawn',
        buyToken: 'Buy token',
      },
    },
    others: { closeBtn: 'Close' },
    errors: {
      notLoggedIn: 'You dont seem to be logged in. Click here to login.',
      reloadPage: 'Press here to reload the page',
      goHome: 'If the issue persist, go to the home page and try again',
      loginBtn: 'Login',
      homeBtn: 'Go Home',
      reloadBtn: 'reload',
      login: 'Login',
    },
  },
};

export type DefaultLangKeys = typeof en;
