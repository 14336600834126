// Account is regarded as shared within the app and can be used by the
// browser, factory and engine selectors/sagas.

import { createSelector } from 'reselect';

function expandLanguagesTypeResult(lto : { [languageTag: string]: string; }): LanguageInstance[] {
  const languageObjects: LanguageInstance[] = [];
  for (const [key, value] of Object.entries(lto)) {
    languageObjects.push({
      name: value,
      tag: key,
    });
  }
  return languageObjects;
}

function findCurrentLanguageObjectResult(li: LanguageInstance[], currentTag: string | undefined)
  :LanguageInstance | undefined {
  return li.find((lang) => lang.tag === currentTag);
}

const currentTagSelector = (state: AppState): string | undefined => state.langReducer.currentLanguageTag;
const availableLanguagesSelector = (state: AppState)
: { [languageTag: string]: string; } => state.langReducer.availableLanguages;

const availableObjectLanguagesSelector = createSelector(availableLanguagesSelector, expandLanguagesTypeResult);
const currentLanguageObjectSelector = createSelector(
  availableObjectLanguagesSelector, currentTagSelector, findCurrentLanguageObjectResult,
);

// this is just trying something out feel free to change.
export const localize = {
  availableLanguages: availableObjectLanguagesSelector,
  currentLanguageObject: currentLanguageObjectSelector,
};
