import { LANGUAGE_CURRENT_TAG_UPDATE } from '../actions';

export interface LangState<LanguageTags> {
  currentLanguageTag: LanguageTags;
  translationVersion: number;
  availableLanguages: {
    [languageTag: string]: string;
  }
}

export const createLangReducer = <LanguageTags extends unknown>(
  initialState: LangState<LanguageTags>,
) => {
  return (
    state: LangState<LanguageTags> = initialState,
    action: GenericAction,
  ): LangState<LanguageTags> => {
    switch (action.type) {
      case LANGUAGE_CURRENT_TAG_UPDATE.type: {
        return {
          ...state,
          currentLanguageTag: LANGUAGE_CURRENT_TAG_UPDATE.payload(action) as LanguageTags,
        };
      }
      default:
        return state;
    }
  };
};
