/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'es';

export const es: RecursivePartial<DefaultLangKeys> = {
  label: 'Español',
  sagaTranslate: {
    id1: 'No se puede cargar el juego: No podemos abrir el juego en este momento. Por favor, inténtalo de nuevo más tarde.',
    id2: 'no es un ID de juego válido',
    id3: 'Algo ha salido mal',
    id4: 'La carta robada no existe',
    id5: 'No se ha encontrado el ID del juego',
    id6: 'Lo siento, Internet funciona un poco lento en este momento. Quédate mientras procesamos tu solicitud nuevamente',
    id7: 'Lamentablemente, aún no ha habido suerte. Todavía estamos intentando cargar el juego. Espera un momento...',
    id8: 'No hay acceso a Internet. Vuelve a conectarte e inténtalo de nuevo.',
    id9: 'No hay acceso a Internet.',
    id10: 'El identificador de acceso no se puede recuperar.',
    id11: 'Debes cargar el juego antes de poder cerrarlo.',
    id12: 'No se ha podido detener el juego',
    id13: 'No se ha podido encontrar este equipo',
    id14: 'La pieza del juego no se puede mover porque está fuera de alcance.',
    id15: 'No hemos recibido una respuesta válida (la respuesta no es válida)',
    id16: 'No hay más cartas disponibles.',
    id17: 'No se ha podido encontrar el juego',
    id18: 'No ha podido actualizarse el estado',
    id19: 'Los compañeros de equipo fueron conducidos con éxito a la reflexión final.',
    id20: 'El juego ha terminado, ¡accede a un juego existente para unirte a un nuevo juego!',
    id21: 'Tu reflexión se completó con éxito',
    id22: 'Carta editada',
  },
  screens: {
    navigation: {
      signed: 'Registrado como',
      home: 'Inicio',
      createGame: 'Crear un juego',
      joinGame: 'Acceder a un juego',
      about: 'Acerca de',
      logOut: 'Cerrar sesión',
      game: 'Juego',
      settings: 'Ajustes',
    },
    home: {
      hi: 'Hola',
      welcome: 'Bienvenido a ComPLAYance en un navegador.',
      goTo: 'En marcha',
      goToGameCreation: 'Crear un nuevo juego',
      goToJoinGame: 'Acceder a un juego ya existente',
      yourRunningGames: 'Selecciona un juego existente.',
      noRunningGames: 'No se pudo encontrar ningún juego existente, tienes las siguientes opciones:',
      login: 'Inicia sesión',
      loginBtn: 'Iniciar sesión',
      buttonLogin: 'Iniciar sesión',
      gameowner: 'Director del juego',
      created: 'Creado',
      enterGameBtn: 'Acceder a un juego',
    },
    settings: {
      settingsHeader: 'Ajustes',
      avatar: 'Avatar',
      name: 'Nombre',
      language: 'Idioma',
      selectLanguage: 'Seleccionar idioma',
      chooseNewImage: 'Elegir una nueva imagen',
      playerIcon: 'Icono del jugador',
      changeName: 'Cambiar el nombre',
      uploadImage: 'Cargar imagen',
      email: 'Correo electrónico',
      unknown: 'Desconocido',
      environment: 'Alrededores',
      environmentVariables: {
        buildShortTag: 'Crear etiqueta',
        apiUrl: 'Punto final de la API',
      },
    },
    gameCodes: {
      code: 'Código',
      waitingForCode: 'Espera a que se genere el código',
      startGame: 'Empezar juego',
    },
    joinGame: {
      title: 'Acceder a un juego',
      enterPin: 'Introducir PIN',
    },
    gameCreation: {
      team: 'EQUIPO',
      selectIconName: 'Elige un símbolo y un nombre',
      createTeams: 'Crear equipo',
      addTeam: 'Añadir un equipo',
      removeTeam: 'Eliminar un equipo',
      submit: 'OK',
      enterTeamName: 'Elegir nombre para el equipo',
      selectGameName: 'Elige un nombre para el juego',
      enterGameName: 'Introduce el nombre del juego',
      header: 'Crear un juego',
      noRole: 'Lamentablemente, no tienes los derechos necesarios para crear un juego.',
      availableGames: 'Juegos disponibles',
      summary: {
        summary: 'Resumen',
        gameName: 'Nombre del juego',
        team: 'Equipo',
        template: 'Plantilla',
        description: 'Descripción',
        difficulty: 'Grado de dificultad',
      },
      subHeaders: {
        gameName: 'Nombre del juego',
        gameTemplate: 'Plantilla del juego',
        teams: 'Equipos',
        summary: 'Resumen',
      },
      progressBar: {
        inactive: 'Vista general',
        summary: 'Espera',
        active: 'En procesamiento',
        done: 'Completado',
      },
      createGameBtn: 'Crear un juego',
      creationStep: 'Paso',
      goBtn: 'En marcha',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Mi principal potencial',
      subTitle: 'Mis reflexiones',
      mainSubTitle: 'Mi principal potencial',
      print: 'Imprimir',
    },
    gamePage: {
      yesBtn: 'Sí',
      stillHereHeader: '¿Sigues ahí?',
      stillHereBody: 'Llevas aquí mucho tiempo. ¿Aún estás jugando?',
      gameEndedHeader: 'Juego completado',
      gameEndedBody: 'El juego ha finalizado. Te reenviamos a la página de inicio',
      notPartOfGameHeader: 'No eres parte de este juego',
      notPartOfGameBody: 'Vuelve a la página de inicio y haz clic en unirse a un juego',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Juego de rol',
          reflection: 'Reflexión',
          discussion: 'Debate grupal',
          action: 'Promoción',
          challenge: 'Challenge',
          teamChallenge: 'Equipo Challenge',
        },
        waitForNewCard: 'Espera hasta que quien dirige el juego haya robado una nueva carta.',
        waitForNewCardHeader: 'Sin cartas',
        enterReflection: 'Registra tus reflexiones',
        submit: 'OK',
        guidance: 'Advertencias',
        replied: 'Respuesta',
        reflectionStateTextForGM: `Estamos en la última fase de reflexión. 
        Espera hasta que todos los jugadores hayan anotado su potencial principal e impreso o guardado sus reflexiones. 
        A continuación, puedes salir del juego haciendo clic en «Salir del juego».`,
        roleplay: {
          myAssignment: 'Mi tarea:',
          myRole: 'Mis roles:',
          noAssignment: 'No se te han asignado roles',
          noRole: 'Ningún rol',
        },
        answerText: {
          youAnswered: 'Has respondido',
          thisIs: 'Es',
          correct: 'correcto',
          incorrect: 'incorrecto',
          points: 'Puntos',
          guidance: 'Advertencias',
        },
      },
      teamCard: {
        score: 'Puntuación total:',
        role: 'Roles:',
        gamemaster: 'Director del juego',
        youreAboutToKickPlayer: 'Eliminar jugador',
        areYouSure: '¿Estás seguro de que deseas eliminar al compañero de equipo?',
      },
      gmControls: {
        gameInfo: 'Información sobre el juego',
        ok: 'Ok',
        stopGame: 'Finalizar juego',
        stopGameQuestion: '¿Estás seguro de que deseas finalizar el juego?',
        reflectionDeletion: ['¿Le pediste a los jugadores que imprimieran sus reflexiones?'],
        moveBack: 'Retroceder',
        moveForwards: 'Avanzar',
        drawCard: 'Coger carta',
        showInfo: 'Mostrar información',
        accept: 'Aceptar',
        endGameReflectionsbtn: 'Últimas reflexiones',
        gamename: 'Nombre del juego',
        gameId: 'ID del juego',
        sectionId: 'ID de sección',
        sectionName: 'Nombre de la sección',
        availCards: 'Cartas disponibles en el montón',
        currentCardId: 'ID de carta actual',
        teams: 'Equipos:',
        yes: 'Sí',
        notEveryOneHeader: 'No todos los jugadores han completado su reflexión',
        notEveryOneContent: '¿Estás seguro de que quieres mover la pieza del juego?',
        cardIsAlreadyDrawnBody: 'Ya has cogido una carta. ¿Estás seguro de que deseas coger una nueva carta?',
        cardIsAlreadyDrawnHeader: 'Ya has cogido una carta',
      },
    },
    others: { closeBtn: 'Cerrar' },
    errors: {
      notLoggedIn: 'Parece que no has iniciado sesión. Haz clic aquí para iniciar sesión.',
      reloadPage: 'Haz clic aquí para volver a cargar la página',
      goHome: 'Si el problema persiste, dirígete a la página de inicio e inténtalo de nuevo.',
      loginBtn: 'Iniciar sesión',
      homeBtn: 'Inicio',
      reloadBtn: 'Cargar de nuevo',
      login: 'Iniciar sesión',
    },
  },
};
