// Create schemas for validation.
import * as z from 'zod';

// Simple types.
export const BoardTypeSchema = z.enum([
  'solo',
  'oneVsOne',
  'teamVsTeam',
  'gameMasterSingleTeam',
]);
export type BoardTypeSchemaType = z.infer<typeof BoardTypeSchema>;

export const GameTypeSchema = z.enum([
  'solo',
  'oneVsOne',
  'teamVsTeam',
  'gameMasterSingleTeam',
]);
export type GameTypeSchemaType = z.infer<typeof GameTypeSchema>;

export const CardTypeSchema = z.enum([
  'action',
  'challenge',
  'teamchallenge',
  'reflection',
  'roleplay',
  'discussion',
  'dilemma',
]);
export type CardTypeSchemaType = z.infer<typeof CardTypeSchema>;

export const ReplyTypeSchema = z.enum([
  'answer',
  'answer_timeout',
  'turn_timeout',
]);
export type ReplyTypeSchemaType = z.infer<typeof ReplyTypeSchema>;

export const GameStatusSchema = z.enum([
  'running',
  'finished',
  'pending',
  'rejected',
  'endgame',
]);
export type GameStatusSchemaType = z.infer<typeof GameStatusSchema>;

export const GameDifficultySchema = z.enum([
  'easy',
  'moderat',
  'normal',
  'challenging',
  'hard',
]);
export type GameDifficultySchemaType = z.infer<typeof GameDifficultySchema>;

export const AccountRoleSchema = z.enum([
  'ROLE_PLAYER',
  'ROLE_ADMIN',
  'ROLE_GAMEMASTER',
]);
export type AccountRoleSchemaType = z.infer<typeof AccountRoleSchema>;

export const EndgameModeSchema = z.enum([
  'endgame-reflection',
  'endgame-award-ceremony',
]);
export type EndgameModeSchemaType = z.infer<typeof EndgameModeSchema>;

export const BoardPresentationSchema = z.enum(['standard', 'card_focused']);
export type BoardPresentationSchemaType = z.infer<
  typeof BoardPresentationSchema
>;

//// Interface object validation schema definitions
//
export const ProfileSchema = z.object({
  id: z.number().nullable(),
  name: z.string().nullable(),
  email: z.string().optional().nullable(),
  avatar: z.string().optional().nullable(),
  companyName: z.string().optional().nullable(),
  companyId: z.number().optional().nullable(),
  score: z.number().nullable(),
  companyRanking: z.number().nullable(),
  isAdmin: z.boolean().optional(),
  roles: z.array(AccountRoleSchema),
});
export type ProfileSchemaType = z.infer<typeof ProfileSchema>;

// Coopgameboard section game tokens.
export const GameTokenSchema = z.object({
  id: z.number(),
  icon: z.string(),
  name: z.string(),
  cost: z.number(),
});
export type GameTokenSchemaType = z.infer<typeof GameTokenSchema>;

export const CurrentCardStateSchema = z.object({
  roleId: z.number().optional().nullable(),
  roleName: z.string().optional().nullable(),
  answered: z.boolean().optional(),
});
export type CurrentCardStateSchemaType = z.infer<typeof CurrentCardStateSchema>;

// Player schema
export const PlayerSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  avatar: z.string().nullable(),
  email: z.string().nullable(), // nullable when guest
  score: z.number().nullable(),
  companyRanking: z.number().nullable(),
  // this will only be from the state -> player and not elsewhere.
  currentCardState: CurrentCardStateSchema.optional().nullable(),
  appVersion: z.string().optional().nullable(),
});
export type PlayerSchemaType = z.infer<typeof PlayerSchema>;

// GameTemplateTeam schema
export const GameTemplateTeamSchema = z.object({
  emails: z.array(z.string()),
  icon: z.string().optional(),
  name: z.string().optional(),
});
export type GameTemplateTeamSchemaType = z.infer<typeof GameTemplateTeamSchema>;

// Game Instance and supporting objects
export const GameBoardSchema = z.object({
  id: z.string(),
  // type: BoardTypeSchema,
  rows: z.number(),
  cols: z.number(),
});
export type GameBoardSchemaType = z.infer<typeof GameBoardSchema>;

export const GameTemplateSchema = z.object({
  id: z.number(),
  name: z.string(), // fx GDPR spillet. kommer fra template.
  // To be extended at a later point to support multiple boards and different team sizes.
  board: GameBoardSchema,
  difficulty: GameDifficultySchema,
  roundTimeout: z.number(),
  description: z.string(),
  maximumNumberOfTeams: z.number().nullable().optional(),
  minimumNumberOfTeams: z.number().nullable().optional(),
});
export type GameTemplateSchemaType = z.infer<typeof GameTemplateSchema>;

export const ModifierSchema = z.object({
  type: z.enum(['bonus', 'forfeit']),
  value: z.number().optional(),
});
export type ModifierSchemaType = z.infer<typeof ModifierSchema>;

export const AnswerSchema = z.object({
  id: z.number(),
  short: z.string(),
  text: z.string(),
  points: z.number(),
  correct: z.boolean(),
});
export type AnswerSchemaType = z.infer<typeof AnswerSchema>;

export const GameRoleSchema = z.object({
  id: z.number(),
  name: z.string(),
  guidance: z.string(),
});
export type GameRoleSchemaType = z.infer<typeof GameRoleSchema>;

export const LinkSchema = z.object({
  linkText: z.string(),
  URL: z.string(),
  icon: z.string(),
});
export type LinkScehmaType = z.infer<typeof LinkSchema>;

export const CardSchema = z.object({
  id: z.number(),
  type: CardTypeSchema,
  modifiers: z.array(ModifierSchema),
  title: z.string(),
  body: z.string(),
  question: z.string(),
  timelimit: z.number(),
  guidance: z.string(),
  answers: z.array(AnswerSchema),
  color: z.string(),
  /**
   * @deprecated Client no longer wants to use this
   */
  roles: z.array(GameRoleSchema).optional().nullable(),
  link: LinkSchema.optional().nullable(),
});
export type CardSchemaType = z.infer<typeof CardSchema>;

const pendingPlayersStatusSchema = z.object({
  status: z.enum(['rejected', 'pending']),
  email: z.string().nullable(),
  player: PlayerSchema.nullable(),
});

export const TeamSectionPointsSchema = z.object({
  sectionId: z.number(),
  point: z.number(),
});
export type TeamSectionPointsSchemaType = z.infer<
  typeof TeamSectionPointsSchema
>;

export const TeamSchema = z.object({
  id: z.number(),
  name: z.string(),
  icon: z.string(),
  pendingPlayersStatus: z.array(pendingPlayersStatusSchema),
  players: z.array(z.number()),
  score: z.number(),
  gameboardPosition: z.number().nullable(),
  code: z.string(),
  teamSectionPoints: z.array(TeamSectionPointsSchema).optional(),
  acquiredGameTokens: z.array(GameTokenSchema).optional(),
  /**
   * @deprecated The client has wished to no longer use this in game.
   */
  assignedRole: z.number().optional().nullable(),
});
export type TeamSchemaType = z.infer<typeof TeamSchema>;

export const ReplySchema = z.object({
  cardId: z.number().optional().nullable(),
  answerId: z.number().optional().nullable(),
  teamId: z.number(),
  playerId: z.number().optional().nullable(),
  duration: z.number().optional().nullable(),
  repliedDate: z.string().optional().nullable(),
  type: ReplyTypeSchema,
  awardedPoints: z.number().optional(),
  answeredByCardPicker: z.boolean().optional().nullable(),
  sectionId: z.number().optional(),
  answerText: z.string().optional(),
});
export type ReplySchemaType = z.infer<typeof ReplySchema>;

export const CardDrawnSchema = z.object({
  cardId: z.number(),
  playerId: z.number(),
  drawnTimestamp: z.string().nullable().optional(),
});
export type CardDrawnSchemaType = z.infer<typeof CardDrawnSchema>;

export const VictoryPointsSchema = z.object({
  teamId: z.number(),
  points: z.number().optional(),
});
export type VictoryPointsSchemaType = z.infer<typeof VictoryPointsSchema>;

export const ChallengeModeSchema = z.object({
  running: z.boolean(),
  cardId: z.number().optional(),
  startTimeStamp: z.string().nullable().optional(), // epoch time
});
export type ChallengeModeSchemaType = z.infer<typeof ChallengeModeSchema>;

export const SharedDataSchema = z.object({
  // can always add more to sharedData, but everything most be optional
  victoryPoints: z.array(VictoryPointsSchema).optional(),
  challengeMode: ChallengeModeSchema.optional(),
  usedDataCardIds: z.array(z.number()).optional(),
});
export type SharedDataSchemaType = z.infer<typeof SharedDataSchema>;

export const GameStateSchema = z.object({
  teams: z.array(TeamSchema),
  replies: z.array(ReplySchema),
  currentTeam: z.number().nullable(),
  status: GameStatusSchema,
  turnDeadline: z.string().nullable(),
  lastTurnChange: z.string().nullable(), //string date-time
  lastSectionChange: z.string().nullable().optional(), //string date-time
  drawn: CardDrawnSchema.nullable(),
  players: z.array(PlayerSchema),
  currentSectionCardId: z.number().nullable().optional(), //The card selected in the current section
  sharedData: SharedDataSchema.nullable(),
});
export type GameStateSchemaType = z.infer<typeof GameStateSchema>;

// CoopGameBoard position
export const CoopGamePositionSchema = z.object({
  positionX: z.number(),
  positionY: z.number(),
});
export type CoopGamePositionSchemaType = z.infer<typeof CoopGamePositionSchema>;

// CoopGameBoard tiles
export const CoopGameTilesSchema = z.object({
  position: CoopGamePositionSchema,
  cards: z.array(CardSchema),
});
export type CoopGameTilesSchemaType = z.infer<typeof CoopGameTilesSchema>;

// CoopGameBoard section
export const CoopGameSectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  textMode: z.enum(['dark', 'light']),
  tiles: z.array(CoopGameTilesSchema),
  gameTokens: z.array(GameTokenSchema).optional(),
});
export type CoopGameSectionSchemaType = z.infer<typeof CoopGameSectionSchema>;

// CoopGameBoard
export const CoopGameBoardSchema = z.object({
  id: z.string(),
  backgroundImage: z.string(),
  name: z.string(),
  sections: z.array(CoopGameSectionSchema),
  startingPosition: CoopGamePositionSchema,
  finishingPosition: CoopGamePositionSchema,
  backgroundColor: z.string(),
  useDropshadow: z.boolean(),
  presentation: BoardPresentationSchema.optional().nullable(),
  cards: z.array(z.number()),
});
export type CoopGameBoardSchemaType = z.infer<typeof CoopGameBoardSchema>;

// GameInstance
export const GameInstanceSchema = z.object({
  id: z.number(),
  name: z.string().optional(),
  gameTemplate: GameTemplateSchema,
  createdTime: z.string(),
  endTime: z.string().nullable(),
  finishedTime: z.string().optional().nullable(),
  players: z.array(PlayerSchema),
  owner: z.number(),
  type: GameTypeSchema,
  cards: z.array(CardSchema),
  state: GameStateSchema,
  lostTurnPoints: z.number(),
  timeoutPoints: z.number(),
  pokes: z.any(),
  virtualGameboard: CoopGameBoardSchema.optional(),
  status: GameStatusSchema.optional(),
  teams: z.array(TeamSchema).optional(),
  instructionsLink: z.string().optional(),
  endgameEnabled: z.boolean().optional(),
  endgameMode: EndgameModeSchema.nullable().optional(),
  reflectionsDeletedWhenGameEnds: z.boolean().optional(),
});
export type GameInstanceSchemaType = z.infer<typeof GameInstanceSchema>;

export const MyReflectionSchema = z.object({
  id: z.number(),
  sectionId: z.number(),
  reflectionText: z.string(),
});
export type MyReflectionSchemaType = z.infer<typeof MyReflectionSchema>;

export const MyEndGameReflectionSchema = z.object({
  createdTime: z.string(),
  id: z.number(),
  reflectionText: z.string(),
  sectionId: z.number().nullable().optional(),
  type: z.string(),
});
export type MyEndGameReflectionSchemaType = z.infer<
  typeof MyEndGameReflectionSchema
>;

// Login flavor
const LoginSystemsScheme = z.enum(['auth0', 'keycloak']);
export type LoginSystemsSchemeType = z.infer<typeof LoginSystemsScheme>;

export const LoginFlavorSchema = z.object({
  type: LoginSystemsScheme,
  version: z.number(),
});

export type LoginFlavorSchemaType = z.infer<typeof LoginFlavorSchema>;
