import { connect } from 'react-redux';
import APILoader from '../components/UI/APILoader';

const mapStateToProps = (state: AppState) => {
  return { show: state.api.isLoading };
};

const mapDispatchToProps = (/* dispatch: Dispatch<GenericAction>*/) => (
  {}
);

export default connect(mapStateToProps, mapDispatchToProps)(APILoader);
