/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'nl';

export const nl: RecursivePartial<DefaultLangKeys> = {
  label: 'Nederlands',
  sagaTranslate: {
    id1: 'Het spel kan niet worden geladen: We kunnen het spel momenteel niet openen. Probeer het later nog een keer.',
    id2: 'Is geen geldige spel-ID',
    id3: 'Er is iets verkeerd gegaan',
    id4: 'De getrokken kaart bestaat niet',
    id5: 'Spel ID kon niet worden gevonden',
    id6: 'Het spijt ons, het internet is momenteel wat langzaam. Heb alsjeblieft even geduld terwijl wij je aanvraag opnieuw verwerken',
    id7: 'Helaas lukt het nog steeds niet. We proberen nog altijd om het spel opnieuw te laden. Nog even geduld alsjeblieft ...',
    id8: 'Momenteel hebben we geen internettoegang. Herstel de verbinding en probeer het opnieuw.',
    id9: 'Geen internettoegang.',
    id10: 'Het toegangstoken kan niet opnieuw worden gemaakt.',
    id11: 'Je moet het spel laden voordat je het kunt sluiten.',
    id12: 'Het spel kon niet worden gestopt',
    id13: 'Dit team kon niet worden gevonden',
    id14: 'Het speelstuk kon niet worden bewogen omdat het buiten bereik is.',
    id15: 'We hebben geen geldig antwoord gekregen (antwoord is ongeldig)',
    id16: 'Er zijn niet meer kaarten beschikbaar.',
    id17: 'Het spel kon niet worden gevonden',
    id18: 'Status kon niet worden gevonden',
    id19: 'De medespelers zijn met succes naar de uiteindelijke reflectie geleid.',
    id20: 'Het spel is beëindigd. Ga naar meedoen met een bestaand spel om mee te doen met een bestaand spel!',
    id21: 'Je reflectie is met succes afgesloten',
    id22: 'Kaart bewerkt',
  },
  screens: {
    navigation: {
      signed: 'Aangemeld als',
      home: 'Home',
      createGame: 'Spel opstellen',
      joinGame: 'Meedoen met spel',
      about: 'Over',
      logOut: 'Afmelden',
      game: 'Spel',
      settings: 'Instellingen',
    },
    home: {
      hi: 'Hallo',
      welcome: 'Welkom bij Gaming4Impact in een browser.',
      goTo: 'Daar gaan we\'',
      goToGameCreation: 'Een nieuw spel opstellen',
      goToJoinGame: 'Meedoen met een bestaand spel',
      yourRunningGames: 'Kies een bestaand spel uit.',
      noRunningGames: 'Er kon geen bestaand spel worden gevonden, je hebt de volgende mogelijkheden:',
      login: 'Meld je aan',
      loginBtn: 'Aanmelden',
      buttonLogin: 'Aanmelden',
      gameowner: 'Spelleider',
      created: 'Opgesteld',
      enterGameBtn: 'Meedoen met spel',
    },
    settings: {
      settingsHeader: 'Instellingen',
      avatar: 'Avatar',
      name: 'Naam',
      language: 'Taal',
      selectLanguage: 'Taal kiezen',
      chooseNewImage: 'Nieuwe afbeelding kiezen',
      playerIcon: 'Speler-pictogram',
      changeName: 'Naam veranderen',
      uploadImage: 'Afbeelding uploaden',
      email: 'E-mail',
      unknown: 'Onbekend',
      environment: 'Omgeving',
      environmentVariables: {
        buildShortTag: 'Tag aanmaken',
        apiUrl: 'API eindpunt',
      },
    },
    gameCodes: {
      code: 'Code',
      waitingForCode: 'Wacht tot er een code is aangemaakt',
      startGame: 'Spel starten',
    },
    joinGame: {
      title: 'Meedoen met spel',
      enterPin: 'Pin invoeren',
    },
    gameCreation: {
      team: 'TEAM',
      selectIconName: 'Kies een symbool en een naam',
      createTeams: 'Team opstellen',
      addTeam: 'Een team toevoegen',
      removeTeam: 'Een team verwijderen',
      submit: 'OK',
      enterTeamName: 'Teamnaam kiezen',
      selectGameName: 'Kies een spelnaam',
      enterGameName: 'Voer de spelnaam in',
      header: 'Spel opstellen',
      noRole: 'Helaas heb je niet de benodigde rechten op een spel op te stellen.',
      availableGames: 'Beschikbare spelen',
      summary: {
        summary: 'Samenvatting',
        gameName: 'Spel naam',
        team: 'Team',
        template: 'Ontwerp',
        description: 'Beschrijving',
        difficulty: 'Moeilijkheidsgraad',
      },
      subHeaders: {
        gameName: 'Spel naam',
        gameTemplate: 'Spelontwerp',
        teams: 'Teams',
        summary: 'Samenvatting',
      },
      progressBar: {
        inactive: 'Overzicht',
        summary: 'Wachten',
        active: 'In uitvoering',
        done: 'Afgesloten',
      },
      createGameBtn: 'Spel opstellen',
      creationStep: 'Stap',
      goBtn: 'Daar gaan we',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Mijn hoofdpotentie',
      subTitle: 'Mijn reflecties',
      mainSubTitle: 'Mijn hoofdpotentie',
      print: 'Afdrukken',
    },
    gamePage: {
      yesBtn: 'Ja',
      stillHereHeader: 'Ben je er nog?',
      stillHereBody: 'Je bent er al heel lang. Ben je nog altijd aan het spelen?',
      gameEndedHeader: 'Spel afgesloten',
      gameEndedBody: 'Het spel is geëindigd. We brengen je terug naar de startpagina.',
      notPartOfGameHeader: 'Je doet helaas nog niet mee met dit spel',
      notPartOfGameBody: 'Ga terug naar de startpagina en klik op Meedoen met spel',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Rollenspel',
          reflection: 'Reflectie',
          discussion: 'Groepsdiscussie',
          action: 'Actie',
          challenge: 'Uitdaging (challenge)',
          teamChallenge: 'Team Challenge',
        },
        waitForNewCard: 'Wacht tot de spelleider een nieuwe kaart heeft getrokken.',
        waitForNewCardHeader: 'Geen kaart',
        enterReflection: 'Houd je reflecties vast',
        submit: 'OK',
        guidance: 'Aanwijzingen',
        replied: 'Antwoord',
        reflectionStateTextForGM: `We zijn nu in de laatste reflectiefase. 
        Wacht tot alle spellers hun hoofdpotentie hebben genoteerd en hun reflecties hebben afgedrukt of opgeslagen. 
        Vervolgens kun je het spel beëindigen door te klikken op "Spel beëindigen".`,
        roleplay: {
          myAssignment: 'Mijn taak:',
          myRole: 'Mijn rol:',
          noAssignment: 'Er is geen rol aan jou toegewezen',
          noRole: 'Geen rol',
        },
        answerText: {
          youAnswered: 'Je hebt geantwoord',
          thisIs: 'Dat is',
          correct: 'goed',
          incorrect: 'niet goed',
          points: 'Punten',
          guidance: 'Aanwijzingen',
        },
      },
      teamCard: {
        score: 'Totaal aantal punten:',
        role: 'Rol:',
        gamemaster: 'Spelleider',
        youreAboutToKickPlayer: 'Speler verwijderen',
        areYouSure: 'Weet je zeker dat je je medespeler wilt verwijderen?',
      },
      gmControls: {
        gameInfo: 'Spelinformatie',
        ok: 'Ok',
        stopGame: 'Spel beëindigen',
        stopGameQuestion: 'Weet je zeker dat je het spel wilt beëindigen?',
        reflectionDeletion: ['Heb je de spelers gevraagd, hun reflecties af te drukken?'],
        moveBack: 'Naar achteren rijden',
        moveForwards: 'Naar voren bewegen',
        drawCard: 'Kaart trekken',
        showInfo: 'Informatie laten zien',
        accept: 'Akkoord gaan',
        endGameReflectionsbtn: 'Laatste reflecties',
        gamename: 'Spel naam',
        gameId: 'Spel-ID',
        sectionId: 'Hoofdstuk-ID',
        sectionName: 'Naam van het hoofdstuk',
        availCards: 'Beschikbare kaarten op de stapel',
        currentCardId: 'Huidige kaart-ID',
        teams: 'Teams:',
        yes: 'Ja',
        notEveryOneHeader: 'Niet alle spelers hebben hun reflectie afgesloten',
        notEveryOneContent: 'Weet je zeker dat je het speelstuk verder wilt laten gaan?',
        cardIsAlreadyDrawnBody: 'Je hebt al een kaart getrokken. Weet je zeker dat je een nieuwe kaart wilt trekken?',
        cardIsAlreadyDrawnHeader: 'Je hebt al een kaart getrokken',
      },
    },
    others: { closeBtn: 'Sluiten' },
    errors: {
      notLoggedIn: 'Blijkbaar ben je niet ingelogd. Klik hier om je aan te melden.',
      reloadPage: 'Klik hier om de pagina opnieuw te laden',
      goHome: 'Als het probleem zich blijft voordoen, ga dan naar de startpagina en probeer het opnieuw.',
      loginBtn: 'Aanmelden',
      homeBtn: 'Home',
      reloadBtn: 'Opnieuw laden',
      login: 'Login',
    },
  },
};
