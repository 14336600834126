import React from 'react';
import styles from './TeamCards/TeamComponent.module.css';
import PlayerAvatar from './PlayerAvatar';
import { mainColors } from '../../utils/variables';
import Icon from './Icon';
import { StringsConsumer } from '../../locales';
import PopUpBox from './PopUpBox';
import { ThemeContext } from '../../App';

interface Props {
  player?: Player;
  cardType?: CardType;
  hasFinishedTask?: boolean;
  isGameOwner?: boolean;
  showRemoveBtn?: boolean;
  onRemoveBtnClick?: () => void;
}

const PlayerBox: React.FC<Props> = (props) => {
  const [showRemovePopup, setShowRemovePopup] = React.useState(false);

  const theme = React.useContext(ThemeContext);

  const playerRole = (roleText: string, gmText: string) => {
    if (
      props.cardType === 'roleplay' &&
      props.player?.currentCardState?.roleName
    ) {
      return (
        <p
          className={styles.playerRole}
          style={{ lineHeight: '15px', whiteSpace: 'nowrap' }}
        >
          {roleText} {props.player?.currentCardState?.roleName}
        </p>
      );
    }
    if (props.isGameOwner) {
      return (
        <p
          className={styles.playerRole}
          style={{ lineHeight: '15px', whiteSpace: 'nowrap' }}
        >
          {roleText} {gmText}
        </p>
      );
    }
    return <div />;
  };

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <div
            style={{
              backgroundColor: mainColors.lightGrey,
              padding: '5px 15px 5px 5px',
              borderRadius: '30px',
              margin: '5px 0',
              flexShrink: 0,
            }}
            className={styles.playerContainer}
          >
            <div style={{ alignSelf: 'center' }}>
              <PlayerAvatar
                player={props.player}
                text={props.player?.name}
                size={35}
              />
            </div>
            {props.cardType === 'reflection' &&
              props.player?.currentCardState?.answered && (
                <div
                  style={{
                    position: 'relative',
                    bottom: 5,
                    margin: '-3px -10px -3px -3px',
                  }}
                >
                  <Icon icon="check" color={theme.primary} size={15} />
                </div>
              )}
            <div
              className={styles.teamRoles}
              style={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <p
                className={styles.playerName}
                style={{
                  color: mainColors.black,
                  lineHeight: '15px',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.player?.name || ''}
              </p>
              {playerRole(gamePage.teamCard.role, gamePage.teamCard.gamemaster)}
            </div>
            {props.showRemoveBtn === true && props.isGameOwner !== true && (
              <div style={{ margin: 'auto' }}>
                <button
                  style={{
                    color: mainColors.white,
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    backgroundColor: mainColors.red,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '15px',
                    border: 'none',
                  }}
                  onClick={() => setShowRemovePopup(true)}
                  type="button"
                >
                  <div>
                    <Icon icon="times" color={mainColors.white} size={15} />
                  </div>
                </button>
              </div>
            )}
            <PopUpBox
              key="Popup box for confirming to kick a player"
              type="confirm"
              onAction={props.onRemoveBtnClick}
              show={showRemovePopup}
              onClose={() => setShowRemovePopup(false)}
              actionButtonText={gamePage.yesBtn}
              header={gamePage.teamCard.youreAboutToKickPlayer}
              content={gamePage.teamCard.areYouSure}
            />
          </div>
        );
      }}
    </StringsConsumer>
  );
};

export default PlayerBox;
