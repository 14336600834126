import { takeEvery, delay } from 'redux-saga/effects';
import merge from 'deepmerge';
import {
  changeLocale,
  setTranslations,
  getCurrentTranslations,
  isValidLocale,
} from '../Providers';
import { LANGUAGE_CURRENT_TAG_UPDATE, LANGUAGE_TRANSLATIONS_UPDATE } from '../actions';

function* handleLanguageStringsListener(action: any) {
  switch (action.type) {
    case LANGUAGE_CURRENT_TAG_UPDATE.type: {
      while (!changeLocale()) {
        yield delay(100); // initialization but workaround
      }
      const newTag = LANGUAGE_CURRENT_TAG_UPDATE.payload(action);
      if (isValidLocale(newTag)) {
        changeLocale()(newTag);
      }

      break;
    }

    case LANGUAGE_TRANSLATIONS_UPDATE.type: {
      const oldTranslations = { ...getCurrentTranslations() };
      const translationPatch = LANGUAGE_TRANSLATIONS_UPDATE.payload(action);

      const updatedTranslations = merge(oldTranslations, translationPatch);
      setTranslations()(updatedTranslations);
      break;
    }

    default: {
      console.warn('Invalid action type for handling flash messages');
      break;
    }
  }
}

export const languageStringsListenerCreator = () => {
  // eslint-disable-next-line func-names
  return function* () {
    yield takeEvery([
      LANGUAGE_CURRENT_TAG_UPDATE.type,
      LANGUAGE_TRANSLATIONS_UPDATE.type,
    ],
    handleLanguageStringsListener);
  };
};
