import {
  APP_RESET_STORE,
  FACTORY_TEMPLATES_AVAILABLE_SUCCESS,
  FACTORY_DRAFT_CLEAR_UPDATE,
  FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS,
  FACTORY_DRAFT_SET_GAME_TEMPLATE_FAILURE,
  FACTORY_DRAFT_SUBMIT_REQUEST,
  FACTORY_DRAFT_SUBMIT_SUCCESS,
  FACTORY_DRAFT_SUBMIT_FAILURE,
  FACTORY_COMPANY_PLAYERS_UPDATE,
  FACTORY_DRAFT_SET_TEAM_SUCCESS,
  FACTORY_TEMPLATES_AVAILABLE_REQUEST,
  FACTORY_GAME_NAME_SET,
  FACTORY_SELECT_UPDATE_SELECTED,
  FACTORY_DRAFT_SET_COOP_SUCCESS,
  FACTORY_TEAM_ICON_SUCCESS,
  FACTORY_TEAM_NAME_SUCCESS,
  FACTORY_TEAM_ADD_NEW_SET,
  FACTORY_TEAM_REMOVE_SET,
  FACTORY_TEAMS_SET,
} from '../actions';

interface GameFactory {
  availableGameTemplates?: GameTemplate[];
  gameDraft?: GameDraft;
  status: {
    isLoading: boolean;
  };
  availablePlayers: Player[];
  select?: {
    mode?: 'single' | 'multiple';
    filter?: string;
    selectedPlayers?: Player[];
  };
  teamsHolder: {
    emails: string[];
    name?: string;
    icon?: string;
    code?: string;
  }[];
}

const initialState = {
  status: { isLoading: false },
  availablePlayers: [],
  teamsHolder: [{ emails: [] }],
};

export function factory(
  state: GameFactory = initialState,
  action: GenericAction
): GameFactory {
  switch (action.type) {
    case FACTORY_TEMPLATES_AVAILABLE_REQUEST.type: {
      return { ...state };
    }
    case FACTORY_TEMPLATES_AVAILABLE_SUCCESS.type: {
      const payload = FACTORY_TEMPLATES_AVAILABLE_SUCCESS.payload(action);
      const payloadStr = JSON.parse(JSON.stringify(payload));

      return {
        ...state,
        availableGameTemplates: [...payloadStr],
      };
    }

    case FACTORY_DRAFT_CLEAR_UPDATE.type: {
      return {
        ...state,
        gameDraft: undefined,
        select: undefined,
        teamsHolder: [{ emails: [] }],
      };
    }

    case FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS.type: {
      const payload = FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS.payload(action);
      return {
        ...state,
        gameDraft: {
          ...state.gameDraft,
          gameTemplate: payload,
        },
        status: {
          ...state.status,
          isLoading: false,
        },
      };
    }

    case FACTORY_DRAFT_SUBMIT_REQUEST.type: {
      return {
        ...state,
        status: { isLoading: true },
      };
    }

    case FACTORY_DRAFT_SUBMIT_SUCCESS.type: {
      return {
        ...state,
        gameDraft: undefined,
        status: { isLoading: false },
      };
    }

    case FACTORY_DRAFT_SUBMIT_FAILURE.type: {
      return {
        ...state,
        status: { isLoading: false },
      };
    }

    case FACTORY_DRAFT_SET_GAME_TEMPLATE_FAILURE.type: {
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: false,
        },
      };
    }

    case FACTORY_COMPANY_PLAYERS_UPDATE.type: {
      const payload = FACTORY_COMPANY_PLAYERS_UPDATE.payload(action);

      return {
        ...state,
        availablePlayers: payload,
        status: {
          ...state.status,
          isLoading: false,
        },
      };
    }

    case FACTORY_DRAFT_SET_TEAM_SUCCESS.type: {
      const payload = FACTORY_DRAFT_SET_TEAM_SUCCESS.payload(action);
      return {
        ...state,
        gameDraft: payload,
      };
    }

    case FACTORY_GAME_NAME_SET.type: {
      const payload = FACTORY_GAME_NAME_SET.payload(action);
      return {
        ...state,
        gameDraft: {
          ...state.gameDraft,
          name: payload,
        },
      };
    }

    case FACTORY_SELECT_UPDATE_SELECTED.type: {
      const payload = FACTORY_SELECT_UPDATE_SELECTED.payload(action);
      return {
        ...state,
        select: {
          ...state.select,
          selectedPlayers: payload,
        },
      };
    }

    case FACTORY_DRAFT_SET_COOP_SUCCESS.type: {
      const payload = FACTORY_DRAFT_SET_COOP_SUCCESS.payload(action);
      return {
        ...state,
        gameDraft: payload,
      };
    }

    case FACTORY_TEAM_ICON_SUCCESS.type: {
      const payload = FACTORY_TEAM_ICON_SUCCESS.payload(action);
      return {
        ...state,
        teamsHolder: payload.teamsHolder,
      };
    }

    case FACTORY_TEAM_NAME_SUCCESS.type: {
      const payload = FACTORY_TEAM_NAME_SUCCESS.payload(action);
      return {
        ...state,
        teamsHolder: payload.teamsHolder,
      };
    }

    case FACTORY_TEAM_ADD_NEW_SET.type: {
      return {
        ...state,
        teamsHolder: [...state.teamsHolder, { emails: [] }],
      };
    }

    case FACTORY_TEAM_REMOVE_SET.type: {
      return {
        ...state,
        teamsHolder: [...state.teamsHolder.slice(0, -1)],
      };
    }

    case FACTORY_TEAMS_SET.type: {
      const payload = FACTORY_TEAMS_SET.payload(action);
      return {
        ...state,
        teamsHolder: [...payload.teams],
      };
    }

    case APP_RESET_STORE.type:
      return initialState;

    default: {
      return state;
    }
  }
}
