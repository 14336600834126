import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../../../../../App';
import Button from '../../../../../../components/UI/Button';
import Markdown from '../../../../../../components/UI/Markdown';
import { StringsConsumer } from '../../../../../../locales';
import { engine } from '../../../../../../redux/selectors';

interface Props {
  activeCard: DCardInstance | undefined;
  currentTeam: DTeam | undefined;
  isGameOwner: boolean | undefined;
  onAnswer: (reply: Reply) => void;
  isModal: boolean | undefined;
}

function RolePlayCard(props: Props) {
  const { activeCard, currentTeam } = props;
  const theme = useContext(ThemeContext);

  let cardReply: DReply | undefined;
  if (activeCard?.replies) {
    if (activeCard.replies.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      cardReply = activeCard.replies[0];
    }
  }

  const onPressAnswer = (answerId: number) => {
    if (activeCard && answerId && currentTeam) {
      const answerObject: Reply = {
        type: 'answer',
        cardId: activeCard.id,
        answerId,
        teamId: currentTeam.id,
        playerId: currentTeam.players[0].id,
      };
      props.onAnswer(answerObject);
    }
  };
  const myRolePlayRole = useSelector(engine.myRoleInformation);
  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <div>
            {props.isGameOwner ? (
              <div>
                <br />
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  {gamePage.activeCard.guidance}
                </span>
                <br />
                <Markdown fontSize="16px" style={{ whiteSpace: 'pre-wrap' }}>
                  {props.activeCard?.guidance}
                </Markdown>
                {cardReply ? (
                  <div>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                      {gamePage.activeCard.replied}
                    </span>
                    : <Markdown>{cardReply?.answer?.text}</Markdown>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '5px',
                      flexFlow: 'row wrap',
                      width: '100%',
                      height: '100%',
                      alignItems: 'flex-end',
                    }}
                  >
                    {props.activeCard?.answers.map((answer) => {
                      return (
                        <Button
                          title={`${answer.text}`}
                          onClick={() => onPressAnswer(answer.id)}
                          style={
                            props.activeCard?.answers.length === 4
                              ? {
                                  margin: '5px 10px',
                                  width: '40%',
                                }
                              : {
                                  margin: '5px 10px',
                                }
                          }
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {theme.content.showNoRole && (
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {gamePage.activeCard.roleplay.myRole}
                    </span>{' '}
                    {myRolePlayRole?.name
                      ? myRolePlayRole.name
                      : gamePage.activeCard.roleplay.noRole}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                      {gamePage.activeCard.roleplay.myAssignment}
                    </span>
                    <br />
                    <Markdown>
                      {myRolePlayRole?.guidance
                        ? myRolePlayRole.guidance
                        : gamePage.activeCard.roleplay.noAssignment}
                    </Markdown>
                    {cardReply && (
                      <div>
                        <br />
                        <span style={{ fontWeight: 'bold' }}>
                          {gamePage.activeCard.replied}
                        </span>
                        : <Markdown>{cardReply?.answer?.text}</Markdown>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }}
    </StringsConsumer>
  );
}

export default RolePlayCard;
