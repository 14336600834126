import React, { useContext } from 'react';
import styles from './ErstContent.module.css';
import { ThemeContext } from '../../../../App';

import P0 from './assets/point0.png';
import P1 from './assets/point1.png';
import P3 from './assets/point3.png';
import Icon, { SVGIcon } from '../../Icon';

export const ErstContent = () => {
  const theme = useContext(ThemeContext);
  return (
    <div className={styles.container}>
      <hr className={styles.horizontal_line}></hr>
      <span className={styles.points}>
        <RenderItem src={P0} text="Ingen relevante overvejelser" />
        <RenderItem src={P1} text="Få relevante overvejelser" />
        <RenderItem src={P3} text="Adskillige relevante overvejelser" />
      </span>
      <Phases />
      <div className={styles.description}>
        <p>
          <span
            style={{ color: theme.buttonColors.active, fontWeight: 'bold' }}
          >
            Køb et værktøj = 5 point
          </span>
          <br />
          Stetoskop, Boremaskine eller en Dynamitstang.
          <br />
          <br />
          <span
            style={{ color: theme.buttonColors.active, fontWeight: 'bold' }}
          >
            Køb en udfordring = 2 point
          </span>
          <br />
          Overtag jeres modstanders værktøj ved at komme med flere relevante
          reflektioner end dem.
          <br />
          Kræver at jeres modstander har et værktøj.
        </p>
      </div>
    </div>
  );
};

interface Props {
  src: string;
  text: string;
}
function RenderItem({ src, text }: Props) {
  return (
    <div>
      <img width="136px" src={src} />
      <p>{text}</p>
    </div>
  );
}

function Phases() {
  const item = (icon: SVGIcon, name: string) => {
    return (
      <div>
        <Icon icon={icon} color="white" size="medium" />
        <p>{name}</p>
      </div>
    );
  };
  return (
    <div className={styles.phases}>
      <p className={styles.phases_title}>Faser</p>
      <span className={styles.gamePhases}>
        {item('stethoscope', '1. Indsamling')}
        {item('powertool', '2. Bearbejdning')}
        {item('dynamite', '3. Anvendelse')}
      </span>
    </div>
  );
}
