import React, { useRef, useContext, useEffect } from 'react';
import { StringsConsumer } from '../../../../locales';
import styles from '../../TeamCards/TeamComponent.module.css';
import DiceContainer from '..';
import { ThemeContext } from '../../../../App';
import { useSelector } from 'react-redux';
import { engine } from '../../../../redux/selectors';

interface Props {
  isGameOwner?: boolean; // for gameowner
  currentTeamId?: number | undefined | null;
  rollNumber: number;
  combinedTeamPosition: number;
  currentGame: DGameInstance | undefined;
  gameLength: number | undefined;
  currentTeamPosToNextSection: number | undefined;
  drawCardAfterRoll: boolean;
  onMoveSection: () => void;
  onDiceRollDone: (dieValue: number, teamId: number) => void;
  onDiceRollDoneToEndgame: (dieValue: number, teamId: number) => void;
  onDrawCard: () => void;
}

/* PLEASE USE CONTAINER */
const DiceButton = ({
  isGameOwner,
  currentTeamId,
  rollNumber,
  combinedTeamPosition,
  currentGame,
  gameLength,
  currentTeamPosToNextSection,
  drawCardAfterRoll,
  onMoveSection,
  onDiceRollDone,
  onDiceRollDoneToEndgame,
  onDrawCard,
}: Props) => {
  const theme = useContext(ThemeContext);
  let diceRef = useRef<DiceContainer | null>(null);
  const [eyes, setEyes] = React.useState(6);
  const [rolled, setRolled] = React.useState(false);
  const [clickedOnce, setClickedOnce] = React.useState(false);

  const [savedTeams, setSavedTeams] = React.useState(
    currentGame?.state.teams || []
  );
  const gmboardPos: number | undefined | null =
    currentGame?.state.currentTeam?.gameboardPosition;

  const rollDice = () => {
    if (diceRef.current) {
      let rn = rollNumber;
      /* To end */
      // We make sure dice will always land us on the end
      // This ensures we cant go over
      if (gmboardPos && gameLength && gameLength - gmboardPos < 6) {
        rn = gameLength - gmboardPos;

        /* To new section */
        // We want to make sure dice only moves to new section.
      } else if (
        currentTeamPosToNextSection &&
        currentTeamPosToNextSection <= rn
      ) {
        rn = currentTeamPosToNextSection;
      }
      // @ts-ignore
      try {
        diceRef.current.rollAll([rn]);
      } catch (error) {
        console.error(error);
      }
      setClickedOnce(true);
    }
  };

  const onfinishedRoll = (totalValue: number, diceValues: any[]) => {
    setEyes(totalValue);
    setRolled(true);
    if (isGameOwner) {
      if (
        gmboardPos &&
        gameLength &&
        gameLength - gmboardPos < 6 &&
        currentTeamId
      ) {
        onDiceRollDoneToEndgame(totalValue, currentTeamId);
      } else if (totalValue === currentTeamPosToNextSection) {
        onMoveSection();
      } else if (currentTeamId) {
        onDiceRollDone(totalValue, currentTeamId);
      } else {
        console.warn('No team found');
      }
      // draw card cannot happen before we get successfull result from the other person
      if (drawCardAfterRoll) {
        setTimeout(function () {
          onDrawCard();
        }, 1200);
      }
    }
  };

  const playerDiceRoll = () => {
    if (diceRef.current) {
      let rn = rollNumber;

      const currentSavedTeam = savedTeams.find((st) => st.id === currentTeamId);
      // Calculates the difference between saved state, and the new one
      if (
        currentSavedTeam?.gameboardPosition !== null &&
        currentSavedTeam?.gameboardPosition !== undefined &&
        currentGame?.state.currentTeam?.gameboardPosition
      ) {
        rn =
          currentGame.state.currentTeam.gameboardPosition -
          currentSavedTeam.gameboardPosition;
        setSavedTeams(currentGame.state.teams);
      }

      // @ts-ignore
      try {
        if (rn >= 6) {
          rn = 6;
        }
        if (rn <= 0) {
          rn = 1;
        }
        diceRef.current.rollAll([rn]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (!isGameOwner) {
      playerDiceRoll();
    }
  }, [combinedTeamPosition]);

  useEffect(() => {
    setRolled(false);
    setClickedOnce(false);
  }, [currentTeamId]);

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <button
            className={styles.diceButton}
            style={{
              backgroundColor: theme.buttonColors.active,
              overflow: 'visble',
            }}
            disabled={!isGameOwner || clickedOnce}
            onClick={rollDice}
          >
            <DiceContainer
              numDice={1}
              // To stop it from rolling set value over 6
              defaultRoll={99}
              disableIndividual={true}
              dotColor={'black'}
              faceColor={'white'}
              margin={10}
              outline={false}
              outlineColor={'white'}
              //rollDone={() => console.log('Roll do be done')}
              sides={6}
              rollTime={1}
              dieSize={60}
              ref={diceRef}
              totalCb={onfinishedRoll}
            />
            <div className={styles.diceShadow}></div>
            <p
              className={styles.dice_number}
              style={{ color: theme.buttonColors.text }}
            >
              {rolled ? eyes : gamePage.gmControls.rollDice}
            </p>
          </button>
        );
      }}
    </StringsConsumer>
  );
};
export default DiceButton;
