import { ActionCreator } from 'dense-redux-actions';

export const CLEAR_NOTIFICATIONS = new ActionCreator<void>(
  'CLEAR_NOTIFICATIONS',
);
export const TOAST_NOTIFICATION_OPEN = new ActionCreator<ToastNotification>(
  'TOAST_NOTIFICATION_OPEN',
);
export const TOAST_NOTIFICATION_CLOSE = new ActionCreator<null>(
  'TOAST_NOTIFICATION_CLOSE',
);
