import { GenericAction } from 'dense-redux-actions';
import {
  ENGINE_INSTANCE_CLEAR_SUCCESS,
  ENGINE_MOVE_GAMEPIECE_SUCCESS,
  ENGINE_INSTANCE_LOAD_SUCCESS,
  BROWSER_GAME_STATE_UPDATE_SUCCESS,
  ENGINE_GAME_INSTANCE_HOLDER_UPDATE,
  API_GAME_JOIN_SUCCESS,
  ENGINE_DRAW_CARD_SUCCESS,
  ENGINE_QUESTION_ANSWER_SUCCESS,
  ENGINE_ACTIVE_CARD_SET,
  ENGINE_STILL_HERE_ADD,
  ENGINE_STILL_HERE_RESET,
  FACTORY_DRAFT_SUBMIT_SUCCESS,
  ENGINE_INSTANCE_CLEAR,
  ENGINE_ACTIVE_CARD_CLEAR,
  ENGINE_TIMER_COUNTDOWN_SET,
  ENGINE_REFLECTIONS_GET_SUCCESS,
  ENGINE_REMOVE_PLAYER_SUCCESS,
  ENGINE_CHANGE_TURN_SUCCESS,
  ENGINE_BUY_TOKEN_SUCCESS,
  ENGINE_MOVE_GAME_SECTION_SUCCESS,
  BROWSER_COMBINED_MOVEMENT_SET,
  ENGINE_ENDGAME_AWARDS_STATE_UPDATE,
  ENGINE_VICTORY_POINTS_SUCCESS,
  ENGINE_CREATE_CHALLENGE_SUCCESS,
  ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS,
} from '../actions';

const initialState: GameEngine = {
  isLoading: false,
  isSendingReply: false,
  showResult: false,
  gameInstanceHolder: undefined,
  youStillHereCounter: 0,
  timerCountdown: 0,
  combinedTeamPosition: 0,
};

const engineReducer = (
  state: GameEngine = initialState,
  action: GenericAction
): GameEngine => {
  switch (action.type) {
    case ENGINE_INSTANCE_CLEAR_SUCCESS.type: {
      return initialState;
    }
    case ENGINE_INSTANCE_LOAD_SUCCESS.type: {
      const payload = ENGINE_INSTANCE_LOAD_SUCCESS.payload(action);
      return {
        ...state,
        ...payload,
      };
    }
    case BROWSER_COMBINED_MOVEMENT_SET.type: {
      const payload = BROWSER_COMBINED_MOVEMENT_SET.payload(action);
      return {
        ...state,
        combinedTeamPosition: payload,
      };
    }
    case BROWSER_GAME_STATE_UPDATE_SUCCESS.type: {
      const payload = BROWSER_GAME_STATE_UPDATE_SUCCESS.payload(action);
      if (state.game) {
        return {
          ...state,
          isLoading: false,
          game: {
            ...state.game!,
            state: payload,
          },
          youStillHereCounter: state.youStillHereCounter + 1,
        };
      }
      return state;
    }
    case API_GAME_JOIN_SUCCESS.type: {
      const payload = API_GAME_JOIN_SUCCESS.payload(action);
      return {
        ...state,
        game: payload,
      };
    }
    case ENGINE_MOVE_GAMEPIECE_SUCCESS.type: {
      const payload = ENGINE_MOVE_GAMEPIECE_SUCCESS.payload(action);
      // we know that game and state excists, because the calculation is done in ENGINE_MOVE_GAMEPIECE_REQUEST,
      // before ENGINE_MOVE_GAMEPIECE_SUCCESS is called.
      return {
        ...state,
        activeCard: undefined,
        game: {
          ...state.game!,
          state: {
            ...state.game!.state,
            drawn: null,
            teams: payload,
          },
        },
      };
    }

    case ENGINE_GAME_INSTANCE_HOLDER_UPDATE.type: {
      const payload = ENGINE_GAME_INSTANCE_HOLDER_UPDATE.payload(action);
      return {
        ...state,
        gameInstanceHolder: payload,
      };
    }

    case ENGINE_DRAW_CARD_SUCCESS.type: {
      const payload = ENGINE_DRAW_CARD_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: payload,
        },
      };
    }

    case ENGINE_ACTIVE_CARD_SET.type: {
      const payload = ENGINE_ACTIVE_CARD_SET.payload(action);
      return {
        ...state,
        activeCard: {
          ...state.activeCard!,
          card: { ...payload },
        },
      };
    }
    case ENGINE_ACTIVE_CARD_CLEAR.type: {
      return {
        ...state,
        activeCard: undefined,
      };
    }
    case ENGINE_QUESTION_ANSWER_SUCCESS.type: {
      const payload = ENGINE_QUESTION_ANSWER_SUCCESS.payload(action);

      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload.gameState },
        },
        activeCard: {
          ...state.activeCard!,
          card: { ...payload.card },
        },
        isSendingReply: false,
      };
    }
    case ENGINE_STILL_HERE_ADD.type: {
      return {
        ...state,
        youStillHereCounter: state.youStillHereCounter + 1,
      };
    }
    case ENGINE_STILL_HERE_RESET.type: {
      return {
        ...state,
        youStillHereCounter: 0,
      };
    }

    case FACTORY_DRAFT_SUBMIT_SUCCESS.type: {
      const payload = FACTORY_DRAFT_SUBMIT_SUCCESS.payload(action);
      return {
        ...state,
        game: payload,
      };
    }

    case ENGINE_INSTANCE_CLEAR.type: {
      return {
        ...state,
        game: undefined,
      };
    }

    case ENGINE_TIMER_COUNTDOWN_SET.type: {
      const payload = ENGINE_TIMER_COUNTDOWN_SET.payload(action);
      return {
        ...state,
        timerCountdown: payload,
      };
    }

    case ENGINE_REFLECTIONS_GET_SUCCESS.type: {
      const payload = ENGINE_REFLECTIONS_GET_SUCCESS.payload(action);
      return {
        ...state,
        myReflections: payload.myReflections,
        myEndGameReflection: payload.endGameReflection,
      };
    }
    case ENGINE_REMOVE_PLAYER_SUCCESS.type: {
      const payload = ENGINE_REMOVE_PLAYER_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: {
            ...state.game!.state,
            teams: payload,
          },
        },
      };
    }

    case ENGINE_CHANGE_TURN_SUCCESS.type: {
      const payload = ENGINE_CHANGE_TURN_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    case ENGINE_BUY_TOKEN_SUCCESS.type: {
      const payload = ENGINE_BUY_TOKEN_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    case ENGINE_MOVE_GAME_SECTION_SUCCESS.type: {
      const payload = ENGINE_MOVE_GAME_SECTION_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    case ENGINE_ENDGAME_AWARDS_STATE_UPDATE.type: {
      const payload = ENGINE_ENDGAME_AWARDS_STATE_UPDATE.payload(action);
      return {
        ...state,
        showEndgameAwards: payload,
      };
    }

    case ENGINE_VICTORY_POINTS_SUCCESS.type: {
      const payload = ENGINE_VICTORY_POINTS_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    case ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS.type: {
      const payload = ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    case ENGINE_CREATE_CHALLENGE_SUCCESS.type: {
      const payload = ENGINE_CREATE_CHALLENGE_SUCCESS.payload(action);
      return {
        ...state,
        game: {
          ...state.game!,
          state: { ...payload },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const engine = engineReducer;
