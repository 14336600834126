// Account is regarded as shared within the app and can be used by the
// browser, factory and engine selectors/sagas.

import { createSelector } from 'reselect';

interface Status {
  isLoading: boolean;
}

// Result functions
function meResult(profile?: Profile): Player | undefined {
  const {
    name,
    avatar,
    id,
    email,
    score = 0,
    companyRanking = Infinity,
  } = profile || {};

  // empty avatar field in a valid profile should be null.
  if (
    name === undefined || avatar === undefined
    || id === undefined || id === null
    || email === undefined || email === null
  ) {
    return undefined;
  }

  return {
    name, avatar, id, email, score, companyRanking,
  };
}

function profileNameResult(profile?: Profile): string {
  return profile?.name || '';
}

function profileIsAdmin(profile?: Profile): boolean {
  return !!profile?.isAdmin;
}

function profileAvatarResult(profile?: Profile): string | null | undefined {
  return profile?.avatar;
}

function profileCompanyNameResult(profile?: Profile): string | null | undefined {
  return profile?.companyName;
}
function profileCompanyIdResult(profile?: Profile): number | null | undefined {
  return profile?.companyId;
}

function statusIsLoadingResult(status?: Status): boolean {
  return status?.isLoading || false;
}

export function isDemoCompanyResult(companyId?: number | null): boolean {
  const isPartOfACompany = companyId && companyId > 1; // companyId 1 is the default Demo company.
  return !isPartOfACompany;
}
function isAccountGameMasterResult(ar:AccountRoles[] | undefined):boolean {
  if (!ar) {
    return false;
  }
  return ar.includes('ROLE_GAMEMASTER');
}

// non-caching (simple) selectors
const accountProfileSelector = (state: AppState): Profile | undefined => state.account.profile;
const accountStatusSelector = (state: AppState): Status | undefined => state.account.status;
const accountProfileRolesSelector = (state: AppState): AccountRoles[] | undefined => state.account.profile?.roles;
// caching selectors
const meSelector = createSelector(accountProfileSelector, meResult);
const accountProfileNameSelector = createSelector(accountProfileSelector, profileNameResult);
const accountProfileAvatarSelector = createSelector(accountProfileSelector, profileAvatarResult);
const accountProfileCompanyNameSelector = createSelector(accountProfileSelector, profileCompanyNameResult);
const accountProfileCompanyIdSelector = createSelector(accountProfileSelector, profileCompanyIdResult);
const accountProfileIsDemoCompanySelector = createSelector(accountProfileCompanyIdSelector, isDemoCompanyResult);
const accountStatusIsLoadingSelector = createSelector(accountStatusSelector, statusIsLoadingResult);
const accountIsAdminSelector = createSelector(accountProfileSelector, profileIsAdmin);
const accountIsGameMasterSelector = createSelector(accountProfileRolesSelector, isAccountGameMasterResult);

// this is just trying something out feel free to change.
export const account = {
  me: meSelector,
  profile: accountProfileSelector,
  profileName: accountProfileNameSelector,
  profileAvatar: accountProfileAvatarSelector,
  profileCompanyName: accountProfileCompanyNameSelector,
  profileCompanyId: accountProfileCompanyIdSelector,
  isDemoCompany: accountProfileIsDemoCompanySelector,
  accountStatusIsLoading: accountStatusIsLoadingSelector,
  profileIsAdmin: accountIsAdminSelector,
  profileRoles: accountProfileRolesSelector,
  accountIsGameMaster: accountIsGameMasterSelector,
};
