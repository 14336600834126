import { expandTeams, playerOnTeam, findNextTeam } from './teams';
// eslint-disable-next-line import/no-cycle
import { expandCard } from './card';

export function expandGameCard(
  state: GameState,
  expandedCards: DCardInstance[],
  players: Player[]
): DDrawn | null {
  // find drawn card state
  const cardThatIsDrawn: DCardInstance | undefined = expandedCards.find(
    (card) => card.id === state.drawn?.cardId
  );
  // find player
  const playerWhoDrew: Player | undefined = players.find(
    (player) => player.id === state.drawn?.playerId
  );

  if (state.drawn === undefined) {
    return null;
  }
  if (playerWhoDrew === undefined || cardThatIsDrawn === undefined) {
    return null;
  }
  return {
    player: playerWhoDrew!,
    card: cardThatIsDrawn!,
  };
}

export function expandSectionCard(
  currentSectionCardId: number | undefined | null,
  expandedCards: DCardInstance[]
): DCardInstance | undefined {
  return expandedCards.find((c) => c.id === currentSectionCardId);
}

export function expandReply(
  reply: Reply | undefined,
  cards: Card[],
  dnormTeams: DTeam[],
  players: Player[]
): DReply | undefined {
  if (reply === undefined || reply === null) {
    return undefined;
  }

  const card = cards.find((cardElement) => cardElement.id === reply.cardId);
  let answer = card?.answers.find(
    (answerElement) => answerElement.id === reply.answerId
  );
  const team = dnormTeams.find((dTeam) => dTeam.id === reply.teamId);
  let player = reply.playerId
    ? players.find((playerElement) => playerElement.id === reply.playerId)
    : null;
  player = null;
  let timedOut = false;
  // this is a timeout - create timeout answer.
  // get points from BE default to one and get text from translation.
  if (reply.answerId === null && answer === undefined) {
    timedOut = true;
    // this should really test if there is lostTurn modifier, since answerId will be be null for these as well
    const hasLostRoundModifier = card?.modifiers.find((item) => {
      return item.type === 'forfeit';
    });

    if (hasLostRoundModifier) {
      timedOut = false;
      answer = {
        id: 0,
        text: 'Sorry, you lost the round',
        points: 0,
        short: 'Lost round',
        correct: false,
      };
    } else {
      // Must be a timeout, shold probably check
      if (reply.type === 'answer') {
        // To be backwards compatible, we define these as answer_timeout if type is answer and np answerId,
        // since this was the old way of specifying it
        /* eslint-disable-next-line no-param-reassign */
        reply.type = 'answer_timeout';
      }
      answer = {
        id: 0,
        text: 'Remember to answer before the timer reaches 0...',
        points: -1,
        short: 'You times out!',
        correct: false,
      };
    }
  }
  if (answer === undefined || /* player === undefined|| */ card === undefined) {
    let hint = '';

    hint += answer ? `answer ${answer} ` : '';
    hint += player ? `player ${player} ` : '';
    hint += card ? `card ${card}` : '';

    throw new Error(
      `Invalid reply used in expandReply. This is defined: ${hint}`
    );
  }

  const repliedDate =
    reply.repliedDate === undefined ? null : reply.repliedDate;

  return {
    duration: reply.duration,
    card,
    answer,
    team,
    player,
    repliedDate,
    type: reply.type,
    timedOut,
    awardedPoints: reply.awardedPoints,
  };
}

export function expandState(
  state: GameState,
  cards: Card[],
  players: Player[],
  me?: Player,
  gameTiles?: GameTile[],
  sections?: GameSection[]
): DGameState {
  const expandedCards: DCardInstance[] = cards
    .map(expandCard(players, state, me))
    .filter((card) => card !== null) as DCardInstance[];

  const currentSectionCard = expandSectionCard(
    state.currentSectionCardId,
    expandedCards
  );

  const dnormTeams: DTeam[] = expandTeams(
    state.teams || [],
    state.players,
    sections,
    gameTiles,
    state,
    currentSectionCard
  );

  const dnormCurrentTeam: DTeam | undefined = dnormTeams.find(
    (team) => team.id === state.currentTeam
  );

  const dnormReplies: (DReply | undefined)[] = state.replies.map((reply) =>
    expandReply(reply, cards, dnormTeams, players)
  );

  const dnormRepliesFiltered: DReply[] = dnormReplies.filter(
    (item) => item !== undefined
  ) as DReply[];

  const howManyAtStart: number = state.teams.filter(
    (team) => team.gameboardPosition === null || team.gameboardPosition === 0
  ).length;

  // sort teams by homeness and awayness with home teams first.
  const orderedTeams = dnormTeams.sort((team) =>
    playerOnTeam(team, me) ? -1 : 1
  );

  const expandedDrawn = expandGameCard(state, expandedCards, players);

  const nextTeam = findNextTeam(dnormTeams, dnormCurrentTeam?.id);

  return {
    ...state,
    teams: orderedTeams,
    currentTeam: dnormCurrentTeam,
    replies: dnormRepliesFiltered || [],
    cards: expandedCards,
    drawn: expandedDrawn,
    howManyAtStart,
    nextTeam,
    currentSectionCard,
  };
}

export function expandGameInstance(
  gi?: GameInstance,
  me?: Player,
  gameTiles?: GameTile[],
  sections?: GameSection[]
): DGameInstance | undefined {
  if (gi === undefined) {
    return undefined;
  }

  const dnormState = expandState(
    gi.state,
    gi.cards,
    gi.players,
    me,
    gameTiles,
    sections
  );
  const dnormOwner = gi.players.find((player) => player.id === gi.owner);

  if (dnormOwner === undefined) {
    throw new Error('Invalid game owner');
  }

  return {
    ...gi,
    state: dnormState,
    owner: dnormOwner,
  };
}
