/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'hu';

export const hu: RecursivePartial<DefaultLangKeys> = {
  label: 'Magyar',
  sagaTranslate: {
    id1: 'A játékot nem lehet betölteni: jelenleg a játékot nem tudjuk betölteni. Kérlek, próbáld meg később még egyszer.',
    id2: 'nincs érvényes játék ID',
    id3: 'Valami nem sikerült',
    id4: 'A kihúzott kártya nem létezik',
    id5: 'A játék ID nem található',
    id6: 'Elnézést, az internet pillanatnyilag elég lassú. Kérjük, maradj itt, amíg feldolgozzuk új kérésedet',
    id7: 'Sajnos még mindig nincs szerencséd. Még mindig megpróbáljuk a játékot újra betölteni. Kérlek, várj egy pillanatot...',
    id8: 'Pillanatnyilag nincs internet hozzáférés. Állítsd helyre a kapcsolatot, és próbáld meg újra.',
    id9: 'Nincs internet hozzáférés.',
    id10: 'A hozzáférési tokent nem lehet helyreállítani.',
    id11: 'Be kell töltened a játékot, mielőtt bezárhatod.',
    id12: 'A játékot nem lehetett leállítani',
    id13: 'Ez a csapat nem található',
    id14: 'A játékfigurát nem lehet mozgatni, mivel az az elérési távolságon kívül van.',
    id15: 'Nem kaptunk érvényes választ (a válasz helytelen)',
    id16: 'Nem érhető el több kártya.',
    id17: 'A játék nem található',
    id18: 'A státuszt nem lehet frissíteni',
    id19: 'A játékot sikeresen betöltötték végső önértékeléshez.',
    id20: 'A játék befejeződött, menj egy még folyamatban lévő játékhoz, hogy az új játékban részt vegyél!',
    id21: 'Önértékelésed sikeresen lezárult',
    id22: 'Kártyák feldolgozva',
  },
  screens: {
    navigation: {
      signed: 'Bejelentkezve a következő szerepkörben',
      home: 'Home',
      createGame: 'Játék létrehozása',
      joinGame: 'Játékba belépés',
      about: 'Keresztül',
      logOut: 'Kijelentkezés',
      game: 'Játék',
      settings: 'Beállítások',
    },
    home: {
      hi: 'Helló',
      welcome: 'Szeretettel köszöntjük a Gaming4Impact-ben a böngészőben.',
      goTo: 'Rajta!',
      goToGameCreation: 'Új játék létrehozása',
      goToJoinGame: 'Meglévő játékba belépés',
      yourRunningGames: 'Válassz ki meglévő játékot.',
      noRunningGames: 'Nem található meglévő játék, a következő lehetőségeid vannak:',
      login: 'Kérjük, jelentkezz be',
      loginBtn: 'Bejelentkezés',
      buttonLogin: 'Bejelentkezés',
      gameowner: 'Játékvezető',
      created: 'Létrehozva',
      enterGameBtn: 'Játékba belépés',
    },
    settings: {
      settingsHeader: 'Beállítások',
      avatar: 'Avatar',
      name: 'Név',
      language: 'Nyelv',
      selectLanguage: 'Nyelv kiválasztása',
      chooseNewImage: 'Új kép kiválasztása',
      playerIcon: 'Játékos ikon',
      changeName: 'Név megváltoztatása',
      uploadImage: 'Kép feltöltése',
      email: 'Email',
      unknown: 'Ismeretlen',
      environment: 'Környezet',
      environmentVariables: {
        buildShortTag: 'Nap létrehozása',
        apiUrl: 'API végpont',
      },
    },
    gameCodes: {
      code: 'Kód',
      waitingForCode: 'Várd meg a kód létrehozását',
      startGame: 'Játék indítása',
    },
    joinGame: {
      title: 'Játékba belépés',
      enterPin: 'PIN megadása',
    },
    gameCreation: {
      team: 'CSAPAT',
      selectIconName: 'Válassz ki egy ikont és egy nevet',
      createTeams: 'Csapat létrehozása',
      addTeam: 'Csapat hozzárendelése',
      removeTeam: 'Csapat eltávolítása',
      submit: 'OK',
      enterTeamName: 'Csapat nevének kiválasztása',
      selectGameName: 'Válassz ki játéknevet',
      enterGameName: 'Kérjük, adj meg egy játéknevet',
      header: 'Játék létrehozása',
      noRole: 'Sajnos nincsenek meg a jogosultságaid ahhoz, hogy a játékot létrehozd.',
      availableGames: 'Elérhető játékok',
      summary: {
        summary: 'Összefoglalás',
        gameName: 'Játék neve',
        team: 'Csapat',
        template: 'Minta',
        description: 'Leírás',
        difficulty: 'Nehézségi szint',
      },
      subHeaders: {
        gameName: 'Játék neve',
        gameTemplate: 'Játék sablon',
        teams: 'Csapatok',
        summary: 'Összefoglalás',
      },
      progressBar: {
        inactive: 'Áttekintés',
        summary: 'Várjon',
        active: 'Feldolgozás alatt',
        done: 'Bezár',
      },
      createGameBtn: 'Játék létrehozása',
      creationStep: 'Lépés',
      goBtn: 'Rajta!',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Az én fő potenciálom',
      subTitle: 'Önértékeléseim',
      mainSubTitle: 'Az én fő potenciálom',
      print: 'Nyomtatás',
    },
    gamePage: {
      yesBtn: 'Igen',
      stillHereHeader: 'Ott vagy még?',
      stillHereBody: 'Már nagyon régóta itt vagy. Még mindig játszol?',
      gameEndedHeader: 'Játék lezárva',
      gameEndedBody: 'A játék befejeződött. Visszaviszünk téged a kezdőoldalra',
      notPartOfGameHeader: 'Sajnos nem vagy ennek a játéknak a része',
      notPartOfGameBody: 'Menj vissza a kezdőoldalra, és kapcsolódj be a játékba',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Szerepjáték',
          reflection: 'Önismeret',
          discussion: 'Csoportos beszélgetés',
          action: 'Művelet',
          challenge: 'Kihívás',
          teamChallenge: 'Csapat kihívás',
        },
        waitForNewCard: 'Kérjük, várd meg, míg a játékvezető új kártyát húz.',
        waitForNewCardHeader: 'Nincs kártya',
        enterReflection: 'Rögzítsd önértékelésedet',
        submit: 'OK',
        guidance: 'Tudnivalók',
        replied: 'Válasz',
        reflectionStateTextForGM: `Már az utolsó önértékelési fázisban vagyunk. 
        Kérjük, várj, míg minden játékos feljegyezte fő potenciálját, és kinyomtatta önértékelését vagy eltárolta azokat. 
        Végül befejezheted a játékot, ha a „Játék befejezése” gombra kattintasz.`,
        roleplay: {
          myAssignment: 'Az én feladatom:',
          myRole: 'Az én szerepem:',
          noAssignment: 'Nem rendeltek hozzád szerepet',
          noRole: 'Nincs szerep',
        },
        answerText: {
          youAnswered: 'Válaszoltál',
          thisIs: 'Ez',
          correct: 'helyes',
          incorrect: 'nem helyes',
          points: 'Pontok',
          guidance: 'Tudnivalók',
        },
      },
      teamCard: {
        score: 'Összpontszám:',
        role: 'Szerep:',
        gamemaster: 'Játékvezető',
        youreAboutToKickPlayer: 'Játékos eltávolítása',
        areYouSure: 'Biztos vagy benne, hogy a játékost el szeretnéd távolítani?',
      },
      gmControls: {
        gameInfo: 'Játék információ',
        ok: 'Ok',
        stopGame: 'Játék befejezve',
        stopGameQuestion: 'Biztos vagy benne, hogy be szeretnéd fejezni a játékot?',
        reflectionDeletion: ['Megkérted a résztvevőket, hogy nyomtassák ki az önértékelésüket?'],
        moveBack: 'Visszafelé haladás',
        moveForwards: 'Előre mozgás',
        drawCard: 'Kártya húzása',
        showInfo: 'Információk megjelenítése',
        accept: 'Egyetértés',
        endGameReflectionsbtn: 'Utolsó visszajelzések',
        gamename: 'Játék neve',
        gameId: 'Játék ID',
        sectionId: 'Fejezet ID',
        sectionName: 'Fejezet neve',
        availCards: 'Elérhető kártyák a rendszerben',
        currentCardId: 'Aktuális kártya ID',
        teams: 'Csapatok:',
        yes: 'Igen',
        notEveryOneHeader: 'Nem minden résztvevő zárta le az önértékelését',
        notEveryOneContent: 'Biztos vagy benne, hogy a játékos figurát tovább szeretnéd húzni?',
        cardIsAlreadyDrawnBody: 'Már húztál kártyát. Biztos vagy benne, hogy új kártyát szeretnél húzni?',
        cardIsAlreadyDrawnHeader: 'Már húztál egy kártyát',
      },
    },
    others: { closeBtn: 'Bezár' },
    errors: {
      notLoggedIn: 'Úgy tűnik, nem jelentkeztél be. A bejelentkezéshez kattints ide.',
      reloadPage: 'Az oldal újbóli betöltéséhez kattints ide',
      goHome: 'Ha a probléma továbbra is fennáll, menj a főoldalra, és próbáld meg ismét.',
      loginBtn: 'Bejelentkezés',
      homeBtn: 'Home',
      reloadBtn: 'Újbóli betöltés',
      login: 'Bejelentkezés',
    },
  },
};
