import { DefaultLangKeys } from '../en';

export const languageTag = 'cmn';

export const cmn: RecursivePartial<DefaultLangKeys> = {
  label: '中文',
  sagaTranslate: {
    id1: '不能装载游戏: 我们目前无法打开游戏. 请稍后再试.',
    id2: '不是有效的游戏 ID',
    id3: '出现问题',
    id4: "卡牌不存在",
    id5: '找不到游戏 ID',
    id6: '请稍等, 互联网连接速度较慢. 请稍等, 我们将重新您的请求',
    id7: '仍然没有开启. 正在尝试访问服务器. 请稍等...',
    id8: '互联网连接中断了.\n\n请重新连接而再试一次',
    id9: '不能联接因特网',
    id10: '无法访问',
    id11: '在关闭游戏前须装载游戏',
    id12: '不能停止游戏',
    id13: '找不到队伍',
    id14: "玩家棋子超界,无法移动.",
    id15: "未收到有效的回复 (未定义回复)",
    id16: '没有更多可以使用的牌.',
    id17: '找不到游戏',
    id18: '未能更新状态',
    id19: '成功发送至游戏结束反馈',
    id20: '游戏已结束, 点击加入寻找新游戏!',
    id21: '您的反馈被提交',
    id22: '卡牌已回答',
  },
  screens: {
    navigation: {
      signed: '已登陆',
      home: '主页',
      createGame: '创建游戏',
      joinGame: '加入游戏',
      about: '关于',
      logOut: '登出',
      game: '游戏',
      settings: '设置',
    },
    home: {
      hi: '你好',
      welcome: '欢迎在浏览器中的 Gaming4Impact.',
      goTo: '去',
      goToGameCreation: '创建新游戏',
      goToJoinGame: '加入正在进行的游戏',
      yourRunningGames: '选择正在进行的游戏.',
      noRunningGames:
        '找不到正在进行的游戏, 您有以下选择:',
      login: '请登陆网站',
      loginBtn: '登陆',
      joinasGuest: '或者以旁观者加入游戏',
      guestButton: '加入',
      buttonLogin: '登陆',
      gameowner: '游戏主持人',
      created: '已创建',
      enterGameBtn: '进入游戏',
      notVerifiedHeader: "您的帐户未被验证.",
      notVerifiedBody: `为验证帐户, 请打开邮件，并遵循说明`,
      notVerifiedDone: '完成后，重载页面.',
      reloadBtn: '重载',
    },
    settings: {
      settingsHeader: '设置',
      avatar: '头像',
      name: '名字',
      language: '语言',
      email: '电邮',
      environment: '环境',
      environmentVariables: {
        buildShortTag: '建立标记',
        apiUrl: 'API 终端',
      },
      unknown: '未知',
      selectLanguage: '选择语言',
      chooseNewImage: '挑选新的图像',
      playerIcon: '玩家头像',
      changeName: '改变名字',
      uploadImage: '上传图像',
    },
    gameCodes: {
      code: '密码',
      waitingForCode: '等待创建邀请码',
      startGame: '开始游戏',
    },
    joinGame: {
      title: '加入游戏',
      enterPin: '输入邀请码',
      enterName: '输入名字',
    },
    gameCreation: {
      team: '团队',
      selectIconName: '挑选一幅肖像和名字',
      createTeams: '创建团队',
      addTeam: '添加团队',
      removeTeam: '移除团队',
      submit: '提交',
      enterTeamName: '输入团队的名字',
      selectGameName: '请选择您的游戏的名字',
      enterGameName: '输入游戏的名字',
      header: '创建游戏',
      noRole: '未创建游戏所需的角色.',
      availableGames: '现有游戏',
      creationStep: '步',
      goBtn: 'GO',
      createGameBtn: '创建游戏',
      upTo: 'F',
      progressBar: {
        inactive: '概览',
        summary: '等着',
        active: '进行中',
        done: '完成',
      },
      summary: {
        summary: '概述',
        gameName: '游戏的名字',
        team: '团队',
        template: '范本',
        description: '描述',
        difficulty: '难度',
      },
      subHeaders: {
        gameName: '游戏的名字',
        gameTemplate: '游戏范本',
        teams: '团队',
        summary: '概述',
      },
    },
    reflectionPage: {
      submit: '提交',
      print: '打印',
      mainReflectionCardPlaceholder: '输入您主要的反馈',
      subTitle: '我的反馈',
      mainSubTitle: '我的主要反馈',
    },
    gamePage: {
      challengeMode: {
        title: '挑战',
        awaitTeams: '等待团队输入他们的反馈',
      },
      noPlayersPart1: '这个团队没有玩家, 请使用下述的邀请码:',
      noPlayersPart2: 'to invite them.',
      yesBtn: '是',
      okBtn: 'OK',
      stillHereHeader: '您还在这儿吗?',
      stillHereBody: "您在这里呆很久了. 您还在玩儿吗?",
      gameEndedHeader: '游戏结束了',
      gameEndedBody: "游戏结束了,带您回到主页.",
      notPartOfGameHeader: "您未参加这个游戏",
      notPartOfGameBody:
        '返回主页, 然后通过点击加入游戏进入戏.',
      currentTeamTurn: '激活',
      upNextTeamTurn: '下一轮',
      timer: {
        timesUp: "时间到了",
      },
      plaidModal: {
        startGame: '开始游戏',
        stopGame: '停止游戏',
      },
      copiedTeamCode: '团队队码被复制',
      copiedLinkMsg: '点击这里以进入游戏:',
      copiedTeamCodeMsg: '您的团队码是',
      copiedJoinTeamMsg: "您的团队是",

      activeCard: {
        timer: { first: '您现在有', second: '秒来回答' },
        gameIsGoing: '游戏在进行的',
        roleDistribution: '给队友分发角色',
        cardTypes: {
          action: '行动',
          challenge: '挑战',
          rolePlay: '角色扮演',
          teamChallenge: '团队挑战',
          reflection: '反馈',
          discussion: '讨论',
          dilemma: '困境',
        },
        noPlayers: {
          noPlayers: '没有玩家加入.',
          pleaseJoin: '请用下述邀请码加入',
        },
        waitForNewCard: '等待游戏大师抓一张新的卡牌.',
        waitForNewCardHeader: '没卡牌',
        enterReflection: '输入您的反馈',
        submit: '提交',
        guidance: '指导',
        replied: '回复',
        reflectionStateTextForGM: `目前游戏在结束反馈阶段.
         等待玩家输入反馈, 然后您可以点击停止游戏按钮`,
        roleplay: {
          myAssignment: '我的任务:',
          noAssignment:
            "您没有被分配角色, 所以您没有任务.",
          myRole: '我的角色:',
          noRole: '没有角色',
        },
        answerText: {
          youAnswered: '已回答',
          thisIs: '回答',
          correct: '正确',
          incorrect: '错误',
          points: '分',
          guidance: '指导',
          noPlayers:
            "没有玩家, 因此不能回答这个问题.",
        },
        notYourTurn: '未轮到您',
      },
      teamCard: {
        score: '总分:',
        role: '角色:',
        gamemaster: '游戏大师',
        youreAboutToKickPlayer: '赶走一名玩家',
        areYouSure: '确定要赶走这名玩家吗?',
        active: '激活',
        players: '玩家',
        sections: '部分',
        noPlayers: '没有玩家',
      },
      gmControls: {
        submitPoints: '提交分',
        wonChallenge: '赢得了挑战',
        lostChallenge: '输掉了挑战',
        rollDice: '掷骰子',
        gameInfo: '游戏资讯',
        stopGameAndShowResults: '停止游戏并显示结果',
        startGame: '开始游戏',
        ok: '好的',
        stopGame: '停止游戏',
        submitReflectionsPoints: ['给', '反馈'],
        challenge: '挑战队伍',
        stopGameQuestion: '您确定要停止游戏吗?',
        reflectionDeletion: [
          `玩家的反馈都将被删除, 已确保玩家已打印反馈吗?`,
        ],
        moveNextSection: '移至下一部分',
        moveBack: '返回',
        moveForwards: '前进',
        drawCard: '抓一张卡牌',
        showInfo: '显示信息',
        accept: '接受',
        endGameReflectionsbtn: '最终反馈',
        changeTurn: '改变顺序',
        gamename: '游戏的名字',
        gameId: '游戏 ID',
        sectionId: '部分 ID',
        sectionName: '部分名称',
        availCards: '可使用卡牌',
        currentCardId: '目前卡牌 ID',
        teams: '团队:',
        yes: '是',
        notEveryOneHeader: '不是所有人都提交了反馈',
        notEveryOneContent: '您确定要移动?',
        cardIsAlreadyDrawnBody:
          '已抓一张卡牌, 您确定要再抓一张吗?',
        cardIsAlreadyDrawnHeader: '已抓卡牌',
        buyToken: '购买代币',
      },
    },
    others: { closeBtn: '关闭' },
    errors: {
      notLoggedIn: '未登陆. 点击这里登陆.',
      reloadPage: '请点击这里重载页面',
      goHome: '如仍有问题, 请返回主页而再试一次',
      loginBtn: '登陆',
      homeBtn: '返回主页',
      reloadBtn: '重新装',
      login: '登陆',
    },
  },
};
