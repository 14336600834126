import React, { useState } from 'react';
import styles from './AnswerModal.module.css';
import { mainColors } from '../../../../../utils/variables';

interface Props {
  answer?: Answer;
  disabled: boolean;
  userHasAnswered: boolean;
  onClick?: () => void;
  selected?: boolean;
  sectionColor?: string;
}

const AnswerElement: React.FC<Props> = ({
  answer,
  disabled,
  onClick,
  selected,
  sectionColor,
  userHasAnswered,
}) => {
  const [hover, setHover] = useState(false);

  const correct = userHasAnswered && answer?.correct;

  const { backgroundColor, elementWidth, borderWidth, borderColor, textColor } =
    (() => {
      switch (true) {
        case !correct && userHasAnswered:
          return {
            backgroundColor: mainColors.red,
            elementWidth: selected ? '100%' : '95%',
            borderWidth: '3px',
            borderColor: mainColors.red,
            textColor: mainColors.white,
          };
        case correct && userHasAnswered:
          return {
            backgroundColor: mainColors.green,
            elementWidth: selected ? '100%' : '95%',
            borderWidth: '3px',
            borderColor: mainColors.green,
            textColor: mainColors.white,
          };
        case hover:
          return {
            backgroundColor: sectionColor,
            elementWidth: '95%',
            borderWidth: '1px',
            borderColor: sectionColor,
            textColor: mainColors.white,
          };
        default:
          return {
            backgroundColor: '',
            elementWidth: '95%',
            borderWidth: '1px',
            borderColor: mainColors.grey,
            textColor: mainColors.black,
          };
      }
    })();

  const handleBtnPress = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styles.answerBoxContainer}
      style={{
        borderColor,
        borderWidth,
        width: elementWidth,
        margin: '5px auto',
      }}
      onMouseEnter={() => setHover(!disabled)}
      onMouseLeave={() => setHover(false)}
      onClick={handleBtnPress}
      onKeyDown={handleBtnPress}
      role={!disabled ? 'button' : ''}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    >
      <div
        style={{
          flex: '1 1 25px', // 25px should add the default width of the element, as it does not work without it in the IE
          display: 'flex',
          alignContent: 'center ',
          backgroundColor,
          borderRight: '1px solid',
          borderRightColor: borderColor,
          transition: '0.2s',
        }}
      >
        <p className={styles.massiveLetter} style={{ color: textColor }}>
          {answer?.short}
        </p>
      </div>
      <p className={styles.answerText}>{answer?.text}</p>
    </div>
  );
};

export default AnswerElement;
