export const firstname = (name: string) => {
  return name.split(' ')[0];
};

export const languageTagFromLangCode = (langCode: string) => {
  return langCode.split('-')[0];
};

export * from './deepMerge';
export * from './variables';
export * from './date';
export * from './email';
export * from './themes';
export * from './points';
