/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

interface GameLoadRequest {
  instanceId: number;
  showResult?: boolean;
}
export const BROWSER_GAME_INSTANCE_SUMMARY_REQUEST = new ActionCreator<boolean, RetryMeta>('BROWSER_GAME_INSTANCE_SUMMARY_REQUEST');
export const BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS = new ActionCreator<GameInstanceSummary[]>('BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS');
export const BROWSER_GAME_INSTANCE_SUMMARY_FAILURE = new ActionCreator<string>('BROWSER_GAME_INSTANCE_SUMMARY_FAILURE');

export const BROWSER_GAME_LOAD_REQUEST = new ActionCreator<GameLoadRequest>('BROWSER_GAME_LOAD_REQUEST');
export const BROWSER_GAME_LOAD_SUCCESS = new ActionCreator<null>('BROWSER_GAME_LOAD_SUCCESS');
export const BROWSER_GAME_LOAD_FAILURE = new ActionCreator<string>('BROWSER_GAME_LOAD_FAILURE');

export const BROWSER_GAME_STATE_UPDATE_REQUEST = new ActionCreator<GameLoadRequest>('BROWSER_GAME_STATE_UPDATE_REQUEST');
export const BROWSER_GAME_STATE_UPDATE_FAILURE = new ActionCreator<Error>('BROWSER_GAME_STATE_UPDATE_FAILURE');
export const BROWSER_GAME_STATE_UPDATE_SUCCESS = new ActionCreator<GameState>('BROWSER_GAME_STATE_UPDATE_SUCCESS');

export const BROWSER_COMBINED_MOVEMENT_SET = new ActionCreator<number>('BROWSER_COMBINED_MOVEMENT_SET');