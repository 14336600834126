import { GenericAction } from 'dense-redux-actions';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  APP_INITIALIZE_FAILURE,
  APP_INITIALIZE_REQUEST,
  APP_INITIALIZE_SUCCESS,
  APP_START_NAVIGATION_UPDATE,
  APP_LANGUAGE_OVERWRITE_UPDATE,
  APP_COMPANY_CODE_SET,
} from '../actions/app';

interface App {
  isInitialized: boolean;
  isLoading: boolean;
  profile?: Profile;
  startNavigation?: boolean;
  languageOverwrite: string | undefined; // undefined = browser standart.
  companyCode?: string;
}

const initialState: App = {
  isLoading: false,
  isInitialized: false,
  startNavigation: false,
  languageOverwrite: undefined,
};

const persistConfig: PersistConfig<App, any, any, any> = {
  key: 'app',
  storage,
  whitelist: ['languageOverwrite'],
};

const appReducer = (state: App = initialState, action: GenericAction): App => {
  switch (action.type) {
    case APP_LANGUAGE_OVERWRITE_UPDATE.type: {
      const newLanguage = APP_LANGUAGE_OVERWRITE_UPDATE.payload(action);
      return {
        ...state,
        languageOverwrite: newLanguage,
      };
    }
    case APP_INITIALIZE_SUCCESS.type: {
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
      };
    }
    case APP_INITIALIZE_REQUEST.type: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case APP_INITIALIZE_FAILURE.type: {
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
      };
    }
    case APP_START_NAVIGATION_UPDATE.type: {
      const payload = APP_START_NAVIGATION_UPDATE.payload(action);
      return {
        ...state,
        startNavigation: payload,
      };
    }
    case APP_COMPANY_CODE_SET.type: {
      const payload = APP_COMPANY_CODE_SET.payload(action);
      return {
        ...state,
        companyCode: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const app = persistReducer(persistConfig, appReducer);
