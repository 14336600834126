import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_NOTIFICATIONS } from '../../redux/actions';

export const usePresentToast = () => {
  const messages = useSelector(
    (state: AppState) => state.toastNotifications.toasts,
    (previousValue, currentValue) =>
      previousValue.length === currentValue.length
  );
  const emailVerified = useSelector(
    (state: AppState) => state.auth.emailVerified
  );
  const dispatch = useDispatch();
  const { addToast, removeToast } = useToasts();

  const [lastMessage, setLastMessage] = React.useState('');

  useEffect(() => {
    if (messages.length === 0 || emailVerified === false) return;
    messages.forEach((message: ToastNotification) => {
      const autoDismiss = message.timeout == null;
      let toastId: string | null = null;
      if (
        message.content &&
        message.content !== '' &&
        message.appearance !== 'error' &&
        message.content !== lastMessage
      ) {
        setLastMessage(message.content);
        addToast(
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              if (toastId) {
                removeToast(toastId);
              }
            }}
          >
            {message.content}
          </div>,
          {
            appearance: message.appearance,
            autoDismiss,
          },
          (id) => {
            toastId = id;

            if (autoDismiss) {
              return;
            }
            setTimeout(() => {
              removeToast(id);
            }, message.timeout);
          }
        );
      }
    });

    dispatch(CLEAR_NOTIFICATIONS.create());
  }, [messages, addToast, dispatch, removeToast]);
};
