import React from 'react';
import { mainColors } from '../../../utils';
import styles from './ScoreComponent.module.css';

interface Props {
  team: DTeam | undefined;
}

const ScoreCard: React.FC<Props> = (props) => {
  const { team } = props;
  if (!team || !team.teamSectionPoints) {
    return null;
  }

  const maxFlexHeight = () => {
    const allPoints: number[] | undefined = team.teamSectionPoints?.map(
      (el) => {
        return el.point;
      }
    );
    if (allPoints) {
      const maxPoint = Math.max(...allPoints);
      if (maxPoint > 0) {
        return maxPoint;
      }
    }
    return 1;
  };
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: mainColors.white }}
    >
      {team.teamSectionPoints.map((el) => {
        let pointFlex = el.point;
        if (el.point === 0) {
          pointFlex = maxFlexHeight() / 30;
        }

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '150px',
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: maxFlexHeight(),
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: maxFlexHeight() - pointFlex }} />
              <div
                style={{
                  backgroundColor: el.section?.color,
                  flex: pointFlex,
                  width: '60%',
                  maxWidth: '35px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                marginTop: '5px',
                width: '60%',
                alignSelf: 'center',
              }}
            >
              {el.point}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ScoreCard;
