import React from 'react';
import { ThemeContext } from '../App';
import { StringsContext } from '../locales';

interface Props {
  team: Team;
  onCopy: (message: string) => void;
  onClick?: () => void;
}

export function useTeamCodesHelper({ team, onCopy, onClick }: Props) {
  const strings = React.useContext(StringsContext);
  const theme = React.useContext(ThemeContext);
  const message = strings.screens.gamePage;

  const copyToClip = React.useCallback(() => {
    navigator.clipboard
      .writeText(
        `${message.copiedJoinTeamMsg} ${team.name}! \n` +
          `${message.copiedTeamCodeMsg} ${team.code}. \n` +
          `${message.copiedLinkMsg} ${window.location.origin}${
            theme.gameInvitePath &&
            theme.gameInvitePath + '?gameCode=' + team.code
          }`
      )
      .then(() => {
        onCopy(strings.screens.gamePage.copiedTeamCode);
        if (onClick) onClick();
      });
  }, [
    team, 
    onClick, 
    onCopy, 
    message.copiedJoinTeamMsg, 
    message.copiedLinkMsg, 
    message.copiedTeamCodeMsg, 
    theme.gameInvitePath, 
    strings.screens.gamePage.copiedTeamCode]);

  return { copyToClip };
}
