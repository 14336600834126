/* eslint no-continue: 0*/
import { select, put, take, delay } from 'redux-saga/effects';
import jwtdecode from 'jwt-decode';

import { mergeDeep } from '../../../../utils/deepMerge';
import { unpackResponse } from './responseHandler';
import { authClient } from '../../auth0';
import { auth } from '../../../selectors';
import {
  AUTH_TOKEN_REFRESH_REQUEST,
  AUTH_TOKEN_REFRESH_SUCCESS,
  AUTH_TOKEN_REFRESH_FAILURE,
} from '../../../actions/auth';

const { REACT_APP_API_URL } = process.env;

interface Headers {
  'content-type': string;
  Authorization?: string;
}

const rootUrl = REACT_APP_API_URL || 'https://staging2.complayance.dk';
let isRefreshing = false;

const apiUrls = {
  api: `${rootUrl}/async/api/v1`,
  root: rootUrl,
};
export type ApiUrls = keyof typeof apiUrls;

function composeHeader(accessToken: string | undefined, options = {}) {
  // Compose and make request

  const headers: Headers = {
    'content-type': 'application/json',
  };

  if (accessToken != undefined) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const optionsWithBearerToken = mergeDeep(options, { headers });

  return optionsWithBearerToken;
}

function* resolveAccessToken() {
  let accessToken: string | undefined = yield select(auth.getAccessToken);

  if (!accessToken) {
    try {
      accessToken = yield authClient().getTokenSilently();

      return accessToken;
    } catch (e) {
      return undefined;
    }
  }

  if (isRefreshing) { // simple debouncing -- redo authentication in webapp to be cleaner
    yield delay(500);
    accessToken = yield select(auth.getAccessToken);
  }

  if (!accessToken) {
    return;
  }

  // check if token is expired.
  const claims: any = jwtdecode(accessToken);
  const now = +new Date() / 1000;


  if (now + 5 * 60 >= claims.exp) {
    isRefreshing = true;
    // expire 5 min before set to expire to avoid issues with time sync.

    yield put(AUTH_TOKEN_REFRESH_REQUEST.create());

    const newTokenAction: GenericAction = yield take([
      AUTH_TOKEN_REFRESH_SUCCESS.type,
      AUTH_TOKEN_REFRESH_FAILURE.type,
    ]);

    try {
      accessToken = AUTH_TOKEN_REFRESH_SUCCESS.payload(newTokenAction);
    } catch (e) {
      accessToken = undefined;
    }

    isRefreshing = false;
  }

  return accessToken;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* requestHandler(
  endpoint: string,
  options = {},
  base: ApiUrls = 'api',
  bypassAuth?: boolean
): object | undefined {
  // Todo store access token... in store, just to have it available

  let accessToken: string | undefined;
  if (!bypassAuth) {
    accessToken = yield resolveAccessToken();

    if (!accessToken) {
      return;
    }
  }

  const mergedHeader = composeHeader(accessToken, options);

  const baseUrl = apiUrls[base];
  const url = `${baseUrl}${endpoint}`;

  const response: Response = yield fetch(url, mergedHeader);

  const result: object = yield unpackResponse(endpoint, response);
  return result;
}
