/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

interface GameInviteAccept {
  id: number;
  isAccepted: boolean;
}

interface GameInviteSelect {
  id: number;
}

export const INVITATION_GET_INVITES_REQUEST = new ActionCreator<undefined, RetryMeta | undefined>('INVITATION_GET_INVITES_REQUEST');
export const INVITATION_GET_INVITES_SUCCESS = new ActionCreator<GameInvite[]>('INVITATION_GET_INVITES_SUCCESS');
export const INVITATION_GET_INVITES_FAILURE = new ActionCreator<Error>('INVITATION_GET_INVITES_FAILURE');

export const INVITATION_SELECT_REQUEST = new ActionCreator<GameInviteSelect>('INVITATION_SELECT_REQUEST');
export const INVITATION_SELECT_SUCCESS = new ActionCreator<GameInvite>('INVITATION_SELECT_SUCCESS');
export const INVITATION_SELECT_FAILURE = new ActionCreator<Error>('INVITATION_SELECT_FAILURE');

export const INVITATION_REPLY_REQUEST = new ActionCreator<GameInviteAccept>('INVITATION_REPLY_REQUEST');
export const INVITATION_REPLY_SUCCESS = new ActionCreator<null>('INVITATION_REPLY_SUCCESS');
export const INVITATION_REPLY_FAILURE = new ActionCreator<Error>('INVITATION_REPLY_FAILURE');

export const INVITATION_GAME_ID_UPDATE = new ActionCreator<number>('INVITATION_GAME_ID_UPDATE');
