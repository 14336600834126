import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Timer from './components';
import {
  ENGINE_TIMER_COUNTDOWN_SET,
  TOAST_NOTIFICATION_OPEN,
} from '../../../redux/actions';
import { engine } from '../../../redux/selectors';

const mapStateToProps = (state: AppState) => {
  return {
    time: state.engine.timerCountdown,
    cardTimeLimit: engine.drawnCardTimeStamp(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onSetCountDown: (time: number) =>
    dispatch(ENGINE_TIMER_COUNTDOWN_SET.create(time)),
  onEndTime: (message: string) =>
    dispatch(
      TOAST_NOTIFICATION_OPEN.create({
        content: message,
        appearance: 'info',
        autoDismiss: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
