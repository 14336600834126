import { fork } from 'redux-saga/effects';
// import { deviceRegistrationRequests } from './deviceRegistration';
import { profileRequests } from './profile';
import { gameRequests } from './gameRequests';
import { inviteRequests } from './invitation';
import { companyRequests } from './company';
import { feedbackRequests } from './feedback';
import { checkinRequests } from './checkin';
import { pokeRequests } from './poke';

export function* apiSaga() {
  yield fork(companyRequests);
  yield fork(gameRequests);
  yield fork(profileRequests);
  yield fork(inviteRequests);
  yield fork(feedbackRequests);
  yield fork(checkinRequests);
  yield fork(pokeRequests);
}
