import { APP_ERROR_DETECTED, TOAST_NOTIFICATION_OPEN } from '../../../actions';

const timeoutMessageGet: ToastNotification = {
  content: 'Hang on, the internet is a bit slow right now. Please hold on while we retry your request',
  appearance: 'warning',
  autoDismiss: true,
};

const secondTimeoutMessageGet: ToastNotification = {
  content: 'Still no luck. We are still trying to get through to our servers. Please wait...',
  appearance: 'warning',
  autoDismiss: true,
};

const profiles: Record<ProfileLabel, RetryProfile> = {
  defaultGet: {
    errorScheme: 'throw',
    onTimeoutAction: () => APP_ERROR_DETECTED.create({}),
    onNoInternetAction: () => APP_ERROR_DETECTED.create({
      title: 'No internet access',
      body: 'It seem that there is no access to the internet at the moment.\n\nPlease reconnect and try again.',
    }),

    // NOTE: We need to update this when errors from API has been harmonized.
    onRequestErrorAction: (error: APIError) => APP_ERROR_DETECTED.create({
      title: error.message,
      body: error.data?.message || 'no message',
    }),
    timeouts: [
      { timeoutDelay: 3000 },
      { timeoutDelay: 5000, onTimeout: TOAST_NOTIFICATION_OPEN.create(timeoutMessageGet) },
      { timeoutDelay: 30000 },
    ],
  },
  syncGet: {
    errorScheme: 'suppress_connection', // only surface API, data validation or authorizations errors
    timeouts: [
      { timeoutDelay: 5000 },
    ],
  },
  initializationGet: {
    errorScheme: 'throw',
    timeouts: [
      {
        timeoutDelay: 3000,
        onTimeout: TOAST_NOTIFICATION_OPEN.create(timeoutMessageGet),
      },
      {
        timeoutDelay: 5000,
        onTimeout: TOAST_NOTIFICATION_OPEN.create(secondTimeoutMessageGet),
      },
      { timeoutDelay: 30000 },
    ],
  },
  defaultPost: {
    errorScheme: 'throw',
    onTimeoutAction: () => TOAST_NOTIFICATION_OPEN.create(secondTimeoutMessageGet),
    onNoInternetAction: () => APP_ERROR_DETECTED.create({
      title: 'No internet access',
      body: 'It seem that there is no access to the internet at the moment.\n\nPlease reconnect and try again.',
    }),
    onRequestErrorAction: (error: APIError) => APP_ERROR_DETECTED.create({
      title: error.message,
      body: error.data?.message || 'no message',
    }),
    timeouts: [{ timeoutDelay: 15000 }],
  },
  checkInPost: {
    errorScheme: 'throw',
    timeouts: [{ timeoutDelay: 5000 }, { timeoutDelay: 15000 }, { timeoutDelay: 30000 }],
  },
  defaultPut: {
    errorScheme: 'throw',
    timeouts: [{ timeoutDelay: 5000 }],
  },
  defaultDelete: {
    errorScheme: 'throw',
    timeouts: [{ timeoutDelay: 5000 }],
  },
};

export function expandProfile(retry?: RetryMeta): RetryProfile | undefined {
  if (retry?.profileLabel === undefined) {
    return undefined;
  }

  const profile = profiles[retry.profileLabel];

  // Overwrite default handlers
  if (retry.onTimeoutAction !== undefined) {
    profile.onTimeoutAction = retry.onTimeoutAction;
  }

  if (retry.onRequestErrorAction !== undefined) {
    profile.onRequestErrorAction = retry.onRequestErrorAction;
  }

  if (retry.onNoInternetAction !== undefined) {
    profile.onNoInternetAction = retry.onNoInternetAction;
  }

  return profile;
}
