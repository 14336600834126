/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { iconSizes } from '../../utils';
import Icon from './Icon';
import styles from './ExpandButton.module.css';

interface Props {
  onClick: () => void;
  iconColor?: string;
  toCondense?: boolean;
  questionIcon?: boolean;
}

export function ExpandButton(props: Props) {
  const calculateSign = () => {
    if (props.questionIcon === true) {
      return 'question';
    }
    if (props.toCondense !== undefined) {
      if (props.toCondense === false) {
        return 'minus';
      }
    }
    return 'plus';
  };

  return (
    <button onClick={props.onClick} role="button" className={styles.exBtn}>
      <Icon
        icon={calculateSign()}
        color={props.iconColor ? props.iconColor : 'white'}
        size={iconSizes.tiny}
      />
    </button>
  );
}

export default ExpandButton;
