import { DefaultLangKeys } from '../en';

export const languageTag = 'da';

export const da: RecursivePartial<DefaultLangKeys> = {
  label: 'Dansk',
  cookie: {
    title: 'Dette site anvender cookies',
    content: [
      'Vi sætter en cookie ved brug af login.',
      'Login vil foregå via læringsspilsplatformen ComPlayance. Ved at acceptere vores nødvendige cookies, tillader du os at registrere, at du som bruger har anvendt websitet.',
      'Læs mere om vores databehandling og dine rettigheder i vores cookiepolitik og privatlivspolitik her:',
    ],
    button: 'Accepter nødvendige cookies',
  },
  sagaTranslate: {
    id1: 'Vi kan ikke hente spillet lige nu. Prøv igen senere.',
    id2: 'Det er ikke et gyldigt ID',
    id3: 'Noget gik galt',
    id4: 'Det trukket kort eksisterer ikke',
    id5: "Spil id'et blev ikke fundet.",
    id6: 'Internettet er lidt langsomt. Vent lidt.',
    id7: 'Stadig intet held. Vi prøver at kontakte serverne. Vent lidt.',
    id8: 'Det ser stadig ud til at der ikke er noget internet. Prøv at genindlæse siden, og prøv igen.',
    id9: 'Ingen internet adgang',
    id10: 'Ikke muligt at finde din token/kode',
    id11: 'Hov.',
    id12: 'Kunne ikke stoppe spillet',
    id13: 'Holdet blev ikke fundet',
    id14: 'Kunne ikke rykke brikken længere.',
    id15: 'Vi fik ikke et gyldigt svar',
    id16: 'Der er ikke flere tilgængelige kort - gå til næste sektion',
    id17: 'Spillet blev ikke fundet',
    id18: 'Kunne ikke opdatere spillet',
    id19: 'Spillerne blev sendt til den sidste refleksion',
    id20: 'Spillet er færdigt',
    id21: 'Dine refleksioner er afsendt',
    id22: 'Kort svaret',
  },
  screens: {
    navigation: {
      signed: 'Logget ind som',
      home: 'Hjem',
      createGame: 'Lav spil',
      joinGame: 'Tilslut spil',
      about: 'Om',
      logOut: 'Log ud',
      game: 'Spil',
      settings: 'Indstillinger',
    },
    welcome: {
      login: 'Velkommen til',
      loginBtn: 'Login',
      continueBtn: 'Fortsæt',
      explainer:
        'Du er ved at skulle oprette et nyt dilemmaspil. Når du klikker fortsæt vil du få mulighed for at oprette et spil. Husk at læse reglerne inden du starter spillet. God fornøjelse!',
      explainerLogin:
        'Du er ved at oprette et nyt dilemmaspil. Husk at læse reglerne inden du starter spillet. Når du klikker på nedenstående link vil du blive bedt om at logge in via spilservicen Complayance. God fornøjelse!',
      explainerBottom: 'Klik her for at logge ind.',
    },
    home: {
      hi: 'Hej',
      welcome: 'Velkommen til Gaming4Impact.',
      goTo: 'Vælg',
      goToGameCreation: 'Lav nyt spil',
      goToJoinGame: 'Tilslut et spil',
      yourRunningGames: 'Vælg et spil',
      noRunningGames: 'Ingen spil blev fundet, du har nu følgende muligheder.',
      login: 'Log ind for at bruge siden',
      loginBtn: 'Log ind',
      joinasGuest: 'eller tilslut et spil som gæst',
      guestButton: 'tilslut',
      buttonLogin: 'Log ind',
      gameowner: 'Game Master',
      created: 'Oprettet',
      enterGameBtn: 'Tilslut spil',
      notVerifiedHeader: 'Din konto ser ikke ud til at være verificeret.',
      notVerifiedBody:
        'For at verificere din konto, åben venligst emailen vi har sendt dig.',
      notVerifiedDone: 'Når dette er gjort, genindlæs da siden.',
      reloadBtn: 'Genindlæs',
    },
    settings: {
      settingsHeader: 'Indstillinger',
      avatar: 'Avatar',
      name: 'Navn',
      language: 'Sprog',
      contact: {
        title: 'Kontakt',
        description: 'Kontakt support',
      },
      email: 'Email',
      environment: 'Miljø',
      environmentVariables: {
        buildShortTag: 'Build tag',
        apiUrl: 'API endpoint',
      },
      unknown: 'Ukendt',
      selectLanguage: 'Vælg sprog',
      chooseNewImage: 'Vælg nyt billede',
      playerIcon: 'Spiller ikon',
      changeName: 'Skift navn',
      uploadImage: 'Vælg billede',
    },
    gameCodes: {
      code: 'Spilkode',
      waitingForCode: 'Venter på at koderne bliver lavet.',
      startGame: 'Start spil',
    },
    joinGame: {
      title: 'Tilslut spil',
      enterPin: 'Indtast din kode/token',
      enterName: 'Indtast dit ønskede navn',
      enterNewName: 'Indtast nyt navn',
      logoutButton: 'Log ud',
      guestMsg: 'Tilslutter spil som gæst med navnet',
    },
    gameCreation: {
      team: 'HOLD',
      selectIconName: 'Vælg ikon og navn',
      createTeams: 'Lav hold',
      addTeam: 'Tilføj hold',
      removeTeam: 'Fjern hold',
      submit: 'Ok',
      enterTeamName: 'Skriv hold navn',
      selectGameName: 'Vælg spilnavn',
      enterGameName: 'Skriv spilnavn',
      header: 'Opret spil',
      noRole: 'Du har ikke adgang til at lave spil.',
      availableGames: 'Spil',
      creationStep: 'Trin',
      goBtn: 'Vælg',
      createGameBtn: 'Opret spil',
      upTo: 'op til',
      progressBar: {
        inactive: 'Oversigt',
        summary: 'Venter',
        active: 'I gang',
        done: 'Færdigt',
      },
      summary: {
        summary: 'Resumé',
        gameName: 'Spilnavn',
        team: 'Hold',
        template: 'Variant',
        description: 'Beskrivelse',
        difficulty: 'Sværhedsgrad',
      },
      subHeaders: {
        gameName: 'Spilnavn',
        gameTemplate: 'Spilvariant',
        teams: 'Hold',
        summary: 'Resumé',
      },
    },
    reflectionPage: {
      submit: 'Ok',
      print: 'Print',
      mainReflectionCardPlaceholder: 'Skriv dine refleksioner her',
      subTitle: 'Mine refleksioner',
      mainSubTitle: 'Mine hovedrefleksioner',
    },
    gamePage: {
      challengeMode: {
        title: 'Udfordring',
        awaitTeams: 'Vent på at begge hold har skrevet deres refleksioner',
      },
      noPlayersPart1: 'Ingen spillere på dette hold, brug kode:',
      noPlayersPart2: 'for at invitere dem.',
      yesBtn: 'Ja',
      okBtn: 'Ok',
      stillHereHeader: 'Er du her stadig?',
      stillHereBody: 'Du har været her længe, spiller du stadig?',
      gameEndedHeader: 'Spillet er færdigt',
      gameEndedBody: 'Spillet er slut. ',
      notPartOfGameHeader: 'Du er ikke en del af dette spil',
      notPartOfGameBody: 'Gå tilbage til startsiden, og tilslut spillet.',
      currentTeamTurn: 'Aktive',
      upNextTeamTurn: 'næste',
      timer: {
        timesUp: 'Tiden er gået',
      },
      plaidModal: {
        startGame: 'Start spillet',
        stopGame: 'Afslut spillet',
      },
      copiedTeamCode: 'Team ID kopieret',
      copiedLinkMsg: 'Klik her, for at deltage i spillet:',
      copiedTeamCodeMsg: 'Din spilkode er',
      copiedJoinTeamMsg: 'Du er på hold',

      activeCard: {
        timer: { first: 'I har nu', second: 'sek. til at svare.' },
        gameIsGoing: 'Spillet er igang',
        cardTypes: {
          action: 'Handling',
          challenge: 'Udfordring',
          rolePlay: 'Rollespil',
          teamChallenge: 'Hold udfordring',
          reflection: 'Refleksion',
          discussion: 'Diskussion',
          dilemma: 'Dilemma',
        },
        noPlayers: {
          noPlayers: 'Der er ingen spillere på dette hold.',
          pleaseJoin:
            'Brug venligst følgende kode/token for at tilslutte dig dette hold.',
        },
        roleDistribution: 'Fordeling af roller',
        waitForNewCard: 'Vent på at Game Master trækker et nyt kort',
        waitForNewCardHeader: 'Intet kort',
        enterReflection: 'Skriv dine refleksioner',
        submit: 'Ok',
        guidance: 'Vejledning',
        replied: 'Svar',
        reflectionStateTextForGM:
          'Vi venter nu på slutovervejelserne. Vent på at spillerne skriver deres overvejelser ind, og så kan du stoppe spillet.',
        roleplay: {
          myAssignment: 'Min opgave:',
          noAssignment:
            'Du blev ikke tilgivet en rolle, så du har ingen opgave',
          myRole: 'Min rolle:',
          noRole: 'Ingen rolle',
        },
        answerText: {
          youAnswered: 'Du svarede',
          thisIs: 'Dette er',
          correct: 'rigtigt',
          incorrect: 'forkert',
          points: 'point',
          guidance: 'Vejledning',
          noPlayers:
            'Kan ikke svare på spørgsmålet, da der ikke er spillere på holdet.',
        },
        notYourTurn: 'IKKE DIN TUR',
      },
      teamCard: {
        score: 'Total score:',
        role: 'Rolle:',
        gamemaster: 'Game Master',
        youreAboutToKickPlayer: 'Du ved at fjerne en spiller',
        areYouSure: 'Er du sikker på at du vil fjerne en spiller?',
        active: 'Aktiv',
        players: 'Spillere',
        sections: 'Faser',
        noPlayers: 'Ingen spillere',
      },
      gmControls: {
        submitPoints: 'Indsend refleksionspoint',
        rollDice: 'Slå terning',
        wonChallenge: 'Udfordring vundet',
        lostChallenge: 'Udfordring tabt',
        challenge: 'Start udfordring (2 point)',
        gameInfo: 'Spillets info',
        ok: 'Ok',
        stopGame: 'Afslut Spillet',
        stopGameAndShowResults: 'Afslut spillet og vis resultater',
        submitReflectionsPoints: ['Giv', 'refleksioner'],
        startGame: 'Start Spil',
        stopGameQuestion: 'Er du sikker på at du vil stoppe spillet?',
        reflectionDeletion: [
          'Spillernes overvejelser vil blive slettet, er du sikker på at de har printet dem?',
        ],
        moveNextSection: 'Ryk til næste fase',
        moveBack: 'Ryk Tilbage',
        moveForwards: 'Ryk Frem',
        drawCard: 'Træk nyt kort',
        showInfo: 'Vis Info',
        accept: 'Accepter',
        endGameReflectionsbtn: 'Afsluttende refleksioner',
        changeTurn: 'Skift tur',
        gamename: 'Spilnavn',
        gameId: 'Spil id',
        sectionId: 'Fase id',
        sectionName: 'Fase navn',
        availCards: 'Tilgængelige kort her',
        currentCardId: 'Nuværende kort id',
        teams: 'Hold:',
        yes: 'Ja',
        notEveryOneHeader: 'Ikke alle har sendt deres refleksioner endnu',
        notEveryOneContent: 'Er du sikker på at du vil rykke?',
        cardIsAlreadyDrawnBody:
          'Et kort er allerede trukket, er du sikker på at du vil trække et nyt?',
        cardIsAlreadyDrawnHeader: 'Et kort er allerede trukket',
        buyToken: 'Køb et Værktøj (5 point)',
      },
    },
    others: {
      closeBtn: 'Luk',
    },
    errors: {
      notLoggedIn: 'Du er ikke logget ind. Klik her for at logge ind.',
      reloadPage: 'Klik her for at genindlæse siden.',
      goHome: 'Hvis problemet fortsætter, gå til start siden, og prøv igen',
      loginBtn: 'Log ind',
      homeBtn: 'Gå til start',
      reloadBtn: 'Genindlæs',
      login: 'Log ind',
    },
  },
};
