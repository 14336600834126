import { useEffect, useMemo, useState } from 'react';
import { CompanyThemes, ContentTheme } from '../utils';

interface Props {
  companyThemes: CompanyThemes;
}

// todo, ensure that this is correct.
enum subdomains {
  erstStage = 'erst',
  erst = 'dilemmaspillet',
  mmsPFourC = 'mms-p4c',
  mms = 'webapp',
  mmsBase = 'mms',
}

export function useThemeHelper({ companyThemes }: Props) {
  const [currentTheme, setCurrentTheme] = useState(companyThemes.mms);

  const host = useMemo(() => window.location.host, []);
  const urlSubdomains = useMemo(() => host.split('.'), [host]);

  useEffect(() => {
    const getThemeContent = (): ContentTheme => {
      // MUST BE .mms
      let theme: ContentTheme = companyThemes.mms;

      urlSubdomains.forEach((subdomain) => {
        if (
          subdomain === subdomains.erst ||
          subdomain === subdomains.erstStage
        ) {
          theme = companyThemes.erst;
        }
        if (
          subdomain === subdomains.mms ||
          subdomain === subdomains.mmsPFourC ||
          subdomain === subdomains.mmsBase
        ) {
          theme = companyThemes.mms;
        }
      });
      return theme;
    };
    setCurrentTheme(getThemeContent());
  }, [companyThemes.erst, companyThemes.mms, urlSubdomains]);

  useEffect(() => {
    if (currentTheme.documentTitle) document.title = currentTheme.documentTitle;
  }, [currentTheme]);

  return { currentTheme };
}
