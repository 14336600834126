import * as React from 'react';
import { iconSizes, mainColors, spacing } from '../../../utils';
import HoverSimpleCard from './HoverSimpleCard';
import styles from './TeamCardsSimple.module.css';
import { ThemeContext } from '../../../App';
import { StringsContext } from '../../../locales';
import Icon from '../Icon';
import { useGameTemplate } from '../../../hooks/useGameTemplate';
import { useSelector } from 'react-redux';
import { engine } from '../../../redux/selectors';

interface Props {
  teams: DTeam[];
}
function TeamCardsSimple({ teams }: Props) {
  const tOne = teams[0] || undefined;
  const tTwo = teams[1] || undefined;
  const currentSection = useSelector(engine.currentGameSection);

  return (
    <div className={styles.teams_container}>
      {tOne && (
        <Team
          key={tOne.id}
          team={tOne}
          active={tOne.isCurrentTeam}
          currentSetionId={currentSection?.id || 99}
        />
      )}

      {tTwo && (
        <Team
          key={tTwo.id}
          team={tTwo}
          active={tTwo.isCurrentTeam}
          currentSetionId={currentSection?.id || 99}
        />
      )}
    </div>
  );
}
export default TeamCardsSimple;

interface TeamProps {
  team: DTeam;
  active?: boolean;
  currentSetionId: number;
}

function Team({ team, active, currentSetionId }: TeamProps) {
  const [showCard, setShowCard] = React.useState(false);

  const theme = React.useContext(ThemeContext);
  const { screens } = React.useContext(StringsContext);

  const allGameTokens = useSelector(engine.allGameTokens);
  const teamTokens: (GameToken | { icon: null })[] = React.useMemo(() => {
    if (team.acquiredGameTokens) {
      const extraTokens = [];
      for (
        let i = team.acquiredGameTokens.length;
        i < allGameTokens.length;
        i++
      ) {
        extraTokens.push({ icon: null });
      }
      return [...team.acquiredGameTokens, ...extraTokens];
    }
    return [];
  }, [team]);

  const currentTeamSection = team.teamSectionPoints?.find(
    (e) => e.section?.id === currentSetionId
  );

  return (
    <div className={styles.teams_box} style={{ flexDirection: 'column' }}>
      <div
        className={styles.teams_box}
        style={{ backgroundColor: mainColors.white }}
        onMouseEnter={() => setShowCard(true)}
        onMouseLeave={() => setShowCard(false)}
      >
        {active && (
          <p
            className={styles.team_active}
            style={{
              color: mainColors.black,
              backgroundColor: theme.buttonColors.active,
            }}
          >
            {screens.gamePage.teamCard.active}
          </p>
        )}
        <div className={styles.team_content}>
          <p
            className={styles.team_content_name}
            style={{ color: mainColors.black }}
          >
            {team.name}
          </p>
          <p className={styles.team_content_points}>
            {currentTeamSection?.point || 0} Point
          </p>
          {showCard && (
            <HoverSimpleCard
              players={team.players || []}
              gameSections={team.teamSectionPoints || []}
            />
          )}
        </div>
      </div>
      <Tokens
        backgroundColor={theme.primary}
        border={theme.buttonColors.disabled}
        teamTokens={teamTokens}
        iconColor={theme.primaryText}
      />
    </div>
  );
}
interface TokensProps {
  backgroundColor: string;
  border: string;
  teamTokens: (GameToken | { icon: null })[];
  iconColor: string;
}

function Tokens({
  backgroundColor,
  border,
  teamTokens,
  iconColor,
}: TokensProps) {
  const { showMobile } = useGameTemplate();

  return (
    <div
      className={styles.gameTokensContainer}
      style={{
        height: iconSizes.tokenHeight,
        backgroundColor,
        border: `2px solid ${border}`,
        borderLeft: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -iconSizes.tokenHeight,
      }}
    >
      {teamTokens?.map((token, index) => (
        <div
          className={styles.gameToken}
          style={{
            borderLeft: `2px solid ${border}`,
          }}
          key={index}
        >
          {token.icon && (
            <Icon
              icon={token.icon}
              size={showMobile ? 'small' : 'token'}
              color={iconColor}
            />
          )}
        </div>
      ))}
    </div>
  );
}
