import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Navigation from '../components/navigation';
import { account, engine } from '../redux/selectors';
import { AUTH0_LOGOUT_REQUEST } from '../redux/actions';

const mapStateToProps = (state: AppState) => {
  const me = account.me(state);
  return {
    username: me && me?.name ? me.name : '',
    avatar: me && me?.avatar ? me.avatar : '',
    isGameMaster: account.accountIsGameMaster(state),
    isAuthenticated: state.auth.isAuthenticated,
    activeGame: engine.currentGame(state),
    isEmailVerified: state.auth.emailVerified,
    isGuest: state.auth.isGuest,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onPressLogout: () => dispatch(AUTH0_LOGOUT_REQUEST.create(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
