import React from 'react';
import { ThemeContext } from '../../../App';
import styles from './HoverSimpleCard.module.css';
import SectionPresentation from '../SectionPresentation';
import { StringsContext } from '../../../locales';

interface Props {
  gameSections: DTeamSectionPoints[];
  players: Player[];
}

const HoverSimpleCard = ({ gameSections, players }: Props) => {
  const theme = React.useContext(ThemeContext);
  const strings = React.useContext(StringsContext);
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: theme.primary,
      }}
    >
      <span>
        <p
          style={{
            color: theme.primaryText,
            margin: '0px 5px',
            textDecoration: 'underline',
          }}
        >
          {strings.screens.gamePage.teamCard.players}
        </p>
        {players.map((p) => (
          <p
            style={{
              color: theme.primaryText,
              margin: '0px 5px',
              fontWeight: 'normal',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {p.name}
          </p>
        ))}
        {players.length === 0 && (
          <p
            style={{
              color: theme.primaryText,
              margin: '0px 5px',
              fontWeight: 'normal',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {strings.screens.gamePage.teamCard.noPlayers}
          </p>
        )}
      </span>

      <p
        style={{
          color: theme.primaryText,
          margin: '5px 5px',
          textDecoration: 'underline',
        }}
      >
        {strings.screens.gamePage.teamCard.sections}
      </p>
      {gameSections.map((s) => (
        <SectionPresentation gameSection={s} />
      ))}

      <div
        className={styles.arrow}
        style={{
          borderTop: `20px solid ${theme.primary}`,
        }}
      ></div>
    </div>
  );
};

export default HoverSimpleCard;
