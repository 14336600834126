import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';

import {
  API_APP_CHECKIN_POST_REQUEST,
  API_APP_CHECKIN_POST_SUCCESS,
  API_APP_CHECKIN_POST_FAILURE,
} from '../../actions';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_APP_CHECKIN_POST_REQUEST.type: {
      const payload = API_APP_CHECKIN_POST_REQUEST.payload(action);
      const retry = API_APP_CHECKIN_POST_REQUEST.meta(action);
      let response: CheckinResponse;
      try {
        const options = {
          body: JSON.stringify(payload),
          method: 'POST',
        };

        response = yield request('/checkin', options, expandProfile(retry));

        yield put(API_APP_CHECKIN_POST_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_APP_CHECKIN_POST_FAILURE.create(error));
      }
      break;
    }
    default:
  }
}

export function* checkinRequests() {
  yield takeEvery([API_APP_CHECKIN_POST_REQUEST.type], handleRequest);
}
