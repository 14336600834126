import { takeLatest, put, select } from 'redux-saga/effects';

import { requestHandler } from '../api/utils/requestHandler';

import {
  AUTH0_AUTHENTICATE_REQUEST,
  AUTH0_LOGOUT_REQUEST,
  AUTH0_REFRESH_ACCESSTOKEN_FAILURE,
  AUTH0_REFRESH_ACCESSTOKEN_REQUEST,
  AUTH0_REFRESH_ACCESSTOKEN_SUCCESS,
  AUTH_TOKEN_REFRESH_REQUEST,
  AUTH_TOKEN_REFRESH_SUCCESS,
  AUTH_TOKEN_REFRESH_FAILURE,
  AUTH_ACCESS_TOKEN_SET,
} from '../../actions';
import { Auth } from './auth';
import { IdToken } from '@auth0/auth0-spa-js';
import { LoginFlavorSchema } from '../../../types/schemas/entities';
import { request, expandProfile } from '../api/utils';
import { auth as authSelectors } from '../../selectors';

let auth: Auth;
let loginSystem: LoginSystems = 'keycloak';
function* handleAuth0Request(action: GenericAction) {
  if (!auth) {
    try {
      const response: object = yield request(
        '/login_flavor.json',
        {},
        expandProfile({ profileLabel: 'defaultGet' }),
        'root'
      );

      const loginSystemFlavor = LoginFlavorSchema.parse(response);

      loginSystem = loginSystemFlavor.type;
    } catch (e: any) {
      console.warn('Unable to fetch login system');
    }

    auth = new Auth({ loginSystem });
  }

  switch (action.type) {
    case AUTH0_REFRESH_ACCESSTOKEN_REQUEST.type: {
      try {
        const accessToken: string = yield auth.getTokenSilently();
        const tokenClaims: IdToken = yield auth.getIdTokenClaims();

        yield put(
          AUTH0_REFRESH_ACCESSTOKEN_SUCCESS.create({
            accessToken,
            emailVerified: tokenClaims.email_verified!,
          })
        );
      } catch (error: any) {
        console.error('While getting tokens: ', error);
        yield put(
          AUTH0_REFRESH_ACCESSTOKEN_FAILURE.create({
            name: 'access.refresh',
            message: 'Unable to retreive access token. - ' + error,
          })
        );
      }
      break;
    }
    case AUTH0_AUTHENTICATE_REQUEST.type: {
      const payload = AUTH0_AUTHENTICATE_REQUEST.payload(action);

      let redirect_uri;
      if (payload) {
        redirect_uri = `${location.protocol}//${window.location.host}/${payload}`;
      }

      yield auth.loginWithRedirect({ redirect_uri });
      break;
    }
    case AUTH0_LOGOUT_REQUEST.type: {
      yield auth.logout({ returnTo: window.location.origin });
      break;
    }
    case AUTH_TOKEN_REFRESH_REQUEST.type: {
      // somewhat a quick hack - bypass access token resolver.
      // We will need to set the cors headers to allow all in order for token refresh to work
      // And so this is not really tested!

      // What should happen depends on if the user is guest 
      const isGuest: boolean = yield select(authSelectors.isGuest);

      try {

        let accessToken = '';

        if (isGuest) {
          accessToken = yield requestHandler(
            '/auth/token',
            {
              credentials: 'include',
            },
            'api',
            true
          );
        }
        else {
          accessToken = yield auth.getTokenSilently();
        }

        yield put(AUTH_ACCESS_TOKEN_SET.create({ accessToken, isGuest }));

        if (accessToken) {
          yield put(AUTH_TOKEN_REFRESH_SUCCESS.create(accessToken));
        } else {
          // session expired, go to login.
          yield put(AUTH_TOKEN_REFRESH_FAILURE.create('Could not refresh token'));
        }

      } catch (e) {
        console.info('Anonymous token refresh failed');

        // clear token
        yield put(
          AUTH_ACCESS_TOKEN_SET.create({ accessToken: '', isGuest })
        );
        yield put(AUTH_TOKEN_REFRESH_FAILURE.create('Could not refresh token'));
        yield put(AUTH0_LOGOUT_REQUEST.create(null));
      }
      break;
    }
    default:
  }
}

window.addEventListener('load', async () => {
  while (!auth) {
    await new Promise((f) => setTimeout(f, 500));
  }
  await auth.getUser();
  if (window.location.toString().includes('/?code=')) {
    window.history.replaceState({}, document.title, '/');
  }
});

export function* auth0Saga() {
  yield takeLatest(
    [
      AUTH0_REFRESH_ACCESSTOKEN_REQUEST.type,
      AUTH0_AUTHENTICATE_REQUEST.type,
      AUTH0_LOGOUT_REQUEST.type,
      AUTH_TOKEN_REFRESH_REQUEST.type,
    ],
    handleAuth0Request
  );
}

export const authClient = () => auth;
