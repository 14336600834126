/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'tr';

export const tr: RecursivePartial<DefaultLangKeys> = {
  label: 'Türkçe',
  sagaTranslate: {
    id1: 'Oyun yüklenemiyor: Şu anda oyunu açamıyoruz. Lütfen daha sonra tekrar deneyin.',
    id2: 'Geçerli bir oyun kimliği değil',
    id3: 'Bir şeyler ters gitti',
    id4: 'Çekilen kart mevcut değil',
    id5: 'Oyun kimliği bulunamadı',
    id6: 'Özür dileriz, internet şu anda biraz yavaş. Talebini tekrar işleme alırken lütfen ayrılma',
    id7: 'Ne yazık ki hala şansımız yok. Hala oyunu yeniden yüklemeye çalışıyoruz. Lütfen kısa bir süre bekle...',
    id8: 'Şu anda internet erişimi yok. Yeniden bağlantı kur ve tekrar dene.',
    id9: 'İnternet erişimi yok.',
    id10: 'Erişim belirteci yeniden yüklenemiyor.',
    id11: 'Oyunu kapatabilmek için önce yüklemen gerekir.',
    id12: 'Oyun durdurulamadı',
    id13: 'Bu ekip bulunamadı',
    id14: 'Piyon hareket ettirilemiyor, çünkü menzil dışında.',
    id15: 'Geçerli bir cevap almadık (cevap geçersiz)',
    id16: 'Başka kart yok.',
    id17: 'Oyun bulunamadı',
    id18: 'Durum güncellenemedi',
    id19: 'Diğer oyuncular başarılı bir şekilde son yansıtmaya yönlendirildiler.',
    id20: 'Oyun sona erdi, yeni bir oyuna katılmak için mevcut bir oyuna katılmaya git!',
    id21: 'Yansıtman başarıyla tamamlandı',
    id22: 'Kart işlendi',
  },
  screens: {
    navigation: {
      signed: 'Oturum açan kullanıcı',
      home: 'Ana sayfa',
      createGame: 'Oyun oluştur',
      joinGame: 'Oyuna katıl',
      about: 'Hakkında',
      logOut: 'Oturumu kapat',
      game: 'Oyun',
      settings: 'Ayarlar',
    },
    home: {
      hi: 'Merhaba',
      welcome: 'Tarayıcıda Gaming4Impact\'a hoş geldin.',
      goTo: 'Başlıyor',
      goToGameCreation: 'Yeni bir oyun oluştur',
      goToJoinGame: 'Mevcut bir oyuna katıl',
      yourRunningGames: 'Mevcut bir oyun seç.',
      noRunningGames: 'Mevcut bir oyun bulunamadı, aşağıdaki olanaklara sahipsin:',
      login: 'Lütfen oturum aç',
      loginBtn: 'Oturum aç',
      buttonLogin: 'Oturum aç',
      gameowner: 'Oyun yöneticisi',
      created: 'Oluşturuldu',
      enterGameBtn: 'Oyuna katıl',
    },
    settings: {
      settingsHeader: 'Bakış açısı',
      avatar: 'Avatar',
      name: 'Ad',
      language: 'Dil',
      selectLanguage: 'Dil seçin',
      chooseNewImage: 'Yeni resim seç',
      playerIcon: 'Oyuncu simgesi',
      changeName: 'Adı değiştir',
      uploadImage: 'Resim yükle',
      email: 'E-posta',
      unknown: 'Bilinmiyor',
      environment: 'Ortam',
      environmentVariables: {
        buildShortTag: 'Etiket oluştur',
        apiUrl: 'API uç noktası',
      },
    },
    gameCodes: {
      code: 'Kod',
      waitingForCode: 'Kodun oluşturulmasını bekle',
      startGame: 'Oyunu başlat',
    },
    joinGame: {
      title: 'Oyuna katıl',
      enterPin: 'Pin gir',
    },
    gameCreation: {
      team: 'EKİP',
      selectIconName: 'Bir sembol ve ad seç',
      createTeams: 'Ekip oluştur',
      addTeam: 'Ekip ekle',
      removeTeam: 'Ekip sil',
      submit: 'Tamam',
      enterTeamName: 'Ekip adı seç',
      selectGameName: 'Lütfen bir oyun adı seç',
      enterGameName: 'Oyun adını gir',
      header: 'Oyun oluştur',
      noRole: 'Ne yazık ki oyun oluşturmak için gerekli yetkilere sahip değilsin.',
      availableGames: 'Mevcut oyunlar',
      summary: {
        summary: 'Özet',
        gameName: 'Oyun adı',
        team: 'Ekip',
        template: 'Şablon',
        description: 'Açıklama',
        difficulty: 'Zorluk derecesi',
      },
      subHeaders: {
        gameName: 'Oyun adı',
        gameTemplate: 'Oyun şablonu',
        teams: 'Ekipler',
        summary: 'Özet',
      },
      progressBar: {
        inactive: 'Genel bakış',
        summary: 'Bekle',
        active: 'İşleniyor',
        done: 'Tamamlandı',
      },
      createGameBtn: 'Oyun oluştur',
      creationStep: 'Adım',
      goBtn: 'Başlıyor',
    },
    reflectionPage: {
      submit: 'Tamam',
      mainReflectionCardPlaceholder: 'Ana potansiyelim',
      subTitle: 'Yansıtmalarım',
      mainSubTitle: 'Ana potansiyelim',
      print: 'Yazdır',
    },
    gamePage: {
      yesBtn: 'Evet',
      stillHereHeader: 'Hala burada mısın?',
      stillHereBody: 'Çok uzun süredir buradasın. Hala oynuyor musun?',
      gameEndedHeader: 'Oyun tamamlandı',
      gameEndedBody: 'Oyun sona erdi. Sizi anasayfaya geri getiriyoruz',
      notPartOfGameHeader: 'Ne yazık ki bu oyuna dahil değilsin',
      notPartOfGameBody: 'Ana sayfaya geri dön ve oyuna katıl düğmesine tıkla',
      okBtn: 'Tamam',
      activeCard: {
        cardTypes: {
          rolePlay: 'Rol oyunu',
          reflection: 'Yansıtma',
          discussion: 'Grup tartışması',
          action: 'Eylem',
          challenge: 'Meydan okuma',
          teamChallenge: 'Ekip meydan okuması',
        },
        waitForNewCard: 'Lütfen oyun yöneticisi yeni bir kart çekene kadar bekle.',
        waitForNewCardHeader: 'Kart yok',
        enterReflection: 'Yansıtmalarını yaz',
        submit: 'Tamam',
        guidance: 'Bilgiler',
        replied: 'Cevap',
        reflectionStateTextForGM: `Şimdi son yansıtma aşamasındayız. 
        Lütfen tüm oyuncular ana potansiyellerini not edene ve yansıtmalarını yazdırana veya kaydedene kadar bekle. 
        Ardından "Oyunu sonlandır" düğmesine tıklayarak oyunu sonlandırabilirsin.`,
        roleplay: {
          myAssignment: 'Görevim:',
          myRole: 'Rolüm:',
          noAssignment: 'Sana bir rol verilmedi',
          noRole: 'Rol yok',
        },
        answerText: {
          youAnswered: 'Cevap verdin',
          thisIs: 'Bu',
          correct: 'doğru',
          incorrect: 'doğru değil',
          points: 'Puanlar',
          guidance: 'Bilgiler',
        },
      },
      teamCard: {
        score: 'Toplam puan:',
        role: 'Rol:',
        gamemaster: 'Oyun yöneticisi',
        youreAboutToKickPlayer: 'Oyuncuyu çıkar',
        areYouSure: 'Bu oyuncuyu çıkarmak istediğinden emin misin?',
      },
      gmControls: {
        gameInfo: 'Oyun bilgileri',
        ok: 'Tamam',
        stopGame: 'Oyunu sonlandır',
        stopGameQuestion: 'Oyunu sonlandırmak istediğinden emin misin?',
        reflectionDeletion: ['Oyunculardan yansıtmalarını yazdırmalarını rica ettin mi?'],
        moveBack: 'Geriye sürüş',
        moveForwards: 'İleri hareket',
        drawCard: 'Kart çek',
        showInfo: 'Bilgileri göster',
        accept: 'Onay',
        endGameReflectionsbtn: 'Son yansıtmalar',
        gamename: 'Oyun adı',
        gameId: 'Oyun kimliği',
        sectionId: 'Bölüm kimliği',
        sectionName: 'Bölüm adı',
        availCards: 'Destedeki mevcut kartlar',
        currentCardId: 'Güncel kart kimliği',
        teams: 'Ekipler:',
        yes: 'Evet',
        notEveryOneHeader: 'Tüm oyuncular yansıtmalarını tamamlamadı',
        notEveryOneContent: 'Piyonu ilerletmek istediğinden emin misin?',
        cardIsAlreadyDrawnBody: 'Bir kart çektin zaten. Yeni bir kart çekmek istediğinden emin misin?',
        cardIsAlreadyDrawnHeader: 'Bir kart çektin zaten',
      },
    },
    others: { closeBtn: 'Kapat' },
    errors: {
      notLoggedIn: 'Oturum açmamış görünüyorsun. Oturum açmak için buraya tıkla.',
      reloadPage: 'Sayfayı yeniden yüklemek için buraya tıkla',
      goHome: 'Sorun devam ederse lütfen ana sayfaya git ve tekrar dene.',
      loginBtn: 'Oturum aç',
      homeBtn: 'Ana sayfa',
      reloadBtn: 'Yeniden yükle',
      login: 'Oturum aç',
    },
  },
};
