// eslint-disable-next-line import/no-cycle
import { expandReply } from './gameInstance'; // has to be checked after?
import { expandTeams } from './teams';

interface PendingReply {
  isPendingMyReply: boolean;
  isPendingReply: boolean;
  isMyTurn: boolean;
  pendingPlayerReplies: Player[];
}

function playersPendingReply(
  gameState: GameState,
  allPlayers: Player[]
): Player[] {
  // current team
  const currentTeamUnpacked = gameState.teams.filter((gameStateTeam) => {
    return gameStateTeam.id === gameState.currentTeam;
  });
  // Make playerIds into actual players
  if (currentTeamUnpacked[0]) {
    const playerIds: number[] = currentTeamUnpacked[0].players;
    const players: Player[] = [];

    allPlayers.forEach((singlePlayer) => {
      if (playerIds.includes(singlePlayer.id)) {
        players.push(singlePlayer);
      }
    });

    return players;
  }
  return [];
}

function pendingReply(
  gameState: GameState,
  me: Player | undefined,
  allPlayers: Player[],
  cardReplies: Reply[]
): PendingReply {
  // only on the card, not all cards.
  const currentTeam = gameState.teams.find(
    (team) => team.id === gameState.currentTeam
  );
  let isPendingReply = true;
  if (cardReplies.length !== 0) {
    const teamTheDrewTheCardId = cardReplies[0].teamId;
    const drewCardTeam = gameState.teams.find(
      (team) => team.id === teamTheDrewTheCardId
    );
    isPendingReply = cardReplies.length < (drewCardTeam?.players.length || 0);
  }

  let isPendingMyReply = false;
  const isOnCurrentTeam = currentTeam?.players.find(
    (playerId) => playerId === me?.id
  );
  let isMyTurn = !!isOnCurrentTeam;
  if (me?.id === undefined) {
    // guest user have no id currently
    isMyTurn = true;
  }

  if (isPendingReply && isMyTurn) {
    isPendingMyReply = !cardReplies.find((reply) => reply.playerId === me?.id);
  }

  const pendingPlayerReplies = playersPendingReply(gameState, allPlayers);

  return {
    isPendingReply,
    isPendingMyReply,
    isMyTurn,
    pendingPlayerReplies,
  };
}

export function expandCard(
  allPlayers?: Player[],
  gameState?: GameState,
  me?: Player,
  sections?: GameSection[]
) {
  if (!gameState || !allPlayers) {
    return () => null;
  }

  return (cardInstance?: CardInstance | null): DCardInstance | null => {
    if (cardInstance) {
      const forfeit = cardInstance.modifiers.some(
        (modifier) => modifier.type === 'forfeit'
      );
      const bonus = cardInstance.modifiers.some(
        (modifier) => modifier.type === 'bonus'
      );

      const cardReplies = gameState.replies.filter(
        (reply) => reply.cardId === cardInstance.id
      );

      const dteams = expandTeams(
        gameState.teams,
        gameState.players,
        sections,
        undefined,
        gameState,
        null
      );
      const dnormReplies = cardReplies.map((reply) =>
        expandReply(reply, [cardInstance], dteams, gameState.players)
      );

      // filteredDnormReplies does the same as the out commented function, but is only used to keep linter happy
      // const filteredDnormReplies: DReply[] | undefined = dnormReplies.filter((reply) => reply !== undefined);
      const filteredDnormReplies: DReply[] | undefined = dnormReplies.filter(
        (reply): reply is DReply => !!reply
      );

      const isDrawn = gameState.drawn?.cardId === cardInstance.id;
      const {
        isPendingMyReply,
        isPendingReply,
        isMyTurn,
        pendingPlayerReplies,
      } = pendingReply(gameState, me, gameState.players, cardReplies);

      return {
        ...cardInstance,
        isForfeit: forfeit,
        isBonus: bonus,
        replies: filteredDnormReplies,
        isDrawn,
        isPendingMyReply,
        isPendingReply,
        isMyTurn,
        pendingPlayerReplies,
      };
    }

    return null;
  };
}
