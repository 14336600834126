import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';

import {
  API_POKE_POST_FAILURE,
  API_POKE_POST_REQUEST,
  API_POKE_POST_SUCCESS,
  API_POKE_REPLY_POST_FAILURE,
  API_POKE_REPLY_POST_REQUEST,
  API_POKE_REPLY_POST_SUCCESS,
  API_POKE_TEMPLATE_GET_FAILURE,
  API_POKE_TEMPLATE_GET_REQUEST,
  API_POKE_TEMPLATE_GET_SUCCESS,
} from '../../actions';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_POKE_TEMPLATE_GET_REQUEST.type: {
      const retry = API_POKE_TEMPLATE_GET_REQUEST.meta(action);

      try {
        const response: PokeTemplate[] = yield request(
          '/poke/template',
          {},
          expandProfile(retry)
        );

        yield put(API_POKE_TEMPLATE_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_POKE_TEMPLATE_GET_FAILURE.create(error));
      }
      break;
    }

    case API_POKE_POST_REQUEST.type: {
      const payload = API_POKE_POST_REQUEST.payload(action);
      const retry = API_POKE_POST_REQUEST.meta(action);

      const options = {
        method: 'POST',
        body: JSON.stringify(payload),
      };

      try {
        const response: PokeInstance = yield request(
          '/poke/instance',
          options,
          expandProfile(retry)
        );

        yield put(API_POKE_POST_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_POKE_POST_FAILURE.create(error));
      }
      break;
    }

    case API_POKE_REPLY_POST_REQUEST.type: {
      const payload = API_POKE_REPLY_POST_REQUEST.payload(action);
      const retry = API_POKE_REPLY_POST_REQUEST.meta(action);

      const options = {
        method: 'POST',
        body: JSON.stringify(payload.reply),
      };

      try {
        const response: PokeInstance = yield request(
          `/poke/instance/${payload.pokeInstanceId}/reply`,
          options,
          expandProfile(retry)
        );

        yield put(API_POKE_REPLY_POST_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_POKE_REPLY_POST_FAILURE.create(error));
      }
      break;
    }
    default:
  }
}

export function* pokeRequests() {
  yield takeEvery(
    [
      API_POKE_TEMPLATE_GET_REQUEST.type,
      API_POKE_POST_REQUEST.type,
      API_POKE_REPLY_POST_REQUEST.type,
    ],
    handleRequest
  );
}
