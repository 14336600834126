import { all, delay } from 'redux-saga/effects';
import { appSaga } from './app/index';
import { auth0Saga } from './auth0';
import { apiSaga } from './api';
import { accountSaga } from './account';
import { initializationSaga } from './initialization';
import { factorySaga } from './factory';
import { browserSaga } from './browser';
import { engineSaga } from './engine';
import { langSaga } from '../../locales';
import { localizeSaga } from './localize';

function* rootSaga() {
  do {
    try {
      yield all([
        appSaga(),
        auth0Saga(),
        apiSaga(),
        accountSaga(),
        initializationSaga(),
        factorySaga(),
        browserSaga(),
        engineSaga(),
        langSaga(),
        localizeSaga(),
      ]);
    }
    catch (_e) {
      // If any saga dies then wait a bit and start them again. 
      // We should collect metrics for this when that is set up. 
      yield delay(1000);
    }
  } while (true);
}

export default rootSaga;
