import React, { useContext } from 'react';
import styles from './Button.module.css';
import Icon, { IconNames, SVGIcon } from './Icon';
import { ThemeContext } from '../../App';

interface Props {
  title?: string;
  icon?: IconNames | SVGIcon;
  onClick?: () => void;
  disabled?: boolean;
  backgroundColor?: string;
  textColor?: string;
  style?: React.CSSProperties;
}

function Button(props: Props) {
  const theme = useContext(ThemeContext);

  const backgroundColor = () => {
    if (props.disabled) {
      return theme.buttonColors.disabled;
    }
    if (props.backgroundColor) {
      return props.backgroundColor;
    }
    return theme.buttonColors.active;
  };

  return (
    <button
      type="button"
      disabled={props.disabled}
      className={styles.buttonContainer}
      style={{
        backgroundColor: backgroundColor(),
        ...props.style,
      }}
      onClick={() => {
        if (props.disabled) return;
        if (!props.onClick) return;
        props.onClick();
      }}
    >
      <div className={styles.buttonWrapper}>
        <span
          className={[styles.buttonText, 'fontFamilyBold'].join(' ')}
          style={{
            color:
              props.textColor ||
              (props.disabled && theme.buttonColors.disabledText)
                ? theme.buttonColors.disabledText
                : theme.buttonColors.text,
          }}
        >
          {props.title}
        </span>
        {props.icon && (
          <div style={props.title ? { marginLeft: '5px' } : {}}>
            <Icon
              icon={props.icon}
              size="tiny"
              color={props.textColor || theme.buttonColors.text}
            />
          </div>
        )}
      </div>
    </button>
  );
}

export default Button;
