type ErrorTypes = 'request' | 'timeout' | 'validation' | 'internet_access';

interface ErrorPayload {
  status?: number;
  errorType: ErrorTypes;
  url?: string;
  data?: object;
}

export interface APIError {
  message: string;
  name: string;
  status: number | undefined;
  url: string | undefined;
  data: any;
  errorType: ErrorTypes;
}

export class APIErrorCreator extends Error implements APIError {
  public status: number | undefined;

  public errorType: ErrorTypes;

  public url: string | undefined;

  public data: object | undefined;

  constructor(message: string, payload: ErrorPayload) {
    super(message);
    this.name = this.constructor.name;
    this.status = payload.status;
    this.errorType = payload.errorType;
    this.url = payload.url;
    this.data = payload.data;
  }
}
