// import { SagaIterator } from 'redux-saga';
import { race, delay, put } from 'redux-saga/effects';
import { ApiUrls, requestHandler } from './requestHandler';
import { mergeDeep } from '../../../../utils/deepMerge';
import { APIErrorCreator, APIError } from './APIError';
import { TOAST_NOTIFICATION_OPEN } from '../../../actions';

// type RequestHandler = (endpoint: string, options?: object, base?: 'api') => SagaIterator;

const timeoutMessage: ToastNotification = {
  content:
    'Hang on, the internet is a bit slow right now. Please hold on while we retry your request',
  appearance: 'warning',
  autoDismiss: true,
};

const defaultProfile: RetryProfile = {
  errorScheme: 'throw',
  onTimeoutAction: () => TOAST_NOTIFICATION_OPEN.create(timeoutMessage),
  timeouts: [{ timeoutDelay: 5000 }],
};

function* requestWithErrors(
  endpoint: string,
  options = {},
  profile = defaultProfile,
  base: ApiUrls = 'api'
) {
  let timeoutCounter = 0;

  while (true) {
    const config = profile.timeouts[timeoutCounter];

    if (!config) {
      throw new APIErrorCreator('Timed out.', { errorType: 'timeout' });
    }

    const abortController = new AbortController();
    const optionsWithSignal = mergeDeep(options, {
      signal: abortController.signal,
    });

    const { response, timeout } = yield race({
      response: requestHandler(endpoint, optionsWithSignal, base),
      timeout: delay(config.timeoutDelay),
    });

    if (timeout) {
      abortController.abort();
      timeoutCounter += 1;
      if (config.onTimeout) {
        yield put(config.onTimeout);
      }

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // if (__DEV__) {
        console.info(`Request timeout received ${timeoutCounter} ${endpoint}`);
      }
    } else {
      return response;
    }
  }
}

export function* request(
  endpoint: string,
  options = {},
  profile = defaultProfile,
  base: ApiUrls = 'api'
) {
  try {
    // @ts-ignore
    return yield requestWithErrors(endpoint, options, profile, base);
  } catch (e: any) {
    const error: APIError = e;
    const { errorScheme } = profile;

    // handle the different error types
    switch (error.errorType) {
      case 'internet_access': {
        if (profile.onNoInternetAction) {
          const action = profile.onNoInternetAction(error);

          if (action) {
            yield put(action);
          }

          // suppress errors
          if (
            errorScheme === 'suppress_all' ||
            errorScheme === 'suppress_connection'
          ) {
            return undefined;
          }
        }
        break;
      }
      case 'request': {
        if (profile.onRequestErrorAction) {
          const action = profile.onRequestErrorAction(error);

          if (action) {
            yield put(action);
          }

          // suppress errors
          if (errorScheme === 'suppress_all') {
            return undefined;
          }
        }
        break;
      }
      case 'timeout': {
        if (profile.onTimeoutAction) {
          const action = profile.onTimeoutAction(error);

          if (action) {
            yield put(action);
          }

          // suppress all errors but throw
          const hesConnectionErrors =
            errorScheme === 'suppress_connection' ||
            errorScheme === 'suppress_timeout';
          if (errorScheme === 'suppress_all' || hesConnectionErrors) {
            return undefined;
          }
        }
        break;
      }
      default:
    }

    // Should only be reached if profile.errorScheme is set to 'throw'
    throw new APIErrorCreator(error.message, { ...error });
  }
}
