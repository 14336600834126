/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'pt';

export const pt: RecursivePartial<DefaultLangKeys> = {
  label: 'Português',
  sagaTranslate: {
    id1: 'Não é possível carregar o jogo: Não conseguimos abrir o jogo neste momento. Tente novamente mais tarde.',
    id2: 'não é um ID de jogo válido',
    id3: 'Algo correu mal',
    id4: 'A cartão tirada não existe',
    id5: 'Não foi possível encontrar o ID do jogo',
    id6: 'Lamentamos, neste momento a Internet está um pouco lenta. Aguarde, enquanto processamos o seu pedido novamente',
    id7: 'Ainda sem sorte, infelizmente. Ainda estamos a tentar recarregar o jogo. Aguarde um minuto...',
    id8: 'Neste momento, não há acesso à Internet. Voltar a ligar e tentar novamente.',
    id9: 'Sem acesso à Internet.',
    id10: 'Não é possível restaurar o código de acesso.',
    id11: 'Tem de carregar o jogo antes de o poder fechar.',
    id12: 'O jogo não podia ser parado',
    id13: 'Não foi possível encontrar esta equipa',
    id14: 'A personagem não pode ser movida porque está fora de alcance.',
    id15: 'Não recebemos uma resposta válida (a resposta é inválida)',
    id16: 'Não há mais cartões disponíveis.',
    id17: 'Não foi possível encontrar o jogo',
    id18: 'Não foi possível atualizar o estado',
    id19: 'Os colegas de equipa foram guiados com sucesso para a reflexão final.',
    id20: 'O jogo terminou, vá para um jogo existente para se juntar a um novo jogo!',
    id21: 'A sua reflexão foi concluída com sucesso',
    id22: 'Carta editada',
  },
  screens: {
    navigation: {
      signed: 'Registado como',
      home: 'Inicio',
      createGame: 'Criar jogo',
      joinGame: 'Juntar-se ao jogo',
      about: 'Através de',
      logOut: 'Terminar sessão',
      game: 'Jogo',
      settings: 'Preferências',
    },
    home: {
      hi: 'Olá',
      welcome: 'Bem-vindo à ComPLAYance num browser.',
      goTo: 'Força nisso',
      goToGameCreation: 'Criar um jogo novo',
      goToJoinGame: 'Junte-se a um jogo existente',
      yourRunningGames: 'Selecionar um jogo existente.',
      noRunningGames: 'Não foi possível encontrar nenhum jogo existente, tem as seguintes opções:',
      login: 'Inicie sessão',
      loginBtn: 'Iniciar sessão',
      buttonLogin: 'Iniciar sessão',
      gameowner: 'Líder do jogo',
      created: 'Criado',
      enterGameBtn: 'Juntar-se ao jogo',
    },
    settings: {
      settingsHeader: 'Preferências',
      avatar: 'Avatar',
      name: 'Nome',
      language: 'Idioma',
      selectLanguage: 'Selecionar idioma',
      chooseNewImage: 'Selecionar uma imagem nova',
      playerIcon: 'Ícone do jogador',
      changeName: 'Mudar o nome',
      uploadImage: 'Carregar imagem',
      email: 'E-mail',
      unknown: 'Desconhecido',
      environment: 'Ambiente',
      environmentVariables: {
        buildShortTag: 'Criar etiqueta',
        apiUrl: 'Ponto final API',
      },
    },
    gameCodes: {
      code: 'Código',
      waitingForCode: 'Aguardar a criação do código',
      startGame: 'Iniciar jogo',
    },
    joinGame: {
      title: 'Juntar-se ao jogo',
      enterPin: 'Introduzir PIN',
    },
    gameCreation: {
      team: 'EQUIPA',
      selectIconName: 'Escolha um ícone e um nome',
      createTeams: 'Criar equipa',
      addTeam: 'Adicionar uma equipa',
      removeTeam: 'Remover uma equipa',
      submit: 'OK',
      enterTeamName: 'Selecionar o nome da equipa',
      selectGameName: 'Selecione um nome de jogo',
      enterGameName: 'Introduza o nome do jogo',
      header: 'Criar jogo',
      noRole: 'Infelizmente, não tem as permissões necessárias para criar um jogo.',
      availableGames: 'Jogos disponíveis',
      summary: {
        summary: 'Resumo',
        gameName: 'Nome do jogo',
        team: 'Equipa',
        template: 'Modelo',
        description: 'Descrição',
        difficulty: 'Nível de dificuldade',
      },
      subHeaders: {
        gameName: 'Nome do jogo',
        gameTemplate: 'Modelo do jogo',
        teams: 'Equipas',
        summary: 'Resumo',
      },
      progressBar: {
        inactive: 'Visão geral',
        summary: 'Aguardar',
        active: 'Em processamento',
        done: 'Concluído',
      },
      createGameBtn: 'Criar jogo',
      creationStep: 'Passo',
      goBtn: 'Força nisso',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'O meu principal potencial',
      subTitle: 'As minhas reflexões',
      mainSubTitle: 'O meu principal potencial',
      print: 'Imprimir',
    },
    gamePage: {
      yesBtn: 'Sim',
      stillHereHeader: 'Ainda aí está?',
      stillHereBody: 'Já aqui está há muito tempo. Ainda joga?',
      gameEndedHeader: 'Jogo concluído',
      gameEndedBody: 'O jogo terminou. Vamos redirecioná-lo novamente para a página inicial',
      notPartOfGameHeader: 'Receio que não faça parte deste jogo',
      notPartOfGameBody: 'Regresse à página inicial e clique em Juntar-se ao jogo',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'dramatização',
          reflection: 'Reflexão',
          discussion: 'Discussão de grupo',
          action: 'Ação',
          challenge: 'Challenge',
          teamChallenge: 'Team Challenge',
        },
        waitForNewCard: 'Aguarde até que o líder do jogo tenha tirado uma nova carta.',
        waitForNewCardHeader: 'Nenhuma carta',
        enterReflection: 'Guarde as suas reflexões',
        submit: 'OK',
        guidance: 'Nota',
        replied: 'Resposta',
        reflectionStateTextForGM: `Encontramo-nos agora na fase final de reflexão. 
        Aguarde até que todos os jogadores tenham notado o seu principal potencial e impresso ou guardado as suas reflexões. 
        Pode então terminar o jogo clicando em "Sair do Jogo".`,
        roleplay: {
          myAssignment: 'As minhas tarefas:',
          myRole: 'A minha função:',
          noAssignment: 'Não lhe foi atribuída nenhuma função',
          noRole: 'Nenhuma função',
        },
        answerText: {
          youAnswered: 'Respondeu',
          thisIs: 'Está',
          correct: 'correto',
          incorrect: 'incorreto',
          points: 'Pontos',
          guidance: 'Nota',
        },
      },
      teamCard: {
        score: 'Pontuação geral:',
        role: 'Função:',
        gamemaster: 'Líder do jogo',
        youreAboutToKickPlayer: 'Remover jogador',
        areYouSure: 'Tem a certeza de que quer remover o colega de equipa?',
      },
      gmControls: {
        gameInfo: 'Informação sobre o jogo',
        ok: 'Ok',
        stopGame: 'Terminar jogo',
        stopGameQuestion: 'Tem a certeza de que quer terminar o jogo?',
        reflectionDeletion: ['Pediu aos jogadores para imprimir as suas reflexões?'],
        moveBack: 'Retroceder',
        moveForwards: 'Avançar',
        drawCard: 'Tirar uma carta',
        showInfo: 'Mostrar informação',
        accept: 'Concordar',
        endGameReflectionsbtn: 'Reflexões finais',
        gamename: 'Nome do jogo',
        gameId: 'ID de jogo',
        sectionId: 'ID de secção',
        sectionName: 'Nome de secção',
        availCards: 'Cartas disponíveis no garfo',
        currentCardId: 'ID da carta atual',
        teams: 'Equipas:',
        yes: 'Sim',
        notEveryOneHeader: 'Nem todos os jogadores concluíram a sua reflexão',
        notEveryOneContent: 'Tem a certeza de que quer mudar a personagem de sítio?',
        cardIsAlreadyDrawnBody: 'Já tirou uma carta. Tem a certeza de que quer tirar uma carta nova?',
        cardIsAlreadyDrawnHeader: 'Já tirou uma carta',
      },
    },
    others: { closeBtn: 'Fechar' },
    errors: {
      notLoggedIn: 'Não parece ter sessão iniciada. Clique aqui para iniciar sessão.',
      reloadPage: 'Clique aqui para recarregar a página',
      goHome: 'Se o problema persistir, aceda à página inicial e tente novamente.',
      loginBtn: 'Iniciar sessão',
      homeBtn: 'Inicio',
      reloadBtn: 'Carregar novamente',
      login: 'Iniciar sessão',
    },
  },
};
