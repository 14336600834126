import React from 'react';
import styles from './RulesDropdown.module.css';
import { ThemeContext } from '../../../App';
import { ErstContent } from './ErstContent';
import Icon from '../Icon';

//todo: consider getting it from container
interface Props {}

export const RulesDropdown = (props: Props) => {
  const theme = React.useContext(ThemeContext);

  const [dropped, setDropped] = React.useState(true);

  const content = React.useMemo(() => {
    return '';
  }, []); // todo: add the content

  return (
    <div
      style={{ backgroundColor: theme.primary, color: theme.primaryText }}
      className={styles.container}
    >
      <button className={styles.dropBtn} onClick={() => setDropped(!dropped)}>
        Point & Regler
        <Drop color={theme.buttonColors.active} dropped={dropped} />
      </button>
      {dropped && <Content content={content} />}
    </div>
  );
};

interface ContentProps {
  content: string;
}

function Content({ content }: ContentProps) {
  //return <div>{content}</div>;
  return <ErstContent />;
}

interface DropProps {
  color: string;
  dropped: boolean;
}

function Drop({ color, dropped }: DropProps) {
  return (
    <div style={dropped ? { transform: 'rotate(180deg)' } : {}}>
      <Icon size="tiny" color={color} icon="chevron-down" />
    </div>
  );
}
