import React, { useState } from 'react';
import merge from 'deepmerge';

// export type Strings = LangKeys;

// merge translations

type TranslationsPartial<DefaultTranslation> = {
  [tag: string]: DefaultTranslation | RecursivePartial<DefaultTranslation>;
};

interface Translations<DefaultTranslation>
  extends TranslationsPartial<DefaultTranslation> {
  [tag: string]: DefaultTranslation;
}

const overwriteMerge = (
  _destinationArray: any,
  sourceArray: string[],
  // eslint-disable-next-line
  _options: any
) => sourceArray;

export interface ProducerProps<LanguageTags, DefaultTranslation> {
  // children: React.ReactNode;
  translations: TranslationsPartial<DefaultTranslation>;
  defaultLanguageTag: LanguageTags;
}

let setLangState: React.Dispatch<React.SetStateAction<any>>;
let setTranslationsState: React.Dispatch<React.SetStateAction<any>>;
let currentTranslations: any;
let translationRef: any;

export const createLocalizedStringsProvider = <
  LanguageTags extends unknown,
  DefaultTranslation extends unknown
>(
  Provider: React.Provider<DefaultTranslation>,
  init: ProducerProps<LanguageTags, DefaultTranslation>
) => {
  const translationWithFallback = (
    LanguageTag: LanguageTags,
    translations: TranslationsPartial<DefaultTranslation>
  ): Translations<DefaultTranslation> => {
    // enrich translations
    const updatedTranslations: Translations<DefaultTranslation> = {};
    const defaultTranslation = translations[
      LanguageTag as string
    ] as DefaultTranslation;

    for (const tag in translations) {
      if (tag) {
        updatedTranslations[tag] = merge<DefaultTranslation>(
          defaultTranslation,
          translations[tag] as DefaultTranslation, // the defaultness comes from defaultTranslation
          { arrayMerge: overwriteMerge }
        );
      }
    }

    return updatedTranslations;
  };

  // add language listener and manuel overwrite
  return (props: { children: React.ReactNode }) => {
    const [strings, setStrings] = useState<string>(
      init.defaultLanguageTag as string
    );

    const [translations, setTranslations] = useState<
      Translations<DefaultTranslation>
    >(translationWithFallback(init.defaultLanguageTag, init.translations));

    setLangState = setStrings;
    setTranslationsState = setTranslations;
    currentTranslations = translations;
    translationRef = translations[strings];

    return <Provider value={translations[strings]}>{props.children}</Provider>;
  };
};

/**
 * Set the language tag for selecting the current translation.
 *
 * @param tag - language tag i.e. 'en', 'da', 'de' or the like
 */
export const changeLocale = () => setLangState;
export const setTranslations = () => setTranslationsState;
export const getCurrentTranslations = () => currentTranslations;
export const isValidLocale = (langTag: string): boolean => {
  const tags = Object.keys(currentTranslations);

  if (tags.includes(langTag)) {
    return true;
  }

  console.warn(
    `${langTag} isn't a supported language tag. Did you mean any of  ${tags.join(
      ' '
    )}`
  );
  return false;
};
export const getStringTranslationUnsafe = () => translationRef;
