import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import { app } from './app';
import { auth } from './auth';
import { account } from './account';
import { factory } from './factory';
import { browser } from './browser';
import { engine } from './engine';
import { api } from './api';
import { toastNotifications } from './toastNotifications';
import { langReducer } from '../../locales';

const rootReducer = combineReducers({
  browser,
  toastNotifications,
  app,
  auth,
  account,
  factory,
  api,
  engine,
  langReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

export default persistReducer(persistConfig, rootReducer);
export type RootReducerType = ReturnType<typeof rootReducer>;
