import { useContext, useMemo } from 'react';
import { ThemeContext } from '../App';

interface Props {
  hasGameMasterRole: boolean | undefined;
}

export function useAllowedGameCreation({ hasGameMasterRole }: Props) {
  const { allowAllUsersToCreateGame } = useContext(ThemeContext);

  const canCreateGames = useMemo(() => {
    if (hasGameMasterRole === true || allowAllUsersToCreateGame === true) {
      return true;
    }
    return false;
  }, [hasGameMasterRole, allowAllUsersToCreateGame]);

  return { canCreateGames };
}
