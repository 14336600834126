import {
  ACCOUNT_UPDATE_AVATAR_SUCCESS, ACCOUNT_UPDATE_NAME_SUCCESS, ACCOUNT_USER_INFO_SUCCESS,
} from '../actions';

interface Account {
  profile?: Profile;
  status: {
    isLoading: boolean;
  };
}

const initialState: Account = { status: { isLoading: false } };

export function account(state: Account = initialState, action: GenericAction): Account {
  switch (action.type) {
    case ACCOUNT_USER_INFO_SUCCESS.type: {
      const payload = ACCOUNT_USER_INFO_SUCCESS.payload(action);
      return {
        ...state,
        profile: payload,
      };
    }
    case ACCOUNT_UPDATE_NAME_SUCCESS.type: {
      const profile = ACCOUNT_UPDATE_NAME_SUCCESS.payload(action);
      return {
        ...state,
        profile,
        status: { isLoading: false },
      };
    }
    case ACCOUNT_UPDATE_AVATAR_SUCCESS.type: {
      const profile = ACCOUNT_UPDATE_AVATAR_SUCCESS.payload(action);
      return {
        ...state,
        profile,
        status: { isLoading: false },
      };
    }
    default:
      return state;
  }
}
