import { connect } from 'react-redux';
import AnswerModal from './components';
import { engine } from '../../../../redux/selectors';

const mapStateToProps = (state: AppState) => {
  return { section: engine.currentGameSection(state), me: engine.me(state) };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AnswerModal);
