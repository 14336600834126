import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';
import { ProfileSchema } from '../../../types/schemas/entities';

import {
  API_CREATE_ANON_USER_FAILURE,
  API_CREATE_ANON_USER_REQUEST,
  API_CREATE_ANON_USER_SUCCESS,
  API_PROFILE_GET_FAILURE,
  API_PROFILE_GET_REQUEST,
  API_PROFILE_GET_SUCCESS,
  API_PROFILE_POST_FAILURE,
  API_PROFILE_POST_REQUEST,
  API_PROFILE_POST_SUCCESS,
} from '../../actions';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_PROFILE_GET_REQUEST.type: {
      const retry = API_PROFILE_GET_REQUEST.meta(action);
      try {
        const response: Profile = yield request(
          '/profile',
          {},
          expandProfile(retry)
        );
        yield put(API_PROFILE_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_PROFILE_GET_FAILURE.create(error));
      }
      break;
    }

    case API_PROFILE_POST_REQUEST.type: {
      const payload = API_PROFILE_POST_REQUEST.payload(action);
      const retry = API_PROFILE_POST_REQUEST.meta(action);
      const options = {
        method: 'POST',
        body: JSON.stringify(payload),
      };

      try {
        // @ts-ignore
        const response = yield request(
          '/profile',
          options,
          expandProfile(retry)
        );

        const profile = ProfileSchema.parse(response);

        yield put(API_PROFILE_POST_SUCCESS.create(profile));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_PROFILE_POST_FAILURE.create(error));
      }

      break;
    }
    case API_CREATE_ANON_USER_REQUEST.type: {
      const payload = API_CREATE_ANON_USER_REQUEST.payload(action);
      const options = {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'same-origin', // 'include',
        // @NMI
        // TO DO
        // the credentials should be set as 'include' - but cors on the server doesn't allow this atm.
        // We will need to set the cors headers to allow all in order for token refresh to work
      };
      try {
        // @ts-ignore
        const response = yield request('/auth/register', options);
        // console.log(response);
        yield put(API_CREATE_ANON_USER_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        console.error('API_CREATE_ANON_USER_FAILURE', e);

        yield put(API_CREATE_ANON_USER_FAILURE.create(error));
      }

      break;
    }
    default:
      break;
  }
}

export function* profileRequests() {
  yield takeEvery(
    [
      API_PROFILE_GET_REQUEST.type,
      API_PROFILE_POST_REQUEST.type,
      API_CREATE_ANON_USER_REQUEST.type,
    ],
    handleRequest
  );
}
