import { createSelector } from 'reselect';
import {
  dnormGameInstanceFromGameInstanceResult,
  amIGameOwnerResult,
  arrayOfAllGameTilesResult,
  calculateLenghtOfGameResult,
  findUnansweredCardsResult,
  dNormalizeDrawnCardResult,
  meResult,
  findAllUnansweredCardsOnTileResult,
  findCurrentTileResult,
  currentGameSectionResult,
  shouldShowStillHereResult,
  myGameRoleResult,
  dnormMyReflectionsResult,
  hasEveryoneSentReflectionResult,
  amIPartOfGameResult,
  allTeamsReadyForReflectionResult,
  availableGameTokensOnSectionResult,
  allGameTokensResult,
  nextSectionResult,
  hasNextSectionResult,
  thisTurnDiceRollResult,
  currentTeamPosToNextSectionResult,
} from './resultFunctions';

// non-caching (simple) selectors
const currentGameSelector = (state: AppState): GameInstance | undefined =>
  state.engine.game;
const currentGameStateSelector = (state: AppState): GameState | undefined =>
  state.engine.game?.state;
const accountProfileSelector = (state: AppState): Profile | undefined =>
  state.account.profile;
const cardsSelector = (state: AppState): Card[] | undefined =>
  state.engine.game?.cards;
const repliesSelector = (state: AppState): Reply[] | undefined =>
  state.engine.game?.state.replies;
const playersSelector = (state: AppState): Player[] =>
  state.factory.availablePlayers;
const currentGameStatusSelector = (state: AppState): GameStatus | undefined =>
  state.engine.game?.state.status;
const currentGameIdSelector = (state: AppState): number | undefined =>
  state.engine.game?.id;
const stillHereCountSelector = (state: AppState): number =>
  state.engine.youStillHereCounter;
const gameSectionsSelector = (state: AppState): GameSection[] | undefined =>
  state.engine.game?.virtualGameboard?.sections;
const activeCardSelector = (state: AppState): Card | undefined =>
  state.engine.activeCard?.card;
const drawnCardTimeStampSelector = (
  state: AppState
): string | undefined | null => state.engine.game?.state.drawn?.drawnTimestamp;
const lastSectionChangeSelector = (
  state: AppState
): string | undefined | null => state.engine.game?.state.lastSectionChange;
const lastTurnChangeSelector = (state: AppState): string | undefined | null =>
  state.engine.game?.state.lastTurnChange;
const myReflectionsSelector = (state: AppState): MyReflection[] | undefined =>
  state.engine.myReflections;
const boardPresentationSelector = (
  state: AppState
): BoardPresentation | undefined =>
  state.engine.game?.virtualGameboard?.presentation || undefined;
const coopGameBoardSelector = (state: AppState) =>
  state.engine.game?.virtualGameboard;
const currentTeamIdSelector = (state: AppState): number | undefined | null =>
  state.engine.game?.state.currentTeam;
const combinedTeamPositionSelector = (state: AppState): number =>
  state.engine.combinedTeamPosition;
const endgameModeSelector = (state: AppState): EndgameMode | undefined | null =>
  state.engine.game?.endgameMode;
const showEndGameAwardsSelector = (state: AppState): boolean | undefined =>
  state.engine.showEndgameAwards;
const sharedDataSelector = (state: AppState): SharedData | null | undefined =>
  state.engine.game?.state.sharedData;
const sectionsSelector = (state: AppState): GameSection[] | undefined =>
  state.engine.game?.virtualGameboard?.sections;
// Cached (reselet) selectors
const meSelector = createSelector(accountProfileSelector, meResult);

export const amIPartOfGameSelector = createSelector(
  meSelector,
  currentGameSelector,
  amIPartOfGameResult
);

export const myRoleInformationSelector = createSelector(
  meSelector,
  activeCardSelector,
  currentGameStateSelector,
  myGameRoleResult
);

export const shouldShowStillHereSelector = createSelector(
  stillHereCountSelector,
  shouldShowStillHereResult
);

export const amIGameOwnerSelector = createSelector(
  currentGameSelector,
  meSelector,
  amIGameOwnerResult
);

export const gameTilesSelector = createSelector(
  currentGameSelector,
  arrayOfAllGameTilesResult
);

export const gameTileLengthSelector = createSelector(
  gameTilesSelector,
  calculateLenghtOfGameResult
);

export const unansweredCardsSelector = createSelector(
  cardsSelector,
  repliesSelector,
  findUnansweredCardsResult
);

export const currentTileSelector = createSelector(
  currentGameStateSelector,
  gameTilesSelector,
  findCurrentTileResult
);

export const currentTileUnansweredCardsSelector = createSelector(
  cardsSelector,
  currentTileSelector,
  repliesSelector,
  sharedDataSelector,
  findAllUnansweredCardsOnTileResult
);

export const currentGameSectionSelector = createSelector(
  currentGameSelector,
  currentGameSectionResult
);

export const nextSectionSelector = createSelector(
  currentGameSelector,
  currentGameSectionSelector,
  nextSectionResult
);

export const hasNextSectionSelector = createSelector(
  currentGameSelector,
  currentGameSectionSelector,
  hasNextSectionResult
);

// Dnorm selectors
export const dnormGameInstanceSelector = createSelector(
  currentGameSelector,
  meSelector,
  gameTilesSelector,
  gameSectionsSelector,
  dnormGameInstanceFromGameInstanceResult
);

export const availableTokensOnSectionSelector = createSelector(
  currentGameSectionSelector,
  dnormGameInstanceSelector,
  availableGameTokensOnSectionResult
);

export const allGameTokensSelector = createSelector(
  coopGameBoardSelector,
  allGameTokensResult
);

export const dnormDrawnCardSelector = createSelector(
  currentGameSelector,
  cardsSelector,
  playersSelector,
  meSelector,
  dNormalizeDrawnCardResult
);

export const dnormMyReflectionsSelector = createSelector(
  myReflectionsSelector,
  gameSectionsSelector,
  dnormMyReflectionsResult
);

export const everyoneSentReflectionSelector = createSelector(
  dnormGameInstanceSelector,
  hasEveryoneSentReflectionResult
);

export const allTeamsReadyForReflectionSelector = createSelector(
  currentGameStateSelector,
  gameTileLengthSelector,
  allTeamsReadyForReflectionResult
);

export const thisTurnDiceRollSelector = createSelector(
  lastTurnChangeSelector,
  thisTurnDiceRollResult
);

export const currentTeamPosToNextSectionSelector = createSelector(
  dnormGameInstanceSelector,
  currentTeamPosToNextSectionResult
);

export const engine = {
  me: meSelector,
  accountProfile: accountProfileSelector,
  currentGame: currentGameSelector,
  amIGameOwner: amIGameOwnerSelector,
  showEndGameAwards: showEndGameAwardsSelector,
  gameTiles: gameTilesSelector,
  gameTileLength: gameTileLengthSelector,
  unansweredCards: unansweredCardsSelector,
  currentTileUnansweredCards: currentTileUnansweredCardsSelector,
  currentGameTile: currentTileSelector,
  currentGameSection: currentGameSectionSelector,
  currentGameStatus: currentGameStatusSelector,
  currentGameId: currentGameIdSelector,
  shouldShowStillHere: shouldShowStillHereSelector,
  myRoleInformation: myRoleInformationSelector,
  drawnCardTimeStamp: drawnCardTimeStampSelector,
  everyoneSentReflection: everyoneSentReflectionSelector,
  amIPartOfGame: amIPartOfGameSelector,
  allTeamsReadyForReflection: allTeamsReadyForReflectionSelector,
  boardPresentation: boardPresentationSelector,
  availableTokensOnSection: availableTokensOnSectionSelector,
  coopGameBoard: coopGameBoardSelector,
  allGameTokens: allGameTokensSelector,
  nextSection: nextSectionSelector,
  hasNextSection: hasNextSectionSelector,
  endgameMode: endgameModeSelector,
  lastSectionChange: lastSectionChangeSelector,
  currentTeamId: currentTeamIdSelector,
  sharedData: sharedDataSelector,
  thisTurnDiceRoll: thisTurnDiceRollSelector,
  combinedTeamPosition: combinedTeamPositionSelector,
  currentTeamPosToNextSection: currentTeamPosToNextSectionSelector,
  sections: sectionsSelector,
  dnorm: {
    currentGame: dnormGameInstanceSelector,
    drawnCard: dnormDrawnCardSelector,
    myReflections: dnormMyReflectionsSelector,
  },
};
