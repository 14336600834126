import React from 'react';
import { Modal } from 'react-bootstrap';
import { ThemeContext } from '../../../App';
import styles from './PlaidModal.module.css';
import Button from '../Button';
import IntroImage from '../../../assets/erst-icons/jagten-paa-hemmeligheden.svg';
import { useBrowserInfo } from '../../../hooks';
import { StringsContext } from '../../../locales';
import { Awards } from './Awards';

interface Props {
  show: boolean;
  type: 'welcome' | 'award-ceremony';
  teams?: DTeam[];
  isGameOwner?: boolean;
  onDone: () => void;
}

export function PlaidModal({ show, type, teams, isGameOwner, onDone }: Props) {
  const strings = React.useContext(StringsContext);
  const { isMobile } = useBrowserInfo();
  return (
    <div className={styles.modalWrapper}>
      <Modal
        show={show}
        dialogClassName={styles.modalContainer}
        contentClassName={styles.modalContent}
      >
        <div
          className={
            styles.modalContent_inner +
            ' ' +
            (type === 'award-ceremony' && styles.fancyScroll) +
            ' ' +
            (type === 'welcome' && styles.centerWelcomeModal)
          }
        >
          {type === 'award-ceremony' && (
            <Awards teams={teams || []} isMobile={isMobile} />
          )}
          {type === 'welcome' && <Intro />}
          {/* Button */}
          <div className={styles.buttonContainer}>
            <div
              style={
                type === 'award-ceremony'
                  ? {
                      position: 'static',
                      width: '100%',
                      margin: '2rem 0',
                    }
                  : undefined
              }
            >
              {type === 'welcome' && (
                <Button
                  title={strings.screens.gamePage.plaidModal.startGame}
                  onClick={onDone}
                />
              )}
              {type === 'award-ceremony' && isGameOwner && (
                <Button
                  title={strings.screens.gamePage.plaidModal.stopGame}
                  onClick={onDone}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function Intro() {
  const theme = React.useContext(ThemeContext);

  return (
    <div style={{ color: theme.primaryText }}>
      <p>
        <b>Velkommen til</b>
      </p>
      <div className={styles.introImageContainer}>
        <IntroImage />
      </div>
      <p>
        En af dine kollegaer har fundet en skjult dør til en hemmelig kælder
        under jeres virksomhed.
        <br />
        Der hænger et kort over kælderen, som viser, at der gemmer sig et
        pengeskab dernede.
        <br />
        Nu gælder det om at du og dit hold finder vej gennem de snirklede gange
        ved at løse dilemmaer og dermed
        <br />
        komme frem til pengeskabet og åbne det, før det andet hold og før det er
        for sent.
        <br />
        <br />
        <b>God fornøjelse, god jagt!</b>
      </p>
    </div>
  );
}
