import { takeLatest, put } from 'redux-saga/effects';
import { LANGUAGE_CURRENT_TAG_UPDATE } from '../../../modules/LocalizedStrings/actions';
import { APP_LANGUAGE_OVERWRITE_UPDATE, LOCALIZE_LANGUAGE_SET } from '../../actions';

function* handleLocalizeRequest(action: GenericAction) {
  switch (action.type) {
    case LOCALIZE_LANGUAGE_SET.type: {
      const payload = LOCALIZE_LANGUAGE_SET.payload(action);
      yield put(LANGUAGE_CURRENT_TAG_UPDATE.create(payload));
      yield put(APP_LANGUAGE_OVERWRITE_UPDATE.create(payload));
      break;
    }
    default:
  }
}

export function* localizeSaga() {
  yield takeLatest(
    [
      LOCALIZE_LANGUAGE_SET.type,
    ],
    handleLocalizeRequest,
  );
}
