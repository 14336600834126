import React from 'react';
import TeamCard from './TeamCard';
import TeamCardsSimple from './TeamCardsSimple';
import styles from './TeamComponent.module.css';
import DiceButton from '../Dice/DiceButtonContainer';
import { ThemeContext } from '../../../App';
import { useGameTemplate } from '../../../hooks/useGameTemplate';
import SectionTimer from '../Timer/SectionTimer';
import { useSelector } from 'react-redux';
import { engine } from '../../../redux/selectors';
import { iconSizes } from '../../../utils';
import StartGameBtn from '../Dice/Component/StartGameBtn';

export interface TeamCardProps {
  teams: DTeam[] | undefined;
  scrollHandler: () => void;
  activeCard?: Card;
  allowExpandScore?: boolean;
  shadows?: boolean;
  onPlayerRemoval?: (pid: number, teamId: number) => void;
  gameState?: DGameState;
  boardPresentation?: BoardPresentation;
  gameSectionName?: string;
}

function teamState(teamId: number, gameState: DGameState | undefined) {
  if (!gameState) {
    return undefined;
  }
  switch (teamId) {
    case gameState.currentTeam?.id:
      return 'active';
    case gameState.nextTeam?.id:
      return 'upnext';
    default:
      return undefined;
  }
}

export function TeamCards(props: TeamCardProps) {
  if (!props.teams) {
    return null;
  }
  // 3 teams looked weird in the regular style
  const isThreeTeams = props.teams.length === 3;
  const boardPresentation = props.boardPresentation || 'standard';
  return (
    <div
      className={styles.teamCardsBox}
      style={isThreeTeams ? { flexDirection: 'column' } : undefined}
    >
      {boardPresentation === 'standard' ? (
        props.teams.map((team, index) => {
          return (
            <TeamCard
              key={team.id}
              team={team}
              defaultOpen={index === 0}
              activeCard={props.activeCard}
              scrollHandler={props.scrollHandler}
              allowExpandScore={props.allowExpandScore}
              shadows={props.shadows}
              onPlayerRemoval={props.onPlayerRemoval}
              teamState={teamState(team.id, props.gameState)}
            />
          );
        })
      ) : (
        <CardFocused
          teams={props.teams}
          sectionName={props.gameSectionName || ''}
        />
      )}
    </div>
  );
}

export default TeamCards;

interface CardFocused {
  teams: DTeam[];
  sectionName: string;
}

function CardFocused(props: CardFocused) {
  const timeLimitSection = 900;
  const [countdown, setCountdown] = React.useState(timeLimitSection);
  const lastSectionChange = useSelector(engine.lastSectionChange);
  const isGameOwner = useSelector(engine.amIGameOwner);
  const gameboardPos = useSelector(engine.combinedTeamPosition);
  const [runs, setRuns] = React.useState(0);

  const theme = React.useContext(ThemeContext);
  const { showMobile } = useGameTemplate();

  React.useEffect(() => {
    // It cant run on first rerender, otherwise it will always render at max time
    if (runs > 0) {
      setCountdown(timeLimitSection);
    } else {
      setRuns(1);
    }
  }, [lastSectionChange]);
  return (
    <div
      className={styles.card_container}
      style={showMobile ? { flexDirection: 'column' } : {}}
    >
      <div
        className={styles.button_teams}
        style={{ marginBottom: iconSizes.tokenHeight }}
      >
        {isGameOwner && (gameboardPos === 0 || gameboardPos === null) ? (
          <StartGameBtn />
        ) : (
          <DiceButton drawCardAfterRoll />
        )}
        <TeamCardsSimple teams={props.teams} />
      </div>
      <span
        className={styles.timerContainer}
        style={
          showMobile
            ? { width: '100%' }
            : { marginBottom: iconSizes.tokenHeight }
        }
      >
        <span
          style={{
            width: '90%',
            margin: 'auto',
          }}
        >
          <SectionTimer
            time={countdown}
            cardTimeLimit={lastSectionChange}
            sectionName={props.sectionName}
            onSetCountDown={setCountdown}
            modal={false}
            variant="small"
            duration={timeLimitSection}
            disabled={gameboardPos === 0}
            color={theme.buttonColors.active}
          />
        </span>
      </span>
    </div>
  );
}
