import { takeEvery, put, select, take } from 'redux-saga/effects';
import { getStringsUnsafe } from '../../../locales';
import {
  ENGINE_INSTANCE_CLEAR_REQUEST,
  ENGINE_INSTANCE_CLEAR_FAILURE,
  ENGINE_INSTANCE_CLEAR_SUCCESS,
  ENGINE_INSTANCE_LOAD_REQUEST,
  ENGINE_INSTANCE_LOAD_SUCCESS,
  ENGINE_INSTANCE_STOP_REQUEST,
  ENGINE_INSTANCE_STOP_FAILURE,
  ENGINE_INSTANCE_STOP_SUCCESS,
  API_GAME_STOP_SUCCESS,
  API_GAME_STOP_FAILURE,
  APP_ERROR_DETECTED,
  API_GAME_STOP_REQUEST,
  ENGINE_MOVE_GAMEPIECE_REQUEST,
  API_GAME_PIECE_MOVE_REQUEST,
  API_GAME_PIECE_MOVE_SUCCESS,
  API_GAME_PIECE_MOVE_FAILURE,
  ENGINE_MOVE_GAMEPIECE_FAILURE,
  ENGINE_MOVE_GAMEPIECE_SUCCESS,
  TOAST_NOTIFICATION_OPEN,
  ENGINE_QUESTION_ANSWER_REQUEST,
  ENGINE_QUESTION_ANSWER_SUCCESS,
  ENGINE_QUESTION_ANSWER_FAILURE,
  API_GAME_CARD_REPLY_POST_REQUEST,
  API_GAME_CARD_REPLY_POST_SUCCESS,
  API_GAME_CARD_REPLY_POST_FAILURE,
  ENGINE_DRAW_CARD_REQUEST,
  ENGINE_DRAW_CARD_SUCCESS,
  ENGINE_DRAW_CARD_FAILURE,
  API_GAME_CARD_DRAW_POST_REQUEST,
  API_GAME_CARD_DRAW_POST_SUCCESS,
  API_GAME_CARD_DRAW_POST_FAILURE,
  ENGINE_ACTIVE_CARD_SET,
  BROWSER_GAME_STATE_UPDATE_REQUEST,
  ENGINE_PDF_OPEN,
  ENGINE_REFLECTIONS_GET_REQUEST,
  API_REFLECTIONS_GET_REQUEST,
  API_REFLECTIONS_GET_SUCCESS,
  API_REFLECTIONS_GET_FAILURE,
  ENGINE_REFLECTIONS_GET_SUCCESS,
  ENGINE_REFLECTIONS_GET_FAILURE,
  BROWSER_GAME_LOAD_REQUEST,
  ENGINE_REFLECTIONS_ENDGAME_SEND_USERS,
  API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST,
  API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE,
  API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS,
  ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST,
  API_REFLECTIONS_SUBMIT_FINAL_REQUEST,
  API_REFLECTIONS_SUBMIT_FINAL_SUCCESS,
  API_REFLECTIONS_SUBMIT_FINAL_FAILURE,
  ENGINE_REFLECTIONS_SUBMIT_FINAL_SUCCESS,
  ENGINE_REFLECTIONS_SUBMIT_FINAL_FAILURE,
  ENGINE_REMOVE_PLAYER_REQUEST,
  API_REMOVE_PLAYER_REQUEST,
  API_REMOVE_PLAYER_SUCCESS,
  API_REMOVE_PLAYER_FAILURE,
  ENGINE_REMOVE_PLAYER_SUCCESS,
  ENGINE_REMOVE_PLAYER_FAILURE,
  API_REFLECTIONS_GET_FINAL_REQUEST,
  API_REFLECTIONS_GET_FINAL_SUCCESS,
  API_REFLECTIONS_GET_FINAL_FAILURE,
  ENGINE_CHANGE_TURN_REQUEST,
  API_CHANGE_TEAM_TURN_REQUEST,
  API_CHANGE_TEAM_TURN_SUCCESS,
  API_CHANGE_TEAM_TURN_FAILURE,
  ENGINE_CHANGE_TURN_SUCCESS,
  ENGINE_CHANGE_TURN_FAILURE,
  ENGINE_ACTIVE_CARD_CLEAR,
  ENGINE_BUY_TOKEN_REQUEST,
  ENGINE_BUY_TOKEN_FAILURE,
  API_BUY_TOKEN_SUCCESS,
  API_BUY_TOKEN_REQUEST,
  API_BUY_TOKEN_FAILURE,
  ENGINE_BUY_TOKEN_SUCCESS,
  ENGINE_MOVE_GAME_SECTION_REQUEST,
  API_MOVE_GAME_SECTION_REQUEST,
  API_MOVE_GAME_SECTION_SUCCESS,
  API_MOVE_GAME_SECTION_FAILURE,
  ENGINE_MOVE_GAME_SECTION_SUCCESS,
  ENGINE_MOVE_GAME_SECTION_FAILURE,
  ENGINE_ENDGAME_AWARDS_STATE_UPDATE,
  ENGINE_VICTORY_POINTS_REQUEST,
  API_SHARED_DATA_REQUEST,
  API_SHARED_DATA_FAILURE,
  API_SHARED_DATA_SUCCESS,
  ENGINE_VICTORY_POINTS_SUCCESS,
  ENGINE_VICTORY_POINTS_FAILURE,
  ENGINE_CREATE_CHALLENGE_REQUEST,
  ENGINE_FINISH_CHALLENGE_REQUEST,
  API_GAME_ADD_POINTS_REQUEST,
  API_GAME_ADD_POINTS_SUCCESS,
  API_GAME_ADD_POINTS_FAILURE,
  ENGINE_CREATE_CHALLENGE_FAILURE,
  ENGINE_CREATE_CHALLENGE_SUCCESS,
  API_GAME_GIVE_TOKEN_REQUEST,
  API_GAME_GIVE_TOKEN_FAILURE,
  API_GAME_GIVE_TOKEN_SUCCESS,
  ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST,
  ENGINE_MOVE_GAMEPIECE_TOEND_FAILURE,
  ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS,
} from '../../actions';
import { account, engine } from '../../selectors';

function* engineRequestHandler(action: GenericAction) {
  switch (action.type) {
    case ENGINE_INSTANCE_CLEAR_REQUEST.type: {
      try {
        // At some point we may have to do some clean ups etc.
        const payload = ENGINE_INSTANCE_CLEAR_REQUEST.payload(action);

        yield put(ENGINE_INSTANCE_CLEAR_SUCCESS.create(payload));
      } catch (error: any) {
        yield put(
          ENGINE_INSTANCE_CLEAR_FAILURE.create({
            name: 'engine.instance.clear.failure',
            message: error.message,
          })
        );
      }
      break;
    }
    case ENGINE_INSTANCE_LOAD_REQUEST.type: {
      const payload = ENGINE_INSTANCE_LOAD_REQUEST.payload(action);

      const me: Player = yield select(account.me);
      // we keep it here for the moment to allow hidden player swap in game.
      // move to engine selector later.

      yield put(
        ENGINE_INSTANCE_LOAD_SUCCESS.create({
          game: { ...payload },
          me,
          isSendingReply: false,
          showResult: false,
          isLoading: false,
          youStillHereCounter: 0,
          timerCountdown: 0,
          combinedTeamPosition: 0,
        })
      );
      break;
    }
    case ENGINE_INSTANCE_STOP_REQUEST.type: {
      const currentGame: GameInstance = yield select(engine.currentGame);
      const showingAwards: boolean = yield select(engine.showEndGameAwards);
      // When gamemode is awards, we dont want to stop the game before awards has been shown.
      if (
        currentGame.endgameMode === 'endgame-award-ceremony' &&
        !showingAwards
      ) {
        yield put(ENGINE_REFLECTIONS_ENDGAME_SEND_USERS.create());
        yield put(ENGINE_ENDGAME_AWARDS_STATE_UPDATE.create(true));
        break;
      }

      const { sagaTranslate } = getStringsUnsafe();
      const payload = ENGINE_INSTANCE_STOP_REQUEST.payload(action);
      try {
        if (payload.gameId !== currentGame.id) {
          throw new Error(sagaTranslate.id11);
        }
        const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });

        yield put(
          API_GAME_STOP_REQUEST.create(
            { instanceId: currentGame.id },
            { profileLabel: 'defaultPost', onTimeoutAction }
          )
        );

        // @ts-ignore
        const newGameStateAction = yield take([
          API_GAME_STOP_SUCCESS.type,
          API_GAME_STOP_FAILURE.type,
        ]);

        if (newGameStateAction.type === API_GAME_STOP_SUCCESS.type) {
          yield put(ENGINE_INSTANCE_STOP_SUCCESS.create(null));
          // navigate home
          window.location.replace('/');
        } else {
          // the request to the backend failed
          throw Error(sagaTranslate.id12);
        }
      } catch (error: any) {
        yield put(ENGINE_INSTANCE_STOP_FAILURE.create(error));
      }

      break;
    }
    case ENGINE_MOVE_GAMEPIECE_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();
      const payload = ENGINE_MOVE_GAMEPIECE_REQUEST.payload(action);
      const game: GameInstance = yield select(engine.currentGame);
      // @ts-ignore
      const gameLength = yield select(engine.gameTileLength);
      try {
        // calculate the team.
        const team: Team | undefined = game.state.teams.find(
          (filterTeam) => filterTeam.id === payload.teamId
        );
        if (!team) {
          throw new Error(sagaTranslate.id13);
        }
        // calculate new position
        let oldPosition = team.gameboardPosition;
        if (oldPosition === null) {
          oldPosition = 0;
        }
        const newPosition = oldPosition + payload.amount;
        // calculate out of bounds
        if (newPosition > gameLength || newPosition < 0) {
          yield put(
            TOAST_NOTIFICATION_OPEN.create({
              content: sagaTranslate.id14,
              appearance: 'info',
              autoDismiss: true,
            })
          );
          break;
        }
        // call backend
        yield put(
          API_GAME_PIECE_MOVE_REQUEST.create({ newPosition, teamId: team.id })
        );
        // @ts-ignore
        const response = yield take([
          API_GAME_PIECE_MOVE_SUCCESS.type,
          API_GAME_PIECE_MOVE_FAILURE.type,
        ]);

        if (response.type === API_GAME_PIECE_MOVE_SUCCESS.type) {
          const teamNewState = API_GAME_PIECE_MOVE_SUCCESS.payload(response);
          const currentTeamIndex = game.state.teams.findIndex(
            (indexTeam) => indexTeam.id === team.id
          );
          const updatedArray = [...game.state.teams];
          updatedArray[currentTeamIndex] = teamNewState;
          // call for update state.
          yield put(ENGINE_MOVE_GAMEPIECE_SUCCESS.create(updatedArray));
        } else {
          const responsePayload = API_GAME_PIECE_MOVE_FAILURE.payload(response);
          throw new Error(responsePayload.message);
        }
      } catch (error: any) {
        yield put(ENGINE_MOVE_GAMEPIECE_FAILURE.create(error));
      }

      break;
    }

    case ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST.type: {
      const payload = ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST.payload(action);
      yield put(ENGINE_MOVE_GAMEPIECE_REQUEST.create(payload));

      const respo: GenericAction = yield take([
        ENGINE_MOVE_GAMEPIECE_SUCCESS.type,
        ENGINE_MOVE_GAMEPIECE_FAILURE.type,
      ]);

      try {
        if (ENGINE_MOVE_GAMEPIECE_SUCCESS.type === respo.type) {
          const currentGame: GameInstance | undefined = yield select(
            engine.currentGame
          );
          const sharedData: ShareData | undefined = yield select(
            engine.sharedData
          );
          if (!currentGame) {
            throw new Error('No CurrentGame');
          }
          const startDate = new Date();

          if (!currentGame.virtualGameboard?.cards) {
            throw new Error('No virtualgameboard, or cards on there');
          }

          const cards = currentGame.virtualGameboard?.cards || [];
          const selectedCardId: number =
            cards[Math.floor(Math.random() * cards.length)];

          yield put(
            API_SHARED_DATA_REQUEST.create({
              gameId: currentGame.id,
              sharedData: {
                ...sharedData,
                challengeMode: {
                  running: true,
                  cardId: selectedCardId,
                  startTimeStamp: startDate.toString(),
                },
              },
            })
          );

          const dataResponse: GenericAction = yield take([
            API_SHARED_DATA_SUCCESS.type,
            API_SHARED_DATA_FAILURE.type,
          ]);

          if (dataResponse.type === API_SHARED_DATA_FAILURE.type) {
            const error = API_SHARED_DATA_FAILURE.payload(dataResponse);
            throw new Error(
              'Api add points error: ' + error.message + ' - ' + error.data
            );
          }

          const successPayload: GameState =
            API_SHARED_DATA_SUCCESS.payload(dataResponse);
          yield put(ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS.create(successPayload));
        } else {
          const payload = ENGINE_MOVE_GAMEPIECE_FAILURE.payload(respo);
          throw new Error(payload.message);
        }
      } catch (e) {
        const error = String(e);
        yield put(
          ENGINE_MOVE_GAMEPIECE_TOEND_FAILURE.create({
            message: 'Something went wrong',
            name: error,
          })
        );
      }

      break;
    }

    case ENGINE_QUESTION_ANSWER_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();

      try {
        const payload = ENGINE_QUESTION_ANSWER_REQUEST.payload(action);
        const game: DGameInstance = yield select(engine.currentGame);
        const instanceId: number = game.id;
        const section: GameSection = yield select(engine.currentGameSection);
        const drawnCard: DDrawn | undefined = yield select(
          engine.dnorm.drawnCard
        );
        const card: DCardInstance | undefined = drawnCard?.card;
        if (!card) {
          throw Error('The chosen card doesnt exists');
        }
        if (card.reply) {
          throw Error(`Card was already answered: ${payload.cardId}`);
        }
        if (!section.id) {
          throw Error('Section not found.');
        }

        const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });
        payload.sectionId = section.id;
        yield put(
          API_GAME_CARD_REPLY_POST_REQUEST.create(
            { reply: payload, instanceId },
            { profileLabel: 'defaultPost', onTimeoutAction }
          )
        );

        // @ts-ignore
        const response = yield take([
          API_GAME_CARD_REPLY_POST_SUCCESS.type,
          API_GAME_CARD_REPLY_POST_FAILURE.type,
        ]);

        if (response.type === API_GAME_CARD_REPLY_POST_SUCCESS.type) {
          const gameState = API_GAME_CARD_REPLY_POST_SUCCESS.payload(response);
          yield put(
            TOAST_NOTIFICATION_OPEN.create({
              content: sagaTranslate.id22,
              appearance: 'info',
              autoDismiss: true,
            })
          );
          const updatedReply = gameState?.replies.reverse().find((reply) => {
            return (
              (reply.answerId === payload.answerId ||
                reply.answerId === null) &&
              reply.cardId === payload.cardId &&
              reply.playerId === payload.playerId
            );
          });

          if (updatedReply) {
            card.reply = updatedReply;
          } else {
            throw new Error(sagaTranslate.id15);
          }

          yield put(
            ENGINE_QUESTION_ANSWER_SUCCESS.create({
              gameState,
              card,
            })
          );
          yield put(BROWSER_GAME_STATE_UPDATE_REQUEST.create({ instanceId }));
        } else {
          const error = API_GAME_CARD_REPLY_POST_FAILURE.payload(response);
          throw new Error(error.data.error);
        }
      } catch (error: any) {
        yield put(ENGINE_QUESTION_ANSWER_FAILURE.create(error));
      }
      break;
    }
    case ENGINE_QUESTION_ANSWER_FAILURE.type: {
      const payload = ENGINE_QUESTION_ANSWER_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case ENGINE_DRAW_CARD_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();

      try {
        // calculate which card.
        const game: GameInstance = yield select(engine.currentGame);
        const availableCards: Card[] = yield select(
          engine.currentTileUnansweredCards
        );
        if (availableCards.length === 0) {
          throw new Error(sagaTranslate.id16);
        }
        const randomCard =
          availableCards[Math.floor(Math.random() * availableCards.length)];
        // make api call.
        yield put(
          API_GAME_CARD_DRAW_POST_REQUEST.create({
            gameInstanceId: game.id,
            cardId: randomCard.id,
          })
        );
        // on card success, change reducer state, so it shows the new card.
        // @ts-ignore
        const response = yield take([
          API_GAME_CARD_DRAW_POST_SUCCESS.type,
          API_GAME_CARD_DRAW_POST_FAILURE.type,
        ]);
        if (response.type === API_GAME_CARD_DRAW_POST_SUCCESS.type) {
          const responsePayload =
            API_GAME_CARD_DRAW_POST_SUCCESS.payload(response);

          yield put(ENGINE_DRAW_CARD_SUCCESS.create(responsePayload));
          const drawnCard: DDrawn = yield select(engine.dnorm.drawnCard);
          if (!drawnCard) {
            throw new Error("Drawn card doesn't exists");
          }
          yield put(ENGINE_ACTIVE_CARD_SET.create(drawnCard.card));
        } else {
          const responsePayload =
            API_GAME_CARD_DRAW_POST_FAILURE.payload(response);
          throw new Error(responsePayload.message);
        }
      } catch (error: any) {
        yield put(ENGINE_DRAW_CARD_FAILURE.create(error));
      }
      break;
    }
    case ENGINE_DRAW_CARD_FAILURE.type: {
      const payload = ENGINE_DRAW_CARD_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case ENGINE_MOVE_GAMEPIECE_FAILURE.type: {
      const payload = ENGINE_MOVE_GAMEPIECE_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case ENGINE_INSTANCE_STOP_FAILURE.type: {
      const payload = ENGINE_INSTANCE_STOP_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case ENGINE_PDF_OPEN.type: {
      // @ts-ignore
      const pdfLink = yield select(
        (state: AppState) => state.engine.game?.instructionsLink
      );
      if (pdfLink) {
        window.open(pdfLink);
      }
      break;
    }

    case ENGINE_REFLECTIONS_GET_REQUEST.type: {
      const payload = ENGINE_REFLECTIONS_GET_REQUEST.payload(action);

      // get gameinstance
      yield put(
        BROWSER_GAME_LOAD_REQUEST.create({ instanceId: payload.gameId })
      );

      const allReflections: {
        myReflections: MyReflection[] | undefined;
        endGameReflection: MyEndGameReflection | undefined;
      } = {
        myReflections: undefined,
        endGameReflection: undefined,
      };

      // get reflections
      yield put(API_REFLECTIONS_GET_REQUEST.create(payload));
      // @ts-ignore
      const response = yield take([
        API_REFLECTIONS_GET_SUCCESS.type,
        API_REFLECTIONS_GET_FAILURE.type,
      ]);
      if (response.type === API_REFLECTIONS_GET_SUCCESS.type) {
        const responsePayload = API_REFLECTIONS_GET_SUCCESS.payload(response);
        allReflections.myReflections = responsePayload;
      } else {
        const responsePayload = API_REFLECTIONS_GET_FAILURE.payload(response);
        yield put(ENGINE_REFLECTIONS_GET_FAILURE.create(responsePayload));
      }

      // get final reflection
      yield put(
        API_REFLECTIONS_GET_FINAL_REQUEST.create({ gameId: payload.gameId })
      );
      // @ts-ignore
      const responseGet = yield take([
        API_REFLECTIONS_GET_FINAL_SUCCESS.type,
        API_REFLECTIONS_GET_FINAL_FAILURE.type,
      ]);
      if (responseGet.type === API_REFLECTIONS_GET_FINAL_SUCCESS.type) {
        const responseGetPayload =
          API_REFLECTIONS_GET_FINAL_SUCCESS.payload(responseGet);
        allReflections.endGameReflection = responseGetPayload;
      } else {
        const responseGetPayload =
          API_REFLECTIONS_GET_FINAL_FAILURE.payload(responseGet);
        yield put(ENGINE_REFLECTIONS_GET_FAILURE.create(responseGetPayload));
      }
      yield put(ENGINE_REFLECTIONS_GET_SUCCESS.create(allReflections));
      break;
    }

    case ENGINE_REFLECTIONS_ENDGAME_SEND_USERS.type: {
      const { sagaTranslate } = getStringsUnsafe();

      const gameId: number = yield select(engine.currentGameId);
      yield put(API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST.create({ gameId }));
      // @ts-ignore
      const response = yield take([
        API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS.type,
        API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE.type,
      ]);
      if (response.type === API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS.type) {
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: sagaTranslate.id19,
            appearance: 'info',
            autoDismiss: true,
          })
        );
      } else {
        const responsePayload =
          API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE.payload(response);
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: responsePayload.message,
            appearance: 'error',
            autoDismiss: true,
          })
        );
      }
      break;
    }

    case ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();

      const payload = ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST.payload(action);
      const gameId: number = yield select(engine.currentGameId);
      yield put(
        API_REFLECTIONS_SUBMIT_FINAL_REQUEST.create({
          gameId,
          text: payload.text,
        })
      );
      // @ts-ignore
      const response = yield take([
        API_REFLECTIONS_SUBMIT_FINAL_SUCCESS.type,
        API_REFLECTIONS_SUBMIT_FINAL_FAILURE.type,
      ]);

      if (response.type === API_REFLECTIONS_SUBMIT_FINAL_SUCCESS.type) {
        yield put(ENGINE_REFLECTIONS_SUBMIT_FINAL_SUCCESS.create());
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: sagaTranslate.id21,
            appearance: 'info',
            autoDismiss: true,
          })
        );
      } else {
        const responsePayload =
          API_REFLECTIONS_SUBMIT_FINAL_FAILURE.payload(response);
        yield put(
          ENGINE_REFLECTIONS_SUBMIT_FINAL_FAILURE.create(responsePayload)
        );
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: responsePayload.message,
            appearance: 'error',
            autoDismiss: true,
          })
        );
      }
      break;
    }

    case ENGINE_REMOVE_PLAYER_REQUEST.type: {
      const payload = ENGINE_REMOVE_PLAYER_REQUEST.payload(action);
      yield put(API_REMOVE_PLAYER_REQUEST.create(payload));
      // @ts-ignore
      const response = yield take([
        API_REMOVE_PLAYER_SUCCESS.type,
        API_REMOVE_PLAYER_FAILURE.type,
      ]);
      if (response.type) {
        const respPayload = API_REMOVE_PLAYER_SUCCESS.payload(response);
        // find what team it is and change that
        const activeGame: GameInstance = yield select(engine.currentGame);
        const newTeamArray: Team[] = [];
        activeGame.state.teams?.forEach((teamEl) => {
          if (teamEl.id === respPayload.id) {
            newTeamArray.push({ ...teamEl, players: respPayload.players });
          } else {
            newTeamArray.push(teamEl);
          }
        });
        yield put(ENGINE_REMOVE_PLAYER_SUCCESS.create(newTeamArray));
      } else {
        const respPayload = API_REMOVE_PLAYER_FAILURE.payload(response);
        yield put(ENGINE_REMOVE_PLAYER_FAILURE.create(respPayload));
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: respPayload.message,
            appearance: 'error',
            autoDismiss: true,
          })
        );
      }
      break;
    }
    case ENGINE_CHANGE_TURN_REQUEST.type: {
      const gameInstance: DGameInstance | undefined = yield select(
        engine.dnorm.currentGame
      );
      const nextTeamId: number | undefined = gameInstance?.state.nextTeam?.id;

      if (gameInstance && nextTeamId) {
        const gameInstanceId: number = gameInstance.id;

        yield put(
          API_CHANGE_TEAM_TURN_REQUEST.create({
            gameInstanceId,
            teamId: nextTeamId,
          })
        );

        const response: GenericAction = yield take([
          API_CHANGE_TEAM_TURN_SUCCESS.type,
          API_CHANGE_TEAM_TURN_FAILURE.type,
        ]);

        if (response.type === API_CHANGE_TEAM_TURN_SUCCESS.type) {
          const respPayload = API_CHANGE_TEAM_TURN_SUCCESS.payload(response);
          // clear activecard
          yield put(ENGINE_ACTIVE_CARD_CLEAR.create());
          yield put(ENGINE_CHANGE_TURN_SUCCESS.create(respPayload));
        } else {
          const respPayload = API_CHANGE_TEAM_TURN_FAILURE.payload(response);
          yield put(ENGINE_CHANGE_TURN_FAILURE.create(respPayload));
        }
      } else {
        yield put(
          ENGINE_CHANGE_TURN_FAILURE.create({
            name: 'ENGINE_CHANGE_TURN_FAILURE',
            message: 'No gameInstance && nextTeamId found',
          })
        );
      }

      break;
    }
    case ENGINE_BUY_TOKEN_REQUEST.type: {
      // Find current team
      const gi: DGameInstance | undefined = yield select(
        engine.dnorm.currentGame
      );
      if (!gi) {
        break;
      }
      const cTeam = gi.state.currentTeam;

      if (!cTeam) {
        break;
      }

      // Find the token
      const tokens: GameToken[] = yield select(engine.availableTokensOnSection);

      if (tokens.length === 0 || !tokens) {
        yield put(
          ENGINE_BUY_TOKEN_FAILURE.create({
            name: 'No available tokens',
            message: `It seems that there are no avaiable tokens`,
          })
        );
        break;
      }

      // for now its only the first one
      const token = tokens[0];

      // Check if team can afford
      if (cTeam.score < token.cost) {
        yield put(
          ENGINE_BUY_TOKEN_FAILURE.create({
            name: 'Not enough score',
            message: `Team score: ${cTeam.score} - Token price: ${token.cost}`,
          })
        );
        break;
      }

      // Try to buy the token
      yield put(
        API_BUY_TOKEN_REQUEST.create({
          teamId: cTeam.id,
          tokenId: token.id,
          giId: gi.id,
        })
      );

      const response: GenericAction = yield take([
        API_BUY_TOKEN_SUCCESS.type,
        API_BUY_TOKEN_FAILURE.type,
      ]);

      if (response.type === API_BUY_TOKEN_SUCCESS.type) {
        const payload = API_BUY_TOKEN_SUCCESS.payload(response);
        yield put(ENGINE_BUY_TOKEN_SUCCESS.create(payload));
      } else {
        const error = API_BUY_TOKEN_FAILURE.payload(response);
        yield put(ENGINE_BUY_TOKEN_FAILURE.create(error));
      }

      break;
    }

    case ENGINE_MOVE_GAME_SECTION_REQUEST.type: {
      const gameId: number | undefined = yield select(engine.currentGameId);
      const nextSection: GameSection | undefined = yield select(
        engine.nextSection
      );

      if (!gameId || !nextSection) {
        yield put(
          ENGINE_MOVE_GAME_SECTION_FAILURE.create({
            message: 'no gameid or nextsection',
            name: 'ENGINE_MOVE_GAME_SECTION_REQUEST',
          })
        );
        break;
      }

      yield put(
        API_MOVE_GAME_SECTION_REQUEST.create({
          giId: gameId,
          sectionId: nextSection.id,
        })
      );

      const response: GenericAction = yield take([
        API_MOVE_GAME_SECTION_SUCCESS.type,
        API_MOVE_GAME_SECTION_FAILURE.type,
      ]);

      if (response.type === API_MOVE_GAME_SECTION_SUCCESS.type) {
        const payload: GameState =
          API_MOVE_GAME_SECTION_SUCCESS.payload(response);
        yield put(ENGINE_MOVE_GAME_SECTION_SUCCESS.create(payload));
      } else {
        const error = API_MOVE_GAME_SECTION_FAILURE.payload(response);
        yield put(ENGINE_MOVE_GAME_SECTION_FAILURE.create(error));
      }

      break;
    }

    case ENGINE_VICTORY_POINTS_REQUEST.type: {
      const payload = ENGINE_VICTORY_POINTS_REQUEST.payload(action);

      const gameId: undefined | number = yield select(engine.currentGameId);
      const teamId: undefined | number = payload.teamId;

      if (!gameId || !teamId) {
        yield put(
          ENGINE_VICTORY_POINTS_FAILURE.create({
            message: 'if (!gameId || !teamId)',
            name: 'src/redux/sagas/engine/index.ts -> ENGINE_VICTORY_POINTS_REQUEST',
          })
        );
        break;
      }

      const sharedData: SharedData | undefined | null = yield select(
        engine.sharedData
      );

      // API validation
      let newVCPoints: VictoryPoints[] = [];
      if (sharedData?.victoryPoints) {
        if (sharedData.victoryPoints.find((vp) => vp.teamId === teamId)) {
          sharedData.victoryPoints.forEach((vc) => {
            if (vc.teamId === teamId) {
              newVCPoints.push({ teamId: teamId, points: payload.val });
            } else {
              newVCPoints.push(vc);
            }
          });
        } else {
          newVCPoints = [
            ...sharedData.victoryPoints,
            { teamId: teamId, points: payload.val },
          ];
        }
      } else {
        newVCPoints.push({ teamId: teamId, points: payload.val });
      }

      const data: SharedData = { ...sharedData, victoryPoints: newVCPoints };

      yield put(
        API_SHARED_DATA_REQUEST.create({
          gameId,
          sharedData: data,
        })
      );

      const response: GenericAction = yield take([
        API_SHARED_DATA_FAILURE.type,
        API_SHARED_DATA_SUCCESS.type,
      ]);

      if (response.type === API_SHARED_DATA_SUCCESS.type) {
        const respPayload = API_SHARED_DATA_SUCCESS.payload(response);
        yield put(ENGINE_VICTORY_POINTS_SUCCESS.create(respPayload));
      } else {
        const error = API_SHARED_DATA_FAILURE.payload(response);
        yield put(ENGINE_VICTORY_POINTS_FAILURE.create(error));
      }

      break;
    }

    case ENGINE_CREATE_CHALLENGE_REQUEST.type: {
      try {
        const currentGame: DGameInstance | undefined = yield select(
          engine.dnorm.currentGame
        );

        if (!currentGame?.cards) {
          throw new Error('No cards available');
        }

        const currentTeam: DTeam | undefined = currentGame?.state.currentTeam;

        if (!currentTeam) {
          throw new Error('No currentTeam');
        }

        const currentSection: GameSection | undefined = yield select(
          engine.currentGameSection
        );

        const teamsCurrentSection = currentTeam.teamSectionPoints?.find(
          (e) => e.section?.id === currentSection?.id
        );

        if (!teamsCurrentSection) {
          throw new Error('No teamsCurrentSection');
        }
        // remove points
        /*if (teamsCurrentSection.point < 2) {
          throw new Error('Team doesnt have enough points');
        }*/

        yield put(
          API_GAME_ADD_POINTS_REQUEST.create({
            points: -2,
            teamId: currentTeam.id,
            sectionId: teamsCurrentSection.section?.id,
          })
        );

        const response: GenericAction = yield take([
          API_GAME_ADD_POINTS_SUCCESS.type,
          API_GAME_ADD_POINTS_FAILURE.type,
        ]);

        if (response.type === API_GAME_ADD_POINTS_FAILURE.type) {
          const error = API_GAME_ADD_POINTS_FAILURE.payload(response);
          throw new Error(
            'Api add points error: ' + error.message + ' - ' + error.data
          );
        }

        // pick card
        const cards: Card[] | undefined = yield select(
          engine.currentTileUnansweredCards
        );
        let selectedCardId = 0;
        if (!cards || cards.length === 0) {
          // pick a random, if no cards are available.
          //@ts-ignore For some reason it thinks its dnorm tiles.
          const tile: any | undefined = yield select(engine.currentGameTile);
          const cards: number[] = tile?.cards || [];
          selectedCardId = cards[Math.floor(Math.random() * cards.length)];
        } else {
          const selectedCard: Card | undefined =
            cards[Math.floor(Math.random() * cards.length)];
          selectedCardId = selectedCard.id;
        }

        const sharedData: SharedData | undefined = yield select(
          engine.sharedData
        );

        const startDate = new Date();
        // start challenge
        const usedDataCardIds = sharedData?.usedDataCardIds
          ? [...sharedData.usedDataCardIds, selectedCardId]
          : [selectedCardId];
        yield put(
          API_SHARED_DATA_REQUEST.create({
            gameId: currentGame.id,
            sharedData: {
              ...sharedData,
              usedDataCardIds,
              challengeMode: {
                running: true,
                cardId: selectedCardId,
                startTimeStamp: startDate.toString(),
              },
            },
          })
        );

        const dataResponse: GenericAction = yield take([
          API_SHARED_DATA_SUCCESS.type,
          API_SHARED_DATA_FAILURE.type,
        ]);

        if (dataResponse.type === API_SHARED_DATA_FAILURE.type) {
          const error = API_SHARED_DATA_FAILURE.payload(dataResponse);
          throw new Error(
            'Api add points error: ' + error.message + ' - ' + error.data
          );
        }

        const successPayload: GameState =
          API_SHARED_DATA_SUCCESS.payload(dataResponse);

        yield put(ENGINE_CREATE_CHALLENGE_SUCCESS.create(successPayload));
      } catch (e) {
        const error = String(e);
        yield put(
          ENGINE_CREATE_CHALLENGE_FAILURE.create({
            name: 'Something went wrong in Engine challenge',
            message: error,
          })
        );
      }
      break;
    }

    case ENGINE_FINISH_CHALLENGE_REQUEST.type: {
      const currentGame: GameInstance | undefined = yield select(
        engine.currentGame
      );
      if (currentGame?.state) {
        const sharedData: SharedData | undefined = yield select(
          engine.sharedData
        );

        const payload = ENGINE_FINISH_CHALLENGE_REQUEST.payload(action);

        if (payload.won) {
          const currentSection: GameSection | undefined = yield select(
            engine.currentGameSection
          );
          const currentTeam = currentGame.state.currentTeam;
          if (
            currentTeam &&
            currentSection?.gameTokens &&
            currentSection.gameTokens.length > 0
          ) {
            yield put(
              API_GAME_GIVE_TOKEN_REQUEST.create({
                giId: currentGame.id,
                tokenId: currentSection.gameTokens[0].id,
                receivingTeamId: currentTeam,
              })
            );
            const resp: GenericAction = yield take([
              API_GAME_GIVE_TOKEN_FAILURE.type,
              API_GAME_GIVE_TOKEN_SUCCESS.type,
            ]);

            if (resp.type === API_GAME_GIVE_TOKEN_SUCCESS.type) {
              yield put(
                API_SHARED_DATA_REQUEST.create({
                  gameId: currentGame.id,
                  sharedData: {
                    ...sharedData,
                    challengeMode: { running: false, cardId: undefined },
                  },
                })
              );
            }
          } else {
            console.warn(
              'No current team, or any gametokens on current section'
            );
          }
        } else {
          yield put(
            API_SHARED_DATA_REQUEST.create({
              gameId: currentGame.id,
              sharedData: {
                ...sharedData,
                challengeMode: { running: false, cardId: undefined },
              },
            })
          );
        }
      } else {
        console.warn('no game state');
      }

      break;
    }

    default:
  }
}

export function* engineSaga() {
  yield takeEvery(
    [
      ENGINE_INSTANCE_CLEAR_REQUEST.type,
      ENGINE_INSTANCE_LOAD_REQUEST.type,
      ENGINE_INSTANCE_STOP_REQUEST.type,
      ENGINE_MOVE_GAMEPIECE_REQUEST.type,
      ENGINE_QUESTION_ANSWER_REQUEST.type,
      ENGINE_QUESTION_ANSWER_FAILURE.type,
      ENGINE_DRAW_CARD_REQUEST.type,
      ENGINE_DRAW_CARD_FAILURE.type,
      ENGINE_MOVE_GAMEPIECE_FAILURE.type,
      ENGINE_INSTANCE_STOP_FAILURE.type,
      ENGINE_PDF_OPEN.type,
      ENGINE_REFLECTIONS_GET_REQUEST.type,
      ENGINE_REFLECTIONS_ENDGAME_SEND_USERS.type,
      ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST.type,
      ENGINE_REMOVE_PLAYER_REQUEST.type,
      ENGINE_CHANGE_TURN_REQUEST.type,
      ENGINE_BUY_TOKEN_REQUEST.type,
      API_BUY_TOKEN_SUCCESS.type,
      API_BUY_TOKEN_FAILURE.type,
      API_MOVE_GAME_SECTION_SUCCESS.type,
      API_MOVE_GAME_SECTION_FAILURE.type,
      ENGINE_MOVE_GAME_SECTION_REQUEST.type,
      ENGINE_VICTORY_POINTS_REQUEST.type,
      ENGINE_CREATE_CHALLENGE_REQUEST.type,
      ENGINE_FINISH_CHALLENGE_REQUEST.type,
      ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST.type,
    ],
    engineRequestHandler
  );
}
