import React, { useCallback, useContext } from 'react';
import { ThemeContext } from '../../App';
import { mainColors } from '../../utils/variables';

interface PlayerAvatarProps {
  player?: Player;
  isSelected?: boolean;
  hasBorder?: boolean; // has default value
  size?: number; // has default value
  text?: string | null;
}

const PlayerAvatar = (props: PlayerAvatarProps) => {
  const baseHasBorder = props.hasBorder || true;
  const baseSize = props.size || 50;

  const theme = useContext(ThemeContext);

  const checkAvatarUrl = useCallback((): boolean => {
    const avatar = props.player?.avatar;
    if (avatar) {
      if (avatar.includes('http')) {
        return true;
      }
    }
    return false;
  }, [props.player?.avatar]);

  const getInitials = useCallback((): string => {
    const { text } = props;

    if (!text) {
      return '';
    }

    const parts = text.split(' ');

    if (parts.length === 1) {
      return parts[0][0];
    }

    return parts[0][0] + parts[1][0];
  }, [props.text]);

  const getBorderWidth = (): number => {
    const { isSelected } = props;

    let borderWidth: number = 0;

    if (!baseHasBorder) {
      return borderWidth;
    }
    const divisor = isSelected ? 15 : 25;

    borderWidth = Math.round(baseSize / divisor);

    if (borderWidth < 0) {
      borderWidth = 0;
    }

    return borderWidth;
  };

  const borderColor = useCallback((): string => {
    const { isSelected } = props;

    if (isSelected) {
      return mainColors.black;
    }
    return mainColors.white;
  }, [props.isSelected]);

  const getAvatarComponent = () => {
    const { size, player } = props;
    if (checkAvatarUrl() && player?.avatar) {
      return (
        <div>
          <img
            alt="playerAvatar"
            src={player.avatar}
            style={{
              borderRadius: size,
              borderColor: mainColors.red,
              border: `${getBorderWidth()}px solid ${borderColor()}`,
            }}
            height={size}
            width={size}
          />
        </div>
      );
    }
    return (
      <div
        style={{
          borderRadius: size,
          backgroundColor: theme.primary,
          borderColor: mainColors.white,
          borderWidth: getBorderWidth(),
          width: size,
          height: size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            color: mainColors.white,
            fontSize: baseSize / 2,
            lineHeight: `${baseSize / 2}px`,
            fontWeight: 'bold',
            margin: '0',
          }}
        >
          {getInitials()}
        </p>
      </div>
    );
  };

  return getAvatarComponent();
};

export default PlayerAvatar;
