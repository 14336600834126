import React from 'react';
import { useBrowserInfo } from '../../../hooks';
import { mainColors } from '../../../utils';
import { ThemeContext } from '../../../App';
import { StringsContext } from '../../../locales';
import LinkBox from '../../../pages/main/browser/AnswerModal/components/LinkBox';
import ExpandButton from '../ExpandButton';
import styles from './ActiveCard.module.css';

interface Props {
  cardTypeText: string;
  content: JSX.Element;
  section?: GameSection;
  expandButton?: boolean;
  boardPresentation?: BoardPresentation;
  hasActiveCard: boolean;
  onClickExpand: () => void;
  activeCardLink?: QuestionLink | null;
}

const CardTemplate = ({
  content,
  cardTypeText,
  section,
  expandButton,
  activeCardLink,
  boardPresentation = 'standard',
  hasActiveCard,
  onClickExpand,
}: Props) => {
  const theme = React.useContext(ThemeContext);
  const { screens } = React.useContext(StringsContext);
  const { isMobile } = useBrowserInfo();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: boardPresentation === 'card_focused' ? '100%' : '',
      }}
    >
      <div
        style={
          boardPresentation === 'standard'
            ? {
                backgroundColor: section?.color ? section.color : theme.primary,
                color:
                  section?.textMode === 'dark'
                    ? mainColors.black
                    : mainColors.white,
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }
            : {
                backgroundColor: theme.primary,
                color: mainColors.white,
                padding: '30px',
                display: 'flex',
                justifyContent: 'space-between',
              }
        }
      >
        <div>
          <h3 className={styles.cardHeader} style={{ marginBottom: '0px' }}>
            {boardPresentation === 'standard'
              ? cardTypeText
              : screens.gamePage.activeCard.gameIsGoing}
          </h3>
        </div>
        <div style={{ alignSelf: 'center' }}>
          {expandButton &&
            hasActiveCard &&
            !isMobile &&
            boardPresentation === 'standard' && (
              <ExpandButton onClick={onClickExpand} />
            )}
          {activeCardLink && boardPresentation === 'card_focused' && (
            <LinkBox link={activeCardLink} colorWay="light" />
          )}
        </div>
      </div>
      <div
        style={
          boardPresentation === 'standard'
            ? { backgroundColor: mainColors.white }
            : { backgroundColor: theme.primary, height: '100%' }
        }
      >
        {expandButton && boardPresentation === 'card_focused' && (
          <div className={styles.cardFocusExpandcontainer}>
            <ExpandButton onClick={onClickExpand} />
          </div>
        )}
        {activeCardLink && boardPresentation === 'standard' && (
          <LinkBox link={activeCardLink} />
        )}
        {content}
      </div>
    </div>
  );
};

export default CardTemplate;
