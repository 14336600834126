interface Account {
  isLoading: boolean;
}

const initialState: Account = { isLoading: false };

export function api(state: Account = initialState, action: GenericAction): Account {
  if (!action.type.includes('API')) {
    return state;
  }
  if (action.type.includes('_REQUEST')) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type.includes('_FAILURE') || action.type.includes('_SUCCESS')) {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
}
