import React from 'react';
import styles from './HighlightText.module.css';

interface Props {
  title: string;
  backgroundColor: string;
  textColor: string;
}

const HighlightText: React.FC<Props> = (props) => {
  const { title, backgroundColor, textColor } = props;

  return (
    <div className={styles.textContainer}>
      <p className={styles.text} style={{ backgroundColor, color: textColor }}>
        <b>{title}</b>
      </p>
    </div>
  );
};

export default HighlightText;
