export const datePercentDifference = (start: Date, end: Date): number => {
  const today = new Date();

  return Math.round(((today.getTime() - start.getTime()) / (end.getTime() - start.getTime())) * 100);
};

export function roundTimeoutToString(timeout: number): string {
  const days = Math.floor(timeout / (3600 * 24));
  const hours = Math.floor((timeout - days * 3600 * 24) / 3600);
  const minutes = Math.floor((timeout - days * 3600 * 24 - hours * 3600) / 60);

  if (minutes) {
    if (days) {
      return `${days}d ${hours}h ${(`00${minutes}`).slice(-2)}m`;
    }

    return `${hours}h ${(`00${minutes}`).slice(-2)}m`;
  }

  if (days) {
    if (days === 1 && hours === 0) {
      return `${24}h`;
    }

    return `${days}d ${hours}h`;
  }

  return `${hours}h`;
}

export function prettifyDate(date: Date): string {
  const dateWithFormat = date.toLocaleDateString('da-DK', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    hour12: false,
  });
  // will return a format like this: "21-12-20 12:22" or "04-01-21 01:23"
  return `${dateWithFormat}`;
}
