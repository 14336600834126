import { useCallback, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';

interface Props {
  backgroundColor: string | null | undefined;
  loadGame: (val: number) => void;
  removeCurrentGame: () => void;
  updateGame: (val: number) => void;
}

interface ParamTypes {
  id: string;
}

export function useGamePage({
  backgroundColor,
  loadGame,
  removeCurrentGame,
  updateGame,
}: Props) {
  const [renderPopup, setRenderPopup] = useState(false);

  const { id } = useParams<ParamTypes>();

  const teamCardRef = useRef<HTMLDivElement | null>(null);

  const executeScroll = useCallback(
    () => teamCardRef?.current?.scrollIntoView(),
    []
  );

  useEffect(() => {
    loadGame(parseInt(id, 10));
    setTimeout(() => {
      setRenderPopup(true);
    }, 2000);
    return () => removeCurrentGame();
  }, []);

  /* Updates game state every 5 secs */
  // this is a bit dangerous relying on side effects on a component refresh.
  // It is more robust to keep this in the apps core and let the view be an 
  // expression of state. 
  useEffect(() => {
    const interval = setInterval(() => {
      updateGame(parseInt(id, 10));
    }, 5000);
    return () => clearInterval(interval);
  });

  const scrollHandler = useCallback(() => {
    setTimeout(() => {
      executeScroll();
    }, 0);
  }, []);

  const calcWallpaperColor = useCallback(() => {
    if (backgroundColor) {
      return backgroundColor;
    }
    return '#ffffff';
  }, [backgroundColor]);

  return {
    calcWallpaperColor,
    scrollHandler,
    renderPopup,
    teamCardRef,
  };
}
