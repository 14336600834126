/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'de';

export const de: RecursivePartial<DefaultLangKeys> = {
  label: 'Deutsch',
  sagaTranslate: {
    id1: 'Spiel kann nicht geladen werden: Wir können das Spiel im Moment nicht öffnen. Bitte versuche es später noch einmal.',
    id2: 'ist keine gültige Spiel ID',
    id3: 'Etwas ist schiefgelaufen',
    id4: 'Die gezogene Karte existiert nicht',
    id5: 'Spiel ID konnte nicht gefunden werden',
    id6: 'Entschuldige, das Internet ist im Moment etwas langsam. Bitte bleib da, während wir deine Anfrage erneut bearbeiten',
    id7: 'Leider immer noch kein Glück. Wir versuchen immer noch, das Spiel neu zu laden. Warte bitte einen Moment...',
    id8: 'Im Moment besteht kein Zugang zum Internet. Stelle die Verbindung wieder her und versuche es erneut.',
    id9: 'Kein Internetzugang.',
    id10: 'Zugriffstoken kann nicht wiederhergestellt werden.',
    id11: 'Du musst das Spiel laden, bevor du es schließen kannst.',
    id12: 'Das Spiel konnte nicht gestoppt werden',
    id13: 'Dieses Team konnte nicht gefunden werden',
    id14: 'Die Spielfigur kann nicht bewegt werden, da sie außer Reichweite ist.',
    id15: 'Wir haben keine gültige Antwort erhalten (Antwort ist ungültig)',
    id16: 'Es sind keine weiteren Karten verfügbar.',
    id17: 'Das Spiel konnte nicht gefunden werden',
    id18: 'Status konnte nicht aktualisiert werden',
    id19: 'Die Mitspieler wurden erfolgreich zur finalen Reflexion geleitet.',
    id20: 'Das Spiel wurde beendet, gehe zu einem bestehenden Spiel beitreten um einem neuen Spiel beizutreten!',
    id21: 'Deine Reflexion wurde erfolgreich abgeschlossen',
    id22: 'Karte bearbeitet',
  },
  screens: {
    navigation: {
      signed: 'Angemeldet als',
      home: 'Home',
      createGame: 'Spiel erstellen',
      joinGame: 'Spiel beitreten',
      about: 'Über',
      logOut: 'Abmelden',
      game: 'Spiel',
      settings: 'Einstellungen',
    },
    home: {
      hi: 'Hallo',
      welcome: 'Wilkommen bei Gaming4Impact in einem Browser.',
      goTo: 'Los geht\'s',
      goToGameCreation: 'Ein neues Spiel erstellen',
      goToJoinGame: 'Einem bestehenden Spiel beitreten',
      yourRunningGames: 'Wähle ein bestehendes Spiel aus.',
      noRunningGames: 'Es konnte kein bestehendes Spiel gefunden werden, du hast folgende Möglichkeiten:',
      login: 'Bitte melden dich an',
      loginBtn: 'Anmelden',
      buttonLogin: 'Anmelden',
      gameowner: 'Spielleiter',
      created: 'Erstellt',
      enterGameBtn: 'Spiel beitreten',
      notVerifiedHeader: 'Dein Account wurde noch nicht verifiziert.',
      notVerifiedBody: 'Um deinen Account zu verifizieren, öffne in die E-Mail die wir dir gerade geschickt haben und folge den Anweisungen.',
      notVerifiedDone: 'Wenn du fertig bist, lade die Seite neu.',
      reloadBtn: 'Neu Laden',
    },
    settings: {
      settingsHeader: 'Einstellungen',
      avatar: 'Avatar',
      name: 'Name',
      language: 'Sprache',
      selectLanguage: 'Sprache wählen',
      chooseNewImage: 'Neues Bild auswählen',
      playerIcon: 'Spieler Icon',
      changeName: 'Name ändern',
      uploadImage: 'Bild hochladen',
      email: 'Email',
      unknown: 'Unbekannt',
      environment: 'Umgebung',
      environmentVariables: {
        buildShortTag: 'Tag erstellen',
        apiUrl: 'API Endpunkt',
      },
    },
    gameCodes: {
      code: 'Code',
      waitingForCode: 'Warte auf die Erstellung des Codes',
      startGame: 'Spiel starten',
    },
    joinGame: {
      title: 'Spiel beitreten',
      enterPin: 'Pin eingeben',
    },
    gameCreation: {
      team: 'TEAM',
      selectIconName: 'Wähle ein Symbol und einen Namen',
      createTeams: 'Team erstellen',
      addTeam: 'Ein Team hinzufügen',
      removeTeam: 'Ein Team entfernen',
      submit: 'OK',
      enterTeamName: 'Team Namen wählen',
      selectGameName: 'Wähle bitte einen Spielnamen',
      enterGameName: 'Gib bitte den Spielnamen ein',
      header: 'Spiel erstellen',
      noRole: 'Leider hast du nicht die erforderlichen Rechte, um ein Spiel zu erstellen.',
      availableGames: 'Verfügbare Spiele',
      summary: {
        summary: 'Zusammenfassung',
        gameName: 'Spiel Name',
        team: 'Team',
        template: 'Vorlage',
        description: 'Beschreibung',
        difficulty: 'Schwierigkeitsgrad',
      },
      subHeaders: {
        gameName: 'Spiel name',
        gameTemplate: 'Spiel Vorlage',
        teams: 'Teams',
        summary: 'Zusammenfassung',
      },
      progressBar: {
        inactive: 'Überlick',
        summary: 'Warten',
        active: 'In Bearbeitung',
        done: 'Abgeschlossen',
      },
      createGameBtn: 'Spiel erstellen',
      creationStep: 'Schritt',
      goBtn: 'Los geht\'s',
    },
    reflectionPage: {
      submit: 'Speichern',
      mainReflectionCardPlaceholder: 'Mein Hauptpotenzial',
      subTitle: 'Meine Reflexionen',
      mainSubTitle: 'Mein Hauptpotenzial',
      print: 'Drucken',
    },
    gamePage: {
      yesBtn: 'Ja',
      stillHereHeader: 'Bist du noch da?',
      stillHereBody: 'Du bist schon sehr lange hier. Spielst du immmer noch?',
      gameEndedHeader: 'Spiel abgeschlossen',
      gameEndedBody: 'Das Spiel wurde beendet. Wir bringen dich zurück zur Startseite',
      notPartOfGameHeader: 'Du bist leider nicht Teil von diesem Spiel',
      notPartOfGameBody: 'Gehe zurück zur Startseite und klicke auf Spiel beitreten',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Rollenspiel',
          reflection: 'Reflexion',
          discussion: 'Gruppendiskussion',
          action: 'Aktion',
          challenge: 'Challenge',
          teamChallenge: 'Team Challenge',
        },
        waitForNewCard: 'Warte bitte, bis der Spielleiter eine neue Karte gezogen hat.',
        waitForNewCardHeader: 'Keine Karte',
        enterReflection: 'Halte deine Reflexionen fest',
        submit: 'OK',
        guidance: 'Hinweise',
        replied: 'Antwort',
        reflectionStateTextForGM: `Wir sind nun in der letzten Reflexionsphase. 
        Warte bitte, bis alle Spieler ihr Hauptpotenzial notiert haben und ihre Reflexionen gedruckt oder gespeichert haben. 
        Anschließend kannst du das Spiel beenden, indem du auf "Spiel beenden" klickst.`,
        roleplay: {
          myAssignment: 'Meine Aufgabe:',
          myRole: 'Meine Rolle:',
          noAssignment: 'Dir wurde keine Rolle zugewiesen',
          noRole: 'Keine Rolle',
        },
        answerText: {
          youAnswered: 'Du hast geantwortet',
          thisIs: 'Das ist',
          correct: 'richtig',
          incorrect: 'nicht richtig',
          points: 'Punkte',
          guidance: 'Hinweise',
        },
      },
      teamCard: {
        score: 'Gesamtpunktzahl:',
        role: 'Rolle:',
        gamemaster: 'Spielleiter',
        youreAboutToKickPlayer: 'Spieler entfernen',
        areYouSure: 'Bist du sicher, dass du den Mitspieler entfernen möchtest?',
      },
      gmControls: {
        gameInfo: 'Spiel Information',
        ok: 'Ok',
        stopGame: 'Spiel beenden',
        stopGameQuestion: 'Bist du sicher, dass du das Spiel beenden willst?',
        reflectionDeletion: ['Hast du die Spieler gebeten, ihre Reflexionen auszudrucken?'],
        moveBack: 'Rückwärts fahren',
        moveForwards: 'Vorwärts bewegen',
        drawCard: 'Karte ziehen',
        showInfo: 'Informationen anzeigen',
        accept: 'Zustimmen',
        endGameReflectionsbtn: 'Letzte Reflexionen',
        gamename: 'Spiel Name',
        gameId: 'Spiel-ID',
        sectionId: 'Abschnitts-ID',
        sectionName: 'Abschnitt Name',
        availCards: 'Verfügbare Karten auf dem Stabel',
        currentCardId: 'Aktuelle Karten-ID',
        teams: 'Teams:',
        yes: 'Ja',
        notEveryOneHeader: 'Nicht alle Spieler haben ihre Reflexion abgeschlossen',
        notEveryOneContent: 'Bist du sicher, dass du die Spielfigur weiterziehen möchtest?',
        cardIsAlreadyDrawnBody: 'Du hast bereits eine Karte gezogen. Bist du sicher, dass du eine neue Karte ziehen möchtest?',
        cardIsAlreadyDrawnHeader: 'Du hast bereits eine Karte gezogen',
      },
    },
    others: { closeBtn: 'Schließen' },
    errors: {
      notLoggedIn: 'Du scheinst nicht eingeloggt zu sein. Klicke hier, um dich anzumelden.',
      reloadPage: 'Klicke hier, um die Seite neu zu laden',
      goHome: 'Wenn das Problem weiterhin besteht, gehe bitte zur Startseite und versuche es erneut.',
      loginBtn: 'Anmelden',
      homeBtn: 'Home',
      reloadBtn: 'Erneut Laden',
      login: 'Login',
    },
  },
};
