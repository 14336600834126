import React, { useEffect } from 'react';
import Button from '../../Button';
import TimerProgressBar from './TimerProgressBar';
import styles from './timer.module.css';
import { calcStartTime } from './timeCalc';

interface Props {
  duration: number;
  showControls?: boolean;
  onEndTime?: (message: string) => void;
  onSetCountDown: (time: number) => void;
  time: number;
  modal: boolean | undefined; // modal decides if its the one who does the counting.
  cardTimeLimit: string | undefined | null;
  variant?: 'big' | 'small';
  color?: string;
  endTimeMessage?: string;
  sectionName?: string;
  disabled?: boolean;
}

class Timer extends React.Component<Props> {
  static defaultProps = {
    showControls: false,
  };

  myInterval: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    if (this.props.modal === false) {
      this.props.onSetCountDown(this.props.duration);
    }
  }

  componentDidMount() {
    if (this.props.modal === false) {
      this.props.onSetCountDown(
        calcStartTime(this.props.cardTimeLimit, this.props.duration)
      );
      this.startTimer();
    }
  }

  componentDidUpdate() {
    if (this.props.time === this.props.duration) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }

  stopTimer = () => {
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  };

  startTimer = () => {
    this.stopTimer();
    if (this.props.modal === false) {
      this.myInterval = setInterval(() => {
        if (!this.props.disabled) {
          if (this.props.time === 0) {
            if (this.props.onEndTime) {
              this.props.onEndTime(this.props.endTimeMessage || "Time's up");
            }
            this.stopTimer();
          } else {
            this.props.onSetCountDown(this.props.time - 1);
          }
        }
      }, 1000);
    }
  };

  render() {
    const remainedSeconds = this.props.time % 60;
    const remainedMinutes = Math.floor(this.props.time / 60);
    const status = 100 * (this.props.time / this.props.duration);

    const labelSeconds =
      remainedSeconds < 10 ? `0${remainedSeconds}` : remainedSeconds;
    return (
      <div className={styles.timerWrapper}>
        {this.props.showControls && (
          <Button
            title="Start"
            onClick={() => {
              this.props.onSetCountDown(this.props.duration);
              this.startTimer();
            }}
            icon="arrow-right"
            disabled={false}
          />
        )}
        <div className={styles.progressbar_contianer}>
          <TimerProgressBar
            status={status}
            color={this.props.color}
            label={`${remainedMinutes} : ${labelSeconds}`}
            sectionName={this.props.sectionName}
            variant={this.props.variant}
          />
        </div>
        {this.props.showControls && (
          <Button
            title="Stop"
            onClick={() => {
              this.props.onSetCountDown(0);
              this.stopTimer();
            }}
            icon="arrow-right"
            disabled={false}
          />
        )}
      </div>
    );
  }
}

export default Timer;
