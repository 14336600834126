import React from 'react';
import ReactMarkdown from 'react-markdown';

interface MarkdownProps {
  fontSize?: string;
  bold?: boolean;
  children: any;
  style?: any;
}

function Markdown({ children, fontSize = '18px', bold, style }: MarkdownProps) {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...itemProps }) => (
          <p
            style={{
              fontSize,
              fontFamily: bold ? 'IBMPlexSans-Bold' : 'IBMPlexSans-Regular',
              ...style,
            }}
            {...itemProps}
          />
        ),
        a: ({ node, ...itemProps }) => (
          <a
            style={{
              fontFamily: bold ? 'IBMPlexSans-Bold' : 'IBMPlexSans-Regular',
              fontSize,
            }}
            target="_blank"
            {...itemProps}
          >
            {itemProps.children}
          </a>
        ),
        img: ({ node, ...itemProps }) => (
          <img
            alt="image"
            {...itemProps}
            style={{ maxWidth: '100%', maxHeight: window.innerHeight * 0.4 }}
          />
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

export default Markdown;
