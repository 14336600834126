/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

export const APP_INITIALIZE_REQUEST = new ActionCreator<boolean>('APP_INITIALIZE_REQUEST');
export const APP_INITIALIZE_SUCCESS = new ActionCreator<boolean>('APP_INITIALIZE_SUCCESS');
export const APP_INITIALIZE_FAILURE = new ActionCreator<Error>('APP_INITIALIZE_FAILURE');

export const APP_ERROR_DETECTED = new ActionCreator<GeneralError>('APP_ERROR_DETECTED');
export const APP_ERROR_RETRY = new ActionCreator<null>('APP_ERROR_RETRY');
export const APP_ERROR_RESET = new ActionCreator<null>('APP_ERROR_RESET');

export const APP_RESET_STORE = new ActionCreator<null>('APP_RESET_STORE');

export const APP_START_NAVIGATION_UPDATE = new ActionCreator<boolean>('APP_START_NAVIGATION_UPDATE');

export const APP_LANGUAGE_OVERWRITE_UPDATE = new ActionCreator<string>('APP_LANGUAGE_OVERWRITE_SET');

export const APP_COMPANY_CODE_SET = new ActionCreator<string | undefined>('APP_COMPANY_CODE_SET');