import * as React from 'react';
import { Container } from 'react-bootstrap';
import styles from './ActiveCard.module.css';
import { StringsConsumer } from '../../../locales';
import AnswerModal from '../../../pages/main/browser/AnswerModal';
import CardTemplate from './CardTemplate';
import { CardContent } from './CardContent';
import { useSelector } from 'react-redux';
import { engine } from '../../../redux/selectors';

export interface ActiveCardProps {
  activeCard?: DCardInstance;
  currentTeam?: DTeam;
  isGameOwner?: boolean;
  onAnswer: (reply: Reply) => void;
  section?: GameSection;
  gameStatus: GameStatus | undefined;
  boardPresentation?: BoardPresentation;
  noShadow?: boolean;
  currentSectionCard: DCardInstance | undefined;
}

export const ActiveCard: React.FC<ActiveCardProps> = (props) => {
  const [showCardModal, setShowCardModal] = React.useState(false);
  const [reflectionText, setReflectionText] = React.useState('');
  const sharedData = useSelector(engine.sharedData);
  const { activeCard, section } = props;

  const headerText = React.useCallback(
    (
      act: string,
      chl: string,
      role: string,
      tchl: string,
      refle: string,
      discu: string,
      dilem: string
    ) => {
      switch (activeCard?.type) {
        case 'action': {
          return act;
        }
        case 'challenge': {
          return chl;
        }
        case 'roleplay': {
          return role;
        }
        case 'teamchallenge': {
          return tchl;
        }
        case 'reflection': {
          return refle;
        }
        case 'discussion': {
          return discu;
        }
        case 'dilemma': {
          return dilem;
        }
        default: {
          return '';
        }
      }
    },
    [activeCard]
  );

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <Container
            className={props.noShadow ? styles.wrapperNoShadow : styles.wrapper}
            style={{ height: '100%' }}
          >
            <CardTemplate
              boardPresentation={props.boardPresentation}
              hasActiveCard={!!activeCard}
              cardTypeText={
                activeCard?.type
                  ? headerText(
                      gamePage.activeCard.cardTypes.action,
                      gamePage.activeCard.cardTypes.challenge,
                      gamePage.activeCard.cardTypes.rolePlay,
                      gamePage.activeCard.cardTypes.teamChallenge,
                      gamePage.activeCard.cardTypes.reflection,
                      gamePage.activeCard.cardTypes.discussion,
                      gamePage.activeCard.cardTypes.dilemma
                    )
                  : gamePage.activeCard.waitForNewCardHeader
              }
              onClickExpand={() => setShowCardModal(true)}
              expandButton={
                (!!props.activeCard || !!props.currentSectionCard) &&
                !sharedData?.challengeMode?.running
              }
              section={section}
              activeCardLink={props.activeCard?.link}
              content={
                <CardContent
                  updateReflectionText={(text: string) =>
                    setReflectionText(text)
                  }
                  reflectionText={reflectionText}
                  activeCard={props.activeCard}
                  currentTeam={props.currentTeam}
                  onAnswer={(reply) => props.onAnswer(reply)}
                  isGameOwner={props.isGameOwner}
                  section={section}
                  isModal={false}
                  gameStatus={props.gameStatus}
                  notYourTurnText={gamePage.activeCard.notYourTurn}
                  reflectionStateTextForGM={
                    gamePage.activeCard.reflectionStateTextForGM
                  }
                  currentSection={props.section}
                  headerText={headerText(
                    gamePage.activeCard.cardTypes.action,
                    gamePage.activeCard.cardTypes.challenge,
                    gamePage.activeCard.cardTypes.rolePlay,
                    gamePage.activeCard.cardTypes.teamChallenge,
                    gamePage.activeCard.cardTypes.reflection,
                    gamePage.activeCard.cardTypes.discussion,
                    gamePage.activeCard.cardTypes.dilemma
                  )}
                  sectionCard={props.currentSectionCard}
                  waitForNewCardText={gamePage.activeCard.waitForNewCard}
                  boardPresentation={props.boardPresentation || 'standard'}
                  challengeMode={sharedData?.challengeMode}
                />
              }
            />
            <AnswerModal
              updateReflectionText={(text: string) => setReflectionText(text)}
              reflectionText={reflectionText}
              onClose={() => setShowCardModal(false)}
              show={showCardModal}
              activeCard={props.activeCard}
              isGameOwner={props.isGameOwner}
              currentTeam={props.currentTeam}
              onAnswer={(reply: Reply) => props.onAnswer(reply)}
              sectionCard={props.currentSectionCard}
            />
          </Container>
        );
      }}
    </StringsConsumer>
  );
};

export default ActiveCard;

/* TODO REMOVE THE FOLLOWING AFTER IE HAS BEEN TESTED


 <div
              style={{
                backgroundColor: section?.color
                  ? section.color
                  : //x.primary,
                color:
                  section?.textMode === 'dark'
                    ? mainColors.black
                    : mainColors.white,
                padding: '15px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {activeCard?.type ? (
                <div>
                  <div>
                    <span className={styles.cardtype}>
                      {headerText(
                        gamePage.activeCard.cardTypes.action,
                        gamePage.activeCard.cardTypes.challenge,
                        gamePage.activeCard.cardTypes.rolePlay,
                        gamePage.activeCard.cardTypes.teamChallenge,
                        gamePage.activeCard.cardTypes.reflection,
                        gamePage.activeCard.cardTypes.discussion
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <span className={styles.cardtype}>
                    {gamePage.activeCard.waitForNewCardHeader}
                  </span>
                </div>
              )}
              <div style={{ alignSelf: 'center' }}>
                {activeCard?.body && (
                  <ExpandButton onClick={() => setShowCardModal(true)} />
                )}
              </div>
            </div>
            <div
              className={styles.activeCardContainer}
              style={{ backgroundColor: mainColors.white }}
            >
              {activeCard?.body ? (
                <div style={{ width: '100%' }}>
                  {activeCard && activeCard.link && (
                    <LinkBox link={activeCard.link} />
                  )}
                  <h3
                    className="ActiveCardQuestion"
                    style={{
                      marginTop: '8px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {activeCard?.body}
                  </h3>
                  <p
                    className="ActiveCardQuestion"
                    style={{
                      padding: '0px',
                      fontSize: '15px',
                      marginBottom: '0px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {activeCard?.question}
                  </p>

                  <div style={{ marginBottom: '15px' }} />
                  <AnswerBox
                    updateReflectionText={(text: string) =>
                      setReflectionText(text)
                    }
                    reflectionText={reflectionText}
                    activeCard={props.activeCard}
                    currentTeam={props.currentTeam}
                    onAnswer={(reply) => props.onAnswer(reply)}
                    isGameOwner={props.isGameOwner}
                    section={section}
                    isModal={false}
                  />
                  {props.activeCard && !props.activeCard.isMyTurn && (
                    <p
                      style={{
                        backgroundColor: section?.color
                          ? section.color
                          : //x.primary,
                        color:
                          section?.textMode === 'dark'
                            ? mainColors.black
                            : mainColors.white,
                      }}
                      className={styles.notYourTurnText}
                    >
                      {gamePage.activeCard.notYourTurn}
                    </p>
                  )}
                </div>
              ) : (
                <p
                  className={styles.question}
                  style={{
                    color: //x.primaryTextColor,
                  }}
                >
                  {props.gameStatus === 'endgame' ? (
                    <span>{gamePage.activeCard.reflectionStateTextForGM}</span>
                  ) : (
                    <span>{gamePage.activeCard.waitForNewCard}</span>
                  )}
                </p>
              )}
            </div>
*/
