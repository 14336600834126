import {
  BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS,
  BROWSER_GAME_INSTANCE_SUMMARY_REQUEST,
  BROWSER_GAME_INSTANCE_SUMMARY_FAILURE,
  BROWSER_GAME_LOAD_REQUEST,
  BROWSER_GAME_LOAD_SUCCESS,
  BROWSER_GAME_LOAD_FAILURE,
} from '../actions';

interface State {
  gameInstances: GameInstanceSummary[];
  gameSummariesLoaded: boolean;
  isLoading: boolean;
  gameSummariesForceLoading: boolean;
}

const initialState: State = {
  gameInstances: [],
  gameSummariesLoaded: false,
  gameSummariesForceLoading: false,
  isLoading: false,
};

export const browser = (
  state: State = initialState,
  action: GenericAction,
): State => {
  switch (action.type) {
    // These are copied from the ComPLAYance app
    case BROWSER_GAME_INSTANCE_SUMMARY_REQUEST.type: {
      const isForceReload = BROWSER_GAME_INSTANCE_SUMMARY_REQUEST.payload(action);
      return {
        ...state,
        gameSummariesForceLoading: isForceReload,
      };
    }
    case BROWSER_GAME_INSTANCE_SUMMARY_FAILURE.type: {
      return {
        ...state,
        gameSummariesForceLoading: false,
      };
    }
    case BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS.type: {
      const payload = BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS.payload(action);

      return {
        ...state,
        gameInstances: payload,
        gameSummariesForceLoading: false,
        gameSummariesLoaded: true,
      };
    }
    case BROWSER_GAME_LOAD_REQUEST.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BROWSER_GAME_LOAD_SUCCESS.type:
    case BROWSER_GAME_LOAD_FAILURE.type: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
