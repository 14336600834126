import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import DiceButton from './Component/DiceButton';
import {
  ENGINE_DRAW_CARD_REQUEST,
  ENGINE_MOVE_GAMEPIECE_REQUEST,
  ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST,
  ENGINE_MOVE_GAME_SECTION_REQUEST,
} from '../../../redux/actions';
import { engine } from '../../../redux/selectors';

const mapStateToProps = (state: AppState) => {
  return {
    isGameOwner: engine.amIGameOwner(state),
    currentTeamId: engine.currentTeamId(state),
    rollNumber: engine.thisTurnDiceRoll(state),
    combinedTeamPosition: engine.combinedTeamPosition(state),
    currentGame: engine.dnorm.currentGame(state),
    gameLength: engine.gameTileLength(state),
    currentTeamPosToNextSection: engine.currentTeamPosToNextSection(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onDiceRollDone: (amount: number, teamId: number) =>
    dispatch(ENGINE_MOVE_GAMEPIECE_REQUEST.create({ amount, teamId })),
  onDiceRollDoneToEndgame: (amount: number, teamId: number) =>
    dispatch(ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST.create({ amount, teamId })),
  onMoveSection: () => dispatch(ENGINE_MOVE_GAME_SECTION_REQUEST.create()),
  onDrawCard: () => dispatch(ENGINE_DRAW_CARD_REQUEST.create(undefined)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiceButton);
