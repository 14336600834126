// eslint-disable-next-line @typescript-eslint/no-empty-function
import { put, takeEvery } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';
import { APP_ERROR_DETECTED, TOAST_NOTIFICATION_OPEN } from '../../actions';

function* handleApp(action: GenericAction) {
  switch (action.type) {
    case APP_ERROR_DETECTED.type: {
      const payload = APP_ERROR_DETECTED.payload(action);
      yield put(TOAST_NOTIFICATION_OPEN.create({
        content: `${payload.title}: ${payload.body}`,
        appearance: 'error',
        autoDismiss: false,
      }));

      break;
    }
    default:
  }
}
export function* appSaga() {
  yield takeEvery([
    APP_ERROR_DETECTED.type,
  ], handleApp);
}
