import { takeEvery, put, take, select } from 'redux-saga/effects';

import {
  API_GAME_INSTANCE_SUMMARY_GET_FAILURE,
  API_GAME_INSTANCE_SUMMARY_GET_REQUEST,
  API_GAME_INSTANCE_SUMMARY_GET_SUCCESS,
  APP_ERROR_DETECTED,
  BROWSER_GAME_INSTANCE_SUMMARY_REQUEST,
  BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS,
  BROWSER_GAME_LOAD_FAILURE,
  BROWSER_GAME_LOAD_SUCCESS,
  TOAST_NOTIFICATION_OPEN,
  BROWSER_GAME_LOAD_REQUEST,
  FACTORY_INSTANCE_LOAD_REQUEST,
  FACTORY_INSTANCE_LOAD_SUCCESS,
  FACTORY_INSTANCE_LOAD_FAILURE,
  ENGINE_INSTANCE_CLEAR_REQUEST,
  ENGINE_INSTANCE_CLEAR_SUCCESS,
  ENGINE_INSTANCE_CLEAR_FAILURE,
  ENGINE_INSTANCE_LOAD_REQUEST,
  ENGINE_INSTANCE_LOAD_SUCCESS,
  BROWSER_GAME_STATE_UPDATE_REQUEST,
  API_GAME_STATE_GET_REQUEST,
  BROWSER_GAME_STATE_UPDATE_FAILURE,
  BROWSER_GAME_STATE_UPDATE_SUCCESS,
  API_GAME_STATE_GET_SUCCESS,
  API_GAME_STATE_GET_FAILURE,
  ENGINE_ACTIVE_CARD_SET,
  ENGINE_ACTIVE_CARD_CLEAR,
  BROWSER_COMBINED_MOVEMENT_SET,
  ENGINE_ENDGAME_AWARDS_STATE_UPDATE,
} from '../../actions';

import { getStringsUnsafe } from '../../../locales';

import { engine, app } from '../../selectors';

function* browserRequestHandler(action: GenericAction) {
  switch (action.type) {
    case BROWSER_GAME_INSTANCE_SUMMARY_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();
      const meta = BROWSER_GAME_INSTANCE_SUMMARY_REQUEST.meta(action);
      const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });
      const retryProfile = meta || {
        profileLabel: 'defaultGet',
        onTimeoutAction,
      };

      // Flavors request params. 
      const types = 'web'; // move to app state later.
      const companyCode: string = yield select(app.getCompanyCode);

      yield put(
        API_GAME_INSTANCE_SUMMARY_GET_REQUEST.create({
          params: {
            types,
            companyCode
          }
        }, retryProfile)
      );

      const response: GenericAction = yield take([
        API_GAME_INSTANCE_SUMMARY_GET_SUCCESS.type,
        API_GAME_INSTANCE_SUMMARY_GET_FAILURE.type,
      ]);

      try {
        const payload = API_GAME_INSTANCE_SUMMARY_GET_SUCCESS.payload(response);
        yield put(BROWSER_GAME_INSTANCE_SUMMARY_SUCCESS.create(payload));
      } catch (e: any) {
        const isLoggedIn: boolean = yield select(
          (state: AppState) => state.auth.isAuthenticated
        );
        if (!isLoggedIn) {
          break;
        }
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: sagaTranslate.id1,
            appearance: 'error',
            autoDismiss: false,
          })
        );
      }

      break;
    }

    case BROWSER_GAME_LOAD_FAILURE.type: {
      const { sagaTranslate } = getStringsUnsafe();
      // alert user and go to home screen.
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: sagaTranslate.id1,
          appearance: 'error',
          autoDismiss: false,
        })
      );

      break;
    }

    case BROWSER_GAME_LOAD_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();
      // clear any ongoing game with a different id
      const payload = BROWSER_GAME_LOAD_REQUEST.payload(action);

      if (!payload.instanceId) {
        // @ts-ignore
        return yield put(
          BROWSER_GAME_LOAD_FAILURE.create(
            `${payload.instanceId} ${sagaTranslate.id2}`
          )
        );
      }

      yield put(ENGINE_INSTANCE_CLEAR_REQUEST.create(undefined));
      yield take([
        ENGINE_INSTANCE_CLEAR_SUCCESS.type,
        ENGINE_INSTANCE_CLEAR_FAILURE.type,
      ]);

      yield put(FACTORY_INSTANCE_LOAD_REQUEST.create(payload));
      // @ts-ignore
      const result = yield take([
        FACTORY_INSTANCE_LOAD_SUCCESS.type,
        FACTORY_INSTANCE_LOAD_FAILURE.type,
      ]);

      try {
        const game = FACTORY_INSTANCE_LOAD_SUCCESS.payload(result);
        yield put(ENGINE_INSTANCE_LOAD_REQUEST.create(game));
        yield take([ENGINE_INSTANCE_LOAD_SUCCESS.type]);
        const drawnCard: DDrawn = yield select(engine.dnorm.drawnCard);
        if (drawnCard) {
          yield put(ENGINE_ACTIVE_CARD_SET.create(drawnCard.card));
        }
        yield put(BROWSER_GAME_LOAD_SUCCESS.create(null));
      } catch (e: any) {
        yield put(BROWSER_GAME_LOAD_FAILURE.create(sagaTranslate.id3));
      }
      break;
    }
    case BROWSER_GAME_STATE_UPDATE_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();
      const gameId: number | undefined = yield select(engine.currentGameId);
      const gameStatus: GameStatus | undefined = yield select(
        engine.currentGameStatus
      );
      const shouldShowStillHere: boolean = yield select(
        engine.shouldShowStillHere
      );
      if (gameId) {
        if (shouldShowStillHere) {
          break;
        }
        try {
          if (gameStatus === 'finished') {
            throw new Error(sagaTranslate.id20);
          }
          yield put(API_GAME_STATE_GET_REQUEST.create({ instanceId: gameId }));
          // @ts-ignore
          const response = yield take([
            API_GAME_STATE_GET_FAILURE.type,
            API_GAME_STATE_GET_SUCCESS.type,
          ]);
          if (response.type === API_GAME_STATE_GET_SUCCESS.type) {
            const gameInstance = API_GAME_STATE_GET_SUCCESS.payload(response);
            yield put(BROWSER_GAME_STATE_UPDATE_SUCCESS.create(gameInstance));
            const drawnCard: DDrawn = yield select(engine.dnorm.drawnCard);
            if (!drawnCard) {
              yield put(ENGINE_ACTIVE_CARD_CLEAR.create());
              throw new Error(sagaTranslate.id4);
            } else {
              yield put(ENGINE_ACTIVE_CARD_SET.create(drawnCard.card));
            }
          } else {
            const apierror = API_GAME_STATE_GET_FAILURE.payload(response);
            if (apierror.status === 403) {
              // in case that im no longer a part of this game,
              // i should get 403 no access, and ill remove the game.
              // This will show the "youre no longer part of the game"
              // and send me to homescreen.
              yield put(ENGINE_INSTANCE_CLEAR_REQUEST.create(undefined));
              yield take([
                ENGINE_INSTANCE_CLEAR_SUCCESS.type,
                ENGINE_INSTANCE_CLEAR_FAILURE.type,
              ]);
            }
            throw new Error(sagaTranslate.id18);
          }
        } catch (e: any) {
          yield put(BROWSER_GAME_STATE_UPDATE_FAILURE.create(e));
        }
      } else {
        const theError = new Error(sagaTranslate.id5);
        yield put(BROWSER_GAME_STATE_UPDATE_FAILURE.create(theError));
      }
      break;
    }

    case BROWSER_GAME_STATE_UPDATE_SUCCESS.type: {
      const gameId: number | undefined = yield select(engine.currentGameId);
      const endgameMode: EndgameMode | undefined = yield select(
        engine.endgameMode
      );
      const payload = BROWSER_GAME_STATE_UPDATE_SUCCESS.payload(action);
      const gameOwner: boolean = yield select(engine.amIGameOwner);

      if (payload.status === 'endgame') {
        if (!gameOwner && endgameMode === 'endgame-reflection') {
          window.location.replace(`/game/reflections/${gameId}`);
        } else if (endgameMode === 'endgame-award-ceremony') {
          yield put(ENGINE_ENDGAME_AWARDS_STATE_UPDATE.create(true));
        }
      }

      // calculate if dice should move.
      const combinedTeamPosition: number = yield select(
        engine.combinedTeamPosition
      );
      let newCombinedTeamPosition = 0;
      payload.teams.forEach((t) => {
        if (t.gameboardPosition) {
          newCombinedTeamPosition += t.gameboardPosition;
        }
      });

      if (newCombinedTeamPosition > combinedTeamPosition) {
        yield put(
          BROWSER_COMBINED_MOVEMENT_SET.create(newCombinedTeamPosition)
        );
      }

      break;
    }
    default:
  }
  return undefined;
}

export function* browserSaga() {
  yield takeEvery(
    [
      BROWSER_GAME_INSTANCE_SUMMARY_REQUEST.type,
      BROWSER_GAME_LOAD_FAILURE.type,
      BROWSER_GAME_LOAD_REQUEST.type,
      BROWSER_GAME_STATE_UPDATE_REQUEST.type,
      BROWSER_GAME_STATE_UPDATE_SUCCESS.type,
      BROWSER_GAME_STATE_UPDATE_FAILURE.type,
    ],
    browserRequestHandler
  );
}
