/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

interface GameInstanceRequest {
  instanceId: number;
}

interface CreateGameInstanceRequest {
  gameTemplate: number;
  type: GameType;
  homeTeam: GameTemplateTeam;
  awayTeam: GameTemplateTeam;
}

interface AddTeamMatesRequest {
  team: {
    name: string;
    icon: number;
    emails: string[];
  };
  teamId: number;
  instanceId: number;
}

interface AnswerReply {
  instanceId: number;
  reply: Reply;
}

interface SettableProfile {
  name?: string;
  avatar?: Avatar;
}

interface GameStopRequest {
  instanceId: number;
}

interface CheckinRequest {
  device_id: string;
  device_name: string;
  application_version: string;
}

interface CardDrawRequest {
  gameInstanceId: number;
  cardId: number;
}

export const API_COMPANY_PLAYERS_GET_REQUEST = new ActionCreator<number, RetryMeta>('API_COMPANY_PLAYERS_GET_REQUEST');
export const API_COMPANY_PLAYERS_GET_FAILURE = new ActionCreator<APIError>('API_COMPANY_PLAYERS_GET_FAILURE');
export const API_COMPANY_PLAYERS_GET_SUCCESS = new ActionCreator<Player[]>('API_COMPANY_PLAYERS_GET_SUCCESS');

export const API_COMPANY_APPLY_REQUEST = new ActionCreator<CompanyApply, RetryMeta>('API_COMPANY_APPLY_REQUEST');
export const API_COMPANY_APPLY_FAILURE = new ActionCreator<APIError>('API_COMPANY_APPLY_FAILURE');
export const API_COMPANY_APPLY_SUCCESS = new ActionCreator<{ id: number }>('API_COMPANY_APPLY_SUCCESS');

export const API_PROFILE_GET_REQUEST = new ActionCreator<null, RetryMeta>('API_PROFILE_GET_REQUEST');
export const API_PROFILE_GET_SUCCESS = new ActionCreator<Profile>('API_PROFILE_GET_SUCCESS');
export const API_PROFILE_GET_FAILURE = new ActionCreator<APIError>('API_PROFILE_GET_FAILURE');

export const API_PROFILE_POST_REQUEST = new ActionCreator<SettableProfile, RetryMeta>('API_PROFILE_POST_REQUEST');
export const API_PROFILE_POST_SUCCESS = new ActionCreator<Profile>('API_PROFILE_POST_SUCCESS');
export const API_PROFILE_POST_FAILURE = new ActionCreator<APIError>('API_PROFILE_POST_FAILURE');

export const API_INVITE_GET_REQUEST = new ActionCreator<number | null, RetryMeta>('API_INVITE_GET_REQUEST');
export const API_INVITE_GET_SUCCESS = new ActionCreator<GameInvite[]>('API_INVITE_GET_SUCCESS');
export const API_INVITE_GET_FAILURE = new ActionCreator<APIError>('API_INVITE_GET_FAILURE');

export const API_INVITE_ACCEPT_REQUEST = new ActionCreator<number, RetryMeta>('API_INVITE_ACCEPT_REQUEST');
export const API_INVITE_ACCEPT_SUCCESS = new ActionCreator<number>('API_INVITE_ACCEPT_SUCCESS');
export const API_INVITE_ACCEPT_FAILURE = new ActionCreator<APIError>('API_INVITE_ACCEPT_FAILURE');

export const API_INVITE_REJECT_REQUEST = new ActionCreator<number, RetryMeta>('API_INVITE_REJECT_REQUEST');
export const API_INVITE_REJECT_SUCCESS = new ActionCreator<number>('API_INVITE_REJECT_SUCCESS');
export const API_INVITE_REJECT_FAILURE = new ActionCreator<APIError>('API_INVITE_REJECT_FAILURE');

export const API_GAME_INSTANCE_SUMMARY_GET_REQUEST = new ActionCreator<GameTemplateParameters, RetryMeta>('API_GAME_INSTANCE_SUMMARY_GET_REQUEST');
export const API_GAME_INSTANCE_SUMMARY_GET_SUCCESS = new ActionCreator<GameInstanceSummary[]>('API_GAME_INSTANCE_SUMMARY_GET_SUCCESS');
export const API_GAME_INSTANCE_SUMMARY_GET_FAILURE = new ActionCreator<APIError>('API_GAME_INSTANCE_SUMMARY_GET_FAILURE');

export const API_TEMPLATE_AVAILABLE_REQUEST = new ActionCreator<GameTemplateParameters, RetryMeta>('API_TEMPLATE_AVAILABLE_REQUEST');
export const API_TEMPLATE_AVAILABLE_SUCCESS = new ActionCreator<GameTemplate[]>('API_TEMPLATE_AVAILABLE_SUCCESS');
export const API_TEMPLATE_AVAILABLE_FAILURE = new ActionCreator<APIError>('API_TEMPLATE_AVAILABLE_FAILURE');

export const API_TEAM_AVAILABLE_REQUEST = new ActionCreator<number, RetryMeta>('API_TEAM_AVAILABLE_REQUEST');
export const API_TEAM_AVAILABLE_SUCCESS = new ActionCreator<GameTemplate[]>('API_TEAM_AVAILABLE_SUCCESS');
export const API_TEAM_AVAILABLE_FAILURE = new ActionCreator<APIError>('API_TEAM_AVAILABLE_FAILURE');

export const API_CREATE_GAME_INSTANCE_REQUEST = new ActionCreator<CreateGameInstanceRequest, RetryMeta>('API_CREATE_GAME_INSTANCE_REQUEST');
export const API_CREATE_GAME_INSTANCE_SUCCESS = new ActionCreator<GameInstance>('API_CREATE_GAME_INSTANCE_SUCCESS');
export const API_CREATE_GAME_INSTANCE_FAILURE = new ActionCreator<APIError>('API_CREATE_GAME_INSTANCE_FAILURE');

export const API_GAME_INSTANCE_GET_REQUEST = new ActionCreator<GameInstanceRequest, RetryMeta>('API_GAME_INSTANCE_GET_REQUEST');
export const API_GAME_INSTANCE_GET_SUCCESS = new ActionCreator<GameInstance>('API_GAME_INSTANCE_GET_SUCCESS');
export const API_GAME_INSTANCE_GET_FAILURE = new ActionCreator<APIError>('API_GAME_INSTANCE_GET_FAILURE');

export const API_GAME_STATE_GET_REQUEST = new ActionCreator<GameInstanceRequest, RetryMeta>('API_GAME_STATE_GET_REQUEST');
export const API_GAME_STATE_GET_SUCCESS = new ActionCreator<GameState, GameInstanceRequest>('API_GAME_STATE_GET_SUCCESS');
export const API_GAME_STATE_GET_FAILURE = new ActionCreator<APIError>('API_GAME_STATE_GET_FAILURE');

export const API_GAME_CARD_REPLY_POST_REQUEST = new ActionCreator<AnswerReply, RetryMeta>('API_GAME_CARD_REPLY_POST_REQUEST');
export const API_GAME_CARD_REPLY_POST_SUCCESS = new ActionCreator<GameState>('API_GAME_CARD_REPLY_POST_SUCCESS');
export const API_GAME_CARD_REPLY_POST_FAILURE = new ActionCreator<APIError>('API_GAME_CARD_REPLY_POST_FAILURE');

export const API_GAME_STOP_REQUEST = new ActionCreator<GameStopRequest, RetryMeta>('API_GAME_STOP_REQUEST');
export const API_GAME_STOP_SUCCESS = new ActionCreator<GameState>('API_GAME_STOP_SUCCESS');
export const API_GAME_STOP_FAILURE = new ActionCreator<APIError>('API_GAME_STOP_FAILURE');

export const API_FEEDBACK_POST_REQUEST = new ActionCreator<StructuredFeedback, RetryMeta>('API_FEEDBACK_POST_REQUEST');
export const API_FEEDBACK_POST_SUCCESS = new ActionCreator<StructuredFeedback>('API_FEEDBACK_POST_SUCCESS');
export const API_FEEDBACK_POST_FAILURE = new ActionCreator<APIError>('API_FEEDBACK_POST_FAILURE');

export const API_APP_CHECKIN_POST_REQUEST = new ActionCreator<CheckinRequest, RetryMeta>('API_APP_CHECKIN_POST_REQUEST');
export const API_APP_CHECKIN_POST_SUCCESS = new ActionCreator<CheckinResponse>('API_APP_CHECKIN_POST_SUCCESS');
export const API_APP_CHECKIN_POST_FAILURE = new ActionCreator<Error>('API_APP_CHECKIN_POST_FAILURE');

export const API_GAME_AWAY_TEAM_ADD_MATES_REQUEST = new ActionCreator<AddTeamMatesRequest, RetryMeta>('API_GAME_AWAY_TEAM_ADD_MATES_REQUEST');
export const API_GAME_AWAY_TEAM_ADD_MATES_SUCCESS = new ActionCreator<null>('API_GAME_AWAY_TEAM_ADD_MATES_SUCCESS');
export const API_GAME_AWAY_TEAM_ADD_MATES_FAILURE = new ActionCreator<APIError>('API_GAME_AWAY_TEAM_ADD_MATES_FAILURE');

export const API_GAME_CARD_DRAW_POST_REQUEST = new ActionCreator<CardDrawRequest, RetryMeta>('API_GAME_CARD_DRAW_POST_REQUEST');
export const API_GAME_CARD_DRAW_POST_SUCCESS = new ActionCreator<GameState>('API_GAME_CARD_DRAW_POST_SUCCESS');
export const API_GAME_CARD_DRAW_POST_FAILURE = new ActionCreator<APIError>('API_GAME_CARD_DRAW_POST_FAILURE');

interface ZodError {
  path: (string | number)[];
  message: string;
}

interface ValidationError {
  type: string;
  errors: ZodError[];
}

export const API_VALIDATION_ERROR = new ActionCreator<ValidationError>('API_VALIDATION_ERROR');

export const API_POKE_TEMPLATE_GET_REQUEST = new ActionCreator<null, RetryMeta>('API_POKE_TEMPLATE_GET_REQUEST');
export const API_POKE_TEMPLATE_GET_SUCCESS = new ActionCreator<PokeTemplate[]>('API_POKE_TEMPLATE_GET_SUCCESS');
export const API_POKE_TEMPLATE_GET_FAILURE = new ActionCreator<Error>('API_POKE_TEMPLATE_GET_FAILURE');

export const API_POKE_POST_REQUEST = new ActionCreator<PokeRequest, RetryMeta>('API_POKE_POST_REQUEST');
export const API_POKE_POST_SUCCESS = new ActionCreator<PokeInstance>('API_POKE_POST_SUCCESS');
export const API_POKE_POST_FAILURE = new ActionCreator<Error>('API_POKE_POST_FAILURE');

export const API_POKE_REPLY_POST_REQUEST = new ActionCreator<PokeReplyRequest, RetryMeta>('API_POKE_REPLY_POST_REQUEST');
export const API_POKE_REPLY_POST_SUCCESS = new ActionCreator<PokeInstance>('API_POKE_REPLY_POST_SUCCESS');
export const API_POKE_REPLY_POST_FAILURE = new ActionCreator<Error>('API_POKE_REPLY_POST_FAILURE');

export const API_GAME_INSTANCE_POKE_GET_REQUEST = new ActionCreator<{ instanceId: number }, RetryMeta>('API_GAME_INSTANCE_POKE_GET_REQUEST');
export const API_GAME_INSTANCE_POKE_GET_SUCCESS = new ActionCreator<PokeInGameExchange, { instanceId: number }>('API_GAME_INSTANCE_POKE_GET_SUCCESS');
export const API_GAME_INSTANCE_POKE_GET_FAILURE = new ActionCreator<Error>('API_GAME_INSTANCE_POKE_GET_FAILURE');

export const API_GAME_JOIN_REQUEST = new ActionCreator<{ code: string }, RetryMeta>('API_GAME_JOIN_REQUEST');
export const API_GAME_JOIN_SUCCESS = new ActionCreator<GameInstance>('API_GAME_JOIN_SUCCESS');
export const API_GAME_JOIN_FAILURE = new ActionCreator<APIError>('API_GAME_JOIN_FAILURE');

export const API_GAME_PIECE_MOVE_REQUEST = new ActionCreator<{ newPosition: number, teamId: number }>('API_GAME_PIECE_MOVE_REQUEST');
export const API_GAME_PIECE_MOVE_SUCCESS = new ActionCreator<Team>('API_GAME_PIECE_MOVE_SUCCESS');
export const API_GAME_PIECE_MOVE_FAILURE = new ActionCreator<APIError>('API_GAME_PIECE_MOVE_FAILURE');

export const API_REFLECTIONS_GET_REQUEST = new ActionCreator<{ gameId: number }>('API_REFLECTIONS_GET_REQUEST');
export const API_REFLECTIONS_GET_SUCCESS = new ActionCreator<MyReflection[]>('API_REFLECTIONS_GET_SUCCESS');
export const API_REFLECTIONS_GET_FAILURE = new ActionCreator<APIError>('API_REFLECTIONS_GET_FAILURE');

export const API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST = new ActionCreator<{ gameId: number }>('API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST');
export const API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS = new ActionCreator<void>('API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS');
export const API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE = new ActionCreator<APIError>('API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE');

export const API_REFLECTIONS_SUBMIT_FINAL_REQUEST = new ActionCreator<{ text: string, gameId: number }>('API_REFLECTIONS_SUBMIT_FINAL_REQUEST');
export const API_REFLECTIONS_SUBMIT_FINAL_SUCCESS = new ActionCreator<void>('API_REFLECTIONS_SUBMIT_FINAL_SUCCESS');
export const API_REFLECTIONS_SUBMIT_FINAL_FAILURE = new ActionCreator<APIError>('API_REFLECTIONS_SUBMIT_FINAL_FAILURE');

export const API_REFLECTIONS_GET_FINAL_REQUEST = new ActionCreator<{ gameId: number }>('API_REFLECTIONS_GET_FINAL_REQUEST');
export const API_REFLECTIONS_GET_FINAL_SUCCESS = new ActionCreator<MyEndGameReflectionType>('API_REFLECTIONS_GET_FINAL_SUCCESS');
export const API_REFLECTIONS_GET_FINAL_FAILURE = new ActionCreator<APIError>('API_REFLECTIONS_GET_FINAL_FAILURE');

export const API_REMOVE_PLAYER_REQUEST = new ActionCreator<{ playerId: number, teamId: number }>('API_REMOVE_PLAYER_REQUEST');
export const API_REMOVE_PLAYER_SUCCESS = new ActionCreator<Team>('API_REMOVE_PLAYER_SUCCESS');
export const API_REMOVE_PLAYER_FAILURE = new ActionCreator<APIError>('API_REMOVE_PLAYER_FAILURE');

export const API_CHANGE_TEAM_TURN_REQUEST = new ActionCreator<{ teamId: number, gameInstanceId: number }>('API_CHANGE_TEAM_TURN_REQUEST');
export const API_CHANGE_TEAM_TURN_SUCCESS = new ActionCreator<GameState>('API_CHANGE_TEAM_TURN_SUCCESS');
export const API_CHANGE_TEAM_TURN_FAILURE = new ActionCreator<APIError>('API_CHANGE_TEAM_TURN_FAILURE');

export const API_BUY_TOKEN_REQUEST = new ActionCreator<{ giId: number, tokenId: number, teamId: number }>('API_BUY_TOKEN_REQUEST');
export const API_BUY_TOKEN_SUCCESS = new ActionCreator<GameState>('API_BUY_TOKEN_SUCCESS');
export const API_BUY_TOKEN_FAILURE = new ActionCreator<APIError>('API_BUY_TOKEN_FAILURE');

export const API_MOVE_GAME_SECTION_REQUEST = new ActionCreator<{ giId: number, sectionId: number }>('API_MOVE_GAME_SECTION_REQUEST');
export const API_MOVE_GAME_SECTION_SUCCESS = new ActionCreator<GameState>('API_MOVE_GAME_SECTION_SUCCESS');
export const API_MOVE_GAME_SECTION_FAILURE = new ActionCreator<APIError>('API_MOVE_GAME_SECTION_FAILURE');

export const API_SHARED_DATA_REQUEST = new ActionCreator<{ sharedData: SharedData, gameId: number }>('API_SHARED_DATA_REQUEST');
export const API_SHARED_DATA_SUCCESS = new ActionCreator<GameState>('API_SHARED_DATA_SUCCESS');
export const API_SHARED_DATA_FAILURE = new ActionCreator<APIError>('API_SHARED_DATA_FAILURE');

export const API_CREATE_ANON_USER_REQUEST = new ActionCreator<{ name: string }>('API_CREATE_ANON_USER_REQUEST');
export const API_CREATE_ANON_USER_SUCCESS = new ActionCreator<{ jwt: string }>('API_CREATE_ANON_USER_SUCCESS');
export const API_CREATE_ANON_USER_FAILURE = new ActionCreator<APIError>('API_CREATE_ANON_USER_FAILURE');

// This also allows subtraction
export const API_GAME_ADD_POINTS_REQUEST = new ActionCreator<{ points: number, teamId: number, sectionId?: number }>('API_GAME_ADD_POINTS_REQUEST');
// the team that we just edited
export const API_GAME_ADD_POINTS_SUCCESS = new ActionCreator<Team>('API_GAME_ADD_POINTS_SUCCESS');
export const API_GAME_ADD_POINTS_FAILURE = new ActionCreator<APIError>('API_GAME_ADD_POINTS_FAILURE');

export const API_GAME_GIVE_TOKEN_REQUEST = new ActionCreator<{ giId: number, tokenId: number, receivingTeamId: number }>('API_GAME_GIVE_TOKEN_REQUEST');
export const API_GAME_GIVE_TOKEN_SUCCESS = new ActionCreator<GameState>('API_GAME_GIVE_TOKEN_SUCCESS');
export const API_GAME_GIVE_TOKEN_FAILURE = new ActionCreator<APIError>('API_GAME_GIVE_TOKEN_FAILURE');