/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'it';

export const it: RecursivePartial<DefaultLangKeys> = {
  label: 'Italiano',
  sagaTranslate: {
    id1: 'Impossibile caricare il gioco: attualmente non è possibile caricare il gioco. Si prega di riprovare più tardi.',
    id2: 'ID gioco non valido',
    id3: 'Qualcosa è andato storto',
    id4: 'La carta pescata non esiste',
    id5: 'Impossibile trovare l’ID gioco',
    id6: 'Spiacenti, attualmente la linea internet è rallentata. Resta qui mentre cerchiamo di elaborare nuovamente la tua richiesta',
    id7: 'Nemmeno questo tentativo è riuscito. Continuiamo a cercare di ricaricare il gioco. Attendi un momento...',
    id8: 'Attualmente non c’è alcun accesso a internet. Ripristina il tuo collegamento e riprova.',
    id9: 'Nessun accesso a internet.',
    id10: 'Impossibile ripristinare il token di accesso.',
    id11: 'Devi caricare il gioco prima di poterlo chiudere.',
    id12: 'Impossibile arrestare il gioco',
    id13: 'Impossibile trovare questa squadra',
    id14: 'Impossibile muovere la pedina, poiché fuori dalla portata.',
    id15: 'Non abbiamo ricevuto alcuna risposta valida (la risposta non è valida)',
    id16: 'Non sono disponibili altre carte.',
    id17: 'Impossibile trovare il gioco',
    id18: 'Impossibile aggiornare lo stato',
    id19: 'Gli altri giocatori sono stati avviati con successo alla riflessione finale.',
    id20: 'Il gioco è stato terminato, quindi vai a iscriverti a un gioco per entrare in uno nuovo!',
    id21: 'Hai concluso la riflessione con successo',
    id22: 'Scheda elaborata',
  },
  screens: {
    navigation: {
      signed: 'Registrato come',
      home: 'Home',
      createGame: 'Crea gioco',
      joinGame: 'Iscriviti a gioco',
      about: 'Info',
      logOut: 'Logout',
      game: 'Gioco',
      settings: 'Impostazioni',
    },
    home: {
      hi: 'Ciao',
      welcome: 'Benvenuto a in Gaming4Impact all’interno del browser.',
      goTo: 'Pronti via',
      goToGameCreation: 'Crea un nuovo gioco',
      goToJoinGame: 'Iscriviti a gioco esistente',
      yourRunningGames: 'Seleziona un gioco esistente.',
      noRunningGames: 'Non è stato possibile trovare alcun gioco esistente, hai le seguenti possibilità:',
      login: 'Registrati',
      loginBtn: 'Login',
      buttonLogin: 'Login',
      gameowner: 'Conduttore del gioco',
      created: 'Creato',
      enterGameBtn: 'Iscriviti a gioco',
    },
    settings: {
      settingsHeader: 'Impostazioni',
      avatar: 'Avatar',
      name: 'Nome',
      language: 'Lingua',
      selectLanguage: 'Seleziona lingua',
      chooseNewImage: 'Seleziona nuova immagine',
      playerIcon: 'Icona giocatore',
      changeName: 'Modifica nome',
      uploadImage: 'Carica immagine',
      email: 'E-mail',
      unknown: 'Sconosciuto',
      environment: 'Ambiente',
      environmentVariables: {
        buildShortTag: 'Crea giorno',
        apiUrl: 'Punto finale API',
      },
    },
    gameCodes: {
      code: 'Codice',
      waitingForCode: 'Attendi la creazione del codice',
      startGame: 'Avvia gioco',
    },
    joinGame: {
      title: 'Iscriviti a gioco',
      enterPin: 'Inserisci pin',
    },
    gameCreation: {
      team: 'SQUADRA',
      selectIconName: 'Seleziona un nome e un simbolo',
      createTeams: 'Crea squadra',
      addTeam: 'Aggiungi una squadra',
      removeTeam: 'Rimuovi una squadra',
      submit: 'OK',
      enterTeamName: 'Seleziona nome squadra',
      selectGameName: 'Seleziona un nome gioco',
      enterGameName: 'Immetti il nome gioco',
      header: 'Crea gioco',
      noRole: 'Purtroppo non hai i diritti necessari a creare un gioco.',
      availableGames: 'Giochi disponibili',
      summary: {
        summary: 'Sintesi',
        gameName: 'Nome gioco',
        team: 'Squadra',
        template: 'Modello',
        description: 'Descrizione',
        difficulty: 'Grado di difficoltà',
      },
      subHeaders: {
        gameName: 'Nome gioco',
        gameTemplate: 'Modello gioco',
        teams: 'Squadre',
        summary: 'Sintesi',
      },
      progressBar: {
        inactive: 'Panoramica',
        summary: 'Attendi',
        active: 'In lavorazione',
        done: 'Concluso',
      },
      createGameBtn: 'Crea gioco',
      creationStep: 'Passaggio',
      goBtn: 'Pronti via',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Il mio potenziale',
      subTitle: 'Le mie riflessioni',
      mainSubTitle: 'Il mio potenziale',
      print: 'Stampa',
    },
    gamePage: {
      yesBtn: 'Sì',
      stillHereHeader: 'Ci sei ancora?',
      stillHereBody: 'Sei qui già da molto. Stai ancora giocando?',
      gameEndedHeader: 'Gioco concluso',
      gameEndedBody: 'Il gioco è stato terminato. Ti riportiamo alla pagina iniziale',
      notPartOfGameHeader: 'Purtroppo non fai parte di questo gioco',
      notPartOfGameBody: 'Torna indietro alla pagina iniziale e fai clic su Iscriviti a gioco',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Gioco di ruolo',
          reflection: 'Riflessione',
          discussion: 'Discussione di gruppo',
          action: 'Azione',
          challenge: 'Sfida',
          teamChallenge: 'Sfida di squadra',
        },
        waitForNewCard: 'Attendi finché il conduttore del gioco avrà pescato una nuova carta.',
        waitForNewCardHeader: 'Nessuna carta',
        enterReflection: 'Annota le tue riflessioni',
        submit: 'OK',
        guidance: 'Indicazioni',
        replied: 'Risposta',
        reflectionStateTextForGM: `Siamo quindi nell'ultima fase di riflessione. 
        Attendi finché tutti i giocatori avranno annotato il loro potenziale e stampato o salvato le loro riflessioni. 
        Infine, potrai terminare il gioco facendo clic su “Termina gioco”.`,
        roleplay: {
          myAssignment: 'I miei compiti:',
          myRole: 'Il mio ruolo:',
          noAssignment: 'Non ti è stato assegnato alcun ruolo',
          noRole: 'Nessun ruolo',
        },
        answerText: {
          youAnswered: 'Hai risposto',
          thisIs: 'È',
          correct: 'giusto',
          incorrect: 'sbagliato',
          points: 'Punti',
          guidance: 'Indicazioni',
        },
      },
      teamCard: {
        score: 'Punteggio totale:',
        role: 'Ruolo:',
        gamemaster: 'Conduttore del gioco',
        youreAboutToKickPlayer: 'Rimuovi giocatore',
        areYouSure: 'Rimuovere l’altro giocatore?',
      },
      gmControls: {
        gameInfo: 'Informazioni sul gioco',
        ok: 'OK',
        stopGame: 'Termina gioco',
        stopGameQuestion: 'Terminare il gioco?',
        reflectionDeletion: ['Hai chiesto ai giocatori di esprimere le loro riflessioni?'],
        moveBack: 'Torna indietro',
        moveForwards: 'Muovi in avanti',
        drawCard: 'Pesca carta',
        showInfo: 'Mostra informazioni',
        accept: 'Accetta',
        endGameReflectionsbtn: 'Ultime riflessioni',
        gamename: 'Nome gioco',
        gameId: 'ID gioco',
        sectionId: 'ID paragrafo',
        sectionName: 'Nome paragrafo',
        availCards: 'Carte disponibili sul mazzo',
        currentCardId: 'Attuale ID carta',
        teams: 'Squadre:',
        yes: 'Sì',
        notEveryOneHeader: 'Non tutti i giocatori hanno concluso le loro riflessioni',
        notEveryOneContent: 'Vuoi avanzare la pedina?',
        cardIsAlreadyDrawnBody: 'Hai già pescato una carta. Vuoi pescare una nuova carta?',
        cardIsAlreadyDrawnHeader: 'Hai già pescato una carta',
      },
    },
    others: { closeBtn: 'Chiudi' },
    errors: {
      notLoggedIn: 'Sembra che tu non abbia effettuato l\'accesso. Fai clic qui per accedere.',
      reloadPage: 'Fai clic qui per ricaricare la pagina',
      goHome: 'Se il problema persiste, vai alla pagina iniziale e riprova.',
      loginBtn: 'Login',
      homeBtn: 'Home',
      reloadBtn: 'Carica di nuovo',
      login: 'Login',
    },
  },
};
