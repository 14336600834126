/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'pl';

export const pl: RecursivePartial<DefaultLangKeys> = {
  label: 'Polski',
  sagaTranslate: {
    id1: 'Nie można wczytać gry: Obecnie nie możemy otworzyć gry. Spróbuj ponownie później.',
    id2: 'to nie jest prawidłowy ident. gry',
    id3: 'Coś poszło nie tak',
    id4: 'Wyciągnięta karta nie istnieje',
    id5: 'Nie udało się znaleźć ident. gry',
    id6: 'Przepraszam, Internet jest obecnie nieco wolny. Pozostań, a my ponownie rozpatrzymy zapytanie',
    id7: 'Niestety nadal brak szczęścia. Próbujemy cały czas ponownie wczytać grę. Poczekaj chwilę...',
    id8: 'Obecnie nie ma dostępu do Internetu. Nawiąż połączenie ponownie i spróbuj jeszcze raz.',
    id9: 'Brak dostępu do Internetu.',
    id10: 'Nie można przywrócić tokena dostępu.',
    id11: 'Musisz wczytać grę zanim będzie można ją zamknąć.',
    id12: 'Nie udało się zatrzymać gry',
    id13: 'Nie udało się znaleźć zespołu',
    id14: 'Nie można przesunąć pionka, ponieważ jest poza zasięgiem.',
    id15: 'Nie otrzymaliśmy prawidłowej odpowiedzi (odpowiedź jest nieprawidłowa)',
    id16: 'Brak innych dostępnych kart.',
    id17: 'Nie udało się znaleźć gry',
    id18: 'Nie udało się zaktualizować statusu',
    id19: 'Inni gracze zostali pomyślnie poprowadzeni do końcowej refleksji.',
    id20: 'Gra została zakończona, przejdź do istniejącej gry, aby przystąpić do nowej gry!',
    id21: 'Twoja refleksja została pomyślnie zakończona',
    id22: 'Edytowano kartę',
  },
  screens: {
    navigation: {
      signed: 'Zalogowano jako',
      home: 'Pozycja podstawowa',
      createGame: 'Utwórz grę',
      joinGame: 'Przystąp do gry',
      about: 'Informacje o',
      logOut: 'Wyloguj',
      game: 'Gra',
      settings: 'Nastawienia',
    },
    home: {
      hi: 'Witaj',
      welcome: 'Witamy w ComPLAYance w przeglądarce.',
      goTo: 'Zaczynajmy',
      goToGameCreation: 'Utwórz nową grę',
      goToJoinGame: 'Przystąp do istniejącej gry',
      yourRunningGames: 'Wybierz istniejącą grę.',
      noRunningGames: 'Nie udało się znaleźć istniejącej gry, masz poniższe możliwości:',
      login: 'Zaloguj się',
      loginBtn: 'Zaloguj',
      buttonLogin: 'Zaloguj',
      gameowner: 'Lider gry',
      created: 'Utworzył',
      enterGameBtn: 'Przystąp do gry',
    },
    settings: {
      settingsHeader: 'Nastawienia',
      avatar: 'Awatar',
      name: 'Nazwa',
      language: 'Język',
      selectLanguage: 'Wybierz język',
      chooseNewImage: 'Wybierz nowy obraz',
      playerIcon: 'Ikona gracza',
      changeName: 'Zmień nazwę',
      uploadImage: 'Prześlij obraz',
      email: 'E-mail',
      unknown: 'Nieznany',
      environment: 'Otoczenie',
      environmentVariables: {
        buildShortTag: 'Utwórz znacznik',
        apiUrl: 'Punkt końcowy API',
      },
    },
    gameCodes: {
      code: 'Kod',
      waitingForCode: 'Poczekaj na utworzenie kodu',
      startGame: 'Uruchom grę',
    },
    joinGame: {
      title: 'Przystąp do gry',
      enterPin: 'Podaj PIN',
    },
    gameCreation: {
      team: 'ZESPÓŁ',
      selectIconName: 'Wybierz symbol i nazwę',
      createTeams: 'Utwórz zespół',
      addTeam: 'Dodaj zespół',
      removeTeam: 'Usuń zespół',
      submit: 'OK',
      enterTeamName: 'Wybierz nazwę zespołu',
      selectGameName: 'Wybierz nazwę gry',
      enterGameName: 'Wpisz nazwę gry',
      header: 'Utwórz grę',
      noRole: 'Niestety nie masz wymaganych uprawnień do utworzenia gry.',
      availableGames: 'Dostępne gry',
      summary: {
        summary: 'Podsumowanie',
        gameName: 'Nazwa gry',
        team: 'Zespół',
        template: 'Szablon',
        description: 'Opis',
        difficulty: 'Stopień trudności',
      },
      subHeaders: {
        gameName: 'Nazwa gry',
        gameTemplate: 'Szablon gry',
        teams: 'Zespoły',
        summary: 'Podsumowanie',
      },
      progressBar: {
        inactive: 'Przegląd',
        summary: 'Oczekiwanie',
        active: 'Trwa edycja',
        done: 'Zakończono',
      },
      createGameBtn: 'Utwórz grę',
      creationStep: 'Krok',
      goBtn: 'Zaczynajmy',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Mój główny potencjał',
      subTitle: 'Moje refleksje',
      mainSubTitle: 'Mój główny potencjał',
      print: 'Drukuj',
    },
    gamePage: {
      yesBtn: 'Tak',
      stillHereHeader: 'Jesteś jeszcze?',
      stillHereBody: 'Jesteś już tutaj bardzo długo. Grasz dalej?',
      gameEndedHeader: 'Gra zakończona',
      gameEndedBody: 'Gra została zakończona. Cofniemy się do strony startowej',
      notPartOfGameHeader: 'Niestety nie jesteś częścią tej gry',
      notPartOfGameBody: 'Wróć do strony startowej i kliknij Przystąp do gry',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Gra z podziałem na role',
          reflection: 'Refleksja',
          discussion: 'Dyskusja grupowa',
          action: 'Akcja',
          challenge: 'Wyzwanie',
          teamChallenge: 'Wyzwanie zespołowe',
        },
        waitForNewCard: 'Poczekaj, aż lider gry wyciągnie nową kartę.',
        waitForNewCardHeader: 'Brak karty',
        enterReflection: 'Zapisz swoje refleksje',
        submit: 'OK',
        guidance: 'Wskazówki',
        replied: 'Odpowiedź',
        reflectionStateTextForGM: `Jesteśmy teraz w ostatniej fazie refleksji. 
        Poczekaj, aż wszyscy gracze zanotują swój potencjał główny i wydrukują lub zapiszą swoje refleksje. 
        Następnie można zakończyć grę, klikając „Zakończ grę”.`,
        roleplay: {
          myAssignment: 'Moje zadanie:',
          myRole: 'Moja rola:',
          noAssignment: 'Nie przypisano Ci żadnej roli',
          noRole: 'Brak roli',
        },
        answerText: {
          youAnswered: 'Udzieliłeś odpowiedzi',
          thisIs: 'To jest',
          correct: 'prawidłowo',
          incorrect: 'nieprawidłowo',
          points: 'Punkty',
          guidance: 'Wskazówki',
        },
      },
      teamCard: {
        score: 'Łączna liczba punktów:',
        role: 'Rola:',
        gamemaster: 'Lider gry',
        youreAboutToKickPlayer: 'Usuń gracza',
        areYouSure: 'Czy na pewno chcesz usunąć gracza?',
      },
      gmControls: {
        gameInfo: 'Informacje o grze',
        ok: 'Ok',
        stopGame: 'Zakończ grę',
        stopGameQuestion: 'Czy na pewno chcesz zakończyć grę?',
        reflectionDeletion: ['Czy poprosiłeś graczy o wydrukowanie ich refleksji?'],
        moveBack: 'Cofnij',
        moveForwards: 'Przesuń do przodu',
        drawCard: 'Wyciągnij kartę',
        showInfo: 'Wyświetl informacje',
        accept: 'Zaakceptuj',
        endGameReflectionsbtn: 'Ostatnie refleksje',
        gamename: 'Nazwa gry',
        gameId: 'Id. gry',
        sectionId: 'Id. fragmentu',
        sectionName: 'Nazwa fragmentu',
        availCards: 'Dostępne karty na stosie',
        currentCardId: 'Aktualny id. karty',
        teams: 'Zespoły:',
        yes: 'Tak',
        notEveryOneHeader: 'Nie wszyscy gracze zakończyli swoje refleksje',
        notEveryOneContent: 'Czy na pewno chcesz przesunąć dalej pionek?',
        cardIsAlreadyDrawnBody: 'Już wyciągnąłeś/-aś kartę. Czy na pewno chcesz wyciągnąć nową kartę?',
        cardIsAlreadyDrawnHeader: 'Już wyciągnąłeś/-aś kartę',
      },
    },
    others: { closeBtn: 'Zamknij' },
    errors: {
      notLoggedIn: 'Chyba nie jesteś zalogowany/-a. Kliknij tutaj, aby się zalogować.',
      reloadPage: 'Kliknij tutaj, aby ponownie wczytać stronę',
      goHome: 'Jeśli problem występuje nadal, przejdź do strony startowej i spróbuj ponownie.',
      loginBtn: 'Zaloguj',
      homeBtn: 'Pozycja podstawowa',
      reloadBtn: 'Wczytaj ponownie',
      login: 'Zaloguj',
    },
  },
};
