import React, { useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { ThemeContext } from '../../../App';
import { StringsContext } from '../../../locales';
import { engine } from '../../../redux/selectors';
import Timer from '../Timer/components';
import styles from './ChallengeCard.module.css';

interface Props {
  challengeMode: ChallengeMode;
  isGameOwner: boolean | undefined;
}

export function ChallengeCard(props: Props) {
  const currentGame = useSelector(engine.dnorm.currentGame);
  const cardId = props.challengeMode.cardId;
  const currentCard = currentGame?.cards.find((c) => c.id === cardId);

  const theme = useContext(ThemeContext);
  const strings = useContext(StringsContext);

  const [timerCountdown, setTimerCountdown] = useState<number>(0);
  const [textEnabled, setTextEnabled] = useState<boolean>(true);

  useEffect(() => {
    setTextEnabled(true);
  }, [props.challengeMode.cardId]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        padding: '20px',
      }}
    >
      <div
        style={{
          height: '300px',
          maxHeight: '500px',
          paddingRight: '0.5em',
        }}
        className={styles.fancyScroll}
      >
        <h3 style={{ color: theme.buttonColors.text, fontSize: '1.4rem' }}>
          {strings.screens.gamePage.challengeMode.title}
        </h3>
        <h5 style={{ fontSize: '1.1rem' }}>{currentCard?.body}</h5>
        <p style={{ fontSize: '1rem' }}>{currentCard?.question} </p>
      </div>
      <div
        style={{
          width: '100%',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
        <div className={styles.dot}></div>
      </div>
      {props.isGameOwner ? (
        <span style={{ marginBottom: '20px', fontStyle: 'italic' }}>
          <p> {strings.screens.gamePage.challengeMode.awaitTeams}</p>
        </span>
      ) : (
        <textarea
          style={{
            width: '100%',
            resize: 'none',
            border: `2px solid ${
              textEnabled ? theme.buttonColors.active : 'gray'
            }`,
            color: 'black',
            padding: '5px',
            backgroundColor: '#FAFAFA',
            marginBottom: '10px',
          }}
          className="reflectionCardPlaceholder"
          placeholder={`${strings.screens.gamePage.activeCard.enterReflection}...`}
          readOnly={!textEnabled}
        />
      )}
      <Timer
        time={timerCountdown}
        modal={false}
        cardTimeLimit={props.challengeMode.startTimeStamp}
        onSetCountDown={setTimerCountdown}
        onEndTime={() => setTextEnabled(false)}
        duration={currentCard?.timelimit || 60}
      />
    </div>
  );
}
