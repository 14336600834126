import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import TeamComponent from './TeamComponent';
import ScoreComponent from './ScoreComponent';
import ExpandButton from '../ExpandButton';
import { StringsConsumer } from '../../../locales';
import { mainColors } from '../../../utils';
import styles from './TeamComponent.module.css';
import HighlightText from '../HighlightText';
import { ThemeContext } from '../../../App';

interface Props {
  team: DTeam;
  defaultOpen: boolean | undefined;
  scrollHandler?: () => void;
  activeCard?: Card;
  allowExpandScore?: boolean;
  containerClassName?: string;
  shadows?: boolean;
  onPlayerRemoval?: (playerId: number, teamId: number) => void;
  teamState?: 'active' | 'upnext';
}

function TeamCard(props: Props) {
  const [teamOpen, setTeamOpen] = useState(props.defaultOpen);
  const [scoreOpen, setScoreOpen] = useState(false);
  const calculateContainerClassName = () => {
    if (props.containerClassName) {
      return props.containerClassName;
    }
    if (props.shadows === true) {
      return styles.teamCardWrapper;
    }
    return styles.teamCardWrapperNoShadow;
  };

  const handleTeamExpand = () => {
    setScoreOpen(false);
    setTeamOpen(!teamOpen);
    if (props.scrollHandler) {
      props.scrollHandler();
    }
  };

  const handleScoreExpand = () => {
    setTeamOpen(false);
    setScoreOpen(!scoreOpen);
    if (props.scrollHandler) {
      props.scrollHandler();
    }
  };

  const theme = React.useContext(ThemeContext);

  // will open the active teamcard, depending of whos team it is.
  useEffect(() => {
    if (props.teamState === 'active') {
      setTeamOpen(true);
      setScoreOpen(false);
    } else {
      setTeamOpen(false);
      setScoreOpen(false);
    }
  }, [props.teamState]);

  const printHighLightText = (activeText: string, nextText: string) => {
    if (props.teamState === 'active') {
      return (
        <HighlightText
          title={activeText}
          backgroundColor="gold"
          textColor="gray"
        />
      );
    }
    if (props.teamState === 'upnext') {
      return (
        <HighlightText
          title={nextText}
          backgroundColor="lightgray"
          textColor="gray"
        />
      );
    }
    return <div />;
  };

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <Container
            className={calculateContainerClassName()}
            style={{
              backgroundColor: mainColors.white,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                padding: '15px 15px 15px 20px',
                backgroundColor: theme.primary,
              }}
            >
              <span
                style={{
                  color: mainColors.white,
                  fontWeight: 'bold',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '80%',
                }}
                className="fontFamilyBold"
              >
                {props.team.name}
              </span>
              <ExpandButton
                onClick={handleTeamExpand}
                toCondense={teamOpen === false}
              />
            </div>
            {teamOpen === true && (
              <div
                style={{
                  transition: '0.5s',
                  padding: 0,
                  backgroundColor: mainColors.white,
                  borderBottom: `2px solid ${mainColors.lightGrey}`,
                }}
              >
                <TeamComponent
                  activeCard={props.activeCard}
                  teams={[props.team]}
                  removePlayer={props.onPlayerRemoval}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                padding: '15px 15px 15px 20px',
                backgroundColor: mainColors.white,
              }}
            >
              <span
                style={{
                  color: mainColors.black,
                  fontWeight: 'bold',
                  margin: 'auto 0px',
                }}
                className="fontFamilyBold"
              >
                {gamePage.teamCard.score} {props.team.score}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {props.teamState &&
                  printHighLightText(
                    gamePage.currentTeamTurn,
                    gamePage.upNextTeamTurn
                  )}
                {props.allowExpandScore && (
                  <ExpandButton
                    onClick={handleScoreExpand}
                    toCondense={scoreOpen === false}
                  />
                )}
              </div>
            </div>
            {scoreOpen === true && (
              <div
                style={{
                  transition: '0.5s',
                  borderTop: `2px solid ${mainColors.lightGrey}`,
                }}
              >
                <ScoreComponent team={props.team} />
              </div>
            )}
          </Container>
        );
      }}
    </StringsConsumer>
  );
}

export default TeamCard;
