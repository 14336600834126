import React from 'react';
import Markdown from '../../../../../../components/UI/Markdown';
import { StringsConsumer } from '../../../../../../locales';
import { mainColors } from '../../../../../../utils';

interface Props {
  points?: number;
  gameMasterGuidance: string | undefined;
  playerAnswer: string;
}

/* After answering, this element will show */
function AnswerText(props: Props) {
  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <div
            style={{ textAlign: 'left', marginTop: 20 }}
            className="pointsText"
          >
            {gamePage.activeCard.answerText.youAnswered} {props.playerAnswer}.{' '}
            {gamePage.activeCard.answerText.thisIs}
            {props.points && props.points > 0 ? (
              <span className="pointsText">
                {' '}
                {gamePage.activeCard.answerText.correct}.{' '}
                <span style={{ color: mainColors.green }}>
                  {props.points} {gamePage.activeCard.answerText.points}
                </span>
              </span>
            ) : (
              <span>
                {' '}
                {gamePage.activeCard.answerText.incorrect}.{' '}
                <span style={{ color: mainColors.brightRed }}>
                  {props.points} {gamePage.activeCard.answerText.points}
                </span>
              </span>
            )}
            {props.gameMasterGuidance && (
              <div>
                <span className="guidance-title">
                  {gamePage.activeCard.answerText.guidance}:{' '}
                </span>
                <span className="guidance-text">
                  <Markdown fontSize="16px" style={{ whiteSpace: 'pre-wrap' }}>
                    {props.gameMasterGuidance}
                  </Markdown>
                </span>
              </div>
            )}
          </div>
        );
      }}
    </StringsConsumer>
  );
}

export default AnswerText;
