import React, { useEffect, useRef } from 'react';
import styles from './ActiveCard.module.css';
import { mainColors } from '../../../utils/variables';
import AnswerBox, {
  AnswerBoxProps,
} from '../../../pages/main/browser/AnswerModal/components/AnswerBox';
import Timer from '../Timer';
import { StringsContext } from '../../../locales';
import Icon from '../Icon';
import { ThemeContext } from '../../../App';
import { ChallengeCard } from './ChallengeCard';
import { NoPlayersOnTeam } from './NoPlayersOnTeam';
import Markdown from '../Markdown';
import { TeamrolesContent } from '../Teamroles';

interface CardProps extends AnswerBoxProps {
  notYourTurnText: string;
  gameStatus: GameStatus | undefined;
  reflectionStateTextForGM: string;
  waitForNewCardText: string;
  boardPresentation: BoardPresentation;
  headerText?: string;
  currentSection: GameSection | undefined;
  sectionCard: DCardInstance | undefined;
  challengeMode: ChallengeMode | undefined;
}

export function CardContent(props: CardProps) {
  // We cant answer without a player on the team
  if (props.currentTeam?.players && props.currentTeam.players.length === 0) {
    return <NoPlayersOnTeam teamJoinCode={props.currentTeam.code} />;
  }
  if (props.challengeMode?.running && props.challengeMode.cardId) {
    return (
      <div className={styles.activeCardContainer}>
        <ChallengeCard
          challengeMode={props.challengeMode}
          isGameOwner={props.isGameOwner}
        />
      </div>
    );
  }

  return (
    <div className={styles.activeCardContainer}>
      {props.activeCard?.body ||
      props.sectionCard ||
      props.challengeMode?.cardId ? (
        <Content {...props} />
      ) : (
        <p
          className={styles.question}
          style={{
            color: mainColors.black,
            backgroundColor: 'white',
            display: 'flex',
            marginRight: 0,
            marginLeft:
              props.boardPresentation === 'standard' ? '-10px' : '0px',
            alignSelf: 'center',
            padding:
              props.boardPresentation === 'standard'
                ? '20px 0px 0px 0px'
                : '20px',
            width: '100%',
          }}
        >
          {props.gameStatus === 'endgame'
            ? props.reflectionStateTextForGM
            : props.waitForNewCardText}
        </p>
      )}
    </div>
  );
}

function Content(props: CardProps) {
  const { activeCard, boardPresentation, sectionCard, currentSection } = props;

  const card = activeCard || sectionCard;

  const theme = React.useContext(ThemeContext);

  const shouldShowTimer =
    card &&
    card.timelimit &&
    (card.replies === undefined || card.replies?.length === 0) &&
    activeCard; // we dont want it on section cards

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef?.current) {
      //@ts-ignore
      myRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [card?.id]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
        {boardPresentation === 'card_focused' && (
          <div className={styles.fade}></div>
        )}
        <div
          ref={myRef}
          className={
            boardPresentation === 'card_focused'
              ? styles.cardTextBox
              : undefined
          }
        >
          <div className={styles.CardTitleWrapper}>
            {currentSection?.gameTokens &&
              currentSection.gameTokens.length > 0 && (
                <span>
                  <Icon
                    size="medium"
                    color={theme.primary}
                    icon={currentSection.gameTokens[0].icon}
                  />
                </span>
              )}
            {currentSection && props.boardPresentation === 'card_focused' && (
              <h2 className={styles.titleWrapper}>{currentSection.name}</h2>
            )}
          </div>
          <h5 className="ActiveCardQuestion">
            <Markdown
              fontSize="18px"
              bold
              style={{ marginTop: '8px', whiteSpace: 'pre-wrap' }}
            >
              {card?.body}
            </Markdown>
          </h5>
          <p className="ActiveCardQuestion">
            <Markdown
              bold
              style={{
                padding: '0px',
                margin: '20px 0px 0px 0px',
                whiteSpace: 'pre-wrap',
              }}
              fontSize="16px"
            >
              {card?.question}
            </Markdown>
          </p>

          <div style={{ marginBottom: '15px' }} />
          <AnswerBox {...props} isModal={false} />
        </div>
      </div>
      {shouldShowTimer && (
        <CountDown
          timelimit={card?.timelimit}
          title={
            boardPresentation === 'card_focused' ? props.headerText : undefined
          }
          sectionName={currentSection?.name}
        />
      )}
      {props.activeCard && !props.activeCard.isMyTurn && !props.isGameOwner && (
        <p
          style={{
            backgroundColor: theme.primary,
            color: theme.buttonColors.active,
            padding: '16px',
            fontWeight: 'bold',
          }}
          className={styles.notYourTurnText}
        >
          {props.notYourTurnText}
        </p>
      )}
    </div>
  );
}

interface CountDownProps {
  timelimit: number | undefined;
  // Giving a title, will change the looks of the component
  title?: string;
  sectionName?: string;
}

function CountDown({ title, timelimit, sectionName }: CountDownProps) {
  const { screens } = React.useContext(StringsContext);
  const theme = React.useContext(ThemeContext);
  if (title) {
    return (
      <div
        style={{
          marginTop: '20px',
          color: 'white',
        }}
      >
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
          }}
        >
          <div>
            <h3>{title}</h3>
            <p>
              {`${screens.gamePage.activeCard.timer.first} ${timelimit} ${screens.gamePage.activeCard.timer.second}`}
            </p>
          </div>
          <span style={{ margin: 'auto 0 auto auto' }}>
            <Icon
              icon="timer"
              size="medium"
              color={theme.buttonColors.active}
            />
          </span>
        </div>
        <Timer
          duration={timelimit || 60}
          showControls={false}
          modal={false}
          variant="small"
          sectionName={undefined}
          endTimeMessage={screens.gamePage.timer.timesUp}
        />
      </div>
    );
  }
  return (
    <div
      style={{
        marginTop: '20px',
      }}
    >
      <Timer duration={timelimit || 60} showControls={false} modal={false} />
    </div>
  );
}
