import React, { useContext, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Dropdown } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './navigation.module.css';
import { mainColors } from '../utils/variables';
import { StringsConsumer } from '../locales';
import { ThemeContext } from '../App';
import TeamCodesDropdown from './UI/TeamCodesDropdown';
import { Logo } from './UI';
import { useAllowedGameCreation } from '../hooks/useAllowedGameCreation';

interface Props {
  username?: string;
  onPressLogout: () => void;
  isGameMaster?: boolean;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  activeGame: GameInstance | undefined;
  avatar: string;
  isGuest?: boolean;
}

function Navigation(props: Props) {
  // DropDown Menu doesn't work in IE so this code was added to ensure that onclick DropDown Menu is shown;
  const [show, setShow] = useState<boolean>(false);

  const { canCreateGames } = useAllowedGameCreation({
    hasGameMasterRole: props.isGameMaster,
  });

  const theme = useContext(ThemeContext);

  const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
  const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

  const clickHandler = () => {
    setShow(!show);
  };

  let dropDownMenu = 'custom-dropdown-menu';

  if (isIE) {
    if (show) {
      dropDownMenu += ' showDropDown';
    } else {
      dropDownMenu += ' closeDropDown';
    }
  }
  return (
    <StringsConsumer>
      {({ screens: { navigation } }) => {
        return (
          <Navbar
            style={{
              backgroundColor: mainColors.black,
              height: '55px',
              padding: '0px',
            }}
            expand="lg"
            className="py-0 navbar-dark"
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                paddingLeft: '16px',
              }}
            >
              <Logo href="/" />
              {props.isAuthenticated && props.isEmailVerified === false && (
                <div
                  className={
                    isIE
                      ? [styles.text, styles.gameTitle, styles.gameNameIE].join(
                          ' '
                        )
                      : [styles.text, styles.gameTitle].join(' ')
                  }
                  style={{
                    color: mainColors.white,
                  }}
                  onClick={props.onPressLogout}
                  role="button"
                >
                  {navigation.logOut}
                </div>
              )}

              {props.activeGame && (
                <div
                  className={
                    isIE
                      ? [styles.text, styles.gameTitle, styles.gameNameIE].join(
                          ' '
                        )
                      : [styles.text, styles.gameTitle].join(' ')
                  }
                  style={{
                    color: mainColors.white,
                  }}
                >
                  <span
                    className={styles.gameTitle}
                    style={{
                      color: mainColors.white,
                      fontWeight: 'bold',
                      fontSize: '24px',
                    }}
                  >
                    &nbsp;
                    {props.activeGame?.name}
                  </span>
                </div>
              )}
            </div>
            {props.isAuthenticated && props.isEmailVerified && !props.isGuest && (
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{
                  padding: '2px 4px',
                  marginRight: '16px',
                }}
              />
            )}

            {props.isAuthenticated && props.isEmailVerified && !props.isGuest && (
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
                style={{
                  height: '55px',
                  position: 'relative',
                  zIndex: 1,
                  padding: '0 6px 0 0',
                  backgroundColor: '#000',
                }}
              >
                {props.activeGame?.state?.teams &&
                  canCreateGames &&
                  theme.content.showTeamCodesDropDown && (
                    <TeamCodesDropdown teams={props.activeGame?.state?.teams} />
                  )}
                <Nav>
                  <NavigationItem to="/" title={navigation.home} />

                  {canCreateGames && (
                    <NavigationItem
                      to="/GameCreation"
                      title={navigation.createGame}
                    />
                  )}
                  <NavigationItem to="/JoinGame" title={navigation.joinGame} />
                  <Dropdown
                    className="custom-dropdown"
                    onClick={() => {
                      if (isIE) {
                        clickHandler();
                      }
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Dropdown.Toggle className="custom-dropdown-toggle">
                        <span
                          style={{
                            marginRight: '5px',
                          }}
                        >
                          {props.username}
                        </span>
                        <Image
                          className={styles.avatar}
                          src={props.avatar}
                          roundedCircle
                        />
                      </Dropdown.Toggle>
                    </div>
                    <Dropdown.Menu className={dropDownMenu}>
                      <Dropdown.Item
                        href="/Settings"
                        className="custom-dropdown-item"
                      >
                        {navigation.settings}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={props.onPressLogout}
                        className="custom-dropdown-item"
                      >
                        {navigation.logOut}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            )}
          </Navbar>
        );
      }}
    </StringsConsumer>
  );
}
export default Navigation;

interface NavigationItemProps {
  to: string;
  title: string;
}

function NavigationItem({ to, title }: NavigationItemProps) {
  return (
    <LinkContainer to={to}>
      <Nav.Link className={[styles.text, styles.alignContent].join(' ')}>
        <span
          style={{
            color: mainColors.white,
          }}
        >
          {title}
        </span>
      </Nav.Link>
    </LinkContainer>
  );
}
