import * as React from 'react';
export function useGameTemplate() {
  const mobileWidth = 1280;

  const [showMobile, setShowMobile] = React.useState(
    window.innerWidth < mobileWidth
  );

  React.useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < mobileWidth) {
        setShowMobile(true);
      } else if (window.innerWidth > mobileWidth) {
        setShowMobile(false);
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { showMobile };
}
