import { createSelector } from 'reselect';
import {
  filterRunningGameInstancesResult,
  dnormGameInstanceSummaryResult,
  dnormFilterRunningGameInstancesResult,
} from './resultFunctions';
import { account } from '../account'; // account selectors.

// Simple selectors
const allGameInstanceSummariesSelector = (state: AppState):
GameInstanceSummary[] | undefined => state.browser.gameInstances;

// Results Selectors
const allRunningGameInstanceSummariesSelector = createSelector(
  allGameInstanceSummariesSelector, filterRunningGameInstancesResult,
);

// Dnorm selectors
const dnormSummariesSelector = createSelector(
  allGameInstanceSummariesSelector,
  account.me,
  dnormGameInstanceSummaryResult,
);

const dnormRunningGameSummarySelector = createSelector(
  dnormSummariesSelector, dnormFilterRunningGameInstancesResult,
);

export const browser = {
  availableGameInstanceSummaries: allGameInstanceSummariesSelector,
  runningGameInstanceSummaries: allRunningGameInstanceSummariesSelector,
  dnorm: { gameSummaries: dnormSummariesSelector, runningGameSummaries: dnormRunningGameSummarySelector },
};
