import { useBrowserInfo } from '.';
import { CompanyThemes, ContentTheme } from '../utils';

interface Props {
    companyThemes: CompanyThemes;
}

// todo, ensure that this is correct.
enum Urls {
    erstStage = 'erst',
    erst = 'dilemmaspillet',
    mmsPFourC = 'mms-p4c',
    mms = 'webapp',
    mmsBase = 'mms',
}

export function useCompanyCodeHelper(): string | undefined {
    const { url } = useBrowserInfo();

    // USE A QUERY STRING PARSER -- also fix the theme helper thing
    if (url.includes(Urls.erst) || url.includes(Urls.erstStage)) {
        return 'MhcHP5KywTZ_mVb3ljvk8FoD';
    }

    // default
    return undefined;
}
