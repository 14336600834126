import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Timer from './components';
import { TOAST_NOTIFICATION_OPEN } from '../../../redux/actions';
import { engine } from '../../../redux/selectors';

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onEndTime: (message: string) =>
    dispatch(
      TOAST_NOTIFICATION_OPEN.create({
        content: message,
        appearance: 'info',
        autoDismiss: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
