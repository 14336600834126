import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import styles from './TeamComponent.module.css';
import { mainColors } from '../../../utils/variables';
import PlayerBox from '../PlayerBox';
import { engine } from '../../../redux/selectors';
import { StringsConsumer } from '../../../locales';

interface Props {
  teams?: DTeam[];
  activeCard?: Card;
  removePlayer?: (playerId: number, teamId: number) => void;
}
function TeamComponent(props: Props) {
  const gameOwnerId = useSelector(
    (state: AppState) => state.engine.game?.owner
  );
  const amIGameOwner = useSelector(engine.amIGameOwner);
  const onRemovePlayer = (pid: number, teamId: number) => {
    if (props.removePlayer) {
      props.removePlayer(pid, teamId);
    }
  };

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <Container
            style={{
              backgroundColor: mainColors.white,
              padding: '0px',
            }}
          >
            <div>
              {props.teams?.map((team) => {
                const { players } = team;
                return (
                  <div key={`team-${team.id}`}>
                    <div
                      style={{
                        backgroundColor: mainColors.white,
                        overflowX: 'auto',
                        margin: '5px',
                      }}
                      className={styles.flexContainer}
                    >
                      {players.length === 0 && (
                        <div
                          style={{
                            marginLeft: '5px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              marginTop: '10px',
                              textAlign: 'center',
                            }}
                          >
                            {gamePage.noPlayersPart1} <b>{team.code}</b>{' '}
                            {gamePage.noPlayersPart2}
                          </p>
                        </div>
                      )}
                      {players?.map((player, idx) => {
                        return (
                          <div
                            key={`players-${idx}`}
                            style={{
                              display: 'flex',
                              flexFlow: 'row nowrap',
                              alignItems: 'center',
                            }}
                          >
                            <PlayerBox
                              cardType={props.activeCard?.type}
                              player={player}
                              isGameOwner={gameOwnerId === player.id}
                              showRemoveBtn={
                                !!props.removePlayer && amIGameOwner
                              }
                              onRemoveBtnClick={() =>
                                onRemovePlayer(player.id, team.id)
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>
        );
      }}
    </StringsConsumer>
  );
}

export default TeamComponent;
