import React, { useContext, useState } from 'react';
import styles from '../../TeamCards/TeamComponent.module.css';
import { ThemeContext } from '../../../../App';
import { StringsContext } from '../../../../locales';
import { Dispatch } from 'redux';
import { engine } from '../../../../redux/selectors';
import { ENGINE_MOVE_GAME_SECTION_REQUEST } from '../../../../redux/actions';
import { connect } from 'react-redux';

/*INFO */
// This component is used as a replacement for dice button, when entering the game.

interface Props {
  isGameOwner?: boolean;
  onMoveSection: () => void;
}

function StartGameBtn({ isGameOwner, onMoveSection }: Props) {
  const theme = useContext(ThemeContext);
  const strings = useContext(StringsContext);
  const [clicked, setClicked] = useState(false);

  const onPress = () => {
    setClicked(true);
    onMoveSection();
  };

  return (
    <button
      className={styles.diceButton}
      style={{
        backgroundColor: theme.buttonColors.active,
        overflow: 'visble',
      }}
      disabled={!isGameOwner || clicked}
      onClick={onPress}
    >
      <p
        className={styles.dice_number}
        style={{ color: theme.buttonColors.text }}
      >
        {strings.screens.gamePage.gmControls.startGame}
      </p>
    </button>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    isGameOwner: engine.amIGameOwner(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onMoveSection: () => dispatch(ENGINE_MOVE_GAME_SECTION_REQUEST.create()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartGameBtn);
