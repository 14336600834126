import React, { useContext } from 'react';
import { ThemeContext } from '../../../App';
import { StringsContext } from '../../../locales';

interface Props {
  teamJoinCode: string;
}

export const NoPlayersOnTeam = ({ teamJoinCode }: Props) => {
  const theme = useContext(ThemeContext);
  const strings = useContext(StringsContext);

  return (
    <p
      style={{
        backgroundColor: 'white',
        margin: '0px 30px 30px 30px',
        padding: '20px',
      }}
    >
      {strings.screens.gamePage.activeCard.noPlayers.noPlayers}
      <br />
      {strings.screens.gamePage.activeCard.noPlayers.pleaseJoin}:<br />
      {teamJoinCode}
    </p>
  );
};
