import {
  AUTH0_LOGOUT_REQUEST,
  AUTH0_REFRESH_ACCESSTOKEN_SUCCESS,
  AUTH_ACCESS_TOKEN_SET,
} from '../actions';

interface State {
  isAuthenticated: boolean;
  accessToken: string;
  emailVerified: boolean;
  isGuest: boolean;
}

const initialState: State = {
  isAuthenticated: false,
  accessToken: '',
  emailVerified: false,
  isGuest: false,
};

const authReducer = (
  state: State = initialState,
  action: GenericAction
): State => {
  switch (action.type) {
    case AUTH0_LOGOUT_REQUEST.type: {
      return {
        ...state,
        accessToken: '',
        isAuthenticated: false,
      };
    }
    case AUTH0_REFRESH_ACCESSTOKEN_SUCCESS.type: {
      const payload = AUTH0_REFRESH_ACCESSTOKEN_SUCCESS.payload(action);
      return {
        ...state,
        emailVerified: payload.emailVerified,
        accessToken: payload.accessToken,
        isAuthenticated: true,
      };
    }
    case AUTH_ACCESS_TOKEN_SET.type: {
      const payload = AUTH_ACCESS_TOKEN_SET.payload(action);
      return {
        ...state,
        isAuthenticated: true,
        accessToken: payload.accessToken,
        emailVerified: true,
        isGuest: payload.isGuest,
      };
    }
    default: {
      return state;
    }
  }
};

export const auth = authReducer;
