import React from 'react';
import { useSelector } from 'react-redux';
import { StringsConsumer } from '../../locales';
import Button from './Button';

interface Props {
  message?: string;
}

export function NoAccess(props: Props) {
  const loggedIn = useSelector((state: AppState) => state.account.profile);
  return (
    <StringsConsumer>
      {({ screens: { errors } }) => {
        return (
          <div
            style={{
              textAlign: 'center',
              paddingTop: '10vh',
              paddingBottom: '50vh',
              alignItems: 'center',
            }}
          >
            {!loggedIn ? (
              <div>
                <h2 style={{ paddingBottom: '10px' }}>{errors.notLoggedIn}</h2>
                <Button
                  title={errors.login}
                  onClick={() => window.location.replace('/')}
                  style={{ margin: 'auto' }}
                />
              </div>
            ) : (
              <div>
                <h2>{props.message}</h2>
                <h3 style={{ padding: '10px' }}>{errors.reloadPage}</h3>
                <Button
                  title={errors.reloadBtn}
                  onClick={() => window.location.reload()}
                  style={{ margin: 'auto' }}
                />
                <br />
                <h3 style={{ padding: '10px' }}>{errors.goHome}</h3>
                <Button
                  title={errors.homeBtn}
                  onClick={() => window.location.replace('/')}
                  style={{ margin: 'auto' }}
                />
              </div>
            )}
          </div>
        );
      }}
    </StringsConsumer>
  );
}

export default NoAccess;
