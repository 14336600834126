/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

interface AccessToken {
  accessToken: string;
  emailVerified: boolean;
}

export const AUTH0_REFRESH_ACCESSTOKEN_REQUEST = new ActionCreator<null>(
  'AUTH0_REFRESH_ACCESSTOKEN_REQUEST'
);
export const AUTH0_REFRESH_ACCESSTOKEN_SUCCESS = new ActionCreator<AccessToken>(
  'AUTH0_REFRESH_ACCESSTOKEN_SUCCESS'
);
export const AUTH0_REFRESH_ACCESSTOKEN_FAILURE = new ActionCreator<Error>(
  'AUTH0_REFRESH_ACCESSTOKEN_FAILURE'
);

export const AUTH0_AUTHENTICATE_REQUEST = new ActionCreator<string | void>(
  'AUTH0_AUTHENTICATE_REQUEST'
);

export const AUTH0_LOGOUT_REQUEST = new ActionCreator<null>(
  'AUTH0_LOGOUT_REQUEST'
);

export const AUTH_ACCESS_TOKEN_SET = new ActionCreator<{
  accessToken: string;
  isGuest: boolean;
}>('AUTH_ACCESS_TOKEN_SET');
