import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './PopUpBox.module.css';
import { mainColors } from '../../utils/variables';
import { StringsConsumer } from '../../locales';
import { ThemeContext } from '../../App';
import Button from './Button';

interface Props {
  onClose?: () => void;
  onAction?: () => void;
  show: boolean;
  header?: string;
  content?: string;
  links?: string[];
  multipleLinesContent?: string[];
  type: 'alert' | 'confirm';
  actionButtonText: string;
  checkBox?: boolean;
}

function PopUpBox(props: Props) {
  const [checked, setChecked] = useState(false);

  const theme = React.useContext(ThemeContext);

  const handleConfirmClick = () => {
    if (props.onAction) {
      props.onAction();
      if (props.onClose) props.onClose();
    }
  };

  const buttonLayout = () => {
    switch (props.type) {
      case 'confirm': {
        return (
          <StringsConsumer>
            {({ screens: { others } }) => {
              return (
                <Modal.Footer>
                  {props.onClose && (
                    <Button title={others.closeBtn} onClick={props.onClose} />
                  )}
                  <Button
                    onClick={handleConfirmClick}
                    disabled={props.checkBox === true && checked === false}
                    title={props.actionButtonText}
                  />
                </Modal.Footer>
              );
            }}
          </StringsConsumer>
        );
      }
      case 'alert': {
        return (
          <Modal.Footer>
            {props.onClose && (
              <Button
                onClick={props.onClose}
                disabled={props.checkBox === true && checked === false}
                title={props.actionButtonText}
              />
            )}
          </Modal.Footer>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.container}>
      <Modal
        show={props.show}
        onHide={props.onClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton={!!props.onClose}>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ marginRight: '30px' }}>
            <p style={{ fontSize: '16px' }}>
              {props.content}
              {props.multipleLinesContent?.map((el, ind) => {
                return (
                  <span key={`el-${ind}`} style={{ fontSize: '16px' }}>
                    {el}
                    <br />
                  </span>
                );
              })}
              {props.links?.map((el, ind) => {
                return (
                  <a
                    key={`links-${ind}`}
                    href={el}
                    style={{ fontSize: '12px' }}
                    target="_blank"
                  >
                    {el}
                    <br />
                  </a>
                );
              })}
            </p>
          </div>
          {props.checkBox && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <form style={{ position: 'relative' }}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className={styles.checkbox}
                  id="checkbox"
                  style={{
                    backgroundColor: checked
                      ? theme.buttonColors.active
                      : theme.buttonColors.disabled,
                    border: `1px solid ${mainColors.black}`,
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="checkbox"
                  className={styles.checkboxLabel}
                  style={{
                    color: mainColors.white,
                  }}
                />
              </form>
            </div>
          )}
        </Modal.Body>
        {buttonLayout()}
      </Modal>
    </div>
  );
}

export default PopUpBox;
