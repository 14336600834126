// GAME FACTORY SAGA
import { takeEvery, put, take, select } from 'redux-saga/effects';
import { getStringsUnsafe } from '../../../locales';

import {
  API_TEMPLATE_AVAILABLE_FAILURE,
  API_TEMPLATE_AVAILABLE_REQUEST,
  API_TEMPLATE_AVAILABLE_SUCCESS,
  APP_ERROR_DETECTED,
  FACTORY_TEMPLATES_AVAILABLE_FAILURE,
  FACTORY_TEMPLATES_AVAILABLE_REQUEST,
  FACTORY_TEMPLATES_AVAILABLE_SUCCESS,
  FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST,
  FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS,
  FACTORY_DRAFT_GET_PLAYERS_REQUEST,
  FACTORY_COMPANY_PLAYERS_UPDATE,
  API_COMPANY_PLAYERS_GET_REQUEST,
  API_COMPANY_PLAYERS_GET_SUCCESS,
  API_COMPANY_PLAYERS_GET_FAILURE,
  FACTORY_SELECT_TOGGLE_PLAYER,
  FACTORY_SELECT_UPDATE_SELECTED,
  FACTORY_JOIN_GAME_REQUEST,
  API_GAME_JOIN_REQUEST,
  API_GAME_JOIN_SUCCESS,
  API_GAME_JOIN_FAILURE,
  TOAST_NOTIFICATION_OPEN,
  FACTORY_DRAFT_SUBMIT_REQUEST,
  FACTORY_DRAFT_SUBMIT_SUCCESS,
  FACTORY_DRAFT_SUBMIT_FAILURE,
  API_CREATE_GAME_INSTANCE_REQUEST,
  API_CREATE_GAME_INSTANCE_SUCCESS,
  API_CREATE_GAME_INSTANCE_FAILURE,
  FACTORY_DRAFT_SET_COOP_REQUEST,
  FACTORY_DRAFT_SET_COOP_SUCCESS,
  FACTORY_INSTANCE_LOAD_REQUEST,
  API_GAME_INSTANCE_GET_REQUEST,
  API_GAME_INSTANCE_GET_SUCCESS,
  API_GAME_INSTANCE_GET_FAILURE,
  FACTORY_INSTANCE_LOAD_SUCCESS,
  FACTORY_INSTANCE_LOAD_FAILURE,
  ENGINE_GAME_INSTANCE_HOLDER_UPDATE,
  APP_START_NAVIGATION_UPDATE,
  FACTORY_DRAFT_CLEAR_UPDATE,
  FACTORY_TEAM_ICON_UPDATE,
  FACTORY_TEAM_ICON_SUCCESS,
  FACTORY_TEAM_NAME_UPDATE,
  FACTORY_TEAM_NAME_SUCCESS,
  FACTORY_TEAM_NAME_FAILURE,
  FACTORY_TEAM_ICON_FAILURE,
  FACTORY_ANON_GAME_JOIN_REQUEST,
  API_CREATE_ANON_USER_REQUEST,
  API_CREATE_ANON_USER_SUCCESS,
  API_CREATE_ANON_USER_FAILURE,
  FACTORY_ANON_GAME_JOIN_FAILURE,
  AUTH_ACCESS_TOKEN_SET,
  FACTORY_TEAMS_SET,
} from '../../actions';
import { account, app, factory } from '../../selectors';

function* factoryRequestHandler(action: GenericAction) {
  switch (action.type) {
    case FACTORY_TEMPLATES_AVAILABLE_REQUEST.type: {
      const companyId: number | null | undefined = yield select(
        account.profileCompanyId
      );

      const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });
      if (companyId) {
        const types = 'web'; // move to app state later.
        const companyCode: string = yield select(app.getCompanyCode);

        yield put(
          API_TEMPLATE_AVAILABLE_REQUEST.create(
            {
              companyId,
              params: {
                types,
                companyCode,
              },
            },
            {
              profileLabel: 'defaultGet',
              onTimeoutAction,
            }
          )
        );
      }
      const response: GenericAction = yield take([
        API_TEMPLATE_AVAILABLE_SUCCESS.type,
        API_TEMPLATE_AVAILABLE_FAILURE.type,
      ]);

      try {
        const payload = API_TEMPLATE_AVAILABLE_SUCCESS.payload(response);
        yield put(FACTORY_TEMPLATES_AVAILABLE_SUCCESS.create(payload));
      } catch (e: any) {
        yield put(
          FACTORY_TEMPLATES_AVAILABLE_FAILURE.create({
            name: 'factory.templates.available.failure',
            message: e.message,
          })
        );
      }

      break;
    }

    case FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST.type: {
      const payload = FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST.payload(action);

      const teams = [];
      const min = payload.minimumNumberOfTeams || 1;
      for (let index = 0; index < min; index++) {
        teams.push({ emails: [] });
      }
      yield put(FACTORY_TEAMS_SET.create({ teams }));

      yield put(FACTORY_DRAFT_SET_GAME_TEMPLATE_SUCCESS.create(payload));
      break;
    }

    case FACTORY_DRAFT_GET_PLAYERS_REQUEST.type: {
      try {
        const companyId: number | null | undefined = yield select(
          account.profileCompanyId
        );
        const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });
        if (companyId) {
          yield put(
            API_COMPANY_PLAYERS_GET_REQUEST.create(companyId, {
              profileLabel: 'defaultGet',
              onTimeoutAction,
            })
          );
        }
        const companyPlayersAction: GenericAction = yield take([
          API_COMPANY_PLAYERS_GET_SUCCESS.type,
          API_COMPANY_PLAYERS_GET_FAILURE.type,
        ]);

        const companyPlayers =
          API_COMPANY_PLAYERS_GET_SUCCESS.payload(companyPlayersAction);
        yield put(FACTORY_COMPANY_PLAYERS_UPDATE.create(companyPlayers));
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      break;
    }

    case FACTORY_SELECT_TOGGLE_PLAYER.type: {
      const playerId = FACTORY_SELECT_TOGGLE_PLAYER.payload(action);
      // @ts-ignore
      const availablePlayers = yield select(factory.availablePlayers);
      // @ts-ignore
      const selectedPlayers = yield select(factory.selectedPlayers);

      // add or remove player.
      const isSelected = !!selectedPlayers.find(
        (player: Player) => player.id === playerId
      );

      let updatedSelection;
      if (isSelected) {
        // remove player from selection.
        updatedSelection = selectedPlayers.filter(
          (player: Player) => player.id !== playerId
        );
      } else {
        // add player to selection.
        const selectedPlayer = availablePlayers.filter(
          (player: Player) => player.id === playerId
        );
        updatedSelection = [...selectedPlayers, ...selectedPlayer];
      }

      yield put(FACTORY_SELECT_UPDATE_SELECTED.create(updatedSelection));

      break;
    }
    case FACTORY_JOIN_GAME_REQUEST.type: {
      const { sagaTranslate } = getStringsUnsafe();
      const payload = FACTORY_JOIN_GAME_REQUEST.payload(action);
      yield put(API_GAME_JOIN_REQUEST.create({
        code: payload.gameCode,
      }));
      // @ts-ignore
      const response = yield take([
        API_GAME_JOIN_SUCCESS.type,
        API_GAME_JOIN_FAILURE.type,
      ]);

      if (response.type === API_GAME_JOIN_SUCCESS.type) {
        const responsePayload = API_GAME_JOIN_SUCCESS.payload(response);
        yield put(ENGINE_GAME_INSTANCE_HOLDER_UPDATE.create(responsePayload));
        window.location.replace(`/Game/${responsePayload.id}`);
      } else {
        const responsePayload = API_GAME_JOIN_FAILURE.payload(response);

        let content = '';
        switch (responsePayload.status) {
          case 404: {
            content = sagaTranslate.id17;
            break;
          }
          case 403: {
            content = sagaTranslate.id20;
            break;
          }
          default:
            content = sagaTranslate.id1;
        }

        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: content,
            appearance: 'info',
            autoDismiss: true,
            timeout: 8000,
          })
        );
      }
      break;
    }

    case FACTORY_DRAFT_SUBMIT_REQUEST.type: {
      try {
        // @ts-ignore
        const gameDraft = yield select(
          (store: AppState) => store.factory.gameDraft
        );

        const modifiedGameDraft = {
          ...gameDraft,
          gameTemplate: gameDraft.gameTemplate.id,
        };

        const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });

        yield put(
          API_CREATE_GAME_INSTANCE_REQUEST.create(modifiedGameDraft, {
            profileLabel: 'defaultPost',
            onTimeoutAction,
          })
        );

        const response: GenericAction = yield take([
          API_CREATE_GAME_INSTANCE_SUCCESS.type,
          API_CREATE_GAME_INSTANCE_FAILURE.type,
        ]);

        const newGameInstance =
          API_CREATE_GAME_INSTANCE_SUCCESS.payload(response);
        yield put(FACTORY_DRAFT_SUBMIT_SUCCESS.create(newGameInstance));
        yield put(ENGINE_GAME_INSTANCE_HOLDER_UPDATE.create(newGameInstance));
      } catch (error: any) {
        yield put(
          FACTORY_DRAFT_SUBMIT_FAILURE.create({
            name: 'factory.draft.submit.failure',
            message: error.message,
          })
        );
      }
      break;
    }

    case FACTORY_DRAFT_SET_COOP_REQUEST.type: {
      // @ts-ignore
      const teamsHolder = yield select(
        (store: AppState) => store.factory.teamsHolder
      );
      // @ts-ignore
      const gameDraft = yield select(
        (store: AppState) => store.factory.gameDraft
      );
      const newGameDraft: GameDraft = {
        ...gameDraft,
        type: 'gameMasterSingleTeam',
        teams: teamsHolder,
      };
      yield put(FACTORY_DRAFT_SET_COOP_SUCCESS.create(newGameDraft));

      yield put(FACTORY_DRAFT_SUBMIT_REQUEST.create(undefined));
      // @ts-ignore
      const response = yield take([
        FACTORY_DRAFT_SUBMIT_SUCCESS.type,
        FACTORY_DRAFT_SUBMIT_FAILURE.type,
      ]);

      if (response.type === FACTORY_DRAFT_SUBMIT_SUCCESS.type) {
        yield put(APP_START_NAVIGATION_UPDATE.create(true));
        // clear gamedraft
        yield put(FACTORY_DRAFT_CLEAR_UPDATE.create(undefined));

        yield put(APP_START_NAVIGATION_UPDATE.create(false));
      }
      if (response.type === FACTORY_DRAFT_SUBMIT_FAILURE.type) {
        const errorPayload = FACTORY_DRAFT_SUBMIT_FAILURE.payload(response);
        // call notif
        yield put(
          TOAST_NOTIFICATION_OPEN.create({
            content: errorPayload.message,
            appearance: 'error',
            autoDismiss: true,
          })
        );
      }
      break;
    }
    case FACTORY_INSTANCE_LOAD_REQUEST.type: {
      const payload = FACTORY_INSTANCE_LOAD_REQUEST.payload(action);
      const onTimeoutAction = () => APP_ERROR_DETECTED.create({ action });

      yield put(
        API_GAME_INSTANCE_GET_REQUEST.create(payload, {
          profileLabel: 'defaultGet',
          onTimeoutAction,
        })
      );
      // @ts-ignore
      const response = yield take([
        API_GAME_INSTANCE_GET_SUCCESS.type,
        API_GAME_INSTANCE_GET_FAILURE.type,
      ]);

      try {
        const gameInstance = API_GAME_INSTANCE_GET_SUCCESS.payload(response);
        yield put(FACTORY_INSTANCE_LOAD_SUCCESS.create(gameInstance));
      } catch (e: any) {
        const error = API_GAME_INSTANCE_GET_FAILURE.payload(response);
        yield put(FACTORY_INSTANCE_LOAD_FAILURE.create(error));
      }
      break;
    }
    case FACTORY_TEAM_ICON_UPDATE.type: {
      const payload = FACTORY_TEAM_ICON_UPDATE.payload(action);
      try {
        // @ts-ignore
        const currentTeamsHolder = yield select(
          (state: AppState) => state.factory.teamsHolder
        );
        let updatedTeamsHolder = [];
        if (
          currentTeamsHolder.length === 0 ||
          payload.teamIndex - 1 > currentTeamsHolder.length
        ) {
          updatedTeamsHolder.push({
            emails: [],
            icon: payload.iconName,
          });
        } else {
          updatedTeamsHolder = [...currentTeamsHolder];

          updatedTeamsHolder[payload.teamIndex] = {
            ...updatedTeamsHolder[payload.teamIndex],
            icon: payload.iconName,
          };
        }
        yield put(
          FACTORY_TEAM_ICON_SUCCESS.create({ teamsHolder: updatedTeamsHolder })
        );
      } catch (error: any) {
        yield put(FACTORY_TEAM_ICON_FAILURE.create(error));
      }
      break;
    }
    case FACTORY_TEAM_NAME_UPDATE.type: {
      const payload = FACTORY_TEAM_NAME_UPDATE.payload(action);
      // @ts-ignore
      const currentTeamsHolder = yield select(
        (state: AppState) => state.factory.teamsHolder
      );

      try {
        let updatedTeamsHolder = [];
        updatedTeamsHolder = [...currentTeamsHolder];

        updatedTeamsHolder[payload.teamIndex] = {
          ...updatedTeamsHolder[payload.teamIndex],
          name: payload.name,
        };

        yield put(
          FACTORY_TEAM_NAME_SUCCESS.create({ teamsHolder: updatedTeamsHolder })
        );
      } catch (e: any) {
        yield put(FACTORY_TEAM_NAME_FAILURE.create(e));
      }
      break;
    }
    case FACTORY_TEAM_NAME_FAILURE.type: {
      const payload = FACTORY_TEAM_NAME_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case FACTORY_TEAM_ICON_FAILURE.type: {
      const payload = FACTORY_TEAM_ICON_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case FACTORY_INSTANCE_LOAD_FAILURE.type: {
      const payload = FACTORY_INSTANCE_LOAD_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'error',
          autoDismiss: true,
        })
      );
      break;
    }
    case FACTORY_ANON_GAME_JOIN_REQUEST.type: {
      const payload = FACTORY_ANON_GAME_JOIN_REQUEST.payload(action);
      // register
      yield put(API_CREATE_ANON_USER_REQUEST.create({ name: payload.name }));
      // @ts-ignore
      const response = yield take([
        API_CREATE_ANON_USER_SUCCESS.type,
        API_CREATE_ANON_USER_FAILURE.type,
      ]);

      if (response.type === API_CREATE_ANON_USER_SUCCESS.type) {
        const resp = API_CREATE_ANON_USER_SUCCESS.payload(response);
        yield put(
          AUTH_ACCESS_TOKEN_SET.create({ accessToken: resp.jwt, isGuest: true })
        );
        window.localStorage.setItem('guestToken', resp.jwt);
        // Join the game
        yield put(
          FACTORY_JOIN_GAME_REQUEST.create({
            gameCode: payload.gameCode,
            name: payload.name,
          })
        );
      } else {
        const resp = API_CREATE_ANON_USER_FAILURE.payload(response);
        yield put(
          FACTORY_ANON_GAME_JOIN_FAILURE.create({
            name: 'FACTORY_ANON_GAME_JOIN_FAILURE',
            message: 'Failed creating an ano user',
          })
        );
      }

      break;
    }
    default:
  }

  return undefined;
}

export function* factorySaga() {
  yield takeEvery(
    [
      FACTORY_TEMPLATES_AVAILABLE_REQUEST.type,
      FACTORY_DRAFT_SET_GAME_TEMPLATE_REQUEST.type,
      FACTORY_DRAFT_GET_PLAYERS_REQUEST.type,
      FACTORY_SELECT_TOGGLE_PLAYER.type,
      FACTORY_JOIN_GAME_REQUEST.type,
      FACTORY_DRAFT_SET_COOP_REQUEST.type,
      FACTORY_DRAFT_SUBMIT_REQUEST.type,
      FACTORY_INSTANCE_LOAD_REQUEST.type,
      FACTORY_TEAM_ICON_UPDATE.type,
      FACTORY_TEAM_NAME_UPDATE.type,
      FACTORY_TEAM_NAME_FAILURE.type,
      FACTORY_TEAM_ICON_FAILURE.type,
      FACTORY_INSTANCE_LOAD_FAILURE.type,
      FACTORY_ANON_GAME_JOIN_REQUEST.type,
    ],
    factoryRequestHandler
  );
}
