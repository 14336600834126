import { expandTeams, playerOnTeam } from './teams';

export function expandGameInstanceSummary(me?: Player) {
  return (summary: GameInstanceSummary): DGameInstanceSummary => {
    // expand properties
    const dteams: DTeam[] = expandTeams(summary.teams || [], summary.players);
    const downer: Player | undefined = summary.players.find(
      (player) => summary.owner === player.id
    );
    const dcurrentTeam: DTeam | undefined = dteams.find(
      (team) => team.id === summary.currentTeam
    );

    if (downer === undefined || dcurrentTeam === undefined) {
      throw new Error('Invalid game instance');
    }

    // sort teams by homeness and awayness with home teams first.
    const orderedTeams = dteams.sort((team) =>
      playerOnTeam(team, me) ? -1 : 1
    );

    const isUsersTurn =
      dcurrentTeam.players.some((player) => player.id === me?.id) &&
      summary.status === 'running';

    let finishedTime = summary.finishedTime
      ? summary.finishedTime
      : summary.lastTurnChange;

    finishedTime = finishedTime || '1970-01-01T00:00:01';

    return {
      ...summary,
      teams: orderedTeams,
      owner: downer!,
      currentTeam: dcurrentTeam!,
      finishedTime,
      isUsersTurn,
    };
  };
}
