import { takeEvery, put } from 'redux-saga/effects';

import { GenericAction } from 'dense-redux-actions';
import { ZodError } from 'zod';

import { request, expandProfile } from './utils';

import {
  GameInstanceSchema,
  GameStateSchema,
} from '../../../types/schemas/entities';

import {
  API_CREATE_GAME_INSTANCE_FAILURE,
  API_CREATE_GAME_INSTANCE_REQUEST,
  API_CREATE_GAME_INSTANCE_SUCCESS,
  API_GAME_CARD_REPLY_POST_FAILURE,
  API_GAME_CARD_REPLY_POST_REQUEST,
  API_GAME_CARD_REPLY_POST_SUCCESS,
  API_GAME_INSTANCE_GET_FAILURE,
  API_GAME_INSTANCE_GET_REQUEST,
  API_GAME_INSTANCE_GET_SUCCESS,
  API_GAME_INSTANCE_POKE_GET_FAILURE,
  API_GAME_INSTANCE_POKE_GET_REQUEST,
  API_GAME_INSTANCE_POKE_GET_SUCCESS,
  API_GAME_INSTANCE_SUMMARY_GET_FAILURE,
  API_GAME_INSTANCE_SUMMARY_GET_REQUEST,
  API_GAME_INSTANCE_SUMMARY_GET_SUCCESS,
  API_GAME_STATE_GET_FAILURE,
  API_GAME_STATE_GET_REQUEST,
  API_GAME_STATE_GET_SUCCESS,
  API_GAME_STOP_FAILURE,
  API_GAME_STOP_REQUEST,
  API_GAME_STOP_SUCCESS,
  API_TEMPLATE_AVAILABLE_FAILURE,
  API_TEMPLATE_AVAILABLE_REQUEST,
  API_TEMPLATE_AVAILABLE_SUCCESS,
  API_VALIDATION_ERROR,
  API_GAME_CARD_DRAW_POST_REQUEST,
  API_GAME_CARD_DRAW_POST_SUCCESS,
  API_GAME_CARD_DRAW_POST_FAILURE,
  API_GAME_AWAY_TEAM_ADD_MATES_FAILURE,
  API_GAME_AWAY_TEAM_ADD_MATES_REQUEST,
  API_GAME_AWAY_TEAM_ADD_MATES_SUCCESS,
  API_GAME_JOIN_REQUEST,
  API_GAME_JOIN_SUCCESS,
  API_GAME_JOIN_FAILURE,
  API_GAME_PIECE_MOVE_REQUEST,
  API_GAME_PIECE_MOVE_FAILURE,
  API_GAME_PIECE_MOVE_SUCCESS,
  TOAST_NOTIFICATION_OPEN,
  API_REFLECTIONS_GET_REQUEST,
  API_REFLECTIONS_GET_SUCCESS,
  API_REFLECTIONS_GET_FAILURE,
  API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST,
  API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS,
  API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE,
  API_REFLECTIONS_SUBMIT_FINAL_REQUEST,
  API_REFLECTIONS_SUBMIT_FINAL_SUCCESS,
  API_REFLECTIONS_SUBMIT_FINAL_FAILURE,
  API_REMOVE_PLAYER_REQUEST,
  API_REMOVE_PLAYER_SUCCESS,
  API_REMOVE_PLAYER_FAILURE,
  API_REFLECTIONS_GET_FINAL_REQUEST,
  API_REFLECTIONS_GET_FINAL_SUCCESS,
  API_REFLECTIONS_GET_FINAL_FAILURE,
  API_CHANGE_TEAM_TURN_REQUEST,
  API_CHANGE_TEAM_TURN_SUCCESS,
  API_CHANGE_TEAM_TURN_FAILURE,
  API_BUY_TOKEN_REQUEST,
  API_BUY_TOKEN_SUCCESS,
  API_BUY_TOKEN_FAILURE,
  API_MOVE_GAME_SECTION_REQUEST,
  API_MOVE_GAME_SECTION_SUCCESS,
  API_MOVE_GAME_SECTION_FAILURE,
  API_SHARED_DATA_REQUEST,
  API_SHARED_DATA_SUCCESS,
  API_SHARED_DATA_FAILURE,
  API_GAME_ADD_POINTS_REQUEST,
  API_GAME_ADD_POINTS_SUCCESS,
  API_GAME_ADD_POINTS_FAILURE,
  API_GAME_GIVE_TOKEN_REQUEST,
  API_GAME_GIVE_TOKEN_FAILURE,
  API_GAME_GIVE_TOKEN_SUCCESS,
} from '../../actions';

// eslint-disable-next-line @typescript-eslint/naming-convention
const __DEV__ = false;

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_GAME_STATE_GET_REQUEST.type: {
      const payload = API_GAME_STATE_GET_REQUEST.payload(action);
      const retry = API_GAME_STATE_GET_REQUEST.meta(action);

      let response: unknown;
      try {
        response = yield request(
          `/gameinstance/${payload.instanceId}/state`,
          {},
          expandProfile(retry)
        );
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_STATE_GET_FAILURE.create(error));
        break;
      }

      try {
        const gameState = GameStateSchema.parse(response);
        yield put(API_GAME_STATE_GET_SUCCESS.create(gameState, payload));
      } catch (e: any) {
        const error: ZodError = e;
        if (__DEV__) {
          console.warn('GameInstance validation error');
        }

        yield put(
          API_VALIDATION_ERROR.create({
            type: API_GAME_STATE_GET_REQUEST.type,
            errors: error.errors,
          })
        );

        // Try to use corrupt data anyway.
        yield put(
          API_GAME_STATE_GET_SUCCESS.create(response as GameState, payload)
        );
      }

      break;
    }

    case API_GAME_CARD_REPLY_POST_REQUEST.type: {
      let response: unknown;
      try {
        const payload = API_GAME_CARD_REPLY_POST_REQUEST.payload(action);
        const retry = API_GAME_CARD_REPLY_POST_REQUEST.meta(action);

        const options = {
          body: JSON.stringify(payload.reply),
          method: 'POST',
        };

        response = yield request(
          `/gameinstance/${payload.instanceId}/state/reply`,
          options,
          expandProfile(retry)
        );
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_CARD_REPLY_POST_FAILURE.create(error));
        break;
      }

      try {
        const gameState = GameStateSchema.parse(response);
        yield put(API_GAME_CARD_REPLY_POST_SUCCESS.create(gameState));
      } catch (e: any) {
        const error: ZodError = e;

        if (__DEV__) {
          console.warn('GameInstance validation error');
        }

        yield put(
          API_VALIDATION_ERROR.create({
            type: API_GAME_CARD_REPLY_POST_REQUEST.type,
            errors: error.errors,
          })
        );
        yield put(
          API_GAME_CARD_REPLY_POST_SUCCESS.create(response as GameState)
        );
      }

      break;
    }

    case API_GAME_INSTANCE_SUMMARY_GET_REQUEST.type: {
      try {
        const payload = API_GAME_INSTANCE_SUMMARY_GET_REQUEST.payload(action);

        const params = Object.keys(payload.params || {})
          .map((k) => {
            if ((payload.params || {})[k as keyof GameTemplateParameters['params']] !== undefined) {
              return `${encodeURIComponent(k)}=${encodeURIComponent((payload.params || {})[k as keyof GameTemplateParameters['params']])}`;
            }
            return '';
          })
          .join('&');

        const retry = API_GAME_INSTANCE_SUMMARY_GET_REQUEST.meta(action);
        const response: GameInstanceSummary[] = yield request(
          `/gameinstancesummary?${params}`,
          {},
          expandProfile(retry)
        );

        yield put(API_GAME_INSTANCE_SUMMARY_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_GAME_INSTANCE_SUMMARY_GET_FAILURE.create(error));
      }
      break;
    }

    case API_GAME_INSTANCE_GET_REQUEST.type: {
      const payload = API_GAME_INSTANCE_GET_REQUEST.payload(action);
      const retry = API_GAME_INSTANCE_GET_REQUEST.meta(action);
      let response: unknown;
      try {
        response = yield request(
          `/gameinstance/${payload.instanceId}`,
          {},
          expandProfile(retry)
        );
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_GAME_INSTANCE_GET_FAILURE.create(error));
        break;
      }

      try {
        const gameInstance = GameInstanceSchema.parse(response);

        yield put(API_GAME_INSTANCE_GET_SUCCESS.create(gameInstance));
      } catch (e: any) {
        const error: ZodError = e;
        if (__DEV__) {
          console.warn('GameInstance validation error');
        }

        yield put(
          API_VALIDATION_ERROR.create({
            type: API_GAME_INSTANCE_GET_REQUEST.type,
            errors: error.errors,
          })
        );

        // Try to use corrupt data anyway.
        yield put(
          API_GAME_INSTANCE_GET_SUCCESS.create(response as GameInstance)
        );
      }

      break;
    }

    case API_TEMPLATE_AVAILABLE_REQUEST.type: {
      try {
        const payload = API_TEMPLATE_AVAILABLE_REQUEST.payload(action);

        const params = Object.keys(payload.params || {})
          .map((k) => {
            if ((payload.params || {})[k as keyof GameTemplateParameters['params']] !== undefined) {
              return `${encodeURIComponent(k)}=${encodeURIComponent((payload.params || {})[k as keyof GameTemplateParameters['params']])}`;
            }
            return '';
          })
          .join('&');

        const retry = API_TEMPLATE_AVAILABLE_REQUEST.meta(action);
        const response: GameTemplate[] = yield request(
          `/gametemplate?${params || ''}`,
          {},
          expandProfile(retry)
        );
        yield put(API_TEMPLATE_AVAILABLE_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_TEMPLATE_AVAILABLE_FAILURE.create(error));
      }
      break;
    }

    case API_CREATE_GAME_INSTANCE_REQUEST.type: {
      try {
        const payload = API_CREATE_GAME_INSTANCE_REQUEST.payload(action);
        const retry = API_CREATE_GAME_INSTANCE_REQUEST.meta(action);
        const options = { method: 'POST', body: JSON.stringify(payload) };
        const response: GameInstance = yield request(
          '/gameinstance',
          options,
          expandProfile(retry)
        );

        yield put(API_CREATE_GAME_INSTANCE_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_CREATE_GAME_INSTANCE_FAILURE.create(error));
      }
      break;
    }

    case API_CREATE_GAME_INSTANCE_FAILURE.type: {
      const payload = API_CREATE_GAME_INSTANCE_FAILURE.payload(action);
      yield put(
        TOAST_NOTIFICATION_OPEN.create({
          content: payload.message,
          appearance: 'warning',
          autoDismiss: true,
        })
      );
      break;
    }

    case API_GAME_STOP_REQUEST.type: {
      try {
        const payload = API_GAME_STOP_REQUEST.payload(action);
        const retry = API_GAME_STOP_REQUEST.meta(action);
        const options = { method: 'POST' };

        const response: GameState = yield request(
          `/gameinstance/${payload.instanceId}/stop`,
          options,
          expandProfile(retry)
        );

        yield put(API_GAME_STOP_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_GAME_STOP_FAILURE.create(error));
      }

      break;
    }

    case API_GAME_INSTANCE_POKE_GET_REQUEST.type: {
      try {
        const gameInstanceId =
          API_GAME_INSTANCE_POKE_GET_REQUEST.payload(action);
        const retry = API_GAME_INSTANCE_POKE_GET_REQUEST.meta(action);

        const response: PokeInGameExchange = yield request(
          `/gameinstance/${gameInstanceId.instanceId}/pokes`,
          {},
          expandProfile(retry)
        );

        yield put(
          API_GAME_INSTANCE_POKE_GET_SUCCESS.create(response, gameInstanceId)
        );
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_GAME_INSTANCE_POKE_GET_FAILURE.create(error));
      }

      break;
    }
    case API_GAME_AWAY_TEAM_ADD_MATES_REQUEST.type: {
      const retry = API_GAME_AWAY_TEAM_ADD_MATES_REQUEST.meta(action);
      const payload = API_GAME_AWAY_TEAM_ADD_MATES_REQUEST.payload(action);
      const options = { method: 'PATCH', body: JSON.stringify(payload.team) };

      try {
        // @ts-ignore
        const response = yield request(
          `/gameinstance/${payload.instanceId}/teams/${payload.teamId}`,
          options,
          expandProfile(retry)
        );
        yield put(API_GAME_AWAY_TEAM_ADD_MATES_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };

        yield put(API_GAME_AWAY_TEAM_ADD_MATES_FAILURE.create(error));
      }

      break;
    }
    case API_GAME_CARD_DRAW_POST_REQUEST.type: {
      const payload = API_GAME_CARD_DRAW_POST_REQUEST.payload(action);
      const retry = API_GAME_CARD_DRAW_POST_REQUEST.meta(action);
      const body = { cardId: payload.cardId };
      const options = { method: 'POST', body: JSON.stringify(body) };

      try {
        // @ts-ignore
        const response = yield request(
          `/gameinstance/${payload.gameInstanceId}/state/draw`,
          options,
          expandProfile(retry)
        );
        if (response.error) {
          throw new Error(`something went wrong ${response.error}`);
        }
        yield put(API_GAME_CARD_DRAW_POST_SUCCESS.create(response));
      } catch (e: any) {
        yield put(API_GAME_CARD_DRAW_POST_FAILURE.create(e));
        break;
      }
      break;
    }
    case API_GAME_JOIN_REQUEST.type: {
      const payload = API_GAME_JOIN_REQUEST.payload(action);
      const retry = API_GAME_JOIN_REQUEST.meta(action);
      const body = payload;
      const options = { method: 'POST', body: JSON.stringify(body) };

      try {
        const response: GameInstance = yield request(
          '/team/join',
          options,
          expandProfile(retry)
        );

        yield put(API_GAME_JOIN_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_JOIN_FAILURE.create(error));
        break;
      }
      break;
    }
    case API_GAME_PIECE_MOVE_REQUEST.type: {
      const payload = API_GAME_PIECE_MOVE_REQUEST.payload(action);
      const retry = API_GAME_PIECE_MOVE_REQUEST.meta(action);
      const body = { position: payload.newPosition };
      const options = { method: 'PATCH', body: JSON.stringify(body) };

      try {
        const response: Team = yield request(
          `/team/${payload.teamId}/position`,
          options,
          expandProfile(retry)
        );
        yield put(API_GAME_PIECE_MOVE_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_PIECE_MOVE_FAILURE.create(error));
      }
      break;
    }
    case API_REFLECTIONS_GET_REQUEST.type: {
      const payload = API_REFLECTIONS_GET_REQUEST.payload(action);
      const retry = API_REFLECTIONS_GET_REQUEST.meta(action);
      try {
        const response: MyReflection[] = yield request(
          `/gameinstance/${payload.gameId}/myreflections`,
          { method: 'GET' },
          expandProfile(retry)
        );
        yield put(API_REFLECTIONS_GET_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_REFLECTIONS_GET_FAILURE.create(error));
      }
      break;
    }

    case API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST.type: {
      const payload =
        API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST.payload(action);
      const retry = API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST.meta(action);

      try {
        yield request(
          `/gameinstance/${payload.gameId}/state/endgame`,
          { method: 'POST' },
          expandProfile(retry)
        );
        yield put(API_REFLECTIONS_ENDGAME_SEND_USERS_SUCCESS.create());
      } catch (error: any) {
        yield put(API_REFLECTIONS_ENDGAME_SEND_USERS_FAILURE.create(error));
      }
      break;
    }
    case API_REFLECTIONS_SUBMIT_FINAL_REQUEST.type: {
      const payload = API_REFLECTIONS_SUBMIT_FINAL_REQUEST.payload(action);
      const retry = API_REFLECTIONS_SUBMIT_FINAL_REQUEST.meta(action);
      const body = { text: payload.text };
      const options = { method: 'POST', body: JSON.stringify(body) };
      try {
        yield request(
          `/gameinstance/${payload.gameId}/myendgamereflection`,
          options,
          expandProfile(retry)
        );
        yield put(API_REFLECTIONS_SUBMIT_FINAL_SUCCESS.create());
      } catch (error: any) {
        yield put(API_REFLECTIONS_SUBMIT_FINAL_FAILURE.create(error));
      }
      break;
    }
    case API_REFLECTIONS_GET_FINAL_REQUEST.type: {
      const payload = API_REFLECTIONS_GET_FINAL_REQUEST.payload(action);
      const retry = API_REFLECTIONS_GET_FINAL_REQUEST.meta(action);
      const options = { method: 'GET' };
      try {
        // @ts-ignore
        const response = yield request(
          `/gameinstance/${payload.gameId}/myendgamereflection`,
          options,
          expandProfile(retry)
        );
        yield put(API_REFLECTIONS_GET_FINAL_SUCCESS.create(response));
      } catch (error: any) {
        yield put(API_REFLECTIONS_GET_FINAL_FAILURE.create(error));
      }
      break;
    }
    case API_REMOVE_PLAYER_REQUEST.type: {
      const payload = API_REMOVE_PLAYER_REQUEST.payload(action);
      const retry = API_REMOVE_PLAYER_REQUEST.meta(action);

      const options = { method: 'DELETE' };
      try {
        // @ts-ignore
        const response = yield request(
          `/team/${payload.teamId}/player/${payload.playerId}`,
          options,
          expandProfile(retry)
        );
        yield put(API_REMOVE_PLAYER_SUCCESS.create(response));
      } catch (error: any) {
        yield put(API_REMOVE_PLAYER_FAILURE.create(error));
      }
      break;
    }

    case API_CHANGE_TEAM_TURN_REQUEST.type: {
      const payload = API_CHANGE_TEAM_TURN_REQUEST.payload(action);
      const options = { method: 'POST' };
      try {
        const response: GameState = yield request(
          `/gameinstance/${payload.gameInstanceId}/changeteam/${payload.teamId}`,
          options
        );
        yield put(API_CHANGE_TEAM_TURN_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_CHANGE_TEAM_TURN_FAILURE.create(error));
      }
      break;
    }

    case API_BUY_TOKEN_REQUEST.type: {
      const payload = API_BUY_TOKEN_REQUEST.payload(action);
      const body = { teamId: payload.teamId };
      const options = { method: 'POST', body: JSON.stringify(body) };

      try {
        const response: GameState = yield request(
          `/gameinstance/${payload.giId}/gametoken/${payload.tokenId}/acquire`,
          options
        );
        yield put(API_BUY_TOKEN_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        console.error(e);
        yield put(API_BUY_TOKEN_FAILURE.create(error));
      }
      break;
    }

    case API_MOVE_GAME_SECTION_REQUEST.type: {
      const payload = API_MOVE_GAME_SECTION_REQUEST.payload(action);
      const options = { method: 'PUT' };

      try {
        const response: GameState = yield request(
          `/gameinstance/${payload.giId}/state/section/${payload.sectionId}`,
          options
        );
        yield put(API_MOVE_GAME_SECTION_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_MOVE_GAME_SECTION_FAILURE.create(error));
      }
      break;
    }

    case API_SHARED_DATA_REQUEST.type: {
      const payload = API_SHARED_DATA_REQUEST.payload(action);
      const body = payload.sharedData;
      const options = { method: 'PUT', body: JSON.stringify(body) };

      try {
        const response: GameState = yield request(
          `/gameinstance/${payload.gameId}/state/sharedData`,
          options
        );
        yield put(API_SHARED_DATA_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_SHARED_DATA_FAILURE.create(error));
      }

      break;
    }
    case API_GAME_ADD_POINTS_REQUEST.type: {
      const payload = API_GAME_ADD_POINTS_REQUEST.payload(action);
      let body = {
        points: payload.points,
        sectionId: payload.sectionId,
      };

      const options = { method: 'PATCH', body: JSON.stringify(body) };

      try {
        const response: Team = yield request(
          `/team/${payload.teamId}/addPoints`,
          options
        );
        yield put(API_GAME_ADD_POINTS_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_ADD_POINTS_FAILURE.create(error));
      }

      break;
    }

    case API_GAME_GIVE_TOKEN_REQUEST.type: {
      const payload = API_GAME_GIVE_TOKEN_REQUEST.payload(action);
      const body = { teamId: payload.receivingTeamId };
      const options = { method: 'POST', body: JSON.stringify(body) };

      try {
        const response: GameState = yield request(
          `/gameinstance/${payload.giId}/gametoken/${payload.tokenId}/transfer`,
          options
        );
        yield put(API_GAME_GIVE_TOKEN_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_GAME_GIVE_TOKEN_FAILURE.create(error));
      }
      break;
    }

    default:
  }
}

export function* gameRequests() {
  yield takeEvery(
    [
      API_CREATE_GAME_INSTANCE_REQUEST.type,
      API_GAME_AWAY_TEAM_ADD_MATES_REQUEST.type,
      API_GAME_CARD_REPLY_POST_REQUEST.type,
      API_GAME_INSTANCE_GET_REQUEST.type,
      API_GAME_INSTANCE_POKE_GET_REQUEST.type,
      API_GAME_INSTANCE_SUMMARY_GET_REQUEST.type,
      API_GAME_STATE_GET_REQUEST.type,
      API_GAME_STOP_REQUEST.type,
      API_GAME_INSTANCE_POKE_GET_REQUEST.type,
      API_GAME_CARD_DRAW_POST_REQUEST.type,
      API_TEMPLATE_AVAILABLE_REQUEST.type,
      API_GAME_JOIN_REQUEST.type,
      API_GAME_PIECE_MOVE_REQUEST.type,
      API_REFLECTIONS_GET_REQUEST.type,
      API_REFLECTIONS_ENDGAME_SEND_USERS_REQUEST.type,
      API_REFLECTIONS_SUBMIT_FINAL_REQUEST.type,
      API_REMOVE_PLAYER_REQUEST.type,
      API_REFLECTIONS_GET_FINAL_REQUEST.type,
      API_CHANGE_TEAM_TURN_REQUEST.type,
      API_BUY_TOKEN_REQUEST.type,
      API_MOVE_GAME_SECTION_REQUEST.type,
      API_SHARED_DATA_REQUEST.type,
      API_GAME_ADD_POINTS_REQUEST.type,
      API_GAME_GIVE_TOKEN_REQUEST.type,
    ],
    handleRequest
  );
}
