import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../components/UI/Button';
import styles from './AnswerModal.module.css';
import AnswerBox from './AnswerBox';
import { StringsConsumer } from '../../../../../locales';
import { mainColors } from '../../../../../utils';
import LinkBox from './LinkBox';
import Timer from '../../../../../components/UI/Timer';
import { ThemeContext } from '../../../../../App';
import Markdown from '../../../../../components/UI/Markdown';

interface Props {
  show: boolean;
  onClose?: () => void;
  isGameOwner?: boolean;
  onAnswer: (reply: Reply) => void;
  currentTeam: DTeam | undefined;
  activeCard?: DCardInstance;
  section?: GameSection;
  reflectionText: string;
  sectionCard: DCardInstance | undefined;
  updateReflectionText: (text: string) => void;
}

const AnswerModal: React.FC<Props> = ({
  show,
  activeCard,
  onClose,
  isGameOwner,
  onAnswer,
  currentTeam,
  section,
  reflectionText,
  sectionCard,
  updateReflectionText,
}) => {
  const card = activeCard || sectionCard;

  const shouldShowTimer =
    card &&
    card.timelimit &&
    (card.replies === undefined || card.replies?.length === 0) &&
    activeCard; // we dont want it on section cards

  const theme = React.useContext(ThemeContext);

  const headerColorSection =
    section?.textMode === 'dark' ? mainColors.black : mainColors.white;
  return (
    <StringsConsumer>
      {({ screens }) => {
        return (
          <Modal
            show={show}
            centered
            dialogClassName={styles.modalContainer}
            onHide={onClose}
          >
            <Modal.Header
              style={{
                backgroundColor: theme.showSectionColor
                  ? section?.color
                  : theme.primary,
                borderBottom: 0,
              }}
            >
              <h5
                className={styles.cardHeader}
                style={{
                  color: theme.showSectionColor ? headerColorSection : 'white',
                }}
              >
                {card?.type}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.cardBody}>
                {card && card.link && <LinkBox link={card.link} />}
                <h3
                  className={styles.text}
                  style={{ fontWeight: 'normal', whiteSpace: 'pre-wrap' }}
                >
                  <Markdown
                    style={{ whiteSpace: 'pre-wrap' }}
                    bold
                    fontSize="24px"
                  >
                    {card?.body}
                  </Markdown>
                </h3>
                <p
                  className={styles.text}
                  style={{ fontWeight: 'bold', whiteSpace: 'pre-wrap' }}
                >
                  <Markdown>{card?.question}</Markdown>
                </p>

                <div style={{ marginBottom: '15px' }} />
                <AnswerBox
                  reflectionText={reflectionText}
                  updateReflectionText={updateReflectionText}
                  isGameOwner={isGameOwner}
                  section={section}
                  activeCard={card}
                  currentTeam={currentTeam}
                  onAnswer={onAnswer}
                  isModal
                />
                {shouldShowTimer && card?.timelimit && (
                  <div
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <Timer
                      duration={card.timelimit}
                      showControls={false}
                      modal
                    />
                  </div>
                )}
                {card && !card.isMyTurn && !isGameOwner && (
                  <p
                    style={{
                      backgroundColor: section?.color
                        ? section.color
                        : theme.primary,
                      color:
                        section?.textMode === 'dark'
                          ? mainColors.black
                          : mainColors.white,
                    }}
                    className={styles.notYourTurnText}
                  >
                    {screens.gamePage.activeCard.notYourTurn}
                  </p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 0 }}>
              <Button title={screens.others.closeBtn} onClick={onClose} />
            </Modal.Footer>
          </Modal>
        );
      }}
    </StringsConsumer>
  );
};

export default AnswerModal;
