import { takeEvery, put } from 'redux-saga/effects';
import { GenericAction } from 'dense-redux-actions';

import { request, expandProfile } from './utils';

import { API_FEEDBACK_POST_REQUEST } from '../../actions';
import {
  API_FEEDBACK_POST_SUCCESS,
  API_FEEDBACK_POST_FAILURE,
} from '../../actions/api';

function* handleRequest(action: GenericAction) {
  switch (action.type) {
    case API_FEEDBACK_POST_REQUEST.type: {
      const feedback = API_FEEDBACK_POST_REQUEST.payload(action);
      const retry = API_FEEDBACK_POST_REQUEST.meta(action);

      const options = {
        method: 'POST',
        body: JSON.stringify(feedback),
      };

      try {
        const response: StructuredFeedback = yield request(
          '/feedback',
          options,
          expandProfile(retry)
        );

        yield put(API_FEEDBACK_POST_SUCCESS.create(response));
      } catch (e: any) {
        const error: APIError = { ...e };
        yield put(API_FEEDBACK_POST_FAILURE.create(error));
      }
      break;
    }
    case API_FEEDBACK_POST_SUCCESS.type: {
      // do specal handling

      // This is removed compared to ComPLAYance app
      /*
      const payload = API_FEEDBACK_POST_SUCCESS.payload(action);
      if (payload.channel === 'contact_details') {
        const now = +new Date();
        yield put(STORE_CONTACT_PROVIDED_SAVE_REQUEST.create({ timestamp: now }));
      }
       */

      break;
    }
    default:
  }
}

export function* feedbackRequests() {
  yield takeEvery(
    [API_FEEDBACK_POST_REQUEST.type, API_FEEDBACK_POST_SUCCESS.type],
    handleRequest
  );
}
