import { LocalizedStrings } from '../modules/LocalizedStrings';
import { en, DefaultLangKeys } from './en';
import { de } from './de';
import { da } from './da';
import { nl } from './nl';
import { hu } from './hu';
import { tr } from './tr';
import { it } from './it';
import { fr } from './fr';
import { se } from './se';
import { pl } from './pl';
import { es } from './es';
import { pt } from './pt';
import { cmn } from './cmn';
import { en_FC } from './Facilitation';

const translations = {
  da,
  de,
  en,
  es,
  fr,
  hu,
  it,
  nl,
  pl,
  pt,
  se,
  tr,
  cmn,
  en_FC,
};

export type LanguageTags = keyof typeof translations;

const Strings = new LocalizedStrings<LanguageTags, DefaultLangKeys>({
  initialTranslations: translations,
  defaultLanguageTag: 'en',
});

// Expose language selection to redux
export const StringsProvider = Strings.Producer;
export const StringsConsumer = Strings.Consumer;
export const StringsContext = Strings.Context;
export const { langReducer, langSaga, getStringsUnsafe } = Strings;
