/* eslint max-len: 0 */
import { ActionCreator } from 'dense-redux-actions';

// handle flow around login.
export const ACCOUNT_LOGOUT_REQUEST = new ActionCreator<boolean>('ACCOUNT_LOGOUT_REQUEST');
export const ACCOUNT_LOGOUT_SUCCESS = new ActionCreator<null>('ACCOUNT_LOGOUT_SUCCESS');
export const ACCOUNT_LOGOUT_FAILURE = new ActionCreator<Error>('ACCOUNT_LOGOUT_FAILURE');

// Update profile data provided by auth0
// export const ACCOUNT_PARSE_TOKEN_REQUEST = new ActionCreator<string>('ACCOUNT_PARSE_TOKEN_REQUEST');
// export const ACCOUNT_PARSE_TOKEN_SUCCESS = new ActionCreator<UserInfo>('ACCOUNT_PARSE_TOKEN_SUCCESS');
// export const ACCOUNT_PARSE_TOKEN_FAILURE = new ActionCreator<Error>('ACCOUNT_PARSE_TOKEN_FAILURE');

export const ACCOUNT_USER_INFO_REQUEST = new ActionCreator<null, RetryMeta>('ACCOUNT_USER_INFO_REQUEST');
export const ACCOUNT_USER_INFO_SUCCESS = new ActionCreator<Profile>('ACCOUNT_USER_INFO_SUCCESS');
export const ACCOUNT_USER_INFO_FAILURE = new ActionCreator<Error>('ACCOUNT_USER_INFO_FAILURE');

export const ACCOUNT_UPDATE_NAME_REQUEST = new ActionCreator<string>('ACCOUNT_UPDATE_NAME_REQUEST');
export const ACCOUNT_UPDATE_NAME_SUCCESS = new ActionCreator<Profile>('ACCOUNT_UPDATE_NAME_SUCCESS');
export const ACCOUNT_UPDATE_NAME_FAILURE = new ActionCreator<Error>('ACCOUNT_UPDATE_NAME_FAILURE');

export const ACCOUNT_UPDATE_AVATAR_REQUEST = new ActionCreator<Avatar>('ACCOUNT_UPDATE_AVATAR_REQUEST');
export const ACCOUNT_UPDATE_AVATAR_SUCCESS = new ActionCreator<Profile>('ACCOUNT_UPDATE_AVATAR_SUCCESS');
export const ACCOUNT_UPDATE_AVATAR_FAILURE = new ActionCreator<Error>('ACCOUNT_UPDATE_AVATAR_FAILURE');

/*
export const ACCOUNT_STORE_FIREBASE_MESSAGING_TOKEN_REQUEST = new ActionCreator<string>('ACCOUNT_STORE_FIREBASE_MESSAGING_TOKEN_REQUEST');

export const ACCOUNT_VALIDATE_EMAIL_REQUEST = new ActionCreator<string>('ACCOUNT_VALIDATE_EMAIL_REQUEST');
export const ACCOUNT_VALIDATE_EMAIL_SUCCESS = new ActionCreator<null>('ACCOUNT_VALIDATE_EMAIL_SUCCESS');
export const ACCOUNT_VALIDATE_EMAIL_FAILURE = new ActionCreator<Error>('ACCOUNT_VALIDATE_EMAIL_FAILURE');

export const ACCOUNT_COMPANY_APPLY_REQUEST = new ActionCreator<CompanyApply>('ACCOUNT_COMPANY_APPLY_REQUEST');
export const ACCOUNT_COMPANY_APPLY_SUCCESS = new ActionCreator<CompanyApply>('ACCOUNT_COMPANY_APPLY_SUCCESS');
export const ACCOUNT_COMPANY_APPLY_FAILURE = new ActionCreator<Error>('ACCOUNT_COMPANY_APPLY_FAILURE');
*/
