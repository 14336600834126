import React from 'react';
import styles from './APILoader.module.css';

const Spinner = require('react-spinkit');

interface Props {
  show: boolean;
}

function APILoader(props: Props) {
  if (props.show === false) {
    return <div className={styles.container} />;
  }
  return (
    <div className={styles.container}>
      <Spinner name="pulse" fadeIn="none" />
    </div>
  );
}

export default APILoader;
