export function expandTeam(
  team: Team,
  players: Player[],
  sections?: GameSection[],
  sharedData?: SharedData | null,
  currentSectionCard?: DCardInstance | null
): DTeam {
  // map sectionids to sections.
  const expandedSections: DTeamSectionPoints[] = [];
  if (team.teamSectionPoints && sections) {
    team.teamSectionPoints.forEach((el) => {
      const section = sections.find((sec) => sec.id === el.sectionId);
      const acquiredTokens: GameToken[] = [];

      section?.gameTokens?.forEach((sgt) => {
        const sectionTokenOnTeam: GameToken | undefined =
          team.acquiredGameTokens?.find((acgt) => acgt.id === sgt.id);
        if (sectionTokenOnTeam) {
          acquiredTokens.push(sectionTokenOnTeam);
        }
      });

      expandedSections.push({
        point: el.point,
        tokens: acquiredTokens,
        section,
      });
    });
  }

  const teamPlayers: Player[] = [];

  team.players.forEach((playerId) => {
    const player = players.find((p) => p.id === playerId);
    if (player) {
      teamPlayers.push(player);
    }
  });

  let victoryPoints = undefined;
  if (sharedData?.victoryPoints) {
    const teamVicPoints = sharedData.victoryPoints.find(
      (vicPoints) => vicPoints.teamId === team.id
    );
    victoryPoints = teamVicPoints?.points;
  }

  let gameRole = undefined;
  if (currentSectionCard?.roles && team.assignedRole) {
    gameRole = currentSectionCard.roles.find(
      (secRole) => secRole.id === team.assignedRole
    );
  }

  return {
    ...team,
    players: teamPlayers,
    victoryPoints,
    teamSectionPoints: expandedSections,
    assignedRole: gameRole,
  };
}

export function expandTeams(
  teams: Team[],
  players: Player[],
  sections?: GameSection[],
  gameTiles?: GameTile[],
  gameState?: GameState,
  currentSectionCard?: DCardInstance | null
): DTeam[] {
  const dteams: DTeam[] = [];

  const countingArray: number[] = [];
  teams.forEach((team) => {
    if (team.gameboardPosition && gameTiles) {
      countingArray.push(team.gameboardPosition);
    }
  });

  teams.forEach((team) => {
    let teamTile;

    if (team.gameboardPosition && gameTiles) {
      const tokensOnTile: number = countingArray.filter(
        (el) => el === team.gameboardPosition
      ).length;
      teamTile = {
        ...gameTiles[team.gameboardPosition - 1],
        tokensOnTile,
      };
    }
    let isCurrentTeam = false;
    if (gameState) {
      isCurrentTeam = team.id === gameState.currentTeam;
    }

    dteams.push({
      ...expandTeam(
        team,
        players,
        sections,
        gameState?.sharedData,
        currentSectionCard
      ),
      currentTile: teamTile,
      isCurrentTeam,
    });
  });

  return dteams || [];
}

export function playerOnTeam(team: DTeam, me?: Player): boolean {
  if (me === undefined) {
    return false;
  }

  const onTeam = team.players.find((player) => player.id === me?.id);

  return !!onTeam;
}

export function findNextTeam(
  teams: DTeam[] | undefined,
  currentTeamId: number | undefined
): DTeam | undefined {
  if (!teams || !currentTeamId) {
    return undefined;
  }
  // as the arrays are the same for every player, this is needed to ensure it looks the same.
  const sortedTeams: DTeam[] = teams.sort((a, b) => a.id - b.id);
  const teamIds: number[] = sortedTeams.map((teamEl) => teamEl.id);
  const curPos = teamIds.indexOf(currentTeamId);
  if (curPos >= sortedTeams.length - 1) {
    return sortedTeams[0];
  }
  return sortedTeams[curPos + 1];
}
