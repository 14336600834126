import {
  CLEAR_NOTIFICATIONS,
  TOAST_NOTIFICATION_OPEN,
} from '../actions/toastNotification';

interface Toasts {
  toasts: ToastNotification[];
}

const initialState: Toasts = { toasts: [] };

export const toastNotifications = (
  state: Toasts = initialState,
  action: GenericAction,
): Toasts => {
  switch (action.type) {
    case TOAST_NOTIFICATION_OPEN.type: {
      return {
        ...state,
        toasts: [...state.toasts, TOAST_NOTIFICATION_OPEN.payload(action)],
      };
    }
    case CLEAR_NOTIFICATIONS.type: {
      return {
        ...state,
        toasts: [],
      };
    }
    default: {
      return state;
    }
  }
};
