import React from 'react';
import Icon from './Icon';
import styles from './SectionPresentation.module.css';
interface SectionPresentationProps {
  gameSection?: DTeamSectionPoints;
  showIcon?: boolean;
  overridePoint?: number;
}

function SectionPresentation({
  gameSection,
  showIcon,
  overridePoint,
}: SectionPresentationProps) {
  return (
    <div
      className={styles.section}
      style={{
        border: `solid 1px ${gameSection?.section?.color}`,
      }}
    >
      <SectionDecoration side="left" color={gameSection?.section?.color} />
      <div className={styles.sectionInner}>
        <div style={{ display: 'flex' }}>
          {showIcon && (
            <SectionIcon
              icon={
                gameSection?.section?.gameTokens &&
                gameSection.section.gameTokens.length > 0
                  ? gameSection.section.gameTokens[0].icon
                  : ''
              }
            />
          )}
          {gameSection?.section?.name}
        </div>
        <div>{overridePoint ?? gameSection?.point}</div>
      </div>
      <SectionDecoration side="right" color={gameSection?.section?.color} />
    </div>
  );
}

interface SectionDecorationProps {
  side: 'left' | 'right';
  color?: string;
}

function SectionDecoration({ side, color }: SectionDecorationProps) {
  return (
    <div
      className={styles.sectionDecoration}
      style={side == 'left' ? { left: -1 } : { right: 1 }}
    >
      <div
        style={{
          border: '1px solid ' + (color ? color : '#000000'),
        }}
      />
      <div
        style={{
          border: '1px solid ' + (color ? color : '#000000'),
        }}
      />
    </div>
  );
}

interface SectionIconProps {
  icon: string;
}
function SectionIcon({ icon }: SectionIconProps) {
  return (
    <span style={{ marginRight: '10px', alignSelf: 'center' }}>
      <Icon
        size={16}
        icon={icon}
        iconStyle={{ display: 'flex' }}
        color="white"
      />
    </span>
  );
}

export default SectionPresentation;
