export const tokenPointMultiplier = 2;

export function combinedPointsTokenGame(team: DTeam): number {
  if (team.acquiredGameTokens && team.victoryPoints) {
    return (
      team.victoryPoints + team.acquiredGameTokens.length * tokenPointMultiplier
    );
  }

  if (team.victoryPoints) {
    return team.victoryPoints;
  }

  return 0;
}

export function highestScoringTeam(teams: DTeam[]) {
  let t;
  if (teams) {
    t = teams.reduce((prev, current) => {
      return prev.score > current.score ? prev : current;
    });
  }
  return t?.score || undefined;
}

export function hightestCombinedPointsTokenGame(teams: DTeam[]): number {
  const team = teams.reduce((prev, current) => {
    return combinedPointsTokenGame(prev) > combinedPointsTokenGame(current)
      ? prev
      : current;
  });
  return combinedPointsTokenGame(team);
}
