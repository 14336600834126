import { expandGameInstance } from '../utils/gameInstance';
import { expandCard } from '../utils/card';

export const dnormGameInstanceFromGameInstanceResult = expandGameInstance;

export function amIGameOwnerResult(
  currentGame?: GameInstance,
  me?: Player
): boolean {
  if (currentGame && me) {
    return currentGame.owner === me.id;
  }
  // If they dont exist, he aint game owner.
  return false;
}

export function arrayOfAllGameTilesResult(
  currentGame: GameInstance | undefined
): GameTile[] | undefined {
  if (!currentGame || !currentGame.virtualGameboard) {
    return undefined;
  }
  const flattenArrayOfSections: any[] = [];
  currentGame.virtualGameboard?.sections.forEach((section) => {
    flattenArrayOfSections.push([...section.tiles]);
  });
  const flattenArrayOfTiles: GameTile[] = Array.prototype.concat.apply(
    [],
    flattenArrayOfSections
  );
  // finishing position tile
  const finishPos = currentGame.virtualGameboard.finishingPosition;
  if (finishPos) {
    flattenArrayOfTiles.push({
      position: {
        positionX: finishPos.positionX,
        positionY: finishPos.positionY,
      },
      cards: [],
    });
  }
  return flattenArrayOfTiles;
}

export function calculateLenghtOfGameResult(
  tiles: GameTile[] | undefined
): number {
  if (tiles) {
    return tiles.length;
  }
  return 0;
}

export function findUnansweredCardsResult(
  cards: Card[] | undefined,
  replies: Reply[] | undefined
): Card[] | undefined {
  if (cards && replies) {
    const availableCards: Card[] = [];
    cards.forEach((card) => {
      if (!replies.some((reply) => reply.cardId === card.id)) {
        availableCards.push(card);
      }
    });
    return availableCards;
  }
  return undefined;
}

export function dNormalizeDrawnCardResult(
  currentGame: GameInstance | undefined,
  cards: Card[] | undefined,
  players: Player[] | undefined,
  me: Player | undefined
): DDrawn | undefined {
  if (currentGame && cards && players) {
    const drawnCard = currentGame.state.drawn;
    if (!drawnCard) {
      return undefined;
    }
    const card: Card | undefined = cards.find(
      (singleCard) => singleCard.id === drawnCard.cardId
    );
    const player: Player | undefined = players.find(
      (singlePlayer) => singlePlayer.id === drawnCard.playerId
    );
    const dnormCard: DCardInstance | null = expandCard(
      players,
      currentGame.state,
      me
    )(card);

    if (dnormCard) {
      return {
        card: dnormCard,
        player,
      };
    }
  }

  return undefined;
}

export function meResult(profile?: Profile): Player | undefined {
  const {
    name,
    avatar,
    id,
    email,
    score = 0,
    companyRanking = Infinity,
  } = profile || {};

  // empty avatar field in a valid profile should be null.
  if (
    name === undefined ||
    id === undefined ||
    id === null ||
    email === undefined ||
    email === null
  ) {
    return undefined;
  }

  return {
    name,
    avatar: avatar ?? null,
    id,
    email,
    score,
    companyRanking,
  };
}

export function findAllUnansweredCardsOnTileResult(
  cards: Card[] | undefined,
  tile: any | undefined,
  replies: Reply[] | undefined,
  sharedData: SharedData | undefined | null
): Card[] | undefined {
  if (cards && tile && replies) {
    const unansweredCardsOnTile: Card[] = [];
    // Shareddata can also have used some cards, during ie. challengemode.
    const cardsUsedThroughSharedData: number[] = sharedData?.usedDataCardIds
      ? sharedData.usedDataCardIds
      : [];
    tile.cards.forEach((tileCard: number) => {
      const filteredCard: Card | undefined = cards.find(
        (cardEl) => cardEl.id === tileCard
      );
      if (
        filteredCard &&
        !replies.some((reply) => reply.cardId === filteredCard.id) &&
        !cardsUsedThroughSharedData.some((cId) => cId === tileCard)
      ) {
        unansweredCardsOnTile.push(filteredCard);
      }
    });
    return unansweredCardsOnTile;
  }
  return undefined;
}

export function findCurrentTileResult(
  gameState: GameState | undefined,
  gameTiles: GameTile[] | undefined
): GameTile | undefined {
  if (gameState && gameTiles) {
    const currentTeam = gameState.teams.find(
      (teamEl) => teamEl.id === gameState.currentTeam
    );
    if (!currentTeam || currentTeam.gameboardPosition === null) {
      return undefined;
    }
    return gameTiles[currentTeam.gameboardPosition! - 1];
  }
  return undefined;
}

export function currentGameSectionResult(
  gameInstance: GameInstance | undefined
): GameSection | undefined {
  if (gameInstance && gameInstance.virtualGameboard && gameInstance.state) {
    let currentGameSection;
    const { teams } = gameInstance.state;

    let cTeamId = gameInstance.state.currentTeam;
    let cTeam = teams.find((t) => t.id === cTeamId);

    if (cTeam?.gameboardPosition) {
      let countDown: number = cTeam.gameboardPosition;

      for (
        let index = 0;
        index < gameInstance.virtualGameboard.sections.length;
        index += 1
      ) {
        const section = gameInstance.virtualGameboard.sections[index];
        countDown -= section.tiles.length;
        if (countDown <= 0) {
          currentGameSection = section;
          break;
        }
      }

      return currentGameSection;
    }
  }

  return undefined;
}

export function shouldShowStillHereResult(counter: number): boolean {
  if (counter >= 900) {
    return true;
  }
  return false;
}

export function myGameRoleResult(
  me: Player | undefined,
  activeCard: Card | undefined,
  gs: GameState | undefined
): GameRole | undefined {
  let currentCardState: GameRole | undefined;
  if (activeCard && gs && me) {
    const meInState = gs.players.find((elPlayer) => elPlayer.id === me.id);
    if (meInState) {
      const meRoleId = meInState.currentCardState?.roleId;
      if (meRoleId && activeCard.roles) {
        const myRole = activeCard.roles.find(
          (elRole) => elRole.id === meRoleId
        );
        if (myRole) {
          currentCardState = {
            id: myRole.id,
            name: myRole.name,
            guidance: myRole.guidance,
          };
        }
      }
    }
  }
  return currentCardState;
}

export function dnormMyReflectionsResult(
  myReflecs: MyReflection[] | undefined,
  sections: GameSection[] | undefined
): DMyReflection[] | undefined {
  if (myReflecs === undefined || myReflecs.length === 0) {
    return undefined;
  }
  const myDnormReflections: DMyReflection[] = [];

  myReflecs.forEach((reflection: MyReflection) => {
    let selectedSection: GameSection | undefined;
    if (sections) {
      selectedSection = sections.find(
        (section) => section.id === reflection.sectionId
      );
    }
    if (!selectedSection) {
      selectedSection = {
        id: reflection.sectionId,
        name: 'Reflection',
        color: 'green',
        textMode: 'light',
        tiles: [],
      };
    }
    myDnormReflections.push({
      section: selectedSection,
      id: reflection.id,
      sectionId: reflection.sectionId,
      reflectionText: reflection.reflectionText,
    });
  });

  return myDnormReflections;
}

export function hasEveryoneSentReflectionResult(
  gi: DGameInstance | undefined
): boolean {
  // important about this function!!!!
  // If we're at any point unsure, it should just return true, as that wont break the functionality.
  if (!gi) {
    return true;
  }
  const gameState = gi.state;
  const playersNotAnswered = gameState.currentTeam?.players.filter(
    (pl) => pl.currentCardState?.answered === false
  );
  const isGameOwnerAmong = playersNotAnswered?.some(
    (pl) => pl.id === gi.owner.id
  );
  if (!playersNotAnswered) {
    return true;
  }
  if (isGameOwnerAmong === true) {
    return !(playersNotAnswered.length > 1);
  }
  return !(playersNotAnswered.length > 0);
}

export function amIPartOfGameResult(
  me: Player | undefined,
  game: GameInstance | undefined
): boolean {
  if (!me) {
    return true;
  }
  if (!game) {
    return false;
  }
  const amIInGame: boolean = game.state.players.some(
    (player) => player.id === me.id
  );
  return amIInGame;
}

export function allTeamsReadyForReflectionResult(
  gs: GameState | undefined,
  gameLength: number | undefined
): boolean {
  if (!gs || gs.teams.length === 0 || !gameLength) {
    return false;
  }

  if (gs.status === 'endgame') {
    return false;
  }

  const readyTeams = gs.teams.filter((team) => {
    if (team.gameboardPosition) {
      return team.gameboardPosition >= gameLength;
    }
    return false;
  });
  return readyTeams.length === gs.teams.length;
}

export function availableGameTokensOnSectionResult(
  gameSection: GameSection | undefined,
  gi: DGameInstance | undefined
): GameToken[] {
  if (!gameSection?.gameTokens || !gi?.state.currentTeam) {
    return [];
  }

  const tokens = gameSection.gameTokens;
  const availTokens: GameToken[] = [];
  const allAcquiredTokens: GameToken[] = [];

  gi.state.teams.forEach((team) => {
    if (team.acquiredGameTokens) {
      team.acquiredGameTokens.forEach((tkn) => {
        allAcquiredTokens.push(tkn);
      });
    }
  });

  for (let i = 0; i < tokens.length; i++) {
    const element = tokens[i];
    if (
      !allAcquiredTokens?.find((token) => {
        return token.id === element.id;
      })
    ) {
      availTokens.push(element);
    }
  }
  return availTokens;
}

export function allGameTokensResult(
  gameBoard: CoopGameBoard | undefined
): GameToken[] {
  if (!gameBoard) {
    return [];
  }

  const tokens: GameToken[] = [];

  for (let i = 0; i < gameBoard.sections.length; i++) {
    const section = gameBoard.sections[i];
    if (section.gameTokens) {
      for (let j = 0; j < section.gameTokens.length; j++) {
        const token = section.gameTokens[j];
        tokens.push(token);
      }
    }
  }

  return tokens;
}

export function nextSectionResult(
  gi: GameInstance | undefined,
  currentSection: GameSection | undefined
): GameSection | undefined {
  if (!gi?.virtualGameboard) {
    return undefined;
  }

  const { sections } = gi.virtualGameboard;

  if (!currentSection) {
    if (sections.length > 0) {
      return sections[0];
    }
    return undefined;
  }

  let nextSection: GameSection | undefined;

  let currentSectionIndex = sections.findIndex(
    (sec) => sec.id === currentSection.id
  );

  if (sections.length > currentSectionIndex + 1) {
    nextSection = sections[currentSectionIndex + 1];
  }

  return nextSection;
}

export function hasNextSectionResult(
  gi: GameInstance | undefined,
  currentSection: GameSection | undefined
): boolean {
  if (!gi?.virtualGameboard || !currentSection) {
    return false;
  }

  const { sections } = gi.virtualGameboard;

  let currentSectionIndex = sections.findIndex(
    (sec) => sec.id === currentSection.id
  );

  if (sections.length > currentSectionIndex + 1) {
    return true;
  }

  return false;
}

export function thisTurnDiceRollResult(
  lastTurnChange: string | null | undefined
): number {
  if (!lastTurnChange) {
    return 1;
  }
  const ltcDate = new Date(lastTurnChange);
  const ltcTime = ltcDate.getSeconds();

  return (ltcTime % 6) + 1;
}

export function currentTeamPosToNextSectionResult(
  gi: DGameInstance | undefined
): number | undefined {
  const gePos = gi?.state.currentTeam?.gameboardPosition;
  const gs = gi?.virtualGameboard?.sections;

  if (!gePos || !gs) {
    return undefined;
  }

  let totalPos = 0;
  let posLeftInSection = 0;

  gs.forEach((s) => {
    // if totalpos + this section < gePos (increase totalpos)
    if (totalPos + s.tiles.length < gePos) {
      totalPos += s.tiles.length;
    } else {
      // find the position in the current section "ie 2",
      const posInSection = gePos - totalPos;
      // subtrack it from total s.tiles.length
      posLeftInSection = s.tiles.length - posInSection;
    }
  });
  // adding one, as its how many are left to next section
  return posLeftInSection + 1;
}
