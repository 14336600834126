import { APIErrorCreator } from './APIError';
export function* unpackResponse(endpoint: string, response: Response) {
  let responseData: object;

  try {
    responseData = yield response.json();
  } catch (e: any) {
    responseData = {};
  }

  if (response.ok) {
    return responseData;
  }

  throw new APIErrorCreator('Request failed', {
    status: response.status,
    url: endpoint,
    errorType: 'request',
    data: responseData,
  });
}
