/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'se';

export const se: RecursivePartial<DefaultLangKeys> = {
  label: 'Svenska',
  sagaTranslate: {
    id1: 'Det går inte att ladda spelet: Vi kan inte öppna spelet just nu. Vänligen försök igen senare.',
    id2: 'är inte ett giltigt spel-ID',
    id3: 'Något gick fel',
    id4: 'Det utdragna kortet finns inte',
    id5: 'Spel-ID kunde inte hittas',
    id6: 'Tyvärr, internet är lite långsamt just nu. Vänligen stanna medan vi behandlar din begäran igen',
    id7: 'Tyvärr fortfarande ingen tur. Vi försöker fortfarande ladda om spelet. Var god vänta ett ögonblick...',
    id8: 'Det finns för närvarande ingen tillgång till Internet. Anslut igen och försök igen.',
    id9: 'Ingen internetuppkoppling.',
    id10: 'Åtkomsttoken kan inte återställas.',
    id11: 'Du måste ladda spelet innan du kan stänga det.',
    id12: 'Spelet kunde inte stoppas',
    id13: 'Det här teamet kunde inte hittas',
    id14: 'Spelpjäsen kan inte flyttas eftersom den är utom räckhåll.',
    id15: 'Vi fick inte ett giltigt svar (svaret är ogiltigt)',
    id16: 'Det finns inga fler kort tillgängliga.',
    id17: 'Spelet kunde inte hittas',
    id18: 'Status kunde inte uppdateras',
    id19: 'Lagkamraterna leddes framgångsrikt till den sista reflektionen.',
    id20: 'Spelet har avslutats, gå till ett befintligt spel gå med för att gå med i ett nytt spel!',
    id21: 'Din reflektion slutfördes framgångsrikt',
    id22: 'Kort redigerat',
  },
  screens: {
    navigation: {
      signed: 'Inloggad som',
      home: 'Hem',
      createGame: 'Skapa ett spel',
      joinGame: 'Gå med i ett spelet',
      about: 'Om',
      logOut: 'Logga ut',
      game: 'Spel',
      settings: 'Inställningar',
    },
    home: {
      hi: 'Hej',
      welcome: 'Välkommen till Gaming4Impact i en webbläsare',
      goTo: 'Kör',
      goToGameCreation: 'Skapa ett nytt spel',
      goToJoinGame: 'Gå med i ett befintligt spel',
      yourRunningGames: 'Välj ett befintligt spel.',
      noRunningGames: 'Inget befintligt spel kunde hittas, du har följande alternativ:',
      login: 'Vänligen logga in',
      loginBtn: 'Logga in',
      buttonLogin: 'Logga in',
      gameowner: 'Game Master',
      created: 'Skapad',
      enterGameBtn: 'Gå in i spelet',
    },
    settings: {
      settingsHeader: 'Inställningar',
      avatar: 'Avatar',
      name: 'Namn',
      language: 'Språk',
      selectLanguage: 'Välj ditt språk',
      chooseNewImage: 'Välj en ny bild',
      playerIcon: 'Spelarikon',
      changeName: 'Byt namn',
      uploadImage: 'Ladda upp bild',
      email: 'Email',
      unknown: 'Unbekannt',
      environment: 'Miljö',
      environmentVariables: {
        buildShortTag: 'Skapa tagg',
        apiUrl: 'API-slutpunkt',
      },
    },
    gameCodes: {
      code: 'Kod',
      waitingForCode: 'Vänta tills koden genereras',
      startGame: 'Starta spelet',
    },
    joinGame: {
      title: 'Gå in i spelet',
      enterPin: 'Ange PIN-koden',
    },
    gameCreation: {
      team: 'TEAM',
      selectIconName: 'Välj en ikon och ett namn',
      createTeams: 'Skapa team',
      addTeam: 'Lägg till ett team',
      removeTeam: 'Ta bort ett team',
      submit: 'OK',
      enterTeamName: 'Välj lagets namn',
      selectGameName: 'Välj ett spelnamn',
      enterGameName: 'Ange spelets namn',
      header: 'Skapa ett spel',
      noRole: 'Tyvärr har du inte de nödvändiga rättigheterna för att skapa ett spel.',
      availableGames: 'Tillgängliga spel',
      summary: {
        summary: 'Sammanfattning',
        gameName: 'Spel namn',
        team: 'Team',
        template: 'Mall',
        description: 'Beskrivning',
        difficulty: 'Svårighetsgrad',
      },
      subHeaders: {
        gameName: 'Spel namn',
        gameTemplate: 'Spelmall',
        teams: 'Teams',
        summary: 'Sammanfattning',
      },
      progressBar: {
        inactive: 'Inaktiv',
        summary: 'Väntar',
        active: 'Aktiv',
        done: 'Avslutad',
      },
      createGameBtn: 'skapa ett spel',
      creationStep: 'steg',
      goBtn: 'Kör',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Min största potential',
      subTitle: 'Mina reflektioner',
      mainSubTitle: 'Min största potential',
      print: 'Skriv ut',
    },
    gamePage: {
      yesBtn: 'Ja',
      stillHereHeader: 'Är du där?',
      stillHereBody: 'Du har varit här länge. Spelar du fortfarande',
      gameEndedHeader: 'Spelet slutfört',
      gameEndedBody: 'Spelet har avslutats. Vi tar dig tillbaka till startsidan',
      notPartOfGameHeader: 'Tyvärr är du inte med i det här spelet',
      notPartOfGameBody: 'Gå tillbaka till hemsidan och klicka på gå med i spelet',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Rollspel',
          reflection: 'Reflektion',
          discussion: 'Gruppdiskussion',
          action: 'handling',
          challenge: 'Utmaning',
          teamChallenge: 'Team Utmaning',
        },
        waitForNewCard: 'Vänta tills Game Mastern har dragit ett nytt kort.',
        waitForNewCardHeader: 'Inget kort',
        enterReflection: 'Skriv ner dina reflektioner',
        submit: 'OK',
        guidance: 'Tips',
        replied: 'Svar',
        reflectionStateTextForGM: `Vi är nu i den sista fasen av eftertanke. 
        Vänta tills alla spelare har noterat sin huvudsakliga potential och har skrivit ut eller sparat sina reflektioner.
        Du kan sedan avsluta spelet genom att klicka på "Avsluta spel".`,
        roleplay: {
          myAssignment: 'Min uppgift:',
          myRole: 'Min roll:',
          noAssignment: 'Du har inte tilldelats någon roll',
          noRole: 'Ingen roll',
        },
        answerText: {
          youAnswered: 'Du har svarat',
          thisIs: 'Detta är',
          correct: 'rätt',
          incorrect: 'inte rätt',
          points: 'Poäng',
          guidance: 'Tips',
        },
      },
      teamCard: {
        score: 'Totalpoäng:',
        role: 'Roll:',
        gamemaster: 'Game Master',
        youreAboutToKickPlayer: 'Ta bort spelaren',
        areYouSure: 'Är du säker på att du vill ta bort lagkamraten?',
      },
      gmControls: {
        gameInfo: 'Spelinformation',
        ok: 'Ok',
        stopGame: 'Avsluta spelet',
        stopGameQuestion: 'Är du säker på att du vill avsluta spelet?',
        reflectionDeletion: ['Frågade du spelarna att skriva ut sina reflektioner?'],
        moveBack: 'Gå bakåt',
        moveForwards: 'Gå framåt',
        drawCard: 'Dra kort',
        showInfo: 'Visa information',
        accept: 'Hålla med',
        endGameReflectionsbtn: 'Slutliga reflektioner',
        gamename: 'Spel namn',
        gameId: 'Spel-ID',
        sectionId: 'Avsnitt-ID',
        sectionName: 'Namnavsnitt',
        availCards: 'Tillgängliga kort på högen',
        currentCardId: 'Aktuellt kort-ID',
        teams: 'Teams:',
        yes: 'Ja',
        notEveryOneHeader: 'Inte alla spelare har slutfört sin reflektion',
        notEveryOneContent: 'Är du säker på att du vill flytta spelpjäsen?',
        cardIsAlreadyDrawnBody: 'Du har redan dragit ett kort. Är du säker på att du vill dra ett nytt kort?',
        cardIsAlreadyDrawnHeader: 'Du har redan dragit ett kort',
      },
    },
    others: { closeBtn: 'Stänga av' },
    errors: {
      notLoggedIn: 'Du verkar inte vara inloggad. Klicka här för att logga in.',
      reloadPage: 'Klicka här för att ladda om sidan',
      goHome: 'Om problemet kvarstår, gå till startsidan och försök igen.',
      loginBtn: 'Logga in',
      homeBtn: 'Hem',
      reloadBtn: 'Ladda igen',
      login: 'Login',
    },
  },
};
