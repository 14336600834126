import { expandGameInstanceSummary } from '../utils/gameInstanceSummary';

export const filterRunningGameInstancesResult = (
  gis: GameInstanceSummary[] | undefined
): GameInstanceSummary[] | undefined => {
  if (gis) {
    const filteredList = gis.filter(
      (gi) => gi.status === ('running' || 'endgame')
    );
    return filteredList;
  }
  return gis;
};

export function dnormGameInstanceSummaryResult(
  summaries?: GameInstanceSummary[],
  me?: Player
): DGameInstanceSummary[] | undefined {
  if (summaries === undefined) {
    return undefined;
  }

  return summaries.map(expandGameInstanceSummary(me));
}

export const dnormFilterRunningGameInstancesResult = (
  gis: DGameInstanceSummary[] | undefined
): DGameInstanceSummary[] | undefined => {
  if (gis) {
    const filteredList = gis.filter(
      (gi) => gi.status === 'running' || gi.status === 'endgame'
    );
    return filteredList;
  }
  return gis;
};
