import { put, takeLatest, take, select } from 'redux-saga/effects';
import {
  APP_INITIALIZE_SUCCESS,
  APP_INITIALIZE_FAILURE,
  APP_ERROR_DETECTED,
  APP_INITIALIZE_REQUEST,
  ACCOUNT_USER_INFO_REQUEST,
  ACCOUNT_USER_INFO_SUCCESS,
  ACCOUNT_USER_INFO_FAILURE,
  AUTH0_REFRESH_ACCESSTOKEN_REQUEST,
  AUTH0_REFRESH_ACCESSTOKEN_SUCCESS,
  AUTH0_REFRESH_ACCESSTOKEN_FAILURE,
  AUTH_ACCESS_TOKEN_SET,
  APP_COMPANY_CODE_SET,
} from '../../actions';
import { LANGUAGE_CURRENT_TAG_UPDATE } from '../../../modules/LocalizedStrings/actions';
import { languageTagFromLangCode } from '../../../utils';
import { localize } from '../../selectors/localize';
import { useCompanyCodeHelper } from '../../../hooks';

// eslint-disable-next-line consistent-return
function* initializationRecipe() {
  // Call auth0
  /*
  const isAuthenticated =  yield select((state: AppState) => state.auth.isAuthenticated);
  if (isAuthenticated === false) {
    return yield put(APP_INITIALIZE_SUCCESS.create(true));
  }
  */
  try {
    yield put(AUTH0_REFRESH_ACCESSTOKEN_REQUEST.create(null));
    // @ts-ignore
    const accessTokensResult = yield take([
      AUTH0_REFRESH_ACCESSTOKEN_SUCCESS.type,
      AUTH0_REFRESH_ACCESSTOKEN_FAILURE.type,
    ]);
    if (accessTokensResult.type === AUTH0_REFRESH_ACCESSTOKEN_SUCCESS.type) {
      // Submit a request to fetch user account from ComPLAYance backend
      yield put(ACCOUNT_USER_INFO_REQUEST.create(null));
      // @ts-ignore
      const userAction = yield take([
        ACCOUNT_USER_INFO_SUCCESS.type,
        ACCOUNT_USER_INFO_FAILURE.type,
      ]);
      if (ACCOUNT_USER_INFO_SUCCESS.type === userAction.type) {
        // @ts-ignore
        return yield put(APP_INITIALIZE_SUCCESS.create(true));
      }
      // @ts-ignore
      return yield put(
        APP_INITIALIZE_FAILURE.create({
          name: 'initialize.failure',
          message: 'fetch user account failed',
        })
      );
    }

    const guestToken = localStorage.getItem('guestToken');
    if (guestToken && guestToken.length > 6) {
      yield put(
        AUTH_ACCESS_TOKEN_SET.create({ accessToken: guestToken, isGuest: true })
      );
      yield put(ACCOUNT_USER_INFO_REQUEST.create(null));
      // @ts-ignore
      const userAction = yield take([
        ACCOUNT_USER_INFO_SUCCESS.type,
        ACCOUNT_USER_INFO_FAILURE.type,
      ]);
      // @ts-ignore
      return yield put(APP_INITIALIZE_SUCCESS.create(true));
    }

    if (
      window.location.pathname.toLowerCase() !== '/' &&
      window.location.pathname.toLowerCase() !== '/join' &&
      window.location.pathname.toLowerCase() !== '/intro'
    ) {
      window.location.href = '/';
    }

    // @ts-ignore
    return yield put(APP_INITIALIZE_SUCCESS.create(true));
  } catch (error: any) {
    yield put(
      APP_INITIALIZE_FAILURE.create({
        name: 'initialize.failure',
        message: 'Error during intialization, user might not be logged in.',
      })
    );
  }
}

function* handleInitialization(action: GenericAction) {
  switch (action.type) {
    case APP_INITIALIZE_REQUEST.type: {
      // set company code if available
      const companyCode = useCompanyCodeHelper();
      yield put(APP_COMPANY_CODE_SET.create(companyCode));

      yield initializationRecipe();
      // @ts-ignore
      const languageOverwrite = yield select(
        (state: AppState) => state.app.languageOverwrite
      );
      if (languageOverwrite) {
        yield put(LANGUAGE_CURRENT_TAG_UPDATE.create(languageOverwrite));
      } else {
        const browserLanTag = languageTagFromLangCode(navigator.language);
        const availableLangs: LanguageInstance[] = yield select(
          localize.availableLanguages
        );
        const foundLanguage = availableLangs.some(
          (el) => el.tag === browserLanTag
        );
        if (foundLanguage === true) {
          yield put(LANGUAGE_CURRENT_TAG_UPDATE.create(browserLanTag));
        }
      }

      break;
    }
    case APP_INITIALIZE_FAILURE.type: {
      const isLoggedIn: boolean = yield select(
        (state: AppState) => state.auth.isAuthenticated
      );
      if (!isLoggedIn) {
        break;
      }
      yield put(
        APP_ERROR_DETECTED.create({
          action: APP_INITIALIZE_REQUEST.create(true),
        })
      );
      break;
    }
    default:
  }
}

export function* initializationSaga() {
  yield takeLatest(
    [APP_INITIALIZE_REQUEST.type, APP_INITIALIZE_FAILURE.type],
    handleInitialization
  );
}
