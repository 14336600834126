import React, { useCallback, useEffect, useState } from 'react';
import PopUpBox from './UI/PopUpBox';
import { StringsContext } from '../locales';

const lsKey = '@pco/cookiesAccepted';

enum ConsentState {
  accepted = 'accepted',
  declined = 'declined',
}

const CookieModal = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  const strings = React.useContext(StringsContext);

  useEffect(() => {
    const cState: ConsentState = localStorage.getItem(lsKey) as ConsentState;
    setCookiesAccepted(cState === ConsentState.accepted);
  }, []);

  const acceptCookies = useCallback(() => {
    localStorage.setItem('@pco/cookiesAccepted', ConsentState.accepted);
    setCookiesAccepted(true);
  }, []);

  return (
    <PopUpBox
      onAction={acceptCookies}
      show={!cookiesAccepted}
      header={strings.cookie.title}
      multipleLinesContent={strings.cookie.content}
      actionButtonText={strings.cookie.button}
      links={[
        'https://production.complayance.dk/cookies',
        'https://production.complayance.dk/privacy',
      ]}
      type="confirm"
    />
  );
};

export default CookieModal;
