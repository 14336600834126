/* eslint max-len: 0 */

import { ActionCreator } from 'dense-redux-actions';

interface MovePieceRequest {
  amount: number;
  teamId: number;
}

interface AllReflections {
  myReflections: MyReflection[] | undefined;
  endGameReflection: MyEndGameReflection | undefined;
}

export const ENGINE_INSTANCE_CLEAR_REQUEST = new ActionCreator<undefined>('ENGINE_INSTANCE_CLEAR_REQUEST');
export const ENGINE_INSTANCE_CLEAR_SUCCESS = new ActionCreator<undefined>('ENGINE_INSTANCE_CLEAR_SUCCESS');
export const ENGINE_INSTANCE_CLEAR_FAILURE = new ActionCreator<Error>('ENGINE_INSTANCE_CLEAR_FAILURE');

export const ENGINE_INSTANCE_LOAD_REQUEST = new ActionCreator<GameInstance>('ENGINE_INSTANCE_LOAD_REQUEST');
export const ENGINE_INSTANCE_LOAD_SUCCESS = new ActionCreator<GameEngine>('ENGINE_INSTANCE_LOAD_SUCCESS');

export const ENGINE_INSTANCE_STOP_REQUEST = new ActionCreator<{gameId: number}>('ENGINE_INSTANCE_STOP_REQUEST');
export const ENGINE_INSTANCE_STOP_SUCCESS = new ActionCreator<null>('ENGINE_INSTANCE_STOP_SUCCESS');
export const ENGINE_INSTANCE_STOP_FAILURE = new ActionCreator<Error>('ENGINE_INSTANCE_STOP_FAILURE');

export const ENGINE_MOVE_GAMEPIECE_REQUEST = new ActionCreator<MovePieceRequest>('ENGINE_MOVE_GAMEPIECE_REQUEST');
export const ENGINE_MOVE_GAMEPIECE_SUCCESS = new ActionCreator<Team[]>('ENGINE_MOVE_GAMEPIECE_SUCCESS');
export const ENGINE_MOVE_GAMEPIECE_FAILURE = new ActionCreator<Error>('ENGINE_MOVE_GAMEPIECE_FAILURE');

export const ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST = new ActionCreator<MovePieceRequest>('ENGINE_MOVE_GAMEPIECE_TOEND_REQUEST');
export const ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS = new ActionCreator<GameState>('ENGINE_MOVE_GAMEPIECE_TOEND_SUCCESS');
export const ENGINE_MOVE_GAMEPIECE_TOEND_FAILURE = new ActionCreator<Error>('ENGINE_MOVE_GAMEPIECE_TOEND_FAILURE');

export const ENGINE_DRAW_CARD_REQUEST = new ActionCreator<undefined>('ENGINE_DRAW_CARD_REQUEST');
export const ENGINE_DRAW_CARD_FAILURE = new ActionCreator<Error>('ENGINE_DRAW_CARD_FAILURE');
export const ENGINE_DRAW_CARD_SUCCESS = new ActionCreator<GameState>('ENGINE_DRAW_CARD_SUCCESS');

export const ENGINE_GAME_INSTANCE_HOLDER_UPDATE = new ActionCreator<GameInstance>('ENGINE_GAME_INSTANCE_HOLDER_UPDATE');

export const ENGINE_QUESTION_ANSWER_REQUEST = new ActionCreator<Reply>('ENGINE_QUESTION_ANSWER_REQUEST');
export const ENGINE_QUESTION_ANSWER_SUCCESS = new ActionCreator<{gameState: GameState, card: DCardInstance}>('ENGINE_QUESTION_ANSWER_SUCCESS');
export const ENGINE_QUESTION_ANSWER_FAILURE = new ActionCreator<Error>('ENGINE_QUESTION_ANSWER_FAILURE');

export const ENGINE_ACTIVE_CARD_SET = new ActionCreator<DCardInstance>('ENGINE_ACTIVE_CARD_SET');
export const ENGINE_ACTIVE_CARD_CLEAR = new ActionCreator<void>('ENGINE_ACTIVE_CARD_CLEAR');

export const ENGINE_STILL_HERE_ADD = new ActionCreator<void>('ENGINE_STILL_HERE_ADD');
export const ENGINE_STILL_HERE_RESET = new ActionCreator<void>('ENGINE_STILL_HERE_RESET');

export const ENGINE_INSTANCE_CLEAR = new ActionCreator<void>('ENGINE_INSTANCE_CLEAR');

export const ENGINE_TIMER_COUNTDOWN_SET = new ActionCreator<number>('ENGINE_TIMER_COUNTDOWN_SET');

export const ENGINE_PDF_OPEN = new ActionCreator<void>('ENGINE_PDF_OPEN');

export const ENGINE_REFLECTIONS_GET_REQUEST = new ActionCreator<{gameId: number}>('ENGINE_REFLECTIONS_GET_REQUEST');
export const ENGINE_REFLECTIONS_GET_SUCCESS = new ActionCreator<AllReflections>('ENGINE_REFLECTIONS_GET_SUCCESS');
export const ENGINE_REFLECTIONS_GET_FAILURE = new ActionCreator<Error>('ENGINE_REFLECTIONS_GET_FAILURE');

export const ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST = new ActionCreator<{text: string}>('ENGINE_REFLECTIONS_SUBMIT_FINAL_REQUEST');
export const ENGINE_REFLECTIONS_SUBMIT_FINAL_SUCCESS = new ActionCreator<void>('ENGINE_REFLECTIONS_SUBMIT_FINAL_SUCCESS');
export const ENGINE_REFLECTIONS_SUBMIT_FINAL_FAILURE = new ActionCreator<Error>('ENGINE_REFLECTIONS_SUBMIT_FINAL_FAILURE');

export const ENGINE_REFLECTIONS_ENDGAME_SEND_USERS = new ActionCreator<void>('ENGINE_REFLECTIONS_ENDGAME_SEND_USERS');

export const ENGINE_REMOVE_PLAYER_REQUEST = new ActionCreator<{playerId: number, teamId: number}>('ENGINE_REMOVE_PLAYER_REQUEST');
export const ENGINE_REMOVE_PLAYER_SUCCESS = new ActionCreator<Team[]>('ENGINE_REMOVE_PLAYER_SUCCESS');
export const ENGINE_REMOVE_PLAYER_FAILURE = new ActionCreator<Error>('ENGINE_REMOVE_PLAYER_FAILURE');

export const ENGINE_CHANGE_TURN_REQUEST = new ActionCreator<void>('ENGINE_CHANGE_TURN_REQUEST');
export const ENGINE_CHANGE_TURN_SUCCESS = new ActionCreator<GameState>('ENGINE_CHANGE_TURN_SUCCESS');
export const ENGINE_CHANGE_TURN_FAILURE = new ActionCreator<Error>('ENGINE_CHANGE_TURN_FAILURE');

export const ENGINE_BUY_TOKEN_REQUEST = new ActionCreator<void>('ENGINE_BUY_TOKEN_REQUEST');
export const ENGINE_BUY_TOKEN_SUCCESS = new ActionCreator<GameState>('ENGINE_BUY_TOKEN_SUCCESS');
export const ENGINE_BUY_TOKEN_FAILURE = new ActionCreator<Error>('ENGINE_BUY_TOKEN_FAILURE');

export const ENGINE_MOVE_GAME_SECTION_REQUEST = new ActionCreator<void>('ENGINE_MOVE_GAME_SECTION_REQUEST');
export const ENGINE_MOVE_GAME_SECTION_SUCCESS = new ActionCreator<GameState>('ENGINE_MOVE_GAME_SECTION_SUCCESS');
export const ENGINE_MOVE_GAME_SECTION_FAILURE = new ActionCreator<Error>('ENGINE_MOVE_GAME_SECTION_FAILURE');

export const ENGINE_ENDGAME_AWARDS_STATE_UPDATE = new ActionCreator<boolean>('ENGINE_ENDGAME_AWARDS_STATE_UPDATE');

export const ENGINE_VICTORY_POINTS_REQUEST = new ActionCreator<{val: number, teamId: number}>('ENGINE_VICTORY_POINTS_REQUEST');
export const ENGINE_VICTORY_POINTS_SUCCESS = new ActionCreator<GameState>('ENGINE_VICTORY_POINTS_SUCCESS');
export const ENGINE_VICTORY_POINTS_FAILURE = new ActionCreator<Error>('ENGINE_VICTORY_POINTS_FAILURE');

export const ENGINE_CREATE_CHALLENGE_REQUEST = new ActionCreator<undefined>('ENGINE_CREATE_CHALLENGE_REQUEST');
export const ENGINE_CREATE_CHALLENGE_SUCCESS = new ActionCreator<GameState>('ENGINE_CREATE_CHALLENGE_SUCCESS');
export const ENGINE_CREATE_CHALLENGE_FAILURE = new ActionCreator<Error>('ENGINE_CREATE_CHALLENGE_FAILURE');

export const ENGINE_FINISH_CHALLENGE_REQUEST = new ActionCreator<{won: boolean}>('ENGINE_FINISH_CHALLENGE_REQUEST');
export const ENGINE_FINISH_CHALLENGE_SUCCESS = new ActionCreator<undefined>('ENGINE_FINISH_CHALLENGE_SUCCESS');
export const ENGINE_FINISH_CHALLENGE_FAILURE = new ActionCreator<Error>('ENGINE_FINISH_CHALLENGE_FAILURE');