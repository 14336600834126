import BaseWallpaper from '../assets/wallpapers/base-wallpaper.webp';
import MMSLogo from '../assets/play.png';
import ERSTLogo from '../assets/erst-logo.png';
import ERSTWallpaper from '../assets/wallpapers/erst-wallpaper.webp';
import { LanguageTags } from '../locales';
export interface ContentTheme {
  id: 'mms' | 'erst';
  companyName?: string;
  primary: string;
  primaryText: string;
  background: string;
  buttonColors: {
    active: string;
    disabled: string;
    text: string;
    disabledText?: string;
    tokenBorder?: boolean;
  };
  navbar: {
    showCodes?: boolean;
  };
  showSectionColor: boolean;
  images: {
    logo?: string;
    wallpaper: string;
  };
  documentTitle?: string;
  defaultLanguage?: LanguageTags;
  content: {
    showRules?: boolean;
    showIntro?: boolean;
    showTeamCodesDropDown?: boolean;
    iconSet: 'basic' | 'retro';
    allowGuest?: boolean;
    // whether or not we want to show "you have no role" on roleplay cards
    showNoRole?: boolean;
    // If we want to end game or end game and show rtesults
    endgameResultsBtn?: boolean;
  };
  allowAllUsersToCreateGame?: boolean;
  gameInvitePath: '/JoinGame' | '/Join' | '';
  gamePlay: {
    moveToNewSectionEnabled: boolean;
  };
  contactDetails?: string;
}

export interface CompanyThemes {
  erst: ContentTheme;
  mms: ContentTheme;
}

export const companyThemes: CompanyThemes = {
  erst: {
    id: 'erst',
    companyName: 'Dataetisk Dilemmaspil',
    primary: '#262C34',
    primaryText: 'white',
    background: '#FFFFFF',
    buttonColors: {
      active: '#FFCA00',
      text: '#2E333B',
      disabled: '#ababab',
      disabledText: '#212121',
    },
    navbar: {
      showCodes: true,
    },
    showSectionColor: false,
    images: {
      wallpaper: ERSTWallpaper,
    },
    defaultLanguage: 'da',
    documentTitle: 'Dataetisk dilemmaspil',
    content: {
      showRules: true,
      showIntro: true,
      showTeamCodesDropDown: true,
      iconSet: 'retro',
      allowGuest: true,
      endgameResultsBtn: true,
    },
    allowAllUsersToCreateGame: true,
    gamePlay: {
      moveToNewSectionEnabled: true,
    },
    gameInvitePath: '/Join',
    contactDetails: 'support@complayance.dk',
  },

  mms: {
    id: 'mms',
    primary: '#086b61',
    primaryText: 'black',
    background: '#FFFFFF',
    buttonColors: {
      active: '#086b61',
      text: 'white',
      disabled: 'grey',
      tokenBorder: true,
    },
    showSectionColor: true,
    images: {
      logo: MMSLogo,
      wallpaper: BaseWallpaper,
    },
    navbar: {},
    content: {
      iconSet: 'basic',
      showNoRole: true,
    },
    gamePlay: {
      moveToNewSectionEnabled: false,
    },
    gameInvitePath: '',
  },
};
