import React from 'react';
import styles from './Loader.module.css';

const Spinner = require('react-spinkit');

export function Loader() {
  return (
    <div className={styles.container}>
      <Spinner name="pulse" fadeIn="none" />
    </div>
  );
}

export default Loader;
