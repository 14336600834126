/* eslint-disable max-len */
import { DefaultLangKeys } from '../en';

export const languageTag = 'fr';

export const fr: RecursivePartial<DefaultLangKeys> = {
  label: 'Français',
  sagaTranslate: {
    id1: 'Impossible de charger le jeu : Nous ne pouvons pas ouvrir le jeu pour le moment. Veuillez réessayer ultérieurement.',
    id2: 'n’est pas un identifiant de jeu valide.',
    id3: 'Un problème est survenu.',
    id4: 'La carte tirée n’existe pas.',
    id5: 'L’identifiant du jeu est introuvable.',
    id6: 'Désolé, la connexion Internet a quelques lenteurs. Merci de patienter pendant que nous relançons le traitement de votre demande.',
    id7: 'Désolé, le problème persiste. Nous sommes encore en train d’essayer de recharger le jeu. Merci de patienter encore quelques instants...',
    id8: 'Vous n’êtes pas connecté à Internet. Reconnectez-vous à Internet et refaites une tentative.',
    id9: 'Aucune connexion Internet.',
    id10: 'Impossible de réinitialiser le jeton d’accès.',
    id11: 'Vous devez charger le jeu avant de pouvoir le fermer.',
    id12: 'Impossible d’arrêter le jeu.',
    id13: 'Cette équipe est introuvable.',
    id14: 'Impossible de déplacer le pion ; il est hors de portée.',
    id15: 'Nous n’avons reçu aucune réponse valide (réponse non valide).',
    id16: 'Aucune autre carte disponible.',
    id17: 'Le jeu est introuvable.',
    id18: 'Impossible d’actualiser le statut.',
    id19: 'Les coéquipiers ont été guidés avec succès vers la réflexion finale.',
    id20: 'Le jeu est terminé ; accédez à un jeu existant pour rejoindre une nouvelle partie.',
    id21: 'Vous avez mené à bien votre réflexion.',
    id22: 'Carte traitée',
  },
  screens: {
    navigation: {
      signed: 'Connecté en tant que',
      home: 'Accueil',
      createGame: 'Créer le jeu',
      joinGame: 'Rejoindre le jeu',
      about: 'Via',
      logOut: 'Se déconnecter',
      game: 'Jeu',
      settings: 'Points de vue',
    },
    home: {
      hi: 'Bonjour',
      welcome: 'Bienvenue dans Gaming4Impact dans un navigateur.',
      goTo: 'C\'est parti.',
      goToGameCreation: 'Créer un nouveau jeu',
      goToJoinGame: 'Rejoindre un jeu existant',
      yourRunningGames: 'Sélectionnez un jeu existant.',
      noRunningGames: 'Aucun jeu en cours ; vous pouvez :',
      login: 'Connectez-vous.',
      loginBtn: 'Se connecter',
      buttonLogin: 'Se connecter',
      gameowner: 'meneur',
      created: 'Créé',
      enterGameBtn: 'Rejoindre le jeu',
    },
    settings: {
      settingsHeader: 'Points de vue',
      avatar: 'Avatar',
      name: 'Nom',
      language: 'Langue',
      selectLanguage: 'Sélectionner une langue',
      chooseNewImage: 'Sélectionner une nouvelle photo',
      playerIcon: 'Icône du joueur',
      changeName: 'Modifier le nom',
      uploadImage: 'Télécharger une photo',
      email: 'E-mail',
      unknown: 'Inconnu',
      environment: 'Environnement',
      environmentVariables: {
        buildShortTag: 'Créer une journée',
        apiUrl: 'Point final - API',
      },
    },
    gameCodes: {
      code: 'Code',
      waitingForCode: 'Patientez pendant la création du code.',
      startGame: 'Lancer le jeu',
    },
    joinGame: {
      title: 'Rejoindre le jeu',
      enterPin: 'Saisir le code PIN',
    },
    gameCreation: {
      team: 'ÉQUIPE',
      selectIconName: 'Sélectionnez un symbole et un nom.',
      createTeams: 'Créer une équipe',
      addTeam: 'Ajouter une équipe',
      removeTeam: 'Supprimer une équipe',
      submit: 'OK',
      enterTeamName: 'Choisir le nom de l’équipe',
      selectGameName: 'Choisissez le nom du jeu.',
      enterGameName: 'Saisissez le nom du jeu.',
      header: 'Créer le jeu',
      noRole: 'Désolé, mais vous ne disposez pas des droits permettant de créer un jeu.',
      availableGames: 'Jeux disponibles',
      summary: {
        summary: 'Résumé',
        gameName: 'Nom du jeu',
        team: 'Équipe',
        template: 'Modèle',
        description: 'Description',
        difficulty: 'Niveau de difficulté',
      },
      subHeaders: {
        gameName: 'Nom du jeu',
        gameTemplate: 'Modèle de jeu',
        teams: 'Équipes',
        summary: 'Résumé',
      },
      progressBar: {
        inactive: 'Vue d’ensemble',
        summary: 'Patienter',
        active: 'En cours de traitement',
        done: 'Terminé',
      },
      createGameBtn: 'Créer le jeu',
      creationStep: 'Étape',
      goBtn: 'C\'est parti.',
    },
    reflectionPage: {
      submit: 'OK',
      mainReflectionCardPlaceholder: 'Mon potentiel principal',
      subTitle: 'Mes réflexions',
      mainSubTitle: 'Mon potentiel principal',
      print: 'Imprimer',
    },
    gamePage: {
      yesBtn: 'Oui',
      stillHereHeader: 'Vous êtes toujours là ?',
      stillHereBody: 'Cela fait très longtemps que vous êtes ici. Vous êtes encore en train de jouer ?',
      gameEndedHeader: 'Jeu terminé',
      gameEndedBody: 'Le jeu est terminé. Vous allez être redirigé vers la page d’accueil.',
      notPartOfGameHeader: 'Désolé, vous ne pouvez pas participer à ce jeu.',
      notPartOfGameBody: 'Revenez à la page d’accueil et cliquez sur Rejoindre le jeu.',
      okBtn: 'OK',
      activeCard: {
        cardTypes: {
          rolePlay: 'Jeu de rôles',
          reflection: 'Réflexion',
          discussion: 'Discussion de groupe',
          action: 'Action',
          challenge: 'Challenge',
          teamChallenge: 'Équipe Challenge',
        },
        waitForNewCard: 'Veuillez attendre que le meneur ait tiré une nouvelle carte.',
        waitForNewCardHeader: 'Aucune carte',
        enterReflection: 'Notez vos réflexions.',
        submit: 'OK',
        guidance: 'Remarques',
        replied: 'Réponse',
        reflectionStateTextForGM: `Nous avons à présent atteint la dernière phase de notre réflexion. 
        Veuillez attendre que tous les joueurs aient noté leur principal potentiel et imprimé ou enregistré leurs réflexions. 
        Vous pourrez ensuite mettre fin au jeu en cliquant sur « Terminer le jeu ».`,
        roleplay: {
          myAssignment: 'Ma tâche :',
          myRole: 'Mon rôle :',
          noAssignment: 'Aucun rôle ne vous a été affecté.',
          noRole: 'Aucun rôle',
        },
        answerText: {
          youAnswered: 'Vous avez répondu',
          thisIs: 'C’est',
          correct: 'exact',
          incorrect: 'inexact',
          points: 'Points',
          guidance: 'Remarques',
        },
      },
      teamCard: {
        score: 'Nombre total de points :',
        role: 'Rôle :',
        gamemaster: 'meneur',
        youreAboutToKickPlayer: 'Supprimer un joueur',
        areYouSure: 'Êtes-vous sûr de vouloir supprimer ce joueur ?',
      },
      gmControls: {
        gameInfo: 'Informations relatives au jeu',
        ok: 'OK',
        stopGame: 'Terminer le jeu',
        stopGameQuestion: 'Êtes-vous sûr de vouloir mettre fin au jeu ?',
        reflectionDeletion: ['Avez-vous demandé aux joueurs d’imprimer leurs réflexions ?'],
        moveBack: 'Reculer',
        moveForwards: 'Avancer',
        drawCard: 'Tirer une carte',
        showInfo: 'Afficher les informations',
        accept: 'Accepter',
        endGameReflectionsbtn: 'Dernières réflexions',
        gamename: 'Nom du jeu',
        gameId: 'ID du jeu',
        sectionId: 'ID de la section',
        sectionName: 'Nom de la section',
        availCards: 'Cartes disponibles sur la pile',
        currentCardId: 'ID de la carte actuelle',
        teams: 'Équipes :',
        yes: 'Oui',
        notEveryOneHeader: 'Les joueurs ne sont pas tous arrivés au bout de leurs réflexions.',
        notEveryOneContent: 'Êtes-vous sûr de vouloir faire avancer ce pion ?',
        cardIsAlreadyDrawnBody: 'Vous avez déjà tiré une carte. Êtes-vous sûr de vouloir tirer une nouvelle carte ?',
        cardIsAlreadyDrawnHeader: 'Vous avez déjà tiré une carte.',
      },
    },
    others: { closeBtn: 'Fermer' },
    errors: {
      notLoggedIn: 'Il semblerait que vous ne soyez pas connecté. Cliquez ici pour vous connecter.',
      reloadPage: 'Cliquez ici pour actualiser la page.',
      goHome: 'Si le problème persiste, veuillez revenir à la page d’accueil et refaites une tentative.',
      loginBtn: 'Se connecter',
      homeBtn: 'Accueil',
      reloadBtn: 'Actualiser',
      login: 'Connexion',
    },
  },
};
