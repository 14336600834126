import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TeamCodesDropdown from './components/TeamCodesDropdown';
import { TOAST_NOTIFICATION_OPEN } from '../../../redux/actions';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<GenericAction>) => ({
  onCopy: (message: string) =>
    dispatch(
      TOAST_NOTIFICATION_OPEN.create({
        content: message,
        appearance: 'info',
        autoDismiss: true,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamCodesDropdown);
