export const timings = {
  xxshort: 50,
  xshort: 100,
  short: 200,
  medium: 300,
  long: 500,
  xlong: 1000,
  xxlong: 2000,
  xxxlong: 4000,
  pollLong: 30000,
};

export const spacing = {
  small: 15,
  middle: 25,
  large: 35,
};

export const iconSizes = {
  tiny: 15,
  small: 25,
  mediumSmall: 35,
  medium: 50,
  // The height of the tokens field
  tokenHeight: /iPhone|iPod|Android/i.test(navigator.userAgent) ? 40 : 80,
};

export const mainColors = {
  green: '#00BF0F',
  brightGreen: '#00A58D',
  blue: '#408fd6',
  red: '#D3083F',
  brightRed: '#FF1C1C',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#D4D4D4',
  lightGrey: '#f5f5f5',
  darkGrey: '#a9a9a9',
};
