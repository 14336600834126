import React from 'react';
import { ForkEffect } from 'redux-saga/effects';
import merge from 'deepmerge';

import { createLangReducer, LangState } from './reducers';
import { languageStringsListenerCreator } from './sagas';

import {
  createLocalizedStringsProvider,
  changeLocale,
  getCurrentTranslations,
  getStringTranslationUnsafe,
} from './Providers';

interface Props<T, P> {
  initialTranslations: {
    [tag: string]: P | RecursivePartial<P>;
  };
  defaultLanguageTag: T;
}

export class LocalizedStrings<LanguageTags, DefaultTranslation> {
  private static _availableLanguages(translations: { [tag: string]: any }): {
    [tag: string]: string;
  } {
    const keys = Object.keys(translations);

    const labels: { [tag: string]: string } = {};

    for (const tag of keys) {
      labels[tag] = translations[tag].label ?? tag;
    }

    return labels;
  }

  public setLocale: (tag: LanguageTags) => void;

  public getStringsUnsafe: () => DefaultTranslation;

  private translations: any;

  public getLocales = (): { [tag: string]: string } => {
    return LocalizedStrings._availableLanguages(this.translations);
  };

  public Context: React.Context<DefaultTranslation>;

  public Consumer: React.Consumer<DefaultTranslation>;

  public langReducer: (
    state: LangState<LanguageTags> | undefined,
    action: GenericAction
  ) => LangState<LanguageTags>;

  public updateTranslations = (
    translationPatch: RecursivePartial<{
      [tag: string]: DefaultTranslation;
    }>
  ) => {
    const oldTranslations = JSON.parse(JSON.stringify(this.translations));
    const patchedTranslations = merge(oldTranslations, translationPatch);

    this.updateTranslations(patchedTranslations);
  };

  public Producer: (props: { children: React.ReactNode }) => JSX.Element;

  public langProducerRef: any;

  public langSaga: () => Generator<ForkEffect<never>, void, unknown>;

  constructor(props: Props<LanguageTags, DefaultTranslation>) {
    this.Context = React.createContext<DefaultTranslation>(
      props.initialTranslations[
        `${props.defaultLanguageTag}`
      ] as DefaultTranslation
    );

    const { Provider, Consumer } = this.Context;

    this.Producer = createLocalizedStringsProvider<
      LanguageTags,
      DefaultTranslation
    >(Provider, {
      translations: props.initialTranslations,
      defaultLanguageTag: props.defaultLanguageTag,
    });

    this.setLocale = changeLocale();
    this.Consumer = Consumer;
    this.translations = getCurrentTranslations();
    this.getStringsUnsafe = getStringTranslationUnsafe;

    this.langReducer = createLangReducer<LanguageTags>({
      currentLanguageTag: props.defaultLanguageTag,
      availableLanguages: LocalizedStrings._availableLanguages(
        props.initialTranslations
      ),
      translationVersion: 0,
    });
    this.langSaga = languageStringsListenerCreator();
  }
}
