import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../../../../../App';
import Button from '../../../../../../components/UI/Button';
import Markdown from '../../../../../../components/UI/Markdown';
import { StringsConsumer } from '../../../../../../locales';
import { mainColors } from '../../../../../../utils';

interface Props {
  isModal?: boolean;
  onAnswer: (reply: Reply) => void;
  activeCard: DCardInstance | undefined;
  currentTeam: DTeam | undefined;
  reflectionText: string;
  updateReflectionText: (text: string) => void;
  isGameOwner: boolean | undefined;
}

function ReflectionCard(props: Props) {
  const profile = useSelector((state: AppState) => state.account.profile);
  const { reflectionText } = props;
  const handleClick = () => {
    if (
      reflectionText !== '' &&
      props.activeCard &&
      props.currentTeam &&
      profile
    ) {
      const answerObject: Reply = {
        type: 'answer',
        cardId: props.activeCard.id,
        answerId: null,
        teamId: props.currentTeam.id,
        playerId: profile.id,
        answerText: reflectionText,
      };
      props.onAnswer(answerObject);
    }
  };
  React.useEffect(() => {
    if (!props.isModal) {
      props.updateReflectionText('');
    }
  }, [props.activeCard?.id]);

  const theme = React.useContext(ThemeContext);

  const buttonBackgroundColor = theme.buttonColors.active;

  return (
    <StringsConsumer>
      {({ screens: { gamePage } }) => {
        return (
          <div>
            {props.isGameOwner ? (
              <Markdown fontSize="16px" style={{ whiteSpace: 'pre-wrap' }}>
                {props.activeCard?.guidance}
              </Markdown>
            ) : (
              <div>
                {props.activeCard?.isMyTurn && (
                  <textarea
                    style={{
                      width: '100%',
                      resize: 'none',
                      border: 'none',
                      height: '100%',
                      padding: '10px',
                      color: mainColors.black,
                      backgroundColor: mainColors.lightGrey,
                    }}
                    className="reflectionCardPlaceholder"
                    rows={10}
                    value={reflectionText}
                    onChange={(e) => props.updateReflectionText(e.target.value)}
                    placeholder={`${gamePage.activeCard.enterReflection}...`}
                    readOnly={props.activeCard?.isPendingMyReply === false}
                  />
                )}
              </div>
            )}
            {!props.isModal &&
              props.isGameOwner !== true &&
              props.activeCard?.isMyTurn && (
                <Button
                  title={gamePage.activeCard.submit}
                  onClick={handleClick}
                  style={{
                    marginTop: '18px',
                    float: 'right',
                    marginBottom: '18px',
                  }}
                  disabled={
                    reflectionText === '' ||
                    props.activeCard?.isPendingMyReply === false
                  }
                  backgroundColor={buttonBackgroundColor}
                />
              )}
          </div>
        );
      }}
    </StringsConsumer>
  );
}

export default ReflectionCard;
